import { Box, useTheme } from "@mui/material";
import { ColDef, GetRowIdParams, ValueFormatterParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { rounder } from "Helpers/rounder";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import { v4 as uuidv4 } from 'uuid';
import { useGetResourceCategoriesQuery } from "State/Services/resource-category";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import { ItemPriceListReport, ItemPriceListReportView } from "Models/item-list-report";

export interface ItemPriceBreakdownByResourceCategoryPercentageProps {
    estimateId: string | undefined;
    itemBreakDownReport: {
        results: Array<ItemPriceListReport>;
        categories: Array<string>;
    }
}

export default function ItemPriceBreakdownByResourceCategoryPercentage(props: ItemPriceBreakdownByResourceCategoryPercentageProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<ItemPriceListReportView>>(null);
    const [rowData, setRowData] = useState<Array<ItemPriceListReportView>>([]);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: storedResourceCategories } = useGetResourceCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId, refetchOnMountOrArgChange: true })

    useEffect(() => {
        if (props.itemBreakDownReport && props.itemBreakDownReport.results.length > 0) {
            const rowData = props.itemBreakDownReport.results.map((result) => ({
                ...result,
                unit: {
                    unitDescription: result.customUnit,
                    unitId: result.unitId
                },
                id: uuidv4()
            })) as Array<ItemPriceListReportView>;
            setRowData(rowData);
        }
    }, [props.itemBreakDownReport])

    const getBaseDefs = useCallback((): Array<ColDef<ItemPriceListReportView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                resizable: true,
                pinned: true,
                headerName: 'ID',
                width: 100,
                cellDataType: "text",
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
                cellRendererParams: {
                    suppressCount: true,
                }
            },
            {
                field: 'description',
                width: 300,
                pinned: true,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                pinned: true,
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalQuantity',
                headerName: 'Quantity',
                pinned: true,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 160,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unitPrice',
                width: 160,
                pinned: true,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'price',
                headerName: 'Amount',
                pinned: true,
                aggFunc: params => {
                    if (params.rowNode.level >= 0) {
                        return params?.data?.price;
                    }
                    let total = 0;
                    params.values.forEach(value => total += value);
                    return total;
                },
                width: 160,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                }
            },
        ];
    }, [colors?.gray, settings?.quantityDecimals, estimate?.CompanyCurrency?.Currency?.minorUnit])

    const [columnDefs, setColumnDefs] = useState<ColDef[]>();

    const calculatePercentage = useCallback((params: ValueFormatterParams<ItemPriceListReportView, any>) => {
        if (params.node?.footer) return '';
        const percentage = (parseFloat(params.value?.toString() ?? '0') / parseFloat(params.data?.price?.toString() ?? '1')) * 100;
        return `${percentage ? rounder(percentage, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2) : '0'}%`
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit])

    const setupGrid = useCallback(async () => {
        const cols = getBaseDefs();
        const sortedCategoryIds = props.itemBreakDownReport.categories
        .map(id => ({
            id,
            displayId: storedResourceCategories?.find(cat => cat.id === id)?.displayId || ''
        }))
        .sort((a, b) => a.displayId.localeCompare(b.displayId))
        .map(item => item.id);
        let subCat: ColDef | null = null;
        for (let i = 0; i < sortedCategoryIds.length; i++) {
            const categoryId = sortedCategoryIds[i];
            if (categoryId === 'Subcontractor') {
                subCat = {
                    field: categoryId as any,
                headerValueGetter: () => {
                    return 'Subcontractor';
                },
                width: 175,
                valueFormatter: (params) => calculatePercentage(params),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
                };
                continue;
            }
            cols.push({
                field: categoryId as any,
                headerValueGetter: () => {
                    return (storedResourceCategories?.find(r => r.id === categoryId)?.description ?? '');
                },
                width: 175,
                valueFormatter: (params) => calculatePercentage(params),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            });
        }
        if (subCat) {
            cols.push(subCat);
        }
        cols.push({
            field: 'markup',
            width: 175,
            valueFormatter: (params) => calculatePercentage(params),
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellDataType: "number",
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        });
        setColumnDefs(cols);
    }, [calculatePercentage, colors?.gray, getBaseDefs, props.itemBreakDownReport.categories, storedResourceCategories])

    useEffect(() => {
        setupGrid();
    }, [setupGrid])

    const getRowId = useCallback(function (params: GetRowIdParams<ItemPriceListReportView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    return <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
        <AgGridReact<ItemPriceListReportView>
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            paginationPageSize={50}
            pagination={true}
            groupDefaultExpanded={-1}
            grandTotalRow="bottom"
            getDataPath={(data: any) => (data.hierarchy)}
        />
    </Box>
}