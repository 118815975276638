import { Box, TextField } from "@mui/material";
import { BaseFileMetadata, FileDetails, FileMetadata } from "Models/file";
import * as yup from 'yup';
import { useFormik } from "formik";
import { useCreateFileMetadataMutation, useGetFileMetadataQuery, useUpdateFileMetadataMutation } from "State/Services/file";
import { useGetUserDetailsQuery } from "State/Services/user";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
export interface ColumnMappingProps {
    fileDetails: FileDetails;
    estimateId: string;
    onColumnMapping: (fileMetadata: FileMetadata) => void;
    onValidate: (isValid: boolean) => void;
    isVariation: boolean;
}

const fileMetadataValidationSchema = yup.object<BaseFileMetadata>({
    itemColumnName: yup
        .string()
        .optional(),
    descriptionColumnName: yup
        .string()
        .required('Description column mapping is required'),
    unitColumnName: yup
        .string()
        .optional(),
    quantityColumnName: yup
        .string()
        .optional(),
    rateColumnName: yup
        .string()
        .optional(),
    amountColumnName: yup
        .string()
        .optional(),
    pageDelimiter: yup
        .string()
        .optional(),
    estQuantityColumnName: yup
        .string()
        .optional(),
    itemCodeColumnName: yup
        .string()
        .optional(),
});

export const ColumnMapping = forwardRef((props: ColumnMappingProps, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: fileMetadataInfo } = useGetFileMetadataQuery(
        {
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            estimateId: (props && props.estimateId) ? props.estimateId : '',
            fileId: (props && props?.fileDetails?.id) ? props.fileDetails.id : ''
        },
        { skip: !user?.companyId || !user?.organizationId });
    const [fileMetadata, setFileMetadata] = useState<FileMetadata>();
    const [createFileMetadata] = useCreateFileMetadataMutation();
    const [updateFileMetadata] = useUpdateFileMetadataMutation();

    const formik = useFormik<FileMetadata>({
        enableReinitialize: true,
        initialValues: fileMetadata ?? {
            descriptionColumnName: props.isVariation ? 'Description' : '',
            fileId: '',
            id: '',
            itemColumnName: props.isVariation ? 'Item' : '',
            quantityColumnName: props.isVariation ? 'Quantity' : '',
            unitColumnName: props.isVariation ? 'Unit' : '',
            amountColumnName: props.isVariation ? 'Amount' : '',
            rateColumnName: props.isVariation ? 'Rate' : '',
            pageDelimiter: '',
            estQuantityColumnName: props.isVariation ? 'Estimated Quantity' : '',
            itemCodeColumnName: props.isVariation ? 'Code' : ''
        },
        validationSchema: fileMetadataValidationSchema,
        onSubmit: async (values) => {
            if (values) {
                let fileMetadata: FileMetadata;
                if (values.id) {
                    fileMetadata = await updateFileMetadata({
                        id: values.id,
                        organizationId: user?.organizationId,
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        fileId: props.fileDetails.id,
                        fileMetadata: {
                            descriptionColumnName: values.descriptionColumnName,
                            itemColumnName: values.itemColumnName,
                            quantityColumnName: values.quantityColumnName,
                            unitColumnName: values.unitColumnName,
                            amountColumnName: values.amountColumnName,
                            rateColumnName: values.rateColumnName,
                            pageDelimiter: values.pageDelimiter,
                            estQuantityColumnName: values.estQuantityColumnName,
                            itemCodeColumnName: values.itemCodeColumnName
                        }
                    }).unwrap();
                    props.onColumnMapping(fileMetadata);
                } else {
                    fileMetadata = await createFileMetadata({
                        organizationId: user?.organizationId,
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        fileId: props.fileDetails.id,
                        fileMetadata: {
                            descriptionColumnName: values.descriptionColumnName,
                            itemColumnName: values.itemColumnName,
                            quantityColumnName: values.quantityColumnName,
                            unitColumnName: values.unitColumnName,
                            amountColumnName: values.amountColumnName,
                            rateColumnName: values.rateColumnName,
                            pageDelimiter: values.pageDelimiter,
                            estQuantityColumnName: values.estQuantityColumnName,
                            itemCodeColumnName: values.itemCodeColumnName
                        }
                    }).unwrap();
                    props.onColumnMapping(fileMetadata);
                }
            }
        },
    });

    useEffect(() => {
        if (props.isVariation){
            props.onValidate(formik.isValid);
        }
        else if (Object.keys(formik.touched).length > 0) {
            props.onValidate(formik.isValid);
        }
    }, [formik.isValid, formik.touched, props])

    useImperativeHandle(
        ref,
        () => ({
            saveMappings() {
                formik.handleSubmit();
            }
        }),
    )

    useEffect(() => {
        if (fileMetadataInfo) {
            setFileMetadata(fileMetadataInfo);
            props.onValidate(formik.isValid);
        }
    }, [fileMetadata, fileMetadataInfo, formik.isValid, props])

    return <Box margin="20px" alignItems="center" justifyContent="center">
        <form noValidate onSubmit={formik.handleSubmit}>
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="itemColumnName"
                name="itemColumnName"
                label="Item Column Name"
                value={formik.values.itemColumnName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.itemColumnName && Boolean(formik.errors.itemColumnName)}
                helperText={formik.touched.itemColumnName && formik.errors.itemColumnName}
            />
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="descriptionColumnName"
                name="descriptionColumnName"
                label="Description Column Name"
                value={formik.values.descriptionColumnName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.descriptionColumnName && Boolean(formik.errors.descriptionColumnName)}
                helperText={formik.touched.descriptionColumnName && formik.errors.descriptionColumnName}
            />
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="unitColumnName"
                name="unitColumnName"
                label="Unit Column Name"
                value={formik.values.unitColumnName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.unitColumnName && Boolean(formik.errors.unitColumnName)}
                helperText={formik.touched.unitColumnName && formik.errors.unitColumnName}
            />
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="quantityColumnName"
                name="quantityColumnName"
                label="Quantity Column Name"
                value={formik.values.quantityColumnName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.quantityColumnName && Boolean(formik.errors.quantityColumnName)}
                helperText={formik.touched.quantityColumnName && formik.errors.quantityColumnName}
            />
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="estQuantityColumnName"
                name="estQuantityColumnName"
                label="Estimated Quantity Column Name"
                value={formik.values.estQuantityColumnName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.estQuantityColumnName && Boolean(formik.errors.estQuantityColumnName)}
                helperText={formik.touched.estQuantityColumnName && formik.errors.estQuantityColumnName}
            />
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="itemCodeColumnName"
                name="itemCodeColumnName"
                label="Item Code Column Name"
                value={formik.values.itemCodeColumnName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.itemCodeColumnName && Boolean(formik.errors.itemCodeColumnName)}
                helperText={formik.touched.itemCodeColumnName && formik.errors.itemCodeColumnName}
            />
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="rateColumnName"
                name="rateColumnName"
                label="Rate Column Name"
                value={formik.values.rateColumnName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.rateColumnName && Boolean(formik.errors.rateColumnName)}
                helperText={formik.touched.rateColumnName && formik.errors.rateColumnName}
            />
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="amountColumnName"
                name="amountColumnName"
                label="Amount Column Name"
                value={formik.values.amountColumnName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.amountColumnName && Boolean(formik.errors.amountColumnName)}
                helperText={formik.touched.amountColumnName && formik.errors.amountColumnName}
            />
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="pageDelimiter"
                name="pageDelimiter"
                label="Page Delimiter"
                value={formik.values.pageDelimiter}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.pageDelimiter && Boolean(formik.errors.pageDelimiter)}
                helperText={formik.touched.pageDelimiter && formik.errors.pageDelimiter}
            />
        </form>
    </Box>
})