import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { usePostHog } from "posthog-js/react";

export default function PostHogPageView() {
  let location = useLocation();
  const posthog = usePostHog();

  // Track pageviews
  useEffect(() => {
    if (posthog) {
      posthog.capture(
        '$pageview',
        {
          '$current_url': window.location.href,
        }
      )
    }
  }, [location, posthog])
  
  return null
}