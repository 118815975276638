import { Alert, AlertTitle, Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useMemo, useState } from "react";
import { tokens } from "theme";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useImportResourcesMutation } from "State/Services/resource";
import { ColDef } from "ag-grid-enterprise";
import { ErroredImportResource } from "Models/resource";
import { rounder } from "Helpers/rounder";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { AgGridReact } from "ag-grid-react";

export interface ImportResourcesModalProps {
    estimateId?: string;
    close: () => void;
}

export default function ImportResourcesModal(props: ImportResourcesModalProps) {
    const [colors, setColors] = useState<any>();
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 48px)', width: '100%' }), []);
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [importResources] = useImportResourcesMutation();
    const [isUploading, setIsUploading] = useState(false);
    const [pageError, setPageError] = useState<string>();
    const [erroredImportResources, setErroredImportResources] = useState<Array<ErroredImportResource>>([]);
    const {
        open,
        acceptedFiles, getInputProps } = useDropzone({
            // Disable click and keydown behavior
            noClick: true,
            noKeyboard: true,
            accept: {
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
            }
        });
    const [columnDefs] = useState<ColDef<ErroredImportResource>[]>([
        {
            field: 'displayId',
            resizable: true,
            headerName: 'ID',
            hide: false,
            sort: 'asc',
            width: 95,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
        },
        {
            field: 'description',
            flex: 0.4,
            resizable: true,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'subcategoryDisplayId',
            headerName: 'Subcategory',
            width: 120,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            resizable: true,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'unit',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            resizable: true,
            width: 75,
            headerName: 'Unit',
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'basicRate',
            width: 120,
            valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            resizable: true,
            headerName: 'Basic Rate',
            cellDataType: "number",
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'factor',
            width: 90,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            resizable: true,
            valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
            headerName: 'Factor',
            cellDataType: "number",
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'reason',
            flex: 1,
            autoHeight: true,
            resizable: true,
            wrapText: true,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}`, color: 'red' }
        },
    ]);

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const formData = new FormData();
            if (acceptedFiles) {
                formData.append("file", acceptedFiles[0]);
                if (user && user.companyId && props.estimateId) {
                    setIsUploading(true);
                    importResources({
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        fileData: formData,
                        organizationId: user?.organizationId
                    }).unwrap().then((erroredImportResources) => {
                        if (erroredImportResources && erroredImportResources.length > 0) {
                            setErroredImportResources(erroredImportResources);
                        } else {
                            props.close();
                        }
                    }).catch((error) => {
                        console.log(error);
                        if ('data' in error) {
                            setPageError(error.data.message);
                        }
                    }).finally(() => {
                        setIsUploading(false);
                    });
                }
            }
        }
    }, [acceptedFiles, importResources, props, props.estimateId, user])

    return <>
        {pageError && <Box width="100%" padding="5px">
            <Alert severity="error">
                <AlertTitle>Error:</AlertTitle>
                {pageError && <Box>{pageError}</Box>}
            </Alert>
        </Box>}
        <Box height="100%" display="flex" alignItems="center" justifyContent="center" margin="25px 50px">
            {isUploading && <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                <CircularProgress color="primary" />
            </Box>
            }
            {!isUploading && erroredImportResources.length === 0 && <Box display="flex" flexDirection="column" marginBottom="10px" alignItems="center" justifyContent="center">
                <Typography variant="h5" sx={{ color: `${colors?.primary[300]}` }}>Select a file to import</Typography>
                <input {...getInputProps()} />
                <Button type="button" color="primary" variant='contained' onClick={open} startIcon={<CloudUploadIcon />}>
                    Browse
                </Button>
            </Box>}
            {erroredImportResources.length > 0 && <Box height="500px" width="80vw" display="flex" flexDirection="column" marginBottom="10px" alignItems="center" justifyContent="center">
                <Box className="ag-theme-alpine ag-theme-bidbow inner-col"
                    style={gridStyle}>
                    <AgGridReact<ErroredImportResource>
                        animateRows={false}
                        columnDefs={columnDefs}
                        rowData={erroredImportResources}
                    />
                </Box>
            </Box>}
        </Box></>
}