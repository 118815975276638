import { Alert, Box, Button, IconButton, Modal, Typography, useTheme } from "@mui/material";
import { VendorView } from "Models/vendor";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useCreateVendorMutation, useDeleteVendorMutation, useExportImportVendorSampleMutation, useGetVendorsQuery, useLazyGetMaxVendorIdQuery, usePushVendorToTemplateMutation, useSyncVendorMutation, useUpdateVendorMutation } from "State/Services/vendor";
import { CellClickedEvent, CellKeyDownEvent, GetRowIdParams, GridReadyEvent, ICellEditor, RowEditingStartedEvent, RowEditingStoppedEvent, SelectionChangedEvent, SelectionColumnDef, SuppressKeyboardEventParams } from "ag-grid-community";
import { ColDef, IRowNode } from "ag-grid-enterprise";
import { AgGridReact, getInstance } from "ag-grid-react";
import { useConfirm } from "material-ui-confirm";
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import CloseIcon from '@mui/icons-material/Close';
import { tokens } from "theme";
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import VendorTypeEditCellRenderer from "./VendorTypeEditCellRenderer";
import VendorTagEditCellRenderer from "./VendorTagEditCellRenderer";
import VendorTagCellRenderer from "./VendorTagCellRenderer";
import VendorFilters, { VendorFilterSettings } from "./VendorFilters";
import { intersectionWith } from "lodash";
import EditIcon from '@mui/icons-material/Edit';
import VendorEdit from "./VendorEdit";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { useGetEstimateQuery } from "State/Services/estimate";
import { ServerError } from "Models/error-info";
import { Errors } from "Models/errors";
import OutOfSyncCellRenderer from "Components/Shared/OutOfSyncCellRender";
import GenericTextEditCellRenderer from "Components/Shared/GenericTextEditCellRenderer";
import GenericActionsCellRenderer from "Components/Shared/GenericActionsEditCellRenderer";
import { BaseEditCellRenderer } from "Models/grid";
import VendorIdEditCellRenderer from "./VendorIdEditCellRenderer";
import ImportVendorsModal from "./ImportVendorsModal";
import SplitButton, { MenuOption } from "Components/SplitButton";

const editModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "450px",
    height: "350px",
    bgcolor: 'background.paper',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    borderRadius: "5px",
    display: 'flex',
    flexDirection: "column"
};


export interface VendorListProps {
    estimateId?: string;
}

export default function VendorList(props: VendorListProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: user } = useGetUserDetailsQuery();
    const { data: existingVendors, refetch: refetchVendors } = useGetVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const [getMaxId] = useLazyGetMaxVendorIdQuery();
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<VendorView> | undefined, column?: string }>();
    const confirm = useConfirm();
    const gridRef = useRef<AgGridReact<VendorView>>(null);
    const [saveVendor] = useCreateVendorMutation();
    const [updateVendor] = useUpdateVendorMutation();
    const [deleteVendor] = useDeleteVendorMutation();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [errors, setErrors] = useState<Array<{ field: string, error: string }>>([]);
    const [disableAdd, setDisableAdd] = useState(false);
    const [vendors, setVendors] = useState<VendorView[]>([]);
    const [tagRef, setTagRef] = useState<MutableRefObject<any> | undefined>();
    const [filterValues, setFilterValues] = useState<VendorFilterSettings | undefined>();
    const [selectedRows, setSelectedRows] = useState<Array<VendorView>>([]);
    const deleteDisabledRef = useRef<boolean>();
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const hasAddEditAccessRef = useRef<boolean>();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [pageError, setPageError] = useState<string | undefined>();
    const [pushToTemplate] = usePushVendorToTemplateMutation();
    const [syncVendor] = useSyncVendorMutation();
    const [downloadImportSample] = useExportImportVendorSampleMutation();
    const [importMenuItems] = useState<Array<MenuOption>>([{ option: 'Import', disabled: false }, { option: 'Download sample', disabled: false }]);
    const [openImportModal, setOpenImportModal] = useState(false);
    const handleOpenImportModal = useCallback(() => {
        setOpenImportModal(true);
    }, []);

    const handleCloseImportModal = useCallback(async () => {
        setOpenImportModal(false);
        refetchVendors();
    }, [refetchVendors]);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                hasAddEditAccessRef.current = false;
                setHasViewAccess(true);
                deleteDisabledRef.current = true;
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Vendors,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Vendors,
                    requiredPermissions: [502]
                });
                hasAddEditAccessRef.current = hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Vendors,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            hasAddEditAccessRef.current = false;
            setHasViewAccess(true);
            deleteDisabledRef.current = true;
        }
        setIsReady(true);
    }, [estimate, estimate?.EstimateUserRole, user])

    const handleOpenEditModal = () => {
        setOpenEditModal(true);
    };
    const handleCloseEditModal = () => {
        gridRef.current!.api.refreshCells({
            columns: ["tags"],
            force: true
        });
        setOpenEditModal(false);
    };

    const defaultVendorColDef = useMemo<ColDef>(() => {
        return {
            editable: true,
            resizable: true
        };
    }, []);

    useEffect(() => {
        const rowData = new Array<VendorView>()
        if (existingVendors && existingVendors?.length > 0) {
            existingVendors.forEach((vendor) => {
                rowData.push({
                    name: vendor.name,
                    type: vendor.type,
                    actions: '',
                    address: vendor.address,
                    canDelete: vendor.canDelete,
                    contact: vendor.contact,
                    masterReferenceId: vendor.masterReferenceId,
                    displayId: vendor.displayId,
                    email: vendor.email,
                    id: vendor.id,
                    isNew: false,
                    phone: vendor.phone,
                    tags: vendor.tags,
                    outOfSync: vendor.outOfSync,
                    errors: [],
                    canPush: true
                });
            });
        }
        setVendors(rowData);
    }, [existingVendors])

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((errorDetails) => {
                const instances = gridRef.current!.api.getCellEditorInstances({
                    columns: [errorDetails.field]
                });
                if (instances.length > 0) {
                    getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
                        if (instance && instance.setError) {
                            instance.setError(errorDetails.error);
                        }
                    });
                }
            });
        }
    }, [errors])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            setDisableAdd(true);
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column ?? 'displayId',
            });
        } else {
            setDisableAdd(false);
        }
    }, [currentEditing])

    const getVendorRowId = useCallback(function (params: GetRowIdParams<VendorView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const saveUpdateVendor = useCallback(async (nodeToSave: IRowNode<VendorView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<VendorView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                setPageError(undefined);
                if (user && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    if (!nodeToSave.data.displayId) {
                        const error: ServerError = { data: { displayId: 'ID is required' } };
                        throw error;
                    }
                    if (!nodeToSave.data.name) {
                        const error: ServerError = { data: { name: 'Name is required' } };
                        throw error;
                    }

                    if (nodeToSave.data.id) {
                        if (nodeToSave.data.isNew) {
                            await saveVendor({
                                companyId: user?.companyId,
                                orgId: user.organizationId,
                                estimateId: props.estimateId,
                                body: {
                                    displayId: nodeToSave.data.displayId,
                                    name: nodeToSave.data.name,
                                    type: nodeToSave.data.type,
                                    address: nodeToSave.data.address,
                                    contact: nodeToSave.data.contact,
                                    email: nodeToSave.data.email,
                                    phone: nodeToSave.data.phone,
                                    tags: nodeToSave.data.tags,
                                    id: nodeToSave.data.id
                                }
                            }).unwrap();
                        } else {
                            await updateVendor({
                                companyId: user?.companyId,
                                orgId: user.organizationId,
                                vendorId: nodeToSave.data.id,
                                estimateId: props.estimateId,
                                body: {
                                    displayId: nodeToSave.data.displayId,
                                    name: nodeToSave.data.name,
                                    type: nodeToSave.data.type,
                                    address: nodeToSave.data.address,
                                    contact: nodeToSave.data.contact,
                                    email: nodeToSave.data.email,
                                    phone: nodeToSave.data.phone,
                                    tags: nodeToSave.data.tags,
                                    id: nodeToSave.data.id
                                }
                            }).unwrap();
                        }
                    }
                    resolve();
                    if (toEditAfterSave) {
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    } else {
                        setCurrentEditing(undefined);
                    }
                }
            } catch (error: any) {
                if (error.status === 500) {
                    setPageError(Errors.generic);
                    return;
                }
                if (error && error.data) {
                    if (typeof nodeToSave.rowIndex === 'number') {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: 'displayId',
                        });
                    }
                    if (error.data.displayId) {
                        setErrors([{ field: 'displayId', error: error.data.displayId }]);
                    } else if (error.data.name) {
                        setErrors([{ field: 'name', error: error.data.name }]);
                    } else if (error.data.email) {
                        setErrors([{ field: 'email', error: error.data.email }]);
                    }
                    else if (error?.data?.message && error.data.message[0]) {
                        setErrors([{ field: 'displayId', error: error.data.message[0] }]);
                    } else if (error.data.page) {
                        setPageError(error.data.page);
                    }
                }
                reject(error);
            }
        });
    }, [props.estimateId, saveVendor, updateVendor, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const saveUpdateVendorRef = useRef<any>();
    saveUpdateVendorRef.current = saveUpdateVendor;

    const onCellClicked = useCallback(async (event: CellClickedEvent) => {
        try {
            if (isCancelClicked) {
                setIsCancelClicked(false);
                return;
            };

            if (event.node.data.masterReferenceId || !hasAddEditAccessRef.current) return;

            if (currentEditing?.node === event.node) {
                return;
            }
            if (event.column.getColId() === 'actions' || event.column.getColId() === 'outOfSync') {
                return;
            }
            if (!currentEditing?.node) {
                setCurrentEditing({ node: event.node, column: event.column.getColId() });
            } else {
                await saveUpdateVendor(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) {

        }

    }, [currentEditing, isCancelClicked, saveUpdateVendor])

    const onVendorRowEditingStarted = useCallback((event: RowEditingStartedEvent<VendorView>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onVendorRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent<VendorView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const addVendor = useCallback(async () => {
        if (currentEditing) {
            try {
                await confirm({ description: "You have unsaved changes. If you proceed, your current edits will be lost. Do you want to continue?" });
                gridRef.current!.api.stopEditing(true);
                if (currentEditing && currentEditing.node?.data) {
                    if (currentEditing.node.data.id) {
                        const vendorBeforeEditing = vendors?.find((vendor) => (vendor.id === currentEditing?.node?.data?.id));
                        if (vendorBeforeEditing) {
                            gridRef.current!.api.applyTransaction({ update: [{ ...vendorBeforeEditing }] });
                        } else {
                            gridRef.current!.api.applyTransaction({ remove: [currentEditing.node.data] });
                        }
                    } else {
                        gridRef.current!.api.applyTransaction({ remove: [currentEditing.node.data] });
                    }
                }
            } catch (error) {
                return;
            }
        }
        let vendorRow = gridRef.current!.api.applyTransaction({
            add: [{
                id: uuidv4(),
                isNew: true,
                name: '',
                actions: '',
                type: (filterValues?.type && filterValues.type !== 'All') ? filterValues.type : '',
                address: '',
                contact: '',
                displayId: undefined,
                email: '',
                phone: '',
                tags: filterValues?.tags ?? [],
                errors: [],
                canDelete: true
            }],
            addIndex: 0
        });
        if (vendorRow && vendorRow.add && vendorRow.add.length > 0 && vendorRow.add[0] && typeof vendorRow.add[0].rowIndex === 'number' && vendorRow.add[0].id) {
            if (vendorRow.add[0].id) {
                queueMicrotask(() => flushSync(() => {
                    if (vendorRow && vendorRow.add[0].id) {
                        const node = gridRef.current!.api.getRowNode(vendorRow.add[0].id);
                        if (node) {
                            gridRef.current!.api.ensureNodeVisible(node, "middle");
                            setCurrentEditing({ node: node, column: 'displayId' });
                        }
                    }
                }))
            }
        }
    }, [confirm, currentEditing, filterValues, vendors])

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<VendorView>) => {
        if (params.event.key === 'Enter' && params.node) {
            if (params.event.target === tagRef?.current) {
                return true;
            }

            params.event.stopPropagation();

            const save = async () => {
                try {
                    await saveUpdateVendor(params.node);
                } catch (error) {

                }
            }
            save();
        }
        return true;
    }, [saveUpdateVendor, tagRef])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const saveOnEnterRef = useRef<any>();
    saveOnEnterRef.current = saveOnEnter;

    const deleteVendorRow = useCallback((node: IRowNode<VendorView>) => {
        return new Promise<void>(async (resolve) => {
            if (node.data) {
                await deleteVendor({
                    companyId: user?.companyId,
                    vendorId: node.data.id,
                    orgId: user?.organizationId,
                    estimateId: props.estimateId
                });
            }
            resolve();
        });
    }, [deleteVendor, props.estimateId, user?.companyId, user?.organizationId])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const deleteVendorRowEditingRef = useRef<any>();
    deleteVendorRowEditingRef.current = deleteVendorRow;

    const cancelVendorEditing = useCallback((node: IRowNode<VendorView>) => {
        if (node && node.data) {
            if (!node.data.isNew) {
                setIsCancelClicked(true);
                const vendor = existingVendors?.find((v) => (v.id === node.data?.id));
                if (vendor) {
                    gridRef.current!.api.applyTransaction({ update: [{ ...vendor, name: vendor?.name ?? '', type: vendor?.type ?? '' }] });
                }
                gridRef.current!.api.stopEditing(true);
            } else {
                gridRef.current!.api.applyTransaction({ remove: [node.data] });
            }
            setCurrentEditing(undefined);
        }
    }, [existingVendors])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const cancelVendorEditingRef = useRef<any>();
    cancelVendorEditingRef.current = cancelVendorEditing;

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelVendorEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelVendorEditing]);

    const sync = useCallback(async (node: IRowNode<VendorView>) => {
        setPageError(undefined);
        if (node && node.data && user) {
            await syncVendor({
                companyId: user.companyId,
                orgId: user.organizationId,
                estimateId: props.estimateId,
                vendorId: node.data.id,
                templateVendorId: node.data.masterReferenceId
            });
            gridRef.current!.api.refreshClientSideRowModel();
            gridRef.current!.api.refreshCells({
                columns: ["outOfSync"],
                rowNodes: [node],
                force: true
            });
        }
    }, [props.estimateId, syncVendor, user])
    const syncRef = useRef<any>();
    syncRef.current = sync;

    const push = useCallback(async (node: IRowNode<VendorView>) => {
        try {
            setPageError(undefined);
            if (node && node.data && user && estimate) {
                const createdVendor = await pushToTemplate({
                    companyId: user.companyId,
                    orgId: user.organizationId,
                    estimateId: props.estimateId,
                    vendorId: node.data.id,
                    masterEstimateId: estimate.masterEstimateId
                }).unwrap();
                node.updateData({
                    ...createdVendor,
                    errors: [],
                    canPush: true,
                    isNew: false
                });
                gridRef.current!.api.refreshClientSideRowModel();
                gridRef.current!.api.refreshCells({
                    columns: ["outOfSync", 'ID'],
                    rowNodes: [node],
                    force: true
                });
            }
        } catch (error: any) {
            if (error && 'data' in error) {
                setPageError(error.data.message);
            }
        }
    }, [estimate, props.estimateId, pushToTemplate, user])
    const pushRef = useRef<any>();
    pushRef.current = push;

    const getBaseDefs = useCallback((): ColDef<VendorView>[] => {
        return [
            {
                field: "id",
                hide: true,
                suppressKeyboardEvent: saveOnEnterRef.current,
            },
            {
                field: "displayId",
                resizable: true,
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                editable: (params) => (!params.data?.masterReferenceId && !!hasAddEditAccessRef.current),
                cellEditor: VendorIdEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    getMaxId: getMaxId,
                    field: "ID"
                },
                headerName: "ID",
                width: 120,
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
            },
            {
                field: "name",
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                flex: 1,
                cellEditor: GenericTextEditCellRenderer,
                cellEditorParams: {
                    field: 'Name'
                },
                editable: (params) => (!params.data?.masterReferenceId && !!hasAddEditAccessRef.current),
                headerName: "Name",
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
            },
            {
                field: "contact",
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                headerName: "Contact",
                editable: (params) => (!params.data?.masterReferenceId && !!hasAddEditAccessRef.current),
                width: 200,
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
            },
            {
                field: "address",
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                headerName: "Address",
                editable: (params) => (!params.data?.masterReferenceId && !!hasAddEditAccessRef.current),
                width: 200,
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
            },
            {
                field: "phone",
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                headerName: "Phone",
                editable: (params) => (!params.data?.masterReferenceId && !!hasAddEditAccessRef.current),
                width: 200,
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
            },
            {
                field: "email",
                cellDataType: '',
                cellEditor: GenericTextEditCellRenderer,
                cellEditorParams: {
                    field: 'Email'
                },
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                headerName: "Email",
                editable: (params) => (!params.data?.masterReferenceId && !!hasAddEditAccessRef.current),
                width: 200,
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
            },
            {
                field: "type",
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                cellEditor: VendorTypeEditCellRenderer,
                headerName: "Type",
                editable: (params) => (!params.data?.masterReferenceId && !!hasAddEditAccessRef.current),
                width: 180,
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
            },
            {
                field: "tags",
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                cellEditor: VendorTagEditCellRenderer,
                autoHeight: true,
                editable: (params) => (!params.data?.masterReferenceId && !!hasAddEditAccessRef.current),
                wrapText: true,
                cellRenderer: VendorTagCellRenderer,
                cellEditorPopup: true,
                cellEditorParams: {
                    setRef: setTagRef,
                    estimateId: props.estimateId
                },
                headerName: "Tags",
                minWidth: 200,
                cellStyle: { height: "40px", borderRight: `1px solid ${colors?.gray[800]}` } as any
            },
            {
                field: 'outOfSync',
                width: 65,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                hide: true,
                editable: false,
                cellDataType: "boolean",
                headerName: 'Sync',
                cellRenderer: OutOfSyncCellRenderer,
                cellRendererParams: {
                    sync: (node: IRowNode<VendorView>) => syncRef.current(node),
                    push: (node: IRowNode<VendorView>) => pushRef.current(node),
                    estimateId: props.estimateId,
                    entity: Entity.Vendors
                },
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'actions',
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                width: 60,
                editable: false,
                headerName: '',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRendererSelector: () => {
                    return {
                        component: GenericActionsCellRenderer,
                        params: {
                            deleteRecord: (node: IRowNode<VendorView>) => deleteVendorRowEditingRef.current(node),
                            save: (node: IRowNode<VendorView>) => saveUpdateVendorRef.current(node),
                            cancel: (node: IRowNode<VendorView>) => cancelVendorEditingRef.current(node),
                            disabled: () => !hasAddEditAccessRef.current,
                            deleteDisabledMessage: 'Vendor is currently being used and cannot be deleted.'
                        },
                    };
                },
            },
        ]
    }, [colors?.gray, getMaxId, props.estimateId])

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const showHideCols = useCallback(() => {
        if (gridRef.current?.api) {
            if (!estimate?.isMaster && estimate?.masterEstimateId) {
                gridRef.current!.api.setColumnsVisible(["outOfSync"], true);
            }
        }
    }, [estimate])

    const onGridReady = useCallback((params: GridReadyEvent) => {
        showHideCols();
        params.api!.setGridOption("rowSelection", {
            mode: "multiRow",
            enableClickSelection: false,
        });
    }, [showHideCols])

    useEffect(() => {
        if (gridRef.current?.api) {
            showHideCols();
        }
    }, [showHideCols])

    const isExternalFilterPresent = useCallback((): boolean => {
        return (filterValues !== undefined)
    }, [filterValues]);

    const onExternalFiltersChanged = (filterSettings: VendorFilterSettings) => {
        gridRef.current!.api.deselectAll();
        setFilterValues({ ...filterSettings });
    }

    const doesExternalFilterPass = useCallback(
        (node: IRowNode<VendorView>): boolean => {
            if (node.data && filterValues) {
                if (filterValues.tags && filterValues.tags.length > 0) {
                    const diff = intersectionWith(node.data.tags, filterValues.tags, (from, to) => {
                        return from.id === to.id
                    });
                    if (diff.length === 0) {
                        return false;
                    }
                }
                if (filterValues.type !== "All") {
                    if (filterValues.type && !node.data.type.includes(filterValues.type)) {
                        return false;
                    }
                }
            }

            return true;
        },
        [filterValues]
    );

    const selectionColumnDef = useMemo<SelectionColumnDef>(() => {
        return {
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
        };
    }, [colors?.gray]);

    const onSelectionChanged = (event: SelectionChangedEvent<VendorView>) => {
        const selectedRows = new Array<VendorView>();
        event.api.forEachNodeAfterFilter(node => {
            if (node.data && node.isSelected()) {
                selectedRows.push(node.data);
            }
        });
        setSelectedRows(selectedRows);
    }

    const onMenuItemClick = useCallback(async (option: string) => {
        setPageError(undefined);
        if (option === 'Import') {
            handleOpenImportModal();
        } else {
            await downloadImportSample({
                companyId: user?.companyId,
                estimateId: props.estimateId,
                organizationId: user?.organizationId
            });
        }
    }, [downloadImportSample, handleOpenImportModal, props.estimateId, user?.companyId, user?.organizationId])

    if (!isReady) {
        return <></>
    }

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <>{colors && <Box display="flex" flexDirection="column" height="100%" width="100%">
        <Box display="flex" flexDirection="column" flex="1" height="100%" padding="5px">
            <Box display="flex" width="100%" justifyContent="space-between">
                <Box display="flex" alignItems="center" width="50%">
                    <Box flex="0.1" display="flex" gap="15px">
                        <Button startIcon={<AddIcon />} disabled={disableAdd || !hasAddEditAccessRef.current} variant="contained" onClick={addVendor}>New</Button>
                        {selectedRows?.length > 0 && <Button startIcon={<EditIcon />} disabled={disableAdd || !hasAddEditAccessRef.current} variant="contained" onClick={handleOpenEditModal}>Edit</Button>}
                    </Box>
                    <Box flex="1">
                        <VendorFilters estimateId={props.estimateId} onFilterChanged={onExternalFiltersChanged} editing={currentEditing?.node} />
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    {!estimate?.masterEstimateId && <Box marginRight="10px">
                        <SplitButton
                            buttonHeight="32px" variant="outlined"
                            disabled={!hasAddEditAccessRef.current}
                            options={importMenuItems} buttonText="Import"
                            onButtonClick={() => handleOpenImportModal()} onMenuItemClick={onMenuItemClick} />
                    </Box>}
                </Box>
            </Box>
            {pageError && <Box marginBottom="10px"><Alert severity="error">{pageError}</Alert></Box>}
            <Box className="ag-theme-alpine ag-theme-bidbow" height="100%">
                <AgGridReact<VendorView>
                    ref={gridRef}
                    editType={'fullRow'}
                    rowData={vendors}
                    rowHeight={40}
                    onGridReady={onGridReady}
                    selectionColumnDef={selectionColumnDef}
                    columnDefs={columnDefs}
                    onSelectionChanged={onSelectionChanged}
                    defaultColDef={defaultVendorColDef}
                    onRowEditingStarted={onVendorRowEditingStarted}
                    onRowEditingStopped={onVendorRowEditingStopped}
                    onCellClicked={onCellClicked}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    getRowId={getVendorRowId}
                    onCellKeyDown={onCellKeyDown}
                />
            </Box>

        </Box>
    </Box>
    }
        <Modal
            open={openEditModal}
            onClose={handleCloseEditModal}
            aria-labelledby="edit-items-title"
            aria-describedby="edit-items-description"
        >
            <Box sx={{ ...editModalStyle }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    marginBottom="10px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" color="primary">Bulk edit {selectedRows?.length} records</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="edit" color="primary" onClick={handleCloseEditModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                {props.estimateId && <VendorEdit estimateId={props.estimateId} closeModal={handleCloseEditModal} vendors={selectedRows} />}
            </Box>
        </Modal>
        <Modal disableRestoreFocus={true}
            open={openImportModal}
            onClose={handleCloseImportModal}
            aria-labelledby="import-modal"
            aria-describedby="import-modal-description">
            <Box className="expand-modal">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Import Vendors</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="close" onClick={handleCloseImportModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <ImportVendorsModal estimateId={props.estimateId} close={handleCloseImportModal} />
            </Box>
        </Modal>
    </>
}