import { ActivityRateCalculated, ActivityUpdateDto, BaseActivity, FilteredActivity } from 'Models/activity';
import { api } from './api'
import { ActivityResource, ActivityResourceDto, AppendActivityResourceDto } from 'Models/resource';
import { IServerSideGetRowsRequest } from 'ag-grid-enterprise';

export const activityApi = api.injectEndpoints({
  endpoints: (build) => ({
    getActivityById: build.query<ActivityRateCalculated, { organizationId: string, companyId: string, estimateId: string, activityId: string }>({
      query: ({ organizationId, companyId, estimateId, activityId }: { organizationId: string, companyId: string, estimateId: string, activityId: string }) => (
        {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/activity/${activityId}`
        }
      ),
      providesTags: ['Activity']
    }),
    getActivitiesByFilter: build.query<Array<FilteredActivity>, { organizationId: string, companyId: string, estimateId: string, filterText: string, categoryId?: string }>({
      query: ({ organizationId, companyId, filterText, estimateId, categoryId }: { organizationId: string, companyId: string, estimateId: string, filterText: string, categoryId?: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/activity/filter/${btoa(filterText)}?categoryId=${categoryId ?? ''}` }),
      transformResponse: (response: Array<FilteredActivity>) => { return [...response] },
      providesTags: ['FilteredActivities']
    }),
    getActivitiesPaged: build.query<Array<FilteredActivity>, { organizationId: string, companyId: string, estimateId: string, skip: number, take: number, categoryId?: string }>({
      query: ({ organizationId, companyId, skip, take, estimateId, categoryId }: { organizationId: string, companyId: string, estimateId: string, skip: number, take: number, categoryId?: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/activity/paged?skip=${skip}&take=${take}&categoryId=${categoryId ?? ''}` }),
      transformResponse: (response: Array<FilteredActivity>) => { return [...response] },
      providesTags: ['FilteredActivities']
    }),
    getActivityByDisplayId: build.query<FilteredActivity, { organizationId: string, companyId: string, estimateId: string, displayId: string }>({
      query: ({ organizationId, companyId, displayId, estimateId }: { organizationId: string, companyId: string, estimateId: string, displayId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/activity/display/${displayId}` }),
      providesTags: ['ActivityByDisplayId']
    }),
    getActivityMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/activity/max-id` }),
      providesTags: ['Activities']
    }),
    getResourcesForActivity: build.query<Array<ActivityResource>, { organizationId: string, companyId: string, estimateId: string, activityId: string }>({
      query: ({ organizationId, companyId, estimateId, activityId }: { organizationId: string, companyId: string, estimateId: string, activityId: string }) => (
        {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/activity/${activityId}/resources`
        }
      ),
      providesTags: ['ActivityResources']
    }),
    createActivity: build.mutation<ActivityRateCalculated, Partial<{ body: BaseActivity, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Activities'],
    }),
    syncActivity: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, activityId: string, templateActivityId: string }>>({
      query({ orgId, companyId, estimateId, activityId, templateActivityId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity/${activityId}/from-template-activity/${templateActivityId}`,
          method: 'PUT',
          body: {},
        }
      },
      invalidatesTags: ['SubcontractorResourcesActivities', 'Pricing', 'Activities', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources"],
    }),
    pushActivityToTemplate: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, activityId: string, masterEstimateId: string }>>({
      query({ orgId, companyId, estimateId, activityId, masterEstimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity/${activityId}/to-template-estimate/${masterEstimateId}`,
          method: 'PUT',
          body: {},
        }
      },
      invalidatesTags: ['SubcontractorResourcesActivities', 'Pricing', 'Activities', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources"],
    }),
    getActivityRows: build.mutation<{ results: Array<ActivityRateCalculated>; totalCount: number }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity/paged`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['Activities'] : []),
    }),
    updateActivity: build.mutation<ActivityRateCalculated, Partial<{ body: ActivityUpdateDto, orgId: string, companyId: string, estimateId: string, activityId: string }>>({
      query({ body, orgId, companyId, estimateId, activityId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity/${activityId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['SubcontractorResourcesActivities', 'Pricing', 'Activities', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources"],
    }),
    createActivityResource: build.mutation<ActivityResourceDto, Partial<{ body: ActivityResourceDto, orgId: string, companyId: string, estimateId: string, activityId: string }>>({
      query({ body, orgId, companyId, estimateId, activityId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity/${activityId}/resource`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'ActivityResources', 'Activities', 'IndirectItemDetails', 'ItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources", 'Resources'] : []),
    }),
    appendActivityResource: build.mutation<null, Partial<{ body: AppendActivityResourceDto, orgId: string, companyId: string, estimateId: string, activityId: string }>>({
      query({ body, orgId, companyId, estimateId, activityId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity/${activityId}/append`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['SubcontractorResourcesActivities', 'Pricing', 'ActivityResources', 'Activities', 'IndirectItemDetails', 'ItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources", 'Resources']
    }),
    updateActivityResource: build.mutation<ActivityResourceDto, Partial<{ body: ActivityResourceDto, orgId: string, companyId: string, estimateId: string, activityId: string }>>({
      query({ body, orgId, companyId, estimateId, activityId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity/${activityId}/resource`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'ActivityResources', 'Activities','IndirectItemDetails', 'ItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources"] : []),
    }),
    deleteActivityResource: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, activityId: string, resourceId: string }>>({
      query({ orgId, companyId, estimateId, activityId, resourceId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity/${activityId}/resource/${resourceId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['SubcontractorResourcesActivities', 'Pricing', 'Resources', 'ActivityResources', 'Activities', 'IndirectItemDetails', 'ItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources"],
    }),
    deleteActivity: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, activityId: string }>>({
      query({ orgId, companyId, estimateId, activityId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity/${activityId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['SubcontractorResourcesActivities', 'Pricing', 'Resources', 'Activities', "FilteredActivities", 'ActivityCategories'],
    }),
  })
});

export const {
  usePushActivityToTemplateMutation,
  useSyncActivityMutation,
  useGetActivityRowsMutation,
  useGetActivitiesPagedQuery,
  useAppendActivityResourceMutation,
  useCreateActivityMutation,
  useUpdateActivityMutation,
  useCreateActivityResourceMutation,
  useUpdateActivityResourceMutation,
  useLazyGetActivityByIdQuery,
  useLazyGetActivityMaxIdQuery,
  useGetActivityMaxIdQuery,
  useGetResourcesForActivityQuery,
  useDeleteActivityResourceMutation,
  useGetActivitiesByFilterQuery,
  useLazyGetActivityByDisplayIdQuery,
  useLazyGetActivitiesPagedQuery,
  useDeleteActivityMutation
} = activityApi