
import { Autocomplete, Avatar, Box, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { tokens } from "theme";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { ResourceCategory, ResourceSubCategoryOption } from "Models/resource-category";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useLazyGetResourceCategoriesQuery } from "State/Services/resource-category";
import { ActivityCategory } from "Models/activity-category";
import { useLazyGetActivityCategoriesQuery } from "State/Services/activity-category";
import QuickAppendResource from "./Resources/QuickAppendResource";
import QuickAppendActivity from "./Activities/QuickAppendActivity";
import QuickAppendStandardItem from "./StandardItems/QuickAppendStandardItem";
import { NavItem } from "Models/nav";
import { useLazyGetStandardItemCategoriesQuery } from "State/Services/standard-item-category";
import { StandardItemCategory } from "Models/standard-item-category";
import QuickAppendItem from "./Items/QuickAppendItem";

export interface QuickAddProps {
    type?: "resource" | "item" | "activity" | "subcontractor" | "direct" | "previous";
    parent: "direct" | "indirect";
    previousItem: NavItem | null;
    itemId: string;
    activityId?: string;
    standardItemId?: string;
    resourceId?: string;
    estimateId: string;
    inHeader: boolean;
    subItemId?: string;
    itemActivityId?: string;
    itemResourceId?: string;
    close: () => void;
}

export default function QuickAdd(props: QuickAddProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [selectedOption, setSelectedOption] = useState<"resource" | "item" | "activity" | "directitem" | 'previousitem'>("resource");
    const [categoryId, setCategoryId] = useState<string>();
    const [resourceCategory, setResourceCategory] = useState<ResourceSubCategoryOption>();
    const [activityCategory, setActivityCategory] = useState<ActivityCategory>();
    const [standardItemCategory, setStandardItemCategory] = useState<StandardItemCategory>();
    const [resourceSubCategoryOptions, setResourceSubCategoryOptions] = useState<Array<ResourceSubCategoryOption>>([]);
    const [getResourceCategories] = useLazyGetResourceCategoriesQuery();
    const [storedResourceCategories, setStoredResourceCategories] = useState<Array<ResourceCategory>>();
    const [storedActivityCategories, setStoredActivityCategories] = useState<Array<ActivityCategory>>([]);
    const [storedStandardItemCategories, setStoredStandardItemCategories] = useState<Array<StandardItemCategory>>([]);
    const [getActivityCategories] = useLazyGetActivityCategoriesQuery();
    const [getStandardItemCategories] = useLazyGetStandardItemCategoriesQuery();

    useEffect(() => {
        if (user?.companyId && user.organizationId) {
            getResourceCategories({
                companyId: user.companyId,
                estimateId: props.estimateId,
                organizationId: user.organizationId
            }, true).then((resourceCategoriesResponse) => {
                if (resourceCategoriesResponse.data) {
                    setStoredResourceCategories(resourceCategoriesResponse.data);
                } else {
                    setStoredResourceCategories([]);
                }
            });
        }
    }, [getResourceCategories, props.estimateId, user?.companyId, user?.organizationId])

    useEffect(() => {
        if (user?.companyId && user.organizationId) {
            getActivityCategories({
                companyId: user?.companyId,
                estimateId: props.estimateId,
                organizationId: user?.organizationId
            }, true).then((activityCategories) => {
                if (activityCategories.data) {
                    setStoredActivityCategories(activityCategories.data);
                } else {
                    setStoredActivityCategories([]);
                }
            });
        }
    }, [getActivityCategories, props.estimateId, user?.companyId, user?.organizationId])

    useEffect(() => {
        if (user?.companyId && user.organizationId) {
            getStandardItemCategories({
                companyId: user?.companyId,
                estimateId: props.estimateId,
                organizationId: user?.organizationId
            }, true).then((standardItemCategories) => {
                if (standardItemCategories.data) {
                    setStoredStandardItemCategories(standardItemCategories.data.filter(data => data.level === 1));
                } else {
                    setStoredStandardItemCategories([]);
                }
            });
        }
    }, [getStandardItemCategories, props.estimateId, user?.companyId, user?.organizationId])

    useEffect(() => {
        const options = new Array<ResourceSubCategoryOption>();
        if (storedResourceCategories && storedResourceCategories.length > 0) {
            storedResourceCategories.forEach((resourceCategory) => {
                resourceCategory.resourceSubCategories?.forEach((subCategory) => {
                    options.push({
                        ...subCategory,
                        resourceCategoryName: resourceCategory.description ?? ''
                    });
                });
            });
        }
        setResourceSubCategoryOptions(options);
    }, [storedResourceCategories]);

    const renderSwitch = useCallback(() => {
        switch (props.type) {
            case "activity":
            case "resource":
                return <QuickAppendResource
                    inHeader={props.inHeader}
                    resourceId={props.resourceId}
                    close={props.close}
                    itemId={props.itemId}
                    estimateId={props.estimateId}
                    type={props.type}
                    activityId={props.activityId}
                    itemResourceId={props.itemResourceId}
                    itemActivityId={props.itemActivityId}
                    subItemId={props.subItemId}
                    resourceSubCategoryId={categoryId}
                    parent={props.parent}
                    standardItemId={props.standardItemId} />;
            case "item":
                switch (selectedOption) {
                    case 'resource':
                        return <QuickAppendResource
                            inHeader={props.inHeader}
                            close={props.close}
                            itemId={props.itemId}
                            estimateId={props.estimateId}
                            subItemId={props.subItemId}
                            type={props.type}
                            parent={props.parent}
                            activityId={props.activityId}
                            standardItemId={props.standardItemId}
                            resourceSubCategoryId={categoryId} />
                    case 'activity':
                        return <QuickAppendActivity
                            inHeader={props.inHeader}
                            close={props.close}
                            itemId={props.itemId}
                            estimateId={props.estimateId}
                            subItemId={props.subItemId}
                            type={props.type}
                            parent={props.parent}
                            activityCategoryId={categoryId}
                            standardItemId={props.standardItemId} />;
                    case 'item':
                        return <QuickAppendStandardItem
                            close={props.close}
                            itemId={props.itemId}
                            categoryId={categoryId}
                            parent={props.parent}
                            estimateId={props.estimateId} />
                    case "directitem":
                        return <QuickAppendItem
                            close={props.close}
                            itemId={props.itemId}
                            parent={props.parent}
                            estimateId={props.estimateId} />
                    case "previousitem":
                        console.log(props.previousItem);
                        return <QuickAppendItem
                            close={props.close}
                            disabled={true}
                            parent={props.parent}
                            prevItem={{ description: props.previousItem?.description ?? '', id: props.previousItem?.itemId, displayId: props.previousItem?.displayId.toString() ?? '' }}
                            itemId={props.itemId}
                            estimateId={props.estimateId} />
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }, [categoryId, props.activityId, props.close, props.estimateId, props.inHeader, props.itemActivityId, props.itemId, props.itemResourceId, props.parent, props.previousItem, props.resourceId, props.standardItemId, props.subItemId, props.type, selectedOption])

    const handleChange = useCallback((event: SelectChangeEvent) => {
        setResourceCategory(undefined);
        setActivityCategory(undefined);
        setCategoryId(undefined);
        setSelectedOption(event.target.value as "resource" | "item" | "activity" | "directitem" | "previousitem");
    }, []);

    const renderCategories = useCallback(() => {
        switch (selectedOption) {
            case "resource":
                return <>&nbsp;&nbsp;from <Autocomplete
                    size="small"
                    fullWidth
                    value={resourceCategory ?? null}
                    className="ag-input-field-input ag-text-field-input"
                    sx={{ height: "inherit", marginLeft: "10px", "& .MuiTextField-root:": { height: "inherit" } }}
                    options={resourceSubCategoryOptions}
                    groupBy={(option) => option.resourceCategoryName}
                    getOptionLabel={(option) => option.description ?? ''}
                    renderInput={(params) => <TextField name="subCategoryId" placeholder='Select Category' {...params} />}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    onChange={(event, value) => {
                        if (value) {
                            setResourceCategory(value);
                            setCategoryId(value.id);
                        } else {
                            setResourceCategory(undefined);
                            setCategoryId(undefined);
                        }
                    }}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.description ?? '', inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(option.description ?? '', matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                /></>
            case "activity":
                return <>&nbsp;&nbsp;from <Autocomplete
                    size="small"
                    fullWidth
                    value={activityCategory ?? null}
                    className="ag-input-field-input ag-text-field-input"
                    sx={{ height: "inherit", marginLeft: "10px", "& .MuiTextField-root:": { height: "inherit" } }}
                    options={storedActivityCategories}
                    getOptionLabel={(option) => option.description ?? ''}
                    renderInput={(params) => <TextField name="subCategoryId" placeholder='Select Category' {...params} />}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    onChange={(event, value) => {
                        if (value) {
                            setActivityCategory(value);
                            setCategoryId(value.id);
                        } else {
                            setActivityCategory(undefined);
                            setCategoryId(undefined);
                        }
                    }}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.description ?? '', inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(option.description ?? '', matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                /></>
            case "item":
                return <>&nbsp;&nbsp;from <Autocomplete
                    size="small"
                    fullWidth
                    value={standardItemCategory ?? null}
                    className="ag-input-field-input ag-text-field-input"
                    sx={{ height: "inherit", marginLeft: "10px", "& .MuiTextField-root:": { height: "inherit" } }}
                    options={storedStandardItemCategories}
                    getOptionLabel={(option) => option.description ?? ''}
                    renderInput={(params) => <TextField name="subCategoryId" placeholder='Select Category' {...params} />}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    onChange={(event, value) => {
                        if (value) {
                            setStandardItemCategory(value);
                            setCategoryId(value.id);
                        } else {
                            setStandardItemCategory(undefined);
                            setCategoryId(undefined);
                        }
                    }}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.description ?? '', inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(option.description ?? '', matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                /></>
        }
    }, [activityCategory, resourceCategory, resourceSubCategoryOptions, selectedOption, standardItemCategory, storedActivityCategories, storedStandardItemCategories]);

    return <>
        <Box sx={{ padding: "5px 5px 5px 10px", fontWeight: 'bold' }} display="flex" alignItems="center">Add
            <Box display="flex" alignItems="center" width="100%">
                {
                    (props.type === 'resource' || props.type === 'activity') ? <>&nbsp;resource{renderCategories()}</> :
                        <>
                            <Box display="flex">
                                <Select
                                    variant="standard"
                                    size="small"
                                    labelId="option-label"
                                    sx={{

                                        marginLeft: "8px",
                                        '& .MuiSelect-select': {
                                            height: 15,
                                            display: "flex",
                                            border: 'none',
                                            alignItems: "center"
                                        },
                                        '& .MuiSelect-select .MuiListItemIcon-root': {
                                            minWidth: "35px",
                                            alignItems: "center",
                                        }
                                    }}
                                    value={selectedOption}
                                    label="Option"
                                    onChange={handleChange}>
                                    <MenuItem key="resource" value="resource">
                                        <ListItemIcon>
                                            <Avatar sx={{ bgcolor: colors.blueAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">R</Avatar>
                                        </ListItemIcon>
                                        <ListItemText>Resource</ListItemText>
                                    </MenuItem>
                                    {(props.inHeader || props.type === "item") && <MenuItem key="activity" value="activity">
                                        <ListItemIcon>
                                            <Avatar sx={{ bgcolor: colors.primary[300], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">A</Avatar>
                                        </ListItemIcon>
                                        <ListItemText>Activity</ListItemText>
                                    </MenuItem>}
                                    {props.inHeader && [
                                        <MenuItem key="item" value="item">
                                            <ListItemIcon>
                                                <Avatar sx={{ bgcolor: colors.purpleAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">I</Avatar>
                                            </ListItemIcon>
                                            <ListItemText>Item</ListItemText>
                                        </MenuItem>,
                                        <MenuItem key="directitem" value="directitem">
                                            <ListItemText>{(props.parent === "direct") ? 'Direct Item' : 'Indirect Item'}</ListItemText>
                                        </MenuItem>,
                                        <MenuItem key="previousitem" value="previousitem" disabled={!props.previousItem}>
                                            <ListItemText>Previous Item</ListItemText>
                                        </MenuItem>
                                    ]}
                                </Select>
                            </Box>
                            <Box display="flex" alignItems="center" flex="1">
                                {renderCategories()}
                            </Box>
                        </>
                }
            </Box>
        </Box>
        {renderSwitch()}
    </>
}