import {
    forwardRef,
    memo,
    MutableRefObject,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { ItemView } from 'Models/item';
import { Autocomplete, Box } from '@mui/material';
import { EstimateCategory } from 'Models/estimate';
import { useGetCategoriesQuery } from 'State/Services/category';
import { useGetUserDetailsQuery } from 'State/Services/user';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { GridTextField } from 'Components/GridTextField';
import { CustomCellEditorProps } from 'ag-grid-react';

export interface ItemCategoryEditCellRendererProps extends CustomCellEditorProps<ItemView, number> {
    estimateId: string;
    placeholder: string;
    level: number;
    setRef: (ref: MutableRefObject<any>) => void;
}

export default memo(
    forwardRef(({ value, onValueChange, setRef, level, estimateId, placeholder }: ItemCategoryEditCellRendererProps, ref) => {
        const { data: user } = useGetUserDetailsQuery();
        const refInput = useRef<HTMLInputElement>();
        const { data: storedCategories } = useGetCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId, refetchOnMountOrArgChange: true  });
        const [categories, setCategories] = useState<EstimateCategory[]>();
        const [open, setOpen] = useState(false);

        useEffect(() => {
            if (storedCategories && storedCategories.length > 0) {
                const cats = storedCategories.filter((c) => (c.level === level));
                setCategories(cats);
            }
        }, [level, storedCategories])

        const getInitialValue = useCallback(() => {
            let category: EstimateCategory | null = null;
            const cat = storedCategories?.find((cat) => (cat.displayId === value && cat.level === level));
            if (cat) {
                category = cat;
            }
            return category;
        }, [level, storedCategories, value]);

        const [category, setCategory] = useState<EstimateCategory | null>(getInitialValue());

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                setFocusOnAdd() {
                    if (refInput.current) {
                        refInput.current.focus();
                        refInput.current.select();
                        setOpen(true);
                    }
                },
            };
        });

        const handleCategoryChanged = useCallback((event: React.SyntheticEvent<Element, Event>, value: EstimateCategory | null) => {
            setCategory(value);
            onValueChange(value?.displayId);
        }, [onValueChange])

        useEffect(() => {
            if (refInput) {
                setRef(refInput);
            }
        }, [setRef])

        const handleClose = () => {
            setOpen(false);
        };

        const handleOpen = () => {
            setOpen(true);
        };

        return (
            <Box sx={{ width: "100%", height: "100% !important" }}>
                <Autocomplete
                    className="ag-input-field-input ag-text-field-input"
                    sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                    value={category ?? null}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    fullWidth
                    size="small"
                    onOpen={handleOpen}
                    onClose={handleClose}
                    open={open}
                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                    getOptionLabel={(option) => `${option.displayId} - ${option.category}`}
                    options={categories ?? []}
                    onChange={handleCategoryChanged}
                    renderInput={(params) => <GridTextField sx={{ height: "100%" }} inputRef={refInput} placeholder={`Select ${placeholder}`} {...params} />}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(`${option.displayId} - ${option.category}`, inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(`${option.displayId} - ${option.category}`, matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                />
            </Box>
        );
    })
);
