import React, {
    MutableRefObject,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Autocomplete, Chip } from '@mui/material';
import { useGetUserDetailsQuery } from 'State/Services/user';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { GridTextField } from 'Components/GridTextField';
import { useGetTagsQuery } from 'State/Services/tag';
import { Tag, VendorView } from 'Models/vendor';
import { CustomCellEditorProps } from 'ag-grid-react';

export interface IVendorTagCellEditorParams extends CustomCellEditorProps<VendorView> {
    setRef: (ref: MutableRefObject<any>) => void;
    estimateId: string;
    onValueChange: (value: Tag[]) => void;
}

export default forwardRef(({ value, estimateId, onValueChange, setRef }: IVendorTagCellEditorParams, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: tags } = useGetTagsQuery(
        {
            companyId: user?.companyId || '',
            organizationId: user?.organizationId || '',
            estimateId: estimateId || '',
        },
        { skip: !user?.companyId || !user?.organizationId || !estimateId }
    );

    const inputRef = useRef<any>();
    const [open, setOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState<Tag[]>(value);

    useImperativeHandle(ref, () => ({
        setFocusOnAdd() {
            inputRef.current?.focus();
            setOpen(true);
        },
        focusIn() {
            inputRef.current?.focus();
            setOpen(true);
        }
    }));

    useEffect(() => {
        setRef(inputRef);
    }, [setRef]);

    const handleChange = (event: React.SyntheticEvent, newTags: string[] | null) => {
        const tagsToSet = newTags?.map((newTag) =>
            tags?.find((tg) => tg.description === newTag) || { description: newTag }
        ) || [];
        setSelectedTags(tagsToSet);
        onValueChange(tagsToSet);
    };

    return (
        <Autocomplete
            fullWidth
            freeSolo
            multiple
            limitTags={2}
            open={open}
            size="small"
            className="ag-input-field-input ag-text-field-input"
            sx={{ border: 'none', background: 'white', minHeight: "40px", height: (value.length > 2) ? "auto" : "40px", width: "200px", "& .MuiInputBase-root": { minHeight: "40px" }, "& .MuiTextField-root": { height: "100%", minHeight: "40px" } }}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            value={selectedTags.map((tag) => tag.description)}
            onChange={handleChange}
            options={tags?.map((tag) => tag.description) ?? []}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <GridTextField sx={{ minHeight: "40px", height: (value.length > 2) ? "100%" : "40px" }} inputRef={inputRef} placeholder='Select/Enter Tag' {...params} />
            )}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option, inputValue, { insideWords: true });
                const parts = parse(option, matches);
                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    );
});
