import { Alert, Autocomplete, Box, TextField, useTheme } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import SplitButton, { MenuOption } from "Components/SplitButton";
import { AddOptions } from "Models/button-options";
import { FilterField } from "Models/resource";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useAppendIndirectItemDetailMutation } from "State/Services/indirect-item";
import { useAppendItemDetailMutation } from "State/Services/item";
import { useGetStandardItemByFilterQuery, useLazyGetStandardItemPagedQuery } from "State/Services/standard-item";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";

export interface QuickAppendStandardItemProps {
    estimateId: string;
    itemId: string;
    close: () => void;
    categoryId?: string;
    parent: 'direct' | 'indirect';
}

export interface FilteredStandardItem {
    id?: string;
    description: string;
    displayId?: string;
    disableSubElements?: boolean;
}

export default function QuickAppendStandardItem(props: QuickAppendStandardItemProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [pageError, setPageError] = useState<string | undefined>();
    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<Array<FilteredStandardItem>>([]);
    const [searchValue, setSearchValue] = useState<FilteredStandardItem>({ id: '', description: '' });
    const [addMenuItems] = useState<Array<MenuOption>>([{ option: AddOptions.AddElement, disabled: false }, { option: AddOptions.AddSubElements, disabled: false }]);
    const [appendItemDetail] = useAppendItemDetailMutation();
    const [appendIndirectItemDetail] = useAppendIndirectItemDetailMutation();
    const { data: filteredStandardItems } = useGetStandardItemByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '', categoryId: props.categoryId }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.description.length > 1) });
    const [getStandardItemsPaged] = useLazyGetStandardItemPagedQuery();

    const getContainerHeight = useCallback(() => {
        return pageError ? 116 : 68;
    }, [pageError]);

    useEffect(() => {
        if (filteredStandardItems && filteredStandardItems.length > 0) {
            setOptions(filteredStandardItems.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [filteredStandardItems])

    const setDefaultStandardItems = useCallback(async () => {
        const results = await getStandardItemsPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : '',
            categoryId: props.categoryId
        }, true).unwrap();
        if (results && results.length > 0) {
            setOptions(results.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                disableSubElements: !item.hasResourcesActivities
            })));
        }

    }, [getStandardItemsPaged, props, user])

    useEffect(() => {
        setDefaultStandardItems();
    }, [setDefaultStandardItems])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            setSearchValue({ description: newValue });
        } else {
            setSearchValue({ description: '' });
            setOpen(false);
            await setDefaultStandardItems();
        }
    }, [setDefaultStandardItems])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: FilteredStandardItem) => {
        if (newValue) {
            setSearchValue(newValue);
        } else {
            setSearchValue({ id: '', description: '', disableSubElements: false });
        }
        setOpen(false);
    }, []);

    const handleClose = useCallback(async (option?: string) => {
        try {
            setPageError(undefined);
            if (props.itemId) {
                if (props.parent === 'direct') {
                    await appendItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        itemId: props.itemId,
                        body: {
                            addOption: option === "Add sub-elements" ? 'sub-element' : 'element',
                            id: searchValue.id,
                            type: 'item'
                        }
                    }).unwrap();
                }else if (props.parent === 'indirect') {
                    await appendIndirectItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        indirectItemId: props.itemId,
                        body: {
                            addOption: option === "Add sub-elements" ? 'sub-element' : 'element',
                            id: searchValue.id,
                            type: 'item'
                        }
                    }).unwrap();
                }
                props.close();
            }
        } catch (error: any) {
            if (error && ('data' in error)) {
                if (error.data.page) {
                    setPageError(error.data.page);
                }
            }
        }
    }, [appendIndirectItemDetail, appendItemDetail, props, searchValue.id, user?.companyId, user?.organizationId]);

    return <Box padding="5px 5px 0px 5px" sx={{ width: 505, height: getContainerHeight() }} >
        {
            colors && <Box>
                {pageError && <Box marginBottom="15px"><Alert sx={{ padding: 0 }} severity="error">{pageError}</Alert></Box>}
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" flexDirection="column" marginBottom="5px" flex="1" marginRight="5px">
                        <Autocomplete
                            sx={{ '& .MuiTextField-root': { height: "1.4375em" } }}
                            disableClearable
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            value={searchValue}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.description}
                            filterOptions={(x) => x}
                            size="small"
                            onChange={onChange}
                            onInputChange={onInputChange}
                            options={options}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    sx={{ '& .MuiInputBase-root': { height: 45 } }}
                                    placeholder='Search Items...' />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.description, inputValue, { insideWords: true });
                                const parts = parse(option.description, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    </Box>

                    <Box display="flex" flexDirection="column" flex="0.1" marginBottom="5px">
                        <SplitButton
                            options={addMenuItems}
                            disablePortal={false}
                            buttonText="Add"
                            onButtonClick={handleClose}
                            onMenuItemClick={handleClose} />
                    </Box>
                </Box>
            </Box>
        }
    </Box>
}