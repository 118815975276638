import { BooleanAdvancedFilterModel, DateAdvancedFilterModel, DateStringAdvancedFilterModel, FilterModel, JoinAdvancedFilterModel, NumberAdvancedFilterModel, ObjectAdvancedFilterModel, TextAdvancedFilterModel } from "ag-grid-enterprise";
import { CompanyCurrency } from "./company-currency";
import { ResourceSubCategoryOption } from "./resource-category";
import { UnitView } from "./unit";

export interface BaseResource {
    id?: string;
    displayId?: string;
    description?: string;
    remarks?: string;
    basicRate?: number;
    rate?: number;
    resourceFactor?: number | null;
    isComposite?: boolean;
    unitId?: string;
    subCategoryId?: string;
    categoryId?: string;
    canDelete?: boolean;
    isSupplierRate?: boolean;
    supplierName?: string;
    masterReferenceId?: string;
    outOfSync?: boolean;
    canPush?: boolean;
}

export interface ResourceView extends BaseResource {
    amount?: number;
    unit?: UnitView;
    actions?: string;
    type?: string;
    subCategory?: ResourceSubCategoryOption;
    categoryDescription?: string;
    subCategoryDescription?: string;
    isNew?: boolean;
}

export interface FilteredResource {
    displayId?: string;
    resourceId?: string;
    description: string;
    basicRate?: number;
    resourceFactor?: number;
    rate?: number;
    unitId?: string;
    isComposite?: boolean;
}

export interface CalculatedResource {
    id: string;
    companyCurrencyId?: string;
    resourceId: string;
    amortizationPeriodDays?: number | null;
    rate?: number | null;
}

export interface CalculatedResourceView extends CalculatedResource{
    companyCurrency?: CompanyCurrency & {currencyOption: string},
}

export interface CompositeResource extends BaseResource {
    factor?: number;
    quantity?: number;
}
export interface CompositeResourceView extends CompositeResource {
    amount?: number;
    unit?: UnitView;
    actions?: string;
    selected?: boolean;
}

export interface CompositeResourceDto {
    resourceId: string;
    compositeId: string;
    factor?: number;
    quantity: number;
}
export interface ActivityResource extends ResourceView {
    quantity?: number;
    quantityPerUnit?: number;
    production?: number;
}
export interface ActivityResourceView extends ActivityResource {
    unit?: UnitView;
    actions?: string;
}
export interface ActivityResourceDto {
    activityId?: string;
    resourceId?: string;
    quantity?: number;
    quantityPerUnit?: number;
    production?: number;
}

export interface FactorUpdateRequestDto {
  filterModel:
    | FilterModel
    | (
        | JoinAdvancedFilterModel
        | (
            | TextAdvancedFilterModel
            | NumberAdvancedFilterModel
            | BooleanAdvancedFilterModel
            | DateAdvancedFilterModel
            | DateStringAdvancedFilterModel
            | ObjectAdvancedFilterModel
          )
      );
    factor: number | null;
}

export enum FilterField {
    id = "id",
    description = "description",
    both = "both"
}

export interface AppendResourceDto {
    id: string | undefined;
    addOption: 'element' | 'sub-element';
}

export interface AppendActivityResourceDto {
    id: string | undefined;
    type: "resource" | "activity";
    addOption: 'element' | 'sub-element';
}

export interface ErroredImportResource{
    reason: string;
    displayId: string;
    description: string;
    subcategoryDisplayId: string;
    basicRate: number;
    unit: string;
    factor?: number;
}


