import { AppendIndirectItemDto, BaseIndirectItem, FilteredIndirectItem, IndirectItemCreated, IndirectItemDetail, IndirectItemMinimalDto, QuickAddActivityIndirectDetailDto, QuickAddResourceIndirectDetailDto, QuickCreateActivityIndirectDetailDto, QuickCreateResourceIndirectDetailDto, QuickCreateStandardItemIndirectDetailDto } from 'Models/indirect-item';
import { api } from './api'
import { FilterField } from 'Models/resource';
import { IndirectItemCalculated } from 'Models/item';
import { Nav, NavItem } from 'Models/nav';

export const indirectItemApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIndirectItemsByFilter: build.query<Array<FilteredIndirectItem>, { organizationId: string, companyId: string, estimateId: string, filterText: string, field: FilterField }>({
      query: ({ organizationId, companyId, filterText, estimateId, field }: { organizationId: string, companyId: string, estimateId: string, filterText: string, field: FilterField }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-item/field/${field}/filter/${btoa(filterText)}` }),
      transformResponse: (response: Array<FilteredIndirectItem>) => { return [...response] },
      providesTags: ['IndirectItems']
    }),
    getIndirectItemNav: build.query<Nav, { organizationId: string, companyId: string, estimateId: string, indirectItemId: string }>({
      query: ({ organizationId, companyId, estimateId, indirectItemId }: { organizationId: string, companyId: string, estimateId: string, indirectItemId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/nav` }),
      providesTags: ['IndirectItems']
    }),
    getIndirectItemId: build.query<BaseIndirectItem, { organizationId: string, companyId: string, estimateId: string, indirectItemId: string }>({
      query: ({ organizationId, companyId, estimateId, indirectItemId }: { organizationId: string, companyId: string, estimateId: string, indirectItemId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}` }),
      providesTags: ['IndirectItems']
    }),
    getFilteredIndirectItemNavs: build.query<Array<NavItem>, { organizationId: string, companyId: string, estimateId: string, filterText: string }>({
      query: ({ organizationId, companyId, estimateId, filterText }: { organizationId: string, companyId: string, estimateId: string, filterText: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-item/filter-text/${btoa(filterText)}` }),
      providesTags: ['IndirectItems']
    }),
    getIndirectItemsPaged: build.query<Array<FilteredIndirectItem>, { organizationId: string, companyId: string, estimateId: string, skip: number, take: number }>({
      query: ({ organizationId, companyId, skip, estimateId, take }: { organizationId: string, companyId: string, estimateId: string, skip: number, take: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-item/paged?skip=${skip}&take=${take}` }),
      transformResponse: (response: Array<FilteredIndirectItem>) => { return [...response] },
      providesTags: ['IndirectItems']
    }),
    getIndirectItemMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-item/max-id` }),
      providesTags: ['IndirectItems']
    }),
    createIndirectItem: build.mutation<IndirectItemCreated, Partial<{ body: BaseIndirectItem, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItems', 'IndirectItemCategories'],
    }),
    deleteIndirectItem: build.mutation<BaseIndirectItem, Partial<{ orgId: string, companyId: string, estimateId: string, indirectItemId: string }>>({
      query({ orgId, companyId, estimateId, indirectItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItems'],
    }),
    updateIndirectItem: build.mutation<IndirectItemCreated, Partial<{ body: BaseIndirectItem, orgId: string, companyId: string, estimateId: string, indirectItemId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItems', 'IndirectItemCategories'],
    }),
    bulkUpdateIndirectItem: build.mutation<void, Partial<{ body: Array<IndirectItemMinimalDto>, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/bulk`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItems'],
    }),
    deleteIndirectItems: build.mutation<IndirectItemCreated, Partial<{ body: Array<string>, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/delete`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItems', 'Activities', 'Resources', 'StandardItems'],
    }),
    createIndirectItemDetail: build.mutation<IndirectItemDetail, Partial<{ body: IndirectItemDetail, orgId: string, companyId: string, estimateId: string, indirectItemId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/detail`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItems', 'IndirectItemDetails', 'Resources', 'Activities', 'StandardItems'],
    }),
    appendIndirectItemDetail: build.mutation<null, Partial<{ body: AppendIndirectItemDto, orgId: string, companyId: string, estimateId: string, indirectItemId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/append-detail`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItems', 'IndirectItemDetails', 'Resources', 'Activities', 'StandardItems'],
    }),
    saveIndirectItemAsStandardItem: build.mutation<null, Partial<{ orgId: string, companyId: string, estimateId: string, indirectItemId: string, overwrite: boolean }>>({
      query({ orgId, companyId, estimateId, indirectItemId, overwrite }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/save-standard-item?overwrite=${overwrite}`,
          method: 'POST',
          body: {},
        }
      },
      invalidatesTags: ['StandardItems', 'StandardItemCategories', 'StandardItemActivityResources'],
    }),
    updateIndirectItemDetail: build.mutation<IndirectItemDetail, Partial<{ body: IndirectItemDetail, orgId: string, companyId: string, estimateId: string, indirectItemId: string, indirectItemDetailId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId, indirectItemDetailId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/detail/${indirectItemDetailId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItemDetails', 'IndirectItems'],
    }),
    deleteIndirectItemDetail: build.mutation<IndirectItemDetail, Partial<{ body: IndirectItemDetail, orgId: string, companyId: string, estimateId: string, indirectItemId: string, indirectItemDetailId: string, type: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId, indirectItemDetailId, type }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/detail/${indirectItemDetailId}?type=${type}`,
          method: 'DELETE',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItemDetails', 'Activities', 'Resources', 'StandardItems', 'IndirectItems'],
    }),
    getIndirectItemDetailsForIndirectItem: build.query<Array<IndirectItemDetail>, { organizationId: string, companyId: string, estimateId: string, indirectItemId: string }>({
      query: ({ organizationId, companyId, estimateId, indirectItemId }: { organizationId: string, companyId: string, estimateId: string, indirectItemId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/detail` }),
      providesTags: ['IndirectItemDetails']
    }),
    createIndirectItemCalculated: build.mutation<IndirectItemCalculated, Partial<{ body: IndirectItemCalculated, orgId: string, companyId: string, estimateId: string, indirectItemId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/calculated`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItemDetails', 'IndirectItems', 'IndirectItemCalculated'],
    }),
    updateIndirectItemCalculated: build.mutation<IndirectItemCalculated, Partial<{ body: IndirectItemCalculated, orgId: string, companyId: string, estimateId: string, indirectItemId: string, calculatedId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId, calculatedId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/calculated/${calculatedId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'IndirectItemDetails', 'IndirectItems', 'IndirectItemCalculated'],
    }),
    getIndirectItemCalculated: build.query<IndirectItemCalculated, { organizationId: string, companyId: string, estimateId: string, indirectItemId: string, entityType: 'resource' | 'activity' | 'subitem' | 'item', entityId: string }>({
      query: ({ organizationId, companyId, estimateId, indirectItemId, entityId, entityType }:
        { organizationId: string, companyId: string, estimateId: string, indirectItemId: string, entityType: 'resource' | 'activity' | 'subitem' | 'item', entityId: string }) => (
        {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/${entityType}/${entityId}/calculated`
        }),
      providesTags: ['IndirectItemCalculated']
    }),
    quickCreateResourceIndirectDetail: build.mutation<void, Partial<{ body: QuickCreateResourceIndirectDetailDto, orgId: string, companyId: string, estimateId: string, indirectItemId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/quick-create/resource`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities',
        'Pricing', 'CompositeResources',
        'Resources', 'IndirectItemDetails',
        'FilteredResources', "StandardItemActivityResources",
        'ItemDetails', 'Activities', 'ActivityResources',
        'StandardItems', 'Items', 'IndirectItems'] : [])
    }),
    quickCreateActivityIndirectDetail: build.mutation<void, Partial<{ body: QuickCreateActivityIndirectDetailDto, orgId: string, companyId: string, estimateId: string, indirectItemId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/quick-create/activity`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'Activities', 'ActivityResources',
        'IndirectItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources",
        'ItemDetails', 'Items', 'IndirectItems'] : []),
    }),
    quickCreateStandardItemIndirectDetail: build.mutation<void, Partial<{ body: QuickCreateStandardItemIndirectDetailDto, orgId: string, companyId: string, estimateId: string, indirectItemId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/quick-create/standard-item`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['Pricing', 'ItemDetails','IndirectItems', 'IndirectItemDetails', 'StandardItems', 'Items'] : []),
    }),
    quickAddResourceIndirectDetail: build.mutation<void, Partial<{ body: QuickAddResourceIndirectDetailDto, orgId: string, companyId: string, estimateId: string, indirectItemId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/quick-add/resource`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities',
        'Pricing', 'CompositeResources',
        'Resources', 'IndirectItemDetails','IndirectItems',
        'FilteredResources', "StandardItemActivityResources",
        'ItemDetails', 'Activities', 'ActivityResources',
        'StandardItems', 'Items'] : []),
    }),
    quickAddActivityIndirectDetail: build.mutation<void, Partial<{ body: QuickAddActivityIndirectDetailDto, orgId: string, companyId: string, estimateId: string, indirectItemId: string }>>({
      query({ body, orgId, companyId, estimateId, indirectItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item/${indirectItemId}/quick-add/activity`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'Activities', 'ActivityResources',
        'IndirectItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources",
        'ItemDetails', 'Items', 'IndirectItems'] : []),
    }),
  })
});

export const {
  useQuickAddActivityIndirectDetailMutation,
  useQuickAddResourceIndirectDetailMutation,
  useQuickCreateActivityIndirectDetailMutation,
  useQuickCreateResourceIndirectDetailMutation,
  useQuickCreateStandardItemIndirectDetailMutation,
  useGetIndirectItemIdQuery,
  useLazyGetFilteredIndirectItemNavsQuery,
  useGetIndirectItemNavQuery,
  useLazyGetIndirectItemMaxIdQuery,
  useCreateIndirectItemCalculatedMutation,
  useUpdateIndirectItemCalculatedMutation,
  useLazyGetIndirectItemCalculatedQuery,
  useSaveIndirectItemAsStandardItemMutation,
  useGetIndirectItemsByFilterQuery,
  useAppendIndirectItemDetailMutation,
  useLazyGetIndirectItemsPagedQuery,
  useCreateIndirectItemMutation,
  useUpdateIndirectItemMutation,
  useDeleteIndirectItemMutation,
  useDeleteIndirectItemsMutation,
  useBulkUpdateIndirectItemMutation,
  useCreateIndirectItemDetailMutation,
  useGetIndirectItemDetailsForIndirectItemQuery,
  useUpdateIndirectItemDetailMutation,
  useDeleteIndirectItemDetailMutation
} = indirectItemApi


