import { Avatar, Box, ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, useTheme } from "@mui/material";
import { StandardItemActivityResource, StandardItemActivityResourceView, StandardItemView } from "Models/standard-item";
import { useDeleteStandardItemActivityMutation, useDeleteStandardItemResourceMutation, useGetStandardItemActivitiesResourcesQuery, useUpdateStandardItemActivityResourceMutation } from "State/Services/standard-item";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef, IRowNode, SuppressKeyboardEventParams } from "ag-grid-enterprise";
import { AgGridReact, getInstance } from "ag-grid-react";
import { useConfirm } from "material-ui-confirm";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import { CellClickedEvent, RowEditingStartedEvent, RowEditingStoppedEvent, CellKeyDownEvent, GetRowIdParams, ICellEditor } from "ag-grid-community";
import { sort } from "Helpers/sort";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { rounder } from "Helpers/rounder";
import StandardItemAppend from "./StandardItemAppend";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { PanelState } from "Models/panel";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import GenericNumberEditCellRenderer from "Components/Shared/GenericNumberEditCellRenderer";
import { BaseEditCellRenderer } from "Models/grid";
import GenericActionsCellRenderer from "Components/Shared/GenericActionsEditCellRenderer";

export interface AddEditStandardActivityResourceProps {
    currentStandardItem: StandardItemView;
    estimateId: string;
    onEdit: (state: boolean) => void;
    setStandardItemActivitiesResources: (standardItemActivitiesResources: Array<StandardItemActivityResourceView>) => void;
    panelState: PanelState;
}

const AddEditStandardActivityResource = (props: AddEditStandardActivityResourceProps) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 32px)', width: '100%' }), []);
    const theme = useTheme();
    const [colors, setColors] = useState<any>(tokens(theme.palette.mode));
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<StandardItemActivityResourceView>>(null);
    const [rowData, setRowData] = useState<Array<StandardItemActivityResourceView>>([]);
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<StandardItemActivityResourceView> | undefined, column?: string }>();
    const { data: storedStandardItemActivitiesResources } = useGetStandardItemActivitiesResourcesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '', standardItemId: props?.currentStandardItem.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !props?.currentStandardItem.id });
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [updateStandardItemActivityResource] = useUpdateStandardItemActivityResourceMutation();
    const confirm = useConfirm();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [deleteStandardActivity] = useDeleteStandardItemActivityMutation();
    const [deleteStandardResource] = useDeleteStandardItemResourceMutation();
    const [openAddDropdown, setOpenAddDropdown] = useState(false);
    const dropdownRef = useRef<HTMLButtonElement>(null);
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();
    const [errors, setErrors] = useState<Array<{ field: string, error: string }>>([]);

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((errorDetails) => {
                switch (errorDetails.field) {
                    case 'factor':
                        const instances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['factor']
                        });
                        if (instances.length > 0) {
                            getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
                                if (instance && instance.setError) {
                                    instance.setError(errorDetails.error);
                                }
                            });
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, [errors])

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const calculateAmount = useCallback((StandardItemActivityResource: StandardItemActivityResource) => {
        let amount: number | undefined = undefined;
        if (!StandardItemActivityResource.quantityPerUnit || !StandardItemActivityResource.rate) return;

        if (StandardItemActivityResource.factor) {
            amount = StandardItemActivityResource.quantityPerUnit * StandardItemActivityResource.rate * StandardItemActivityResource.factor;
        } else {
            amount = StandardItemActivityResource.quantityPerUnit * StandardItemActivityResource.rate;
        }

        return amount;
    }, [])

    const showHideCols = useCallback(() => {
        if (gridRef.current!.api) {
            gridRef.current!.api.setColumnsVisible(["quantity", "factor"], props.panelState.position.state !== "half");
        }
    }, [props.panelState])

    useEffect(() => {
        showHideCols();
    }, [props.panelState, showHideCols])

    const handleDropdownListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenAddDropdown(false);
        } else if (event.key === 'Escape') {
            setOpenAddDropdown(false);
        }
    }

    const calculateQuantity = useCallback((actRes: StandardItemActivityResource) => {
        let quantity = actRes.quantity ?? 0;
        if (actRes?.quantityPerUnit && props.currentStandardItem.quantity) {
            quantity = parseFloat(actRes.quantityPerUnit.toString()) * parseFloat(props.currentStandardItem.quantity.toString());
        }
        if (isNaN(quantity)) {
            return 0;
        }
        return quantity;
    }, [props.currentStandardItem.quantity])

    useEffect(() => {
        if (storedStandardItemActivitiesResources && units) {
            const rows = new Array<StandardItemActivityResourceView>();
            storedStandardItemActivitiesResources.forEach((det: StandardItemActivityResource) => {
                const unit = units?.find((unit) => (unit.id === det.unitId));
                const amount = calculateAmount(det);
                rows.push({
                    id: det.id,
                    description: det.description,
                    displayId: det.displayId,
                    amount: amount,
                    unitId: det.unitId,
                    unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined,
                    quantityPerUnit: det.quantityPerUnit,
                    type: det.type,
                    activityId: det.activityId,
                    factor: det.factor,
                    quantity: calculateQuantity(det),
                    resourceId: det.resourceId,
                    actions: "",
                    rate: det.rate,
                });
            });
            rows.sort(sort);
            setRowData(rows);
            props.setStandardItemActivitiesResources(rows);
        } else {
            setRowData([]);
            props.setStandardItemActivitiesResources([]);
        }
    }, [calculateAmount, calculateQuantity, props, storedStandardItemActivitiesResources, units])

    const saveStandardItemActivityResource = useCallback(async (nodeToSave: IRowNode<StandardItemActivityResourceView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<StandardItemActivityResourceView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && props.estimateId && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    if (nodeToSave.data.id && props?.currentStandardItem.id) {
                        await updateStandardItemActivityResource({
                            companyId: user?.companyId,
                            estimateId: props.estimateId,
                            standardItemId: props?.currentStandardItem.id,
                            orgId: user.organizationId,
                            body: {
                                activityId: nodeToSave.data.activityId ?? undefined,
                                quantity: nodeToSave.data.quantity ?? 0,
                                resourceId: nodeToSave.data.resourceId ?? undefined,
                                quantityPerUnit: nodeToSave.data.quantityPerUnit,
                                factor: nodeToSave.data.factor,
                                type: nodeToSave.data.type
                            }
                        }).unwrap();
                    }

                    resolve();
                    if (toEditAfterSave) {
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    } else {
                        setCurrentEditing(undefined);
                    }
                }
            } catch (error: any) {
                if (error && error.data) {
                    if (typeof nodeToSave.rowIndex === 'number') {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: 'displayId',
                        });
                    }
                    if (error.data.factor) {
                        setErrors([{ field: 'factor', error: error.data.factor }]);
                    }
                }
                reject();
            }
        });
    }, [props?.currentStandardItem.id, props.estimateId, updateStandardItemActivityResource, user])

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<StandardItemActivityResourceView>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();
            saveStandardItemActivityResource(params.node);
            return true;
        }
        return false;
    }, [saveStandardItemActivityResource])

    const cancelEditing = useCallback((node: IRowNode<StandardItemActivityResourceView>) => {
        if (node && node.data) {
            setIsCancelClicked(true);
            const standardActivityResource = storedStandardItemActivitiesResources?.find((c) => (c.id === node.data?.id));
            if (standardActivityResource) {
                const unit = units?.find((unit) => (unit.id === standardActivityResource.unitId));
                const amount = calculateAmount(standardActivityResource);
                gridRef.current!.api.applyTransaction({ update: [{ ...standardActivityResource, amount: amount, unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined }] });
            }
            gridRef.current!.api.stopEditing(true);
            setCurrentEditing(undefined);
        }
    }, [calculateAmount, storedStandardItemActivitiesResources, units])

    const deleteRow = useCallback((node: IRowNode<StandardItemActivityResourceView>) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await confirm({ description: `Are you sure you want to delete the record?`, title: 'Delete confirmation' });
                if (user && node.data) {
                    switch (node.data.type) {
                        case "resource":
                            await deleteStandardResource({
                                orgId: user.organizationId,
                                companyId: user.companyId,
                                estimateId: props.estimateId,
                                standardItemId: props.currentStandardItem.id,
                                standardItemResourceId: node.data.resourceId,
                            });
                            break;
                        case "activity":
                            await deleteStandardActivity({
                                orgId: user.organizationId,
                                companyId: user.companyId,
                                estimateId: props.estimateId,
                                standardItemId: props.currentStandardItem.id,
                                standardItemActivityId: node.data.activityId,
                            });
                            break;
                        default:
                            break;
                    }
                }

                resolve();
            } catch (error) {
                reject('Cancelled by user.');
            }
        });
    }, [confirm, deleteStandardActivity, deleteStandardResource, props.currentStandardItem.id, props.estimateId, user])

    const onChangeFactor = useCallback((node: IRowNode<StandardItemActivityResourceView>, value: number) => {
        if (node.data) {
            if (!node.data.rate) {
                node.setDataValue('amount', undefined);
                return;
            };

            if (isNaN(parseFloat(node.data.rate.toString()))) {
                node.setDataValue('amount', undefined);
                return;
            };

            const quantityPerUnitInstances = gridRef.current!.api.getCellEditorInstances({
                columns: ['quantityPerUnit']
            });
            if (quantityPerUnitInstances.length > 0) {
                getInstance<ICellEditor, BaseEditCellRenderer>(quantityPerUnitInstances[0], (instance) => {
                    let quantityPerUnit: number | undefined;
                    if (instance && instance.getValue) {
                        quantityPerUnit = instance.getValue();
                    }
                    if (!quantityPerUnit) {
                        node.setDataValue('amount', undefined);
                        return;
                    }

                    if (isNaN(parseFloat(quantityPerUnit.toString()))) {
                        node.setDataValue('amount', undefined);
                        return;
                    }
                    let amount: number | undefined = undefined;
                    if (node.data?.rate) {
                        if (value && !isNaN(value)) {
                            amount = quantityPerUnit * parseFloat(node.data.rate.toString()) * value;
                        } else {
                            amount = quantityPerUnit * parseFloat(node.data.rate.toString());
                        }
                        node.setDataValue('amount', amount);
                    }
                });
            }
        }
    }, [])
    const onChangeFactorRef = useRef<any>();
    onChangeFactorRef.current = onChangeFactor;

    const onChangeQuantity = useCallback((node: IRowNode<StandardItemActivityResourceView>, value: number) => {
        const instances = gridRef.current!.api.getCellEditorInstances({
            columns: ['quantityPerUnit']
        });
        if (instances.length > 0) {
            getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
                if (instance && instance.changeValue) {
                    if (!isNaN(value) && props.currentStandardItem.quantity) {
                        instance.changeValue(value / parseFloat(props.currentStandardItem.quantity.toString()));
                    } else {
                        instance.changeValue('');
                    }
                }
            });
        }
    }, [props.currentStandardItem.quantity])
    const onChangeQuantityRef = useRef<any>();
    onChangeQuantityRef.current = onChangeQuantity;

    const onChangeQuantityPerUnit = useCallback((node: IRowNode<StandardItemActivityResourceView>, value: number) => {
        if (node.data) {
            const quantityInstances = gridRef.current!.api.getCellEditorInstances({
                columns: ['quantity']
            });
            getInstance<ICellEditor, BaseEditCellRenderer>(quantityInstances[0], (instance) => {
                if (instance && instance.changeValue) {
                    if (!isNaN(value) && props.currentStandardItem.quantity) {
                        instance.changeValue(value * props.currentStandardItem.quantity);
                    } else {
                        instance.changeValue('');
                    }
                }
            });
            if (isNaN(value)) {
                node.setDataValue('amount', undefined);
                return;
            };

            if (!node.data?.rate) {
                node.setDataValue('amount', undefined);
                return;
            };

            if (isNaN(parseFloat(node.data.rate.toString()))) {
                node.setDataValue('amount', undefined);
                return;
            };

            const factorInstances = gridRef.current!.api.getCellEditorInstances({
                columns: ['factor']
            });
            getInstance<ICellEditor, BaseEditCellRenderer>(factorInstances[0], (instance) => {
                let factor: number | undefined;
                if (instance && node.data?.rate) {
                    factor = instance.getValue();
                    let amount: number | undefined = undefined;
                    if (factor && !isNaN(factor)) {
                        amount = value * parseFloat(node.data.rate.toString()) * factor;
                    } else {
                        amount = value * parseFloat(node.data.rate.toString());
                    }
                    node.setDataValue('amount', amount);
                }
            });
        }
    }, [props.currentStandardItem.quantity])
    const onChangeQuantityPerUnitRef = useRef<any>();
    onChangeQuantityPerUnitRef.current = onChangeQuantityPerUnit;

    const getBaseDefs = useCallback((): Array<ColDef<StandardItemActivityResourceView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'activityId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'resourceId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                headerName: 'ID',
                hide: false,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: props.panelState.position.state === 'half' ? 90 : 200,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
                editable: false,
                suppressKeyboardEvent: saveOnEnter,
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                editable: false,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                width: 75,
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                editable: false,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantityPerUnit',
                headerName: 'Quantity / Unit',
                width: props.panelState.position.state === 'half' ? 100 : 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    field: 'Quantity / Unit',
                    onChangeCallBack: (node: IRowNode<StandardItemActivityResourceView>, value: number | null) => onChangeQuantityPerUnitRef.current(node, value)
                },
                resizable: true,
                cellDataType: "number",
                editable: (params) => (!props.currentStandardItem.masterReferenceId),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                width: props.panelState.position.state === 'half' ? 100 : 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    field: 'Quantity',
                    onChangeCallBack: (node: IRowNode<StandardItemActivityResourceView>, value: number | null) => onChangeQuantityRef.current(node, value)
                },
                resizable: true,
                cellDataType: "number",
                hide: true,
                editable: (params) => (!props.currentStandardItem.masterReferenceId),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'factor',
                headerName: 'Factor',
                width: 90,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                suppressKeyboardEvent: saveOnEnter,
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    field: 'Factor',
                    onChangeCallBack: (node: IRowNode<StandardItemActivityResourceView>, value: number | null) => onChangeFactorRef.current(node, value)
                },
                cellDataType: "number",
                hide: true,
                editable: (params) => (!props.currentStandardItem.masterReferenceId),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                suppressKeyboardEvent: saveOnEnter,
                width: props.panelState.position.state === 'half' ? 100 : 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'amount',
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                width: props.panelState.position.state === 'half' ? 120 : 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                editable: false,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                }
            },
            {
                field: 'actions',
                width: 80,
                headerName: 'Actions',
                editable: false,
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRenderer: GenericActionsCellRenderer,
                cellRendererParams: {
                    save: saveStandardItemActivityResource,
                    cancel: cancelEditing,
                    deleteRecord: deleteRow,
                    disabled: () => !!props.currentStandardItem.masterReferenceId || !!editDisabledRef.current,
                    deleteDisabledMessage: "Unauthorized action."
                }
            },
        ]
    }, [props.panelState.position.state, props.currentStandardItem, colors?.gray, saveOnEnter, saveStandardItemActivityResource, cancelEditing, deleteRow, settings?.quantityDecimals, estimate?.CompanyCurrency?.Currency?.minorUnit]);

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const onRowEditingStarted = (event: RowEditingStartedEvent<StandardItemActivityResourceView>) => {
        setIsCancelClicked(false);
        if (!event.node.group) {
            event.api.refreshCells({
                columns: ["actions"],
                rowNodes: [event.node],
                force: true
            });
        }
    }

    const onRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent<StandardItemActivityResourceView>) => {
        if (isCancelClicked) {
            setIsCancelClicked(false);
            return;
        };

        if (props.currentStandardItem.masterReferenceId || editDisabledRef.current) return;

        if (currentEditing?.node === event.node) {
            return;
        }
        if (event.column.getColId() === 'actions') {
            return;
        }
        if (!currentEditing?.node) {
            if (event.column.isCellEditable(event.node)) {
                setCurrentEditing({ node: event.node, column: event.column.getColId() });
            } else {
                setCurrentEditing({ node: event.node, column: undefined });
            }
        } else {
            await saveStandardItemActivityResource(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
        }
    }, [currentEditing?.node, isCancelClicked, props.currentStandardItem.masterReferenceId, saveStandardItemActivityResource])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            props.onEdit(true);
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node?.rowIndex,
                colKey: currentEditing.column ?? 'quantityPerUnit',
            });
        } else {
            props.onEdit(false);
        }
    }, [currentEditing, props])

    const handleDropdownClose = async (event: Event | React.SyntheticEvent, type: 'activity' | 'resource' | "undefined") => {
        if (
            dropdownRef.current &&
            dropdownRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        if (type === "undefined") {
            return;
        }
        setOpenAddDropdown(false);
    };

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelEditing]);

    const getRowId = useCallback(function (params: GetRowIdParams<StandardItemActivityResourceView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    return <Box height="calc(100% - 200px)">
        <Box paddingBottom="5px" display="flex" alignItems="center" width="500px">
            <StandardItemAppend estimateId={props.estimateId} standardItemId={props.currentStandardItem.id} disabled={!!props.currentStandardItem.masterReferenceId || !!editDisabledRef.current} />
        </Box>
        <Popper
            open={openAddDropdown}
            anchorEl={dropdownRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            sx={{ width: "135px", zIndex: "1000" }}
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={(event) => handleDropdownClose(event, 'undefined')}>
                            <MenuList
                                autoFocusItem={openAddDropdown}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleDropdownListKeyDown}
                            >
                                <MenuItem onClick={(event) => handleDropdownClose(event, 'activity')}>
                                    <ListItemIcon>
                                        <Avatar sx={{ bgcolor: colors.primary[300], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">A</Avatar>
                                    </ListItemIcon>
                                    <ListItemText>Activity</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={(event) => handleDropdownClose(event, 'resource')}>
                                    <ListItemIcon>
                                        <Avatar sx={{ bgcolor: colors.blueAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">R</Avatar>
                                    </ListItemIcon>
                                    <ListItemText>Resource</ListItemText>
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>

        <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
            <AgGridReact<StandardItemActivityResourceView>
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                editType={'fullRow'}
                defaultColDef={defaultColDef}
                onRowEditingStarted={onRowEditingStarted}
                onRowEditingStopped={onRowEditingStopped}
                onGridReady={showHideCols}
                onCellClicked={onCellClicked}
                suppressClickEdit={true}
                getRowId={getRowId}
                onCellKeyDown={onCellKeyDown}
            />
        </Box>
    </Box>
}

export default React.memo(AddEditStandardActivityResource);