import { Autocomplete, Avatar, Box, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import QuickAddEditResource from "./Resources/QuickAddEditResource";
import { useCallback, useEffect, useState } from "react";
import { tokens } from "theme";
import QuickAddEditActivity from "./Activities/QuickAddEditActivity";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { ResourceCategory, ResourceSubCategoryOption } from "Models/resource-category";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useLazyGetResourceCategoriesQuery } from "State/Services/resource-category";
import { ActivityCategory } from "Models/activity-category";
import { useLazyGetActivityCategoriesQuery } from "State/Services/activity-category";
import QuickAddEditStandardItem from "./StandardItems/QuickAddEditStandardItem";

export interface QuickAddProps {
    type?: "resource" | "item" | "activity" | "subcontractor";
    parent: "direct" | "indirect";
    itemId: string;
    activityId?: string;
    standardItemId?: string;
    resourceId?: string;
    estimateId: string;
    inHeader: boolean;
    subItemId?: string;
    itemActivityId?: string;
    itemResourceId?: string;
    close: () => void;
}
export default function QuickCreate(props: QuickAddProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [selectedOption, setSelectedOption] = useState<"resource" | "item" | "activity">("resource");
    const [categoryId, setCategoryId] = useState<string>();
    const [resourceCategory, setResourceCategory] = useState<ResourceSubCategoryOption>();
    const [activityCategory, setActivityCategory] = useState<ActivityCategory>();
    const [resourceSubCategoryOptions, setResourceSubCategoryOptions] = useState<Array<ResourceSubCategoryOption>>([]);
    const [getResourceCategories] = useLazyGetResourceCategoriesQuery();
    const [storedResourceCategories, setStoredResourceCategories] = useState<Array<ResourceCategory>>();
    const [storedActivityCategories, setStoredActivityCategories] = useState<Array<ActivityCategory>>([]);
    const [getActivityCategories] = useLazyGetActivityCategoriesQuery();

    useEffect(() => {
        if (user?.companyId && user.organizationId) {
            getResourceCategories({
                companyId: user.companyId,
                estimateId: props.estimateId,
                organizationId: user.organizationId
            }, true).then((resourceCategoriesResponse) => {
                if (resourceCategoriesResponse.data) {
                    setStoredResourceCategories(resourceCategoriesResponse.data);
                } else {
                    setStoredResourceCategories([]);
                }
            });
        }
    }, [getResourceCategories, props.estimateId, user?.companyId, user?.organizationId])

    useEffect(() => {
        if (user?.companyId && user.organizationId) {
            getActivityCategories({
                companyId: user?.companyId,
                estimateId: props.estimateId,
                organizationId: user?.organizationId
            }, true).then((activityCategories) => {
                if (activityCategories.data) {
                    setStoredActivityCategories(activityCategories.data);
                } else {
                    setStoredActivityCategories([]);
                }
            });
        }
    }, [getActivityCategories, props.estimateId, user?.companyId, user?.organizationId])

    useEffect(() => {
        const options = new Array<ResourceSubCategoryOption>();
        if (storedResourceCategories && storedResourceCategories.length > 0) {
            storedResourceCategories.forEach((resourceCategory) => {
                resourceCategory.resourceSubCategories?.forEach((subCategory) => {
                    options.push({
                        ...subCategory,
                        resourceCategoryName: resourceCategory.description ?? ''
                    });
                });
            });
        }
        setResourceSubCategoryOptions(options);
    }, [storedResourceCategories]);

    const renderSwitch = useCallback(() => {
        switch (props.type) {
            case "activity":
            case "resource":
                return <QuickAddEditResource
                    inHeader={props.inHeader}
                    resourceId={props.resourceId}
                    close={props.close}
                    itemId={props.itemId}
                    estimateId={props.estimateId}
                    type={props.type}
                    activityId={props.activityId}
                    itemResourceId={props.itemResourceId}
                    itemActivityId={props.itemActivityId}
                    subItemId={props.subItemId}
                    parent={props.parent}
                    resourceSubCategoryId={categoryId}
                    standardItemId={props.standardItemId} />;
            case "item":
                switch (selectedOption) {
                    case 'resource':
                        return <QuickAddEditResource
                            inHeader={props.inHeader}
                            close={props.close}
                            itemId={props.itemId}
                            estimateId={props.estimateId}
                            subItemId={props.subItemId}
                            type={props.type}
                            parent={props.parent}
                            activityId={props.activityId}
                            standardItemId={props.standardItemId}
                            resourceSubCategoryId={categoryId} />
                    case 'activity':
                        return <QuickAddEditActivity
                            inHeader={props.inHeader}
                            close={props.close}
                            itemId={props.itemId}
                            estimateId={props.estimateId}
                            subItemId={props.subItemId}
                            type={props.type}
                            parent={props.parent}
                            activityCategoryId={categoryId}
                            standardItemId={props.standardItemId} />;
                    case 'item':
                        return <QuickAddEditStandardItem
                            close={props.close}
                            itemId={props.itemId}
                            parent={props.parent}
                            estimateId={props.estimateId} />
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }, [categoryId, props.activityId, props.close, props.estimateId, props.inHeader, props.itemActivityId, props.itemId, props.itemResourceId, props.parent, props.resourceId, props.standardItemId, props.subItemId, props.type, selectedOption])

    const handleChange = useCallback((event: SelectChangeEvent) => {
        setResourceCategory(undefined);
        setActivityCategory(undefined);
        setCategoryId(undefined);
        setSelectedOption(event.target.value as "resource" | "item" | "activity");
    }, []);

    const renderCategories = useCallback(() => {
        switch (selectedOption) {
            case "resource":
                return <>&nbsp;&nbsp;in <Autocomplete
                    size="small"
                    value={resourceCategory ?? null}
                    className="ag-input-field-input ag-text-field-input"
                    sx={{ height: "inherit", width: "300px", marginLeft: "10px", "& .MuiTextField-root:": { height: "inherit" } }}
                    options={resourceSubCategoryOptions}
                    groupBy={(option) => option.resourceCategoryName}
                    getOptionLabel={(option) => option.description ?? ''}
                    renderInput={(params) => <TextField name="subCategoryId" placeholder='Select Category' {...params} />}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    onChange={(event, value) => {
                        if (value) {
                            setResourceCategory(value);
                            setCategoryId(value.id);
                        } else {
                            setResourceCategory(undefined);
                            setCategoryId(undefined);
                        }
                    }}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.description ?? '', inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(option.description ?? '', matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                /></>
            case "activity":
                return <>&nbsp;&nbsp;in <Autocomplete
                    size="small"
                    value={activityCategory ?? null}
                    className="ag-input-field-input ag-text-field-input"
                    sx={{ height: "inherit", width: "300px", marginLeft: "10px", "& .MuiTextField-root:": { height: "inherit" } }}
                    options={storedActivityCategories}
                    getOptionLabel={(option) => option.description ?? ''}
                    renderInput={(params) => <TextField name="subCategoryId" placeholder='Select Category' {...params} />}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    onChange={(event, value) => {
                        if (value) {
                            setActivityCategory(value);
                            setCategoryId(value.id);
                        } else {
                            setResourceCategory(undefined);
                            setCategoryId(undefined);
                        }
                    }}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.description ?? '', inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(option.description ?? '', matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                /></>
        }
    }, [activityCategory, resourceCategory, resourceSubCategoryOptions, selectedOption, storedActivityCategories]);

    return <>
        <Box sx={{ padding: "5px 5px 5px 10px", fontWeight: 'bold' }} display="flex" alignItems="center">Create
            <Box display="flex" alignItems="center">
                {
                    (props.type === 'resource' || props.type === 'activity') ? <>&nbsp;resource{renderCategories()}</> :
                        <><Select
                            variant="standard"
                            size="small"
                            labelId="option-label"
                            sx={{

                                marginLeft: "8px",
                                '& .MuiSelect-select': {
                                    height: 15,
                                    display: "flex",
                                    border: 'none',
                                    alignItems: "center"
                                },
                                '& .MuiSelect-select .MuiListItemIcon-root': {
                                    minWidth: "35px",
                                    alignItems: "center",
                                }
                            }}
                            value={selectedOption}
                            label="Option"
                            onChange={handleChange}>
                            <MenuItem key="resource" value="resource">
                                <ListItemIcon>
                                    <Avatar sx={{ bgcolor: colors.blueAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">R</Avatar>
                                </ListItemIcon>
                                <ListItemText>Resource</ListItemText>
                            </MenuItem>
                            {(props.inHeader || props.type === "item") && <MenuItem key="activity" value="activity">
                                <ListItemIcon>
                                    <Avatar sx={{ bgcolor: colors.primary[300], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">A</Avatar>
                                </ListItemIcon>
                                <ListItemText>Activity</ListItemText>
                            </MenuItem>}
                            {props.inHeader && <MenuItem key="item" value="item">
                                <ListItemIcon>
                                    <Avatar sx={{ bgcolor: colors.purpleAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">I</Avatar>
                                </ListItemIcon>
                                <ListItemText>Item</ListItemText>
                            </MenuItem>}
                        </Select>{renderCategories()}</>
                }
            </Box>
        </Box>
        {renderSwitch()}
    </>
}

