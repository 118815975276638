import React, { useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useGetUnitsQuery } from 'State/Services/unit';
import { useGetUserDetailsQuery } from 'State/Services/user';

export default function GenericUnitCellRenderer(props: ICellRendererParams) {
    const [value, setValue] = useState<string>('');
    const { data: user } = useGetUserDetailsQuery();
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    
    useEffect(() => {
        if (props && props.data && props.data.unit && units){
            if (props.data.unit.unitId){
                const unt = units.find((unit) => (unit.id===props.data?.unit?.unitId));
                if (unt){
                    setValue(unt.description);
                }
            }else if (props.data.unit?.unitDescription){
                setValue(props.data.unit.unitDescription);
            }else{
                setValue('');
            }
        }
        
    }, [props, units])

    return (
        <span>{value}</span>
    );
};