import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Box, FormControl, InputAdornment, List, ListItem, OutlinedInput, useTheme } from '@mui/material';
import { HtmlTooltip } from 'Components/HtmlToolltip';
import { tokens } from 'theme';
import WarningIcon from '@mui/icons-material/Warning';
import { CustomCellEditorProps, useGridCellEditor } from 'ag-grid-react';
import { IRowNode } from 'ag-grid-enterprise';
import { BaseEditCellRenderer } from 'Models/grid';
import { getParsedValue, isBackspaceOrDelete, isLeftOrRight, isNumericKey } from 'Helpers/grid';

export interface GenericNumberEditCellRendererProps extends CustomCellEditorProps {
    field: string;
    onChangeCallBack?: (node: IRowNode, value: number | null) => void;
    shouldDisableCallBack?: (node: IRowNode) => boolean;
}

export default forwardRef(
    ({ value, node, eventKey, onValueChange, field, cellStartedEdit, onChangeCallBack, shouldDisableCallBack }: GenericNumberEditCellRendererProps, ref) => {
        const initialValue = useRef<string | null>(value);
        const [colors, setColors] = useState<any>();
        const theme = useTheme();
        useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
        const [disabled, setDisabled] = useState(false);
        const refInput = useRef<HTMLInputElement>(null);
        const [error, setError] = useState<string | undefined>(undefined);

        const updateValue = useCallback((val: string) => {
            const parsed = getParsedValue(val);
            onValueChange(parsed);
            if (onChangeCallBack) {
                onChangeCallBack(node, parsed);
            }
        }, [node, onChangeCallBack, onValueChange]);

        useEffect(() => {
            let startValue;
            let highlightAllOnFocus = true;

            if (eventKey === "Backspace" || eventKey === "Delete") {
                startValue = '';
            } else if (eventKey && eventKey.length === 1) {
                startValue = eventKey;
                highlightAllOnFocus = false;
            } else {
                startValue = initialValue.current;
                if (eventKey === "F2") {
                    highlightAllOnFocus = false;
                }
            }
            if (startValue == null) {
                startValue = "";
            }
            const parsed = getParsedValue(startValue);
            onValueChange(parsed);

            if (cellStartedEdit) {
                refInput.current?.focus();
                if (highlightAllOnFocus) {
                    refInput.current?.select();
                }
            }
            if (shouldDisableCallBack) {
                setDisabled(shouldDisableCallBack(node));
            }
        }, [cellStartedEdit, eventKey, node, onValueChange, shouldDisableCallBack]);

        const isCancelBeforeStart = useCallback(() => {
            return (
                !!eventKey &&
                eventKey.length === 1 &&
                "1234567890".indexOf(eventKey) < 0
            );
        }, [eventKey]);

        const onKeyDown = useCallback((event: any) => {
            if (isBackspaceOrDelete(event)) {
                event.stopPropagation();
                return;
            }

            if (!isLeftOrRight(event) && !isNumericKey(event) && event.key !== "Tab") {
                if (event.preventDefault) event.preventDefault();
            }
        }, []);

        useImperativeHandle<any, BaseEditCellRenderer>(ref, () => {
            return {
                setError(message: string) {
                    if (message) {
                        setError(message);
                    }
                },
                setFocusOnAdd() {
                    if (refInput.current) {
                        refInput.current.focus();
                    }
                },
                getValue() {
                    return value;
                },
                changeValue(value: string) {
                    const parsed = getParsedValue(value);
                    onValueChange(parsed ?? null);
                }
            };
        });

        useGridCellEditor({
            isCancelBeforeStart,
        });

        return (
            <Box sx={{ width: "100%", height: "100% !important" }}>
                <FormControl sx={{ height: 'inherit', width: "100%" }}>
                    <OutlinedInput
                        disabled={disabled}
                        sx={{ height: "inherit", backgroundColor: "white" }}
                        className="ag-input-field-input ag-text-field-input"
                        placeholder={field}
                        onChange={(event) => updateValue(event.target.value)}
                        type="number"
                        value={value}
                        endAdornment={error &&
                            <InputAdornment position="end">
                                <HtmlTooltip color={colors?.redAccent[500]}
                                    title={
                                        <List sx={{ listStyleType: 'disc', pl: 1 }}>
                                            <ListItem
                                                sx={{
                                                    padding: 0,
                                                    textAlign: "center",
                                                    listStyleType: "disc",
                                                    display: "list-item",
                                                }}
                                            >{error}</ListItem>
                                        </List>
                                    }>
                                    <WarningIcon sx={{ color: `${colors?.redAccent[500]}`, cursor: "pointer" }} />
                                </HtmlTooltip>
                            </InputAdornment>
                        }
                        inputRef={refInput}
                        onKeyDown={(event) => onKeyDown(event)}
                    />
                </FormControl>
            </Box>
        );
    });