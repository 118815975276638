import { Box, IconButton, Popover } from '@mui/material';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuickCreate from 'Components/QuickCreate';
import { ItemDetailView } from 'Models/item';
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { useGetEstimateQuery } from 'State/Services/estimate';
import { hasEstimatePermission } from 'Helpers/estimate-permissions';
import { Entity } from 'Models/estimate';
import { useGetItemIdQuery } from 'State/Services/item';
import QuickAdd from 'Components/QuickAdd';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { NavItem } from 'Models/nav';

export interface ItemDetailGroupCellRendererProps extends CustomCellRendererProps<ItemDetailView> {
    estimateId: string;
    itemId: string;
    previousItem: NavItem;
}

export default function ItemDetailGroupCellRenderer(props: ItemDetailGroupCellRendererProps) {
    const { node, value } = props;
    const [expanded, setExpanded] = useState(node.expanded);
    const [isHovered, setIsHovered] = useState(false);
    const [quickCreateAnchorEl, setQuickCreateAnchorEl] = useState<any>(null);
    const [quickAddAnchorEl, setQuickAddAnchorEl] = useState<any>(null);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: item } = useGetItemIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', itemId: props.itemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !props.itemId });
    const editDisabledRef = useRef<boolean>();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
            } else {
                if (node.data?.masterReferenceId) {
                    editDisabledRef.current = true;
                    return;
                };

                if (item?.assignedTo && item.assignedTo.length > 0) {
                    if (!item.assignedTo.some((assignedTo) => (assignedTo.id === user?.userId))) {
                        editDisabledRef.current = true;
                        return;
                    }
                }

                const hasItemEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [504]
                });
                const hasResourceEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [502]
                });
                const hasActivityEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [502]
                });
                const hasStandardItemEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Items,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasItemEditAccess || !hasResourceEditAccess || !hasActivityEditAccess || !hasStandardItemEditAccess;
            }
        } else {
            editDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, item?.assignedTo, node.data?.masterReferenceId, user])

    useEffect(() => {
        const expandListener = (event: any) => setExpanded(event.node.expanded);

        node.addEventListener('expandedChanged', expandListener);

        return () => {
            node.removeEventListener('expandedChanged', expandListener);
        };
    }, [node]);

    const onClick = useCallback(() => {
        node.setExpanded(!node.expanded);
    }, [node])

    const handleQuickCreateClose = () => {
        setQuickCreateAnchorEl(null);
    };

    const handleQuickAddClose = () => {
        setQuickAddAnchorEl(null);
    };

    const showHideAddCreate = useCallback(() =>{
        if (isHovered && node.data?.type !== 'subcontractor'){
            if (node.level===0 || (node?.allChildrenCount && node?.allChildrenCount > 0)) return 'visible';

            if (node.parent?.data?.type==='item' && node.level===1) return 'visible';
        }
        return 'hidden';
    }, [isHovered, node?.allChildrenCount, node.data?.type, node.level, node.parent?.data?.type])

    const quickCreateOpen = Boolean(quickCreateAnchorEl);
    const quickAddOpen = Boolean(quickAddAnchorEl);

    return (
        <Box display="flex"
            justifyContent="space-between"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: `${node.level * 20}px`, // Add consistent left padding based on level
            }}>

                {(node?.allChildrenCount && node?.allChildrenCount > 0) &&
                    <IconButton disableRipple disableFocusRipple sx={{
                        marginLeft: `${node.level * 10}px`,
                    }} ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e) => {
                            e.stopPropagation();
                            onClick();
                        };
                    }}>
                        {expanded ? <ExpandMoreIcon className="arrow-click" /> : <ChevronRightIcon className="arrow-click" />}
                    </IconButton>
                }
                <Box sx={{
                    marginLeft: node.allChildrenCount ? '0px' : `${node.level * 20}px`, // Add indentation for rows without chevrons
                }}>{value}</Box>
            </Box>
            {!editDisabledRef.current && <Box justifyContent="end">
                <IconButton onBlur={() => { setIsHovered(false) }} sx={{ visibility: showHideAddCreate() }}
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e) => {
                            e.stopPropagation();
                            setQuickCreateAnchorEl(e.currentTarget);
                        };
                    }}
                    aria-label="create"
                    color="primary" 
                    size="small">
                    <FiberNewRoundedIcon />
                </IconButton>
                <IconButton onBlur={() => { setIsHovered(false) }} sx={{ visibility: showHideAddCreate() }}
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e) => {
                            e.stopPropagation();
                            setQuickAddAnchorEl(e.currentTarget);
                        };
                    }}
                    aria-label="add"
                    color="primary" 
                    size="small">
                    <AddBoxRoundedIcon />
                </IconButton>
                <Popover
                    open={quickCreateOpen}
                    anchorEl={quickCreateAnchorEl}
                    onClose={handleQuickCreateClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}>
                    <QuickCreate
                        type={node.data?.type}
                        itemId={props.itemId}
                        estimateId={props.estimateId}
                        inHeader={false}
                        parent="direct"
                        resourceId={props.data?.resourceId}
                        itemResourceId={props.data?.itemResourceId}
                        activityId={props.data?.activityId}
                        itemActivityId={props.data?.itemActivityId}
                        standardItemId={props.data?.standardItemId}
                        subItemId={props.data?.subitemId}
                        close={handleQuickCreateClose} />
                </Popover>
                <Popover
                    open={quickAddOpen}
                    anchorEl={quickAddAnchorEl}
                    onClose={handleQuickAddClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}>
                    <QuickAdd
                        type={node.data?.type}
                        itemId={props.itemId}
                        estimateId={props.estimateId}
                        inHeader={false}
                        resourceId={props.data?.resourceId}
                        itemResourceId={props.data?.itemResourceId}
                        activityId={props.data?.activityId}
                        itemActivityId={props.data?.itemActivityId}
                        standardItemId={props.data?.standardItemId}
                        subItemId={props.data?.subitemId}
                        close={handleQuickAddClose} 
                        parent='direct' 
                        previousItem={props.previousItem} />
                </Popover>
            </Box>}
        </Box>
    );
};