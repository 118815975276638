import React, {
    MutableRefObject,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Autocomplete, Box } from '@mui/material';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { useGetUnitsQuery } from 'State/Services/unit';
import { Unit, UnitView } from 'Models/unit';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { GridTextField } from 'Components/GridTextField';
import { CustomCellEditorProps } from 'ag-grid-react';

export interface IGenericUnitCellEditorParams extends CustomCellEditorProps<any, UnitView> {
    setRef: (ref: MutableRefObject<any>) => void
}

export default forwardRef(({ value, onValueChange, cellStartedEdit, setRef }: IGenericUnitCellEditorParams, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const inputRef = useRef<any>();
    const initialValue = useRef<UnitView | null | undefined>(value);
    const [open, setOpen] = useState(false);

    const updateValue = useCallback((val: Unit) => {
        onValueChange({
            unitId: val?.id,
            unitDescription: val?.description
        });
    }, [onValueChange]);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    useEffect(() => {
        let valueToSet: Unit = { description: '' };
        if (initialValue.current?.unitDescription) {
            valueToSet = {
                description: initialValue.current?.unitDescription
            };
        }
        if (initialValue.current?.unitId && units && units.find((unit) => (unit.id === initialValue.current?.unitId))) {
            const unt = units.find((unit) => (unit.id === initialValue.current?.unitId));
            if (unt) {
                valueToSet = unt;
            }
        } else if (initialValue.current?.unitDescription) {
            valueToSet = { description: initialValue.current?.unitDescription };
        }
        updateValue(valueToSet);
        if (cellStartedEdit) {
            inputRef.current?.focus();
            inputRef.current?.select();
            handleOpen();
        }
    }, [cellStartedEdit, handleOpen, units, updateValue]);

    useImperativeHandle(ref, () => {
        return {
            setFocusOnAdd() {
                if (inputRef.current) {
                    inputRef.current.focus();
                    setOpen(true);
                }
            },
        };
    });

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    useEffect(() => {
        if (inputRef && setRef) {
            setRef(inputRef);
        }
    }, [setRef])

    return (
        <Box height="100%">
            {units && units.length > 0 && <Autocomplete
                fullWidth
                freeSolo
                className="ag-input-field-input ag-text-field-input"
                sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                value={value?.unitDescription || null}
                size="small"
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                    if (newValue && units) {
                        const unit = units.find((unt) => (unt.description === newValue));
                        if (unit) {
                            updateValue(unit);
                        } else {
                            updateValue({ description: newValue });
                        }
                    } else {
                        updateValue({ description: '' });
                    }
                }}
                onInputChange={(event: React.SyntheticEvent, newValue: string | null) => {
                    if (newValue && units) {
                        const unit = units.find((unt) => (unt.description === newValue));
                        if (unit) {
                            updateValue(unit);
                        } else {
                            updateValue({ description: newValue });
                        }
                    } else {
                        updateValue({ description: '' });
                    }
                }}
                options={units.map((unit) => unit.description) ?? []}
                renderInput={(params) => <GridTextField sx={{ height: "100%" }} inputRef={inputRef} placeholder='Select/Enter Unit' {...params} />}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option, inputValue, {
                        insideWords: true,
                    });
                    const parts = parse(option, matches);

                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
            }
        </Box>
    );
});
