import { Autocomplete, FormControl, TextField } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { SyntheticEvent, useCallback, useRef, useState } from "react";
import { useGetPackagesQuery } from "State/Services/subcontractor";
import { useGetUserDetailsQuery } from "State/Services/user";

export interface ReportSubcontractorPackage {
    id: string | undefined;
    name: string;
}

export interface SubcontractorPackageSelectorProps {
    selectedSubcontractorPackage?: ReportSubcontractorPackage;
    estimateId?: string;
    onSelect: (subcontractorPackage: ReportSubcontractorPackage) => void;
}

export default function SubcontractorPackageSelector(props: SubcontractorPackageSelectorProps) {
    const [open, setOpen] = useState<boolean>(false);
    const inputRef = useRef<any>();
    const { data: user } = useGetUserDetailsQuery();
    const { data: packages } = useGetPackagesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const [searchValue, setSearchValue] = useState<ReportSubcontractorPackage>(props.selectedSubcontractorPackage ? { id: props.selectedSubcontractorPackage.id, name: props.selectedSubcontractorPackage.name } : { id: '', name: '' });

    const onChange = useCallback(async (event: SyntheticEvent, newValue: ReportSubcontractorPackage) => {
        if (newValue) {
            setSearchValue(newValue);
            props.onSelect(newValue);
        } else {
            setSearchValue({ id: '', name: '' });
            props.onSelect({ id: '', name: '' });
        }
        setOpen(false);
    }, [props]);

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            setSearchValue({ name: newValue, id: undefined });
        } else {
            setSearchValue({ name: '', id: undefined });
            setOpen(false);
        }
    }, [])

    return <FormControl sx={{ width: "100%", height: 45, marginBottom: "10px" }} size="small">
        <Autocomplete
            fullWidth
            sx={{ height: "100%" }}
            disableClearable
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={searchValue}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            filterOptions={(x) => x}
            size="small"
            onChange={onChange}
            onInputChange={onInputChange}
            options={packages?.map(p => ({ name: p.name, id: p.id ?? undefined })) ?? []}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Select Packages"
                    sx={{ '& .MuiInputBase-root': { height: 45 } }}
                    inputRef={inputRef}
                    placeholder='Search...' />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.name, inputValue, { insideWords: true });
                const parts = parse(option.name, matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    </FormControl>
}