import { Alert, Box, IconButton, Step, StepLabel, Stepper, Typography, useTheme } from "@mui/material";
import { Estimate } from "Models/estimate";
import { useCallback, useState } from "react";
import { tokens } from "theme";
import CloseIcon from '@mui/icons-material/Close';
import CreateEstimateSelection from "./CreateEstimateSelection";
import CreateUpdateEstimate from "./CreateUpdateEstimate";
import { Master } from "Models/master";
import { useGetUserDetailsQuery } from "State/Services/user";
import { Errors } from "Models/errors";
import { Selections } from "./Selections";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 2,
};

export interface CreateEstimateStepperModalProps {
    estimates: Array<Estimate> | undefined;
    masters: Array<Master> | undefined;
    estimate: Estimate | undefined;
    close: () => void;
    save: (estimate: Estimate, selections?: Selections) => Promise<void>;
}
const steps = ['Select source', 'Create estimate'];
export default function CreateEstimateStepper(props: CreateEstimateStepperModalProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [activeStep, setActiveStep] = useState(0);
    const [selections, setSelections] = useState<Selections | null>();
    const [option, setOption] = useState<"estimate" | "master" | "edit">("estimate");
    const [pageError, setPageError] = useState<string | undefined>();

    const handleClose = useCallback(() => {
        props.close();
    }, [props])

    const handleNext = (selections: Selections | null) => {
        setSelections(selections);
        switch (selections?.selectedOption) {
            case "ImportFromEstimate":
                setOption("estimate");
                break;
            case "ImportFromMaster":
                setOption("master");
                break;
            case "CreateFromScratch":
                setOption("edit");
                break;
            default:
                break;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const save = useCallback(async (estimate: Estimate) => {
        try {
            if (user && selections) {
                await props.save(estimate, selections);
                props.close();
            }
        } catch (error: any) {
            if ('name' in error.data) {
                throw error;
            }else{
                setPageError(Errors.generic);
            }
        }

    }, [props, selections, user])

    return <Box sx={style}>
        <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            marginBottom="10px"
            borderBottom={`1px solid ${colors?.gray[800]}`}>
            <Box padding="10px">
                {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Create new estimate</Typography>}
            </Box>
            <Box>
                <IconButton aria-label="delete" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                    optional?: React.ReactNode;
                } = {};
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
        {pageError && <Box marginTop="10px"><Alert severity="error">{pageError}</Alert></Box>}
        <Box display="flex" flex="1" justifyContent="center" marginTop="20px">
            {steps[activeStep] === 'Select source' && <>
                <CreateEstimateSelection estimate={props.estimate} close={handleClose} selections={selections} saveAndNext={handleNext} estimates={props.estimates} masters={props.masters} />
            </>}
            {steps[activeStep] === 'Create estimate' && <>
                <CreateUpdateEstimate selections={selections} selectedOption={option} master={selections?.selectedMaster ?? undefined} secondaryButtonText="Previous" close={handleBack} estimate={selections?.selectedEstimate ?? undefined} save={save} />
            </>}
        </Box>
    </Box>
}