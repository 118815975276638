import { useCallback, useMemo, useState } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button, TextField, useTheme, Autocomplete } from "@mui/material";
import { useGetCategoriesQuery } from "State/Services/category";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { EstimateCategory } from "Models/estimate";
import { useBulkUpdateItemMutation } from "State/Services/item";
import { ItemMinimalDto, ItemView } from "Models/item";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useGetItemCodesQuery } from "State/Services/item-code";
export interface EditItemsProps {
    items: Array<ItemView>;
    estimateId: string;
    noOfLevels: number;
    closeModal: () => void;
}

export function EditItems(props: EditItemsProps) {
    const theme = useTheme();
    const [bulkUpdate] = useBulkUpdateItemMutation();
    const { data: user } = useGetUserDetailsQuery();
    const { data: categories } = useGetCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId, refetchOnMountOrArgChange: true  });
    const { data: itemCodes } = useGetItemCodesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId, refetchOnMountOrArgChange: true  });
    const [levelCategories, setLevelCategories] = useState<Array<EstimateCategory> | undefined>();
    const [colors, setColors] = useState<any>();
    const [propertySelected, setPropertySelected] = useState('');
    const [page, setPage] = useState<string | undefined>();
    const [itemCode, setItemCode] = useState<string>('');
    const [units, setUnits] = useState<string | undefined>();
    const [selectedStatus, setSelectedStatus] = useState<"Not Priced" | "In Progress" | "In Review" | "Done">();
    const [selectedCategory, setSelectedCategory] = useState<EstimateCategory | null | undefined>();
    const [isSaving, setIsSaving] = useState(false);
    const handleChange = (event: SelectChangeEvent) => {
        setPage(undefined);
        setUnits(undefined);
        setSelectedCategory(undefined);
        switch (event.target.value) {
            case 'Category 1':
                setLevelCategories(categories?.filter((cat) => {
                    return cat.level === 1;
                }));
                break;
            case 'Category 2':
                setLevelCategories(categories?.filter((cat) => {
                    return cat.level === 2;
                }));
                break;
            case 'Category 3':
                setLevelCategories(categories?.filter((cat) => {
                    return cat.level === 3;
                }));
                break;
            case 'Category 4':
                setLevelCategories(categories?.filter((cat) => {
                    return cat.level === 4;
                }));
                break;
            default:
                break;
        }
        setPropertySelected(event.target.value as string);
    };
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);

    const handleCategoryChanged = useCallback((event: React.SyntheticEvent<Element, Event>, value: EstimateCategory | null) => {
        setSelectedCategory(value);
    }, [])

    const onSubmit = useCallback(async () => {
        let itemsMinimalDtos = new Array<ItemMinimalDto>();
        if (props.items && props.items.length > 0 && user) {
            setIsSaving(true);
            switch (propertySelected) {
                case 'Category 1':
                    if (selectedCategory) {
                        itemsMinimalDtos = props.items.map((item) => {
                            return {
                                category2Id: item.category2Id,
                                category3Id: item.category3Id,
                                category4Id: item.category4Id,
                                customUnit: item.unit?.unitDescription,
                                page: item.page,
                                id: item.id,
                                category1Id: selectedCategory?.id,
                                code: item.code,
                                status: item.status
                            }
                        });
                    }
                    break;
                case 'Category 2':
                    if (selectedCategory) {
                        itemsMinimalDtos = props.items.map((item) => {
                            return {
                                category1Id: item.category1Id,
                                category3Id: item.category3Id,
                                category4Id: item.category4Id,
                                customUnit: item.unit?.unitDescription,
                                page: item.page,
                                id: item.id,
                                category2Id: selectedCategory?.id,
                                code: item.code,
                                status: item.status
                            }
                        });
                    }

                    break;
                case 'Category 3':
                    if (selectedCategory) {
                        itemsMinimalDtos = props.items.map((item) => {
                            return {
                                category1Id: item.category1Id,
                                category2Id: item.category2Id,
                                category4Id: item.category4Id,
                                customUnit: item.unit?.unitDescription,
                                page: item.page,
                                id: item.id,
                                category3Id: selectedCategory?.id,
                                code: item.code,
                                status: item.status
                            }
                        });
                    }
                    break;
                case 'Category 4':
                    if (selectedCategory) {
                        itemsMinimalDtos = props.items.map((item) => {
                            return {
                                category1Id: item.category1Id,
                                category2Id: item.category2Id,
                                category3Id: item.category3Id,
                                customUnit: item.unit?.unitDescription,
                                page: item.page,
                                id: item.id,
                                category4Id: selectedCategory?.id,
                                code: item.code,
                                status: item.status
                            }
                        });
                    }
                    break;
                case 'Page':
                    if (page) {
                        itemsMinimalDtos = props.items.map((item) => {
                            return {
                                category1Id: item.category1Id,
                                category2Id: item.category2Id,
                                category3Id: item.category3Id,
                                category4Id: item.category4Id,
                                customUnit: item.unit?.unitDescription,
                                page: page,
                                id: item.id,
                                code: item.code,
                                status: item.status
                            }
                        });
                    }
                    break;
                case 'Unit':
                    if (units) {
                        itemsMinimalDtos = props.items.map((item) => {
                            return {
                                category1Id: item.category1Id,
                                category2Id: item.category2Id,
                                category3Id: item.category3Id,
                                category4Id: item.category4Id,
                                page: item.page,
                                customUnit: units,
                                id: item.id,
                                code: item.code,
                                status: item.status
                            }
                        });
                    }
                    break;
                case 'Code':
                    if (itemCode) {
                        itemsMinimalDtos = props.items.map((item) => {
                            return {
                                category1Id: item.category1Id,
                                category2Id: item.category2Id,
                                category3Id: item.category3Id,
                                category4Id: item.category4Id,
                                page: item.page,
                                customUnit: item.unit?.unitDescription,
                                id: item.id,
                                code: itemCode,
                                status: item.status
                            }
                        });
                    }
                    break;
                case 'Status':
                    if (selectedStatus) {
                        itemsMinimalDtos = props.items.map((item) => {
                            return {
                                category1Id: item.category1Id,
                                category2Id: item.category2Id,
                                category3Id: item.category3Id,
                                category4Id: item.category4Id,
                                page: item.page,
                                customUnit: item.unit?.unitDescription,
                                id: item.id,
                                code: item.code,
                                status: selectedStatus
                            }
                        });
                    }
                    break;
                default:
                    break;
            }
            await bulkUpdate({
                body: itemsMinimalDtos,
                companyId: user.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId
            });
            setIsSaving(false);
            props.closeModal();
        }
    }, [bulkUpdate, itemCode, page, propertySelected, props, selectedCategory, selectedStatus, units, user])

    return <>
        <Box margin="40px">
            <Box marginBottom="20px">
                <FormControl fullWidth>
                    <InputLabel id="prop-label">Select Property</InputLabel>
                    <Select
                        labelId="prop-label"
                        value={propertySelected}
                        label="Select Property"
                        onChange={handleChange}
                    >
                        <MenuItem value='Page'>Page</MenuItem>
                        <MenuItem value='Unit'>Unit</MenuItem>
                        <MenuItem value='Status'>Status</MenuItem>
                        <MenuItem value='Code'>Code</MenuItem>
                        {[...Array(props.noOfLevels)].map((x, i) => {
                            return <MenuItem key={i} value={`Category ${i + 1}`}>{`Category ${i + 1}`}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Box>
            {propertySelected === "Page" &&
                <Box marginBottom="20px">
                    <FormControl fullWidth>
                        <TextField
                            label={`Set ${propertySelected}`}
                            value={page}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUnits(undefined);
                                setPage(event.target.value);
                            }}
                            variant="outlined" />
                    </FormControl>
                </Box>
            }
            {propertySelected === "Unit" &&
                <Box marginBottom="20px">
                    <FormControl fullWidth>
                        <TextField
                            value={units}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUnits(event.target.value);
                                setPage(undefined);
                            }}
                            label={`Set ${propertySelected}`}
                            variant="outlined" />
                    </FormControl>
                </Box>
            }
            {propertySelected === "Status" &&
                <Box display="flex" flexDirection="column" width="100%" marginBottom="20px">
                    <FormControl fullWidth>
                        <InputLabel id="status-select-label">Status</InputLabel>
                        <Select
                            labelId="status-select-label"
                            onChange={(event) => {
                                setSelectedStatus(event.target.value as "Not Priced" | "In Progress" | "In Review" | "Done");
                            }}
                            value={selectedStatus}
                            label="Status"
                        >
                            <MenuItem value={"Not Priced"}>Not Priced</MenuItem>
                            <MenuItem value={"In Progress"}>In Progress</MenuItem>
                            <MenuItem value={"In Review"}>In Review</MenuItem>
                            <MenuItem value={"Done"}>Done</MenuItem>
                        </Select>
                    </FormControl>
                </Box>}
            {propertySelected === "Code" &&
                <Box marginBottom="20px">
                    <FormControl fullWidth>
                        <Autocomplete
                            fullWidth
                            freeSolo
                            value={itemCode ?? null}
                            onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                                if (newValue) {
                                    if (itemCodes) {
                                        const itemCode = itemCodes?.find((itemCode) => (itemCode.code === newValue));
                                        if (itemCode) {
                                            setItemCode(itemCode.code);
                                        } else {
                                            setItemCode(newValue);
                                        }
                                    }
                                } else {
                                    setItemCode('');
                                }
                            }}
                            onInputChange={(event: React.SyntheticEvent, newValue: string | null) => {
                                if (newValue) {
                                    if (itemCodes) {
                                        const itemCode = itemCodes?.find((itemCode) => (itemCode.code === newValue));
                                        if (itemCode) {
                                            setItemCode(itemCode.code);
                                        } else {
                                            setItemCode(newValue);
                                        }
                                    }
                                } else {
                                    setItemCode('');
                                }
                            }}
                            options={itemCodes?.map((itemCode) => itemCode.code) ?? []}
                            renderInput={(params) => <TextField {...params} label={`Select ${propertySelected}`} />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    </FormControl>
                </Box>
            }
            {(propertySelected === "Category 1" || propertySelected === "Category 2" || propertySelected === "Category 3" || propertySelected === "Category 4") && levelCategories && levelCategories.length > 0 &&
                <Box marginBottom="20px">
                    <FormControl fullWidth>
                        <Autocomplete
                            value={selectedCategory ?? null}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                            disablePortal
                            getOptionLabel={(option) => option.category ?? ""}
                            options={levelCategories}
                            onChange={handleCategoryChanged}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label={`Select ${propertySelected}`} />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.category, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.category, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    </FormControl>
                </Box>
            }
            <Box display="flex" justifyContent="center">
                <LoadingButton
                    variant="contained"
                    sx={{ marginRight: "10px", backgroundColor: `${colors?.primary[400]}` }}
                    disabled={!(page || units || selectedCategory || itemCode || selectedStatus)}
                    type="submit"
                    endIcon={<SaveIcon />}
                    loading={isSaving}
                    loadingPosition="end"
                    onClick={onSubmit}>
                    Update
                </LoadingButton>

                <Button variant="contained" color="secondary"
                    sx={{ backgroundColor: `${colors?.gray[1000]}` }} onClick={props.closeModal}>Cancel</Button>
            </Box>
        </Box>
    </>;
}