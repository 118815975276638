import { Autocomplete, FormControl, TextField } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { FilterField } from "Models/resource";
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useGetResourcesByFilterQuery, useLazyGetResourcesPagedQuery } from "State/Services/resource";
import { useGetUserDetailsQuery } from "State/Services/user";

export interface ReportResource {
    id?: string;
    description: string;
    displayId?: string;
    disableSubElements?: boolean;
}

export interface ResourceSelectorProps {
    selectedResource?: ReportResource;
    estimateId?: string;
    onSelect: (resource: ReportResource) => void;
}

export default function ResourceSelector(props: ResourceSelectorProps) {
    const [open, setOpen] = useState<boolean>(false);
    const inputRef = useRef<any>();
    const { data: user } = useGetUserDetailsQuery();
    const [getResourcesPaged] = useLazyGetResourcesPagedQuery();
    const [options, setOptions] = useState<Array<ReportResource>>([]);
    const [searchValue, setSearchValue] = useState<ReportResource>(props.selectedResource ? { id: props.selectedResource.id, description: props.selectedResource.description, disableSubElements: false } : { id: '', description: '', disableSubElements: false });
    const { data: filteredResources } = useGetResourcesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.description.length > 1) });

    useEffect(() => {
        if (filteredResources && filteredResources.length > 0) {
            setOptions(filteredResources.map((res) => ({
                description: `${res.displayId} - ${res.description}`,
                id: res.resourceId,
                displayId: res.displayId,
                disableSubElements: !res.isComposite
            })));
        }
    }, [filteredResources])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: ReportResource) => {
        if (newValue) {
            setSearchValue(newValue);
            props.onSelect(newValue);
        } else {
            setSearchValue({ id: '', description: '', disableSubElements: false });
            props.onSelect({ id: '', description: '', disableSubElements: false });
        }
        setOpen(false);
    }, [props]);

    const setDefaultResources = useCallback(async () => {
        const response = await getResourcesPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true);
        if ('data' in response) {
            const results = response.data;
            if (results && results.length > 0) {
                setOptions(results.map((res) => ({
                    description: `${res.displayId} - ${res.description}`,
                    id: res.resourceId,
                    displayId: res.displayId,
                    disableSubElements: !res.isComposite
                })));
            }
        }
    }, [getResourcesPaged, props, user])

    useEffect(() => {
        setDefaultResources();
    }, [setDefaultResources])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            setSearchValue({ description: newValue });
        } else {
            setSearchValue({ description: '' });
            setOpen(false);
            await setDefaultResources();
        }
    }, [setDefaultResources])

    return <FormControl sx={{ width: "100%", height: 45, marginBottom: "10px" }} size="small">
        <Autocomplete
            fullWidth
            sx={{ height: "100%" }}
            disableClearable
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={searchValue}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.description}
            filterOptions={(x) => x}
            size="small"
            onChange={onChange}
            onInputChange={onInputChange}
            options={options}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Select Resource"
                    sx={{ '& .MuiInputBase-root': { height: 45 } }}
                    inputRef={inputRef}
                    placeholder='Search...' />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.description, inputValue, { insideWords: true });
                const parts = parse(option.description, matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    </FormControl>
}