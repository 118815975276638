import { SupplierResourceReport, SupplierResourceReportQuoteView } from "Models/report";
import { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useGetUserDetailsQuery } from "State/Services/user";
import { Box, useTheme } from "@mui/material";
import { tokens } from "theme";
import { useGetEstimateQuery } from "State/Services/estimate";
import { v4 as uuidv4 } from 'uuid';
import { ColDef, ColGroupDef, GetRowIdParams } from "ag-grid-enterprise";
import { rounder } from "Helpers/rounder";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import SubcontractorQuotesVendorHeaderRenderer from "./SubcontractorQuotesVendorHeaderRenderer";
import { md5 } from "js-md5";
import { ReportSupplierPackage } from "./SupplierPackageSelector";
import { useGetSupplierPackageVendorsQuery } from "State/Services/supplier";
import { Unit } from "Models/unit";

export interface SupplierQuotesReportProps {
    estimateId?: string;
    pageSize: number;
    supplierResourceReport: SupplierResourceReport,
    selectedSupplierPackage?: ReportSupplierPackage;
    units: Array<Unit>;
}

export default function SupplierQuotesReport(props: SupplierQuotesReportProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const gridRef = useRef<AgGridReact<SupplierResourceReportQuoteView>>(null);
    const [quoteItems, setQuoteItems] = useState<Array<SupplierResourceReportQuoteView>>([]);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: packageVendors } = useGetSupplierPackageVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', packageId: props.selectedSupplierPackage?.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.selectedSupplierPackage })
    const packageVendorsRef = useRef<any>();
    packageVendorsRef.current = packageVendors;

    const getBaseDefs = useCallback((): Array<ColDef<SupplierResourceReportQuoteView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'resourceDisplayId',
                headerName: 'ID',
                cellRenderer: 'agGroupCellRenderer',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 150,
                pinned: "left",
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            },
            {
                field: 'description',
                width: 300,
                pinned: "left",
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                pinned: "left",
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                width: 175,
                pinned: "left",
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
        ];
    }, [colors?.gray, estimate?.CompanyCurrency?.Currency?.minorUnit]);

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const getRowId = useCallback(function (params: GetRowIdParams<SupplierResourceReportQuoteView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const getNewColDefs = useCallback((vendorName?: string, vendorIdHash?: string): ColDef | ColGroupDef => {
        return {
            groupId: vendorIdHash,
            headerGroupComponent: SubcontractorQuotesVendorHeaderRenderer,
            headerGroupComponentParams: {
                vendorName: vendorName,
                estimateId: props.estimateId,
                vendorHash: vendorIdHash,
                packageVendors: () => packageVendorsRef.current,
                packageId: props.selectedSupplierPackage?.id,
            },
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
            children: [
                {
                    field: `vendor_id_${vendorIdHash}`,
                    hide: true,
                    width: 200,
                    editable: false,
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                },
                {
                    field: `quotedRate_${vendorIdHash}`,
                    headerName: "Quoted Rate",
                    cellDataType: "number",
                    width: 150,
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                },
                {
                    field: `rate_${vendorIdHash}`,
                    headerName: "Rate",
                    width: 150,
                    valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                    cellDataType: "number",
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: `amount_${vendorIdHash}`,
                    headerName: "Amount",
                    width: 150,
                    valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                    cellDataType: "number",
                    aggFunc: 'sum',
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: `isChecked_${vendorIdHash}`,
                    cellDataType: 'boolean',
                    width: 50,
                    editable: false,
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}`, justifyContent: "center" }
                },
            ]
        };
    }, [colors?.gray, estimate?.CompanyCurrency?.Currency?.minorUnit, props.estimateId, props.selectedSupplierPackage?.id])

    const attachVendorColValues = useCallback((packageItem: { vendorId?: string, vendorName?: string, selection?: boolean, quotedRate?: number, amount?: number, rate?: number }, qt: SupplierResourceReportQuoteView) => {
        if (packageItem.vendorId) {
            const vendorIdHash = md5(packageItem.vendorId);
            qt[`vendor_id_${vendorIdHash}`] = packageItem.vendorId;
            qt[`quotedRate_${vendorIdHash}`] = packageItem.quotedRate;
            qt[`rate_${vendorIdHash}`] = packageItem.rate;
            qt[`isChecked_${vendorIdHash}`] = packageItem.selection;
            qt[`amount_${vendorIdHash}`] = (packageItem.amount) ? parseFloat(packageItem.amount.toFixed((estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)) : null;
        }
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit])

    const attachVendorColumns = useCallback((vendor: { id: string, name: string }, vendors: string[], colDefs: any) => {
        const vendorIdHash = md5(vendor.id);
        if (!vendors.includes(vendorIdHash)) {
            vendors.push(vendorIdHash);
        }
        if (!colDefs?.some((col: any) => (col.children?.some((child: any) => child.field === `vendor_id_${vendorIdHash}`)))) {
            colDefs.push(getNewColDefs(vendor.name, vendorIdHash));
        }
    }, [getNewColDefs])

    const setupGrid = useCallback(async () => {
        if (gridRef.current!.api) {
            const qItems = new Array<SupplierResourceReportQuoteView>();
            const colDefs = gridRef.current!.api.getColumnDefs();
            const addedVends = new Array<string>();
            props.supplierResourceReport.vendors?.forEach((packageVendor) => {
                attachVendorColumns({
                    id: packageVendor.id,
                    name: packageVendor.name
                }, addedVends, colDefs);
            });
            props.supplierResourceReport.packageResources?.forEach((packageResource) => {
                const existing = qItems.find((item) => (item.resourceDisplayId === packageResource.resourceDisplayId));
                let qt: SupplierResourceReportQuoteView
                if (existing) {
                    qt = existing;
                    attachVendorColValues(packageResource, qt);
                } else {
                    const unit = props.units?.find(u => u.id === packageResource.unitId);
                    qt = {
                        amount: packageResource.amount,
                        description: packageResource.description,
                        quotedRate: packageResource.quotedRate,
                        id: uuidv4(),
                        resourceDisplayId: packageResource.resourceDisplayId,
                        quantity: packageResource.quantity,
                        rate: packageResource.rate,
                        selection: packageResource.selection,
                        unitId: packageResource.unitId,
                        vendorId: packageResource.vendorId,
                        unit: {
                            unitId: unit?.id,
                            unitDescription: unit?.description
                        }
                    };
                    attachVendorColValues(packageResource, qt);
                    qItems.push(qt);
                }
            });
            setQuoteItems(qItems);
            gridRef.current!.api.setGridOption("columnDefs", colDefs);
        }
    }, [attachVendorColValues, attachVendorColumns, props.supplierResourceReport.packageResources, props.supplierResourceReport.vendors, props.units])

    return <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
            <AgGridReact<SupplierResourceReportQuoteView>
                ref={gridRef}
                rowData={quoteItems}
                columnDefs={columnDefs}
                onGridReady={setupGrid}
                defaultColDef={defaultColDef}
                getRowId={getRowId}
            />
        </Box>
    </Box>
}