import { Autocomplete, Box } from "@mui/material";
import { ICellEditor } from "ag-grid-enterprise";
import { CustomCellEditorProps, getInstance } from "ag-grid-react";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { GridTextField } from "Components/GridTextField";
import { ResourceCategory } from "Models/resource-category";
import { forwardRef, MutableRefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useGetResourceCategoriesQuery } from "State/Services/resource-category";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ResourceListSubCategoryEditCellRender } from "./ResourceListSubCategoryEditCellRenderer";

export interface ResourceListCategoryEditCellRendererParams extends CustomCellEditorProps {
    estimateId: string;
    setRef: (ref: MutableRefObject<any>) => void;
}

export default forwardRef(({ node, api, value, onValueChange, cellStartedEdit, setRef, estimateId }: ResourceListCategoryEditCellRendererParams, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedResourceCategories } = useGetResourceCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId, refetchOnMountOrArgChange: true });
    const [open, setOpen] = useState(false);
    const inputRef = useRef<any>();
    const [localValue, setLocalValue] = useState<ResourceCategory | null>();
    const categoryIdRef = useRef<string | null | undefined>(node.data?.categoryId);

    const updateValue = useCallback((val: ResourceCategory | null) => {
        onValueChange(val?.description);
        setLocalValue(val);
    }, [onValueChange]);

    useEffect(() => {
        let valueToSet: ResourceCategory | null = null;
        const resCategory = storedResourceCategories?.find((resCategory) => (resCategory.id === categoryIdRef.current));
        if (resCategory) {
            valueToSet = resCategory;
        }
        updateValue(valueToSet);
        if (cellStartedEdit) {
            inputRef.current?.focus();
            inputRef.current?.select();
            handleOpen();
        }
    }, [cellStartedEdit, storedResourceCategories, updateValue])

    useImperativeHandle(ref, () => {
        return {
            setFocusOnAdd() {
                if (inputRef.current) {
                    inputRef.current.focus();
                    setOpen(true);
                }
            },
        };
    });

    const onChange = useCallback((event: React.SyntheticEvent, newValue: ResourceCategory | null) => {
        const subCategoryInstances = api.getCellEditorInstances({
            columns: ['subCategoryDescription']
        });
        if (subCategoryInstances.length > 0
            && subCategoryInstances[0]) {
            getInstance<ICellEditor, ResourceListSubCategoryEditCellRender>(subCategoryInstances[0], (instance) => {
                if (newValue && storedResourceCategories) {
                    const resCategory = storedResourceCategories.find((category) => (category.id === newValue.id));
                    if (resCategory) {
                        updateValue(resCategory);
                        instance?.loadSubcategories(resCategory.id);
                    } else {
                        updateValue(null);
                        instance?.loadSubcategories();
                    }
                } else {
                    updateValue(null);
                    instance?.loadSubcategories();
                }
            });

        }
    }, [api, storedResourceCategories, updateValue])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (inputRef) {
            setRef(inputRef);
        }
    }, [setRef])

    return <Box height="100%">
        <Autocomplete
            fullWidth
            className="ag-input-field-input ag-text-field-input"
            sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
            value={localValue || null}
            size="small"
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            onOpen={handleOpen}
            open={open}
            onChange={onChange}
            options={storedResourceCategories ?? []}
            getOptionLabel={(resCategory) => {
                if (typeof resCategory === 'string') {
                    return resCategory;
                }
                return resCategory.description ?? '';
            }}
            renderInput={(params) => <GridTextField sx={{ height: "100%" }} inputRef={inputRef} placeholder='Select Category' {...params} />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.description ?? '', inputValue, {
                    insideWords: true,
                });
                const parts = parse(option.description ?? '', matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    </Box>
});