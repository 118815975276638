import { IndirectItemCategory } from 'Models/estimate';
import { api } from './api'

export const indirectItemCategoryApi = api.injectEndpoints({
    endpoints: (build) => ({
        createIndirectItemCategory: build.mutation<IndirectItemCategory, Partial<{ body: IndirectItemCategory, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item-category`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['IndirectItemCategories', 'IndirectItems'],
        }),
        updateIndirectItemCategory: build.mutation<IndirectItemCategory, Partial<{ body: IndirectItemCategory, orgId: string, companyId: string, estimateId: string, indirectItemCategoryId: number }>>({
            query({ body, orgId, companyId, estimateId, indirectItemCategoryId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item-category/${indirectItemCategoryId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['IndirectItemCategories', 'Pricing'],
        }),
        deleteIndirectItemCategory: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, indirectItemCategoryId: number, deleteEmptyItems: boolean }>>({
            query({ orgId, companyId, estimateId, indirectItemCategoryId, deleteEmptyItems }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-item-category/${indirectItemCategoryId}?deleteEmptyItems=${(deleteEmptyItems) ? true : false}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['IndirectItemCategories', 'IndirectItems'],
        }),
        getIndirectItemCategories: build.query<Array<IndirectItemCategory>, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-item-category` }),
            providesTags: ['IndirectItemCategories']
        }),
        getIndirectCategoryMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string, level: number }>({
            query: ({ organizationId, companyId, estimateId, level }: { organizationId: string, companyId: string, estimateId: string, level: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-item-category/level/${level}/max-id` }),
            providesTags: ['IndirectItemCategories']
        }),
    })
});

export const {
    endpoints: { createIndirectItemCategory, updateIndirectItemCategory, getIndirectItemCategories },
} = indirectItemCategoryApi

export const {
    useLazyGetIndirectCategoryMaxIdQuery,
    useCreateIndirectItemCategoryMutation,
    useUpdateIndirectItemCategoryMutation,
    useGetIndirectItemCategoriesQuery,
    useDeleteIndirectItemCategoryMutation
} = indirectItemCategoryApi