
import { api } from './api'

export const reportFileApi = api.injectEndpoints({
    endpoints: (build) => ({
        exportDefaultTemplate: build.mutation<any, Partial<{ organizationId: string, companyId: string }>>({
            query({ organizationId, companyId }: { organizationId: string, companyId: string }) {
                return {
                    url: `organization/${organizationId}/company/${companyId}/report-file/default-template`,
                    method: "POST",
                    responseHandler: async (response: any) => window.open(window.URL.createObjectURL(await response.blob()), '_blank'),
                    cache: "no-cache",
                };
            },
        }),
        importReportTemplate: build.mutation<void, { organizationId: string, companyId: string, fileData: FormData }>({
            query({ organizationId, companyId, fileData }: { organizationId: string, companyId: string, fileData: FormData }) {
                return {
                    url: `organization/${organizationId}/company/${companyId}/report-file/upload/template`,
                    method: 'POST',
                    body: fileData,
                };
            },
            invalidatesTags: ['ReportTemplate'],
        }),
        getReportTemplateFiles: build.query<Array<{  name: string, id: string }>, { organizationId: string, companyId: string }>({
            query: ({ organizationId, companyId }: { organizationId: string, companyId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report-file` }),
            providesTags: ['ReportTemplate']
        }),
        downloadReportFile: build.mutation<any, Partial<{ organizationId: string, companyId: string, fileId: string }>>({
            query({ organizationId, companyId, fileId }: { organizationId: string, companyId: string, fileId: string }) {
              return {
                url: `organization/${organizationId}/company/${companyId}/report-file/${fileId}`,
                method: "POST",
                responseHandler: async (response: any) => window.open(window.URL.createObjectURL(await response.blob()), '_blank'),
                cache: "no-cache",
              };
            },
          }),
    })
});

export const {
    useDownloadReportFileMutation,
    useGetReportTemplateFilesQuery,
    useImportReportTemplateMutation,
    useExportDefaultTemplateMutation
} = reportFileApi