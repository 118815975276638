import { Autocomplete, Box } from "@mui/material";
import { ICellEditorParams } from "ag-grid-enterprise";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { GridTextField } from "Components/GridTextField";
import { StandardItemView } from "Models/standard-item";
import { StandardItemCategory } from "Models/standard-item-category";
import { forwardRef, MutableRefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useGetStandardItemCategoriesQuery } from "State/Services/standard-item-category";
import { useGetUserDetailsQuery } from "State/Services/user";

export interface StandardItemListCategoryEditCellRendererParams extends ICellEditorParams<StandardItemView, StandardItemCategory> {
    estimateId: string;
    setRef: (ref: MutableRefObject<any>) => void;
    level: number;
}

export default forwardRef((props: StandardItemListCategoryEditCellRendererParams, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedStandardItemCategories } = useGetStandardItemCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId, refetchOnMountOrArgChange: true });
    const [categories, setCategories] = useState<Array<StandardItemCategory>>([]);
    const [open, setOpen] = useState(false);
    const inputRef = useRef<any>();

    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value?.description ?? null;
            },
            focusIn() {
                inputRef.current.focus();
                handleOpen();
            },
            setFocusOnAdd() {
                if (inputRef.current) {
                    inputRef.current.focus();
                    setOpen(true);
                }
            },
        };
    });

    useEffect(() =>{
        if (storedStandardItemCategories && storedStandardItemCategories.length>0){
            setCategories(storedStandardItemCategories.filter((cat) => (cat.level===props.level)));
        }
    }, [props.level, storedStandardItemCategories])

    const getInitialValue = useCallback((params: ICellEditorParams<StandardItemView, StandardItemCategory>) => {
        let value: StandardItemCategory | null = null;
        let categoryId = '';
        if (props.level===1){
            categoryId = params.data?.category1 ?? '';
        } else if (props.level===2){
            categoryId = params.data?.category2 ?? '';
        }
        const stdCategory = storedStandardItemCategories?.find((cat) => (cat.id === categoryId && cat.level===props.level));
        if (stdCategory) {
            value = stdCategory;
        }
        return value;
    }, [props.level, storedStandardItemCategories]);

    const [value, setValue] = useState<StandardItemCategory | null>(getInitialValue(props));

    const onChange = useCallback((event: React.SyntheticEvent, newValue: StandardItemCategory | null) => {
        if (newValue && categories) {
            const stdCategory = categories.find((category) => (category.id === newValue.id));
            if (stdCategory) {
                setValue(stdCategory);
                props.node.setDataValue(`category${props.level}`, stdCategory.id);
            } else {
                setValue(null);
                props.node.setDataValue(`category${props.level}`, undefined);
            }
        } else {
            setValue(null);
            props.node.setDataValue(`category${props.level}`, undefined);
        }
    }, [categories, props.node, props.level])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (inputRef) {
            props.setRef(inputRef);
        }
    }, [props])

    return <Box height="100%">
        <Autocomplete
            fullWidth
            className="ag-input-field-input ag-text-field-input"
            sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
            value={value || null}
            size="small"
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            onOpen={handleOpen}
            open={open}
            onChange={onChange}
            options={categories ?? []}
            getOptionLabel={(stdCategory) => {
                if (typeof stdCategory === 'string') {
                    return stdCategory;
                }
                return stdCategory.description ?? '';
            }}
            renderInput={(params) => <GridTextField sx={{ height: "100%" }} inputRef={inputRef} placeholder={`Select Category${props.level}`} {...params} />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.description ?? '', inputValue, {
                    insideWords: true,
                });
                const parts = parse(option.description ?? '', matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    </Box>
});