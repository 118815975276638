import { Box, useTheme } from "@mui/material";
import { ColDef, IRowNode, SuppressKeyboardEventParams } from "ag-grid-enterprise";
import { AgGridReact, getInstance } from "ag-grid-react";
import { ActivityResourceView } from "Models/resource";
import { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useDeleteActivityResourceMutation, useGetResourcesForActivityQuery, useUpdateActivityResourceMutation } from "State/Services/activity";
import { CellClickedEvent, RowEditingStartedEvent, RowEditingStoppedEvent, CellKeyDownEvent, GetRowIdParams, ICellEditor } from "ag-grid-community";
import { useConfirm } from "material-ui-confirm";
import { tokens } from "theme";
import { useGetUnitsQuery } from "State/Services/unit";
import React from "react";
import { ActivityView } from "Models/activity";
import { sort } from "Helpers/sort";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetEstimateQuery } from "State/Services/estimate";
import { rounder } from "Helpers/rounder";
import ActivityAppend from "./ActivityAppend";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { PanelState } from "Models/panel";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import { BaseEditCellRenderer } from "Models/grid";
import GenericNumberEditCellRenderer from "Components/Shared/GenericNumberEditCellRenderer";
import GenericActionsCellRenderer from "Components/Shared/GenericActionsEditCellRenderer";

export interface AddEditResourceProps {
    currentActivity: ActivityView;
    estimateId: string;
    onEdit: (state: boolean) => void;
    setActivityResources: (activityResources: Array<ActivityResourceView>) => void;
    panelState: PanelState;
}

const AddEditResource = (props: AddEditResourceProps) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 32px)', width: '100%' }), []);
    const theme = useTheme();
    const [colors, setColors] = useState<any>(tokens(theme.palette.mode));
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<ActivityResourceView>>(null);
    const [rowData, setRowData] = useState<Array<ActivityResourceView>>([]);
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<ActivityResourceView> | undefined, column?: string }>();
    const { data: storedActivityResources } = useGetResourcesForActivityQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '', activityId: props?.currentActivity.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !props?.currentActivity.id });
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [updateActivityResource] = useUpdateActivityResourceMutation();
    const confirm = useConfirm();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [deleteActivityResource] = useDeleteActivityResourceMutation();
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true,
        };
    }, []);

    const calculateAmount = useCallback((activityResource: ActivityResourceView) => {
        if (!activityResource.quantity || !activityResource.rate) return;
        return activityResource.quantity * activityResource.rate;
    }, [])

    const calculateQuantity = useCallback((actRes: ActivityResourceView) => {
        let quantity = actRes.quantity ?? 0;
        if (actRes && props.currentActivity && props.currentActivity.output) {
            if (actRes?.production) {
                quantity = parseFloat(props.currentActivity.output.toString()) / actRes.production;
            } else if (actRes?.quantityPerUnit) {
                quantity = parseFloat(props.currentActivity.output.toString()) * actRes.quantityPerUnit;
            }
        }
        if (isNaN(quantity)) {
            return 0;
        }
        return quantity;
    }, [props.currentActivity])

    const showHideCols = useCallback(() => {
        if (gridRef.current!.api) {
            gridRef.current!.api.setColumnsVisible(["production", "quantityPerUnit"], props.panelState.position.state !== "half");
        }
    }, [props.panelState])

    useEffect(() => {
        showHideCols();
    }, [props.panelState, showHideCols])

    useEffect(() => {
        if (storedActivityResources && units) {
            const rows = new Array<ActivityResourceView>();
            storedActivityResources.forEach((det: ActivityResourceView) => {
                const unit = units?.find((unit) => (unit.id === det.unitId));
                const quantity = calculateQuantity(det);
                rows.push({
                    id: det.id,
                    description: det.description,
                    displayId: det.displayId,
                    quantity: calculateQuantity(det),
                    subCategoryId: det.subCategoryId,
                    amount: (quantity ?? 0) * (det.rate ?? 0),
                    rate: det.rate,
                    unitId: det.unitId,
                    unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined,
                    production: det.production,
                    quantityPerUnit: det.quantityPerUnit
                });
            });
            rows.sort(sort);
            setRowData(rows);
            props.setActivityResources(rows);
        } else {
            setRowData([]);
            props.setActivityResources([]);
        }
    }, [calculateAmount, calculateQuantity, props, storedActivityResources, units])

    const saveActivityResource = useCallback(async (nodeToSave: IRowNode<ActivityResourceView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<ActivityResourceView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && props.estimateId && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    if (nodeToSave.data.id && props?.currentActivity.id) {
                        await updateActivityResource({
                            companyId: user?.companyId,
                            estimateId: props.estimateId,
                            activityId: props?.currentActivity.id,
                            orgId: user.organizationId,
                            body: {
                                activityId: props?.currentActivity.id,
                                quantity: nodeToSave.data.quantity ?? 0,
                                resourceId: nodeToSave.data.id,
                                production: nodeToSave.data.production,
                                quantityPerUnit: nodeToSave.data.quantityPerUnit,
                            }
                        }).unwrap();
                    }
                    resolve();
                    if (toEditAfterSave) {
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    } else {
                        setCurrentEditing(undefined);
                    }
                }
            } catch (error: any) {
                reject(error);
            }
        });
    }, [props, updateActivityResource, user])

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<ActivityResourceView>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();
            saveActivityResource(params.node);
            return true;
        }
        return false;
    }, [saveActivityResource])

    const cancelEditing = useCallback((node: IRowNode<ActivityResourceView>) => {
        if (node && node.data) {
            setIsCancelClicked(true);
            const activityResource = storedActivityResources?.find((c) => (c.id === node.data?.id));
            if (activityResource) {
                const unit = units?.find((unit) => (unit.id === activityResource.unitId));
                const amount = calculateAmount(activityResource);
                gridRef.current!.api.applyTransaction({ update: [{ ...activityResource, amount: amount, unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined }] });
            }
            gridRef.current!.api.stopEditing(true);
            setCurrentEditing(undefined);
        }
    }, [calculateAmount, storedActivityResources, units])

    const deleteRow = useCallback((node: IRowNode<ActivityResourceView>) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await confirm({ description: `Are you sure you want to delete the record?`, title: 'Delete confirmation' });
                if (user && node.data) {
                    await deleteActivityResource({
                        orgId: user.organizationId,
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        resourceId: node.data.id,
                        activityId: props?.currentActivity.id,
                    });
                }

                resolve();
            } catch (error) {
                reject('Cancelled by user.');
            }
        });
    }, [confirm, deleteActivityResource, props?.currentActivity.id, props.estimateId, user])

    const onChangeProduction = useCallback((node: IRowNode<ActivityResourceView>, value: number) => {
        const quantityPerUnitInstances = gridRef.current!.api.getCellEditorInstances({
            columns: ['quantityPerUnit']
        });
        if (quantityPerUnitInstances.length > 0) {
            getInstance<ICellEditor, BaseEditCellRenderer>(quantityPerUnitInstances[0], (instance) => {
                if (instance && instance.changeValue) {
                    instance.changeValue('');
                }
            });
        }
        const quantityInstances = gridRef.current!.api.getCellEditorInstances({
            columns: ['quantity']
        });
        if (quantityInstances.length > 0) {
            getInstance<ICellEditor, BaseEditCellRenderer>(quantityInstances[0], (instance) => {
                if (!isNaN(value) && props.currentActivity?.output && instance?.changeValue) {
                    instance.changeValue(parseFloat(props.currentActivity.output.toString()) / value);
                }
            });
        }
    }, [props.currentActivity.output])
    const onChangeProductionRef = useRef<any>();
    onChangeProductionRef.current = onChangeProduction;

    const onChangeQuantity = useCallback((node: IRowNode<ActivityResourceView>, value: number) => {
        let editingCells = gridRef.current!.api.getEditingCells();
        let isCurrentRowEditing = editingCells.some((cell) => {
            return cell.rowIndex === node.rowIndex;
        });
        if (isCurrentRowEditing) {
            const productionInstances = gridRef.current!.api.getCellEditorInstances({
                columns: ['production']
            });
            if (productionInstances.length > 0) {
                getInstance<ICellEditor, BaseEditCellRenderer>(productionInstances[0], (instance) => {
                    if (instance && instance.changeValue) {
                        instance.changeValue('');
                    }
                });
            }
            const quantityPerUnitInstances = gridRef.current!.api.getCellEditorInstances({
                columns: ['quantityPerUnit']
            });
            if (quantityPerUnitInstances.length > 0) {
                getInstance<ICellEditor, BaseEditCellRenderer>(quantityPerUnitInstances[0], (instance) => {
                    if (instance && instance.changeValue) {
                        instance.changeValue('');
                    }
                });
            }
            else {
                node.setDataValue('quantityPerUnit', null);
            }
        } else {
            node.updateData({
                ...node.data,
                production: undefined,
                quantityPerUnit: undefined
            });
        }

        if (node.data?.rate && !isNaN(parseFloat(node.data.rate.toString()))) {
            let amount = value * parseFloat(node.data?.rate.toString());
            node.setDataValue('amount', amount);
        } else {
            node.setDataValue('amount', undefined);
        }
    }, [])
    const onChangeQuantityRef = useRef<any>();
    onChangeQuantityRef.current = onChangeQuantity;

    const onChangeQuantityPerUnit = useCallback((node: IRowNode<ActivityResourceView>, value: number) => {
        const productionInstances = gridRef.current!.api.getCellEditorInstances({
            columns: ['production']
        });
        if (productionInstances.length > 0) {
            getInstance<ICellEditor, BaseEditCellRenderer>(productionInstances[0], (instance) => {
                if (instance && instance.changeValue) {
                    instance.changeValue(undefined);
                }
            });
        }
        const quantityInstances = gridRef.current!.api.getCellEditorInstances({
            columns: ['quantity']
        });
        if (quantityInstances.length > 0) {
            getInstance<ICellEditor, BaseEditCellRenderer>(quantityInstances[0], (instance) => {
                if (!isNaN(value) && props.currentActivity.output && instance?.changeValue) {
                    instance.changeValue(parseFloat(props.currentActivity.output.toString()) * value);
                }
            });
        }
    }, [props.currentActivity.output])
    const onChangeQuantityPerUnitRef = useRef<any>();
    onChangeQuantityPerUnitRef.current = onChangeQuantityPerUnit;

    const getBaseDefs = useCallback((): Array<ColDef<ActivityResourceView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                resizable: true,
                headerName: 'ID',
                hide: false,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: props.panelState.position.state === "half" ? 90 : 200,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
                editable: false,
                suppressKeyboardEvent: saveOnEnter,
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                resizable: true,
                editable: false,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                cellRenderer: GenericUnitCellRenderer,
                resizable: true,
                width: 75,
                headerName: 'Unit',
                editable: false,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                width: props.panelState.position.state === "half" ? 100 : 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    field: 'Quantity',
                    onChangeCallBack: (node: IRowNode<ActivityResourceView>, value: number | null) => onChangeQuantityRef.current(node, value)
                },
                resizable: true,
                cellDataType: "number",
                editable: (params) => (!props.currentActivity.masterReferenceId),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'production',
                headerName: 'Production',
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    field: 'Production',
                    onChangeCallBack: (node: IRowNode<ActivityResourceView>, value: number | null) => onChangeProductionRef.current(node, value)
                },
                resizable: true,
                hide: true,
                cellDataType: "number",
                editable: (params) => (!props.currentActivity.masterReferenceId),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantityPerUnit',
                headerName: 'Quantity / Unit',
                width: 175,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                hide: true,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    field: 'Quantity / Unit',
                    onChangeCallBack: (node: IRowNode<ActivityResourceView>, value: number | null) => onChangeQuantityPerUnitRef.current(node, value)
                },
                resizable: true,
                cellDataType: "number",
                editable: (params) => (!props.currentActivity.masterReferenceId),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                suppressKeyboardEvent: saveOnEnter,
                width: props.panelState.position.state === 'half' ? 100 : 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: false,
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'amount',
                suppressKeyboardEvent: saveOnEnter,
                width: props.panelState.position.state === 'half' ? 120 : 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                editable: false,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                    background: `repeating-linear-gradient(
                        -45deg,
                        transparent,
                        transparent 4px,
                        transparent 1px,
                        ${colors?.gray[1000]} 7px
                      ),
                      linear-gradient(
                        to bottom,
                        transparent,
                        transparent
                      )`
                }
            },
            {
                field: 'actions',
                resizable: true,
                width: 80,
                headerName: 'Actions',
                editable: false,
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRenderer: GenericActionsCellRenderer,
                cellRendererParams: {
                    save: saveActivityResource,
                    cancel: cancelEditing,
                    deleteRecord: deleteRow,
                    disabled: () => !!props.currentActivity.masterReferenceId || !!editDisabledRef.current,
                    deleteDisabledMessage: "Unauthorized action."
                }
            },
        ]
    }, [cancelEditing, colors?.gray, deleteRow, estimate?.CompanyCurrency?.Currency?.minorUnit, props.currentActivity, props.panelState.position.state, saveActivityResource, saveOnEnter, settings?.quantityDecimals]);

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const onRowEditingStarted = (event: RowEditingStartedEvent<ActivityResourceView>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }

    const onRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent<ActivityResourceView>) => {
        if (isCancelClicked) {
            setIsCancelClicked(false);
            return;
        };

        if (props.currentActivity.masterReferenceId || editDisabledRef.current) return;

        if (currentEditing?.node === event.node) {
            return;
        }
        if (event.column.getColId() === 'actions') {
            return;
        }
        if (!currentEditing?.node) {
            if (event.column.isCellEditable(event.node)) {
                setCurrentEditing({ node: event.node, column: event.column.getColId() });
            } else {
                setCurrentEditing({ node: event.node, column: undefined });
            }
        } else {
            await saveActivityResource(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
        }
    }, [currentEditing?.node, isCancelClicked, props.currentActivity.masterReferenceId, saveActivityResource])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            props.onEdit(true);
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column ?? 'quantity',
            });
        } else {
            props.onEdit(false);
        }
    }, [currentEditing, props])

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelEditing]);

    const getRowId = useCallback(function (params: GetRowIdParams<ActivityResourceView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    return <Box height="calc(100% - 200px)">
        <Box paddingBottom="5px" display="flex" alignItems="center" width="500px">
            <ActivityAppend activityId={props.currentActivity.id} estimateId={props.estimateId} disabled={!!props.currentActivity.masterReferenceId || !!editDisabledRef.current} />
        </Box>
        <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
            <AgGridReact<ActivityResourceView>
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                editType={'fullRow'}
                defaultColDef={defaultColDef}
                onRowEditingStarted={onRowEditingStarted}
                onRowEditingStopped={onRowEditingStopped}
                onGridReady={showHideCols}
                suppressClickEdit={true}
                onCellClicked={onCellClicked}
                onCellKeyDown={onCellKeyDown}
                getRowId={getRowId}
            />
        </Box>
    </Box>
};

export default React.memo(AddEditResource);
