import { Box, Typography, useTheme } from "@mui/material";
import { StandardItemCategoryView } from "Models/standard-item-category";
import { useCreateStandardItemCategoryMutation, useDeleteStandardItemCategoryMutation, useGetStandardItemCategoriesQuery, useLazyGetStandardItemCategoryMaxIdQuery, useUpdateStandardItemCategoryMutation } from "State/Services/standard-item-category";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef, IRowNode, SuppressKeyboardEventParams } from "ag-grid-enterprise";
import { AgGridReact, getInstance } from "ag-grid-react";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import { v4 as uuidv4 } from 'uuid';
import { CellClickedEvent, RowEditingStartedEvent, RowEditingStoppedEvent, CellKeyDownEvent, GetRowIdParams, ICellRendererParams, ICellEditor } from "ag-grid-community";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { useGetEstimateQuery } from "State/Services/estimate";
import { ServerError } from "Models/error-info";
import GenericTextEditCellRenderer from "Components/Shared/GenericTextEditCellRenderer";
import GenericIdEditCellRenderer from "Components/Shared/GenericIdEditCellRenderer";
import GenericActionsCellRenderer from "Components/Shared/GenericActionsEditCellRenderer";
import { BaseEditCellRenderer } from "Models/grid";

export interface AddEditStandardItemCategoryProps {
    estimateId: string | undefined;
}

export default function AddEditStandardItemCategory(props: AddEditStandardItemCategoryProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: user } = useGetUserDetailsQuery();
    const [currentCategory1Editing, setCurrentCategory1Editing] = useState<{ node: IRowNode<StandardItemCategoryView> | undefined, column?: string }>();
    const [currentCategory2Editing, setCurrentCategory2Editing] = useState<{ node: IRowNode<StandardItemCategoryView> | undefined, column?: string }>();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: storedStandardItemCategories } = useGetStandardItemCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId, refetchOnMountOrArgChange: true });
    const [createStandardItemCategory] = useCreateStandardItemCategoryMutation();
    const [updateStandardItemCategory] = useUpdateStandardItemCategoryMutation();
    const [deleteStandardItemCategory] = useDeleteStandardItemCategoryMutation();
    const standardItemCategory1GridRef = useRef<AgGridReact<StandardItemCategoryView>>(null);
    const standardItemCategory2GridRef = useRef<AgGridReact<StandardItemCategoryView>>(null);
    const [standardItem1Categories, setStandardItem1Categories] = useState<Array<StandardItemCategoryView>>([]);
    const [standardItem2Categories, setStandardItem2Categories] = useState<Array<StandardItemCategoryView>>([]);
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();
    const [category1Errors, setCategory1Errors] = useState<Array<{ field: string, error: string }>>([]);
    const [category2Errors, setCategory2Errors] = useState<Array<{ field: string, error: string }>>([]);
    const [isCategory1CancelClicked, setIsCategory1CancelClicked] = useState(false);
    const [isCategory2CancelClicked, setIsCategory2CancelClicked] = useState(false);
    const [getMaxId] = useLazyGetStandardItemCategoryMaxIdQuery();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Items,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Items,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() => {
        if (currentCategory1Editing && typeof currentCategory1Editing.node?.rowIndex === 'number') {
            if (currentCategory1Editing.node.isRowPinned()) {
                standardItemCategory1GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategory1Editing.node.rowIndex,
                    colKey: currentCategory1Editing.column ?? 'displayId',
                    rowPinned: 'top'
                });
            } else {
                standardItemCategory1GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategory1Editing.node.rowIndex,
                    colKey: currentCategory1Editing.column ?? 'displayId',
                });
            }
        }
    }, [currentCategory1Editing])

    useEffect(() => {
        if (currentCategory2Editing && typeof currentCategory2Editing.node?.rowIndex === 'number') {
            if (currentCategory2Editing.node.isRowPinned()) {
                standardItemCategory2GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategory2Editing.node.rowIndex,
                    colKey: currentCategory2Editing.column ?? 'displayId',
                    rowPinned: 'top'
                });
            } else {
                standardItemCategory2GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategory2Editing.node.rowIndex,
                    colKey: currentCategory2Editing.column ?? 'displayId',
                });
            }
        }
    }, [currentCategory2Editing])

    useEffect(() => {
        if (category1Errors.length > 0) {
            category1Errors.forEach((errorDetails) => {
                const instances = standardItemCategory1GridRef.current!.api.getCellEditorInstances({
                    columns: [errorDetails.field]
                });
                if (instances.length > 0) {
                    getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
                        if (instance && instance.setError) {
                            instance.setError(errorDetails.error);
                        }
                    });
                }
            });
        }
    }, [category1Errors])

    useEffect(() => {
        if (category2Errors.length > 0) {
            category2Errors.forEach((errorDetails) => {
                const instances = standardItemCategory2GridRef.current!.api.getCellEditorInstances({
                    columns: [errorDetails.field]
                });
                if (instances.length > 0) {
                    getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
                        if (instance && instance.setError) {
                            instance.setError(errorDetails.error);
                        }
                    });
                }
            });
        }
    }, [category2Errors])

    useEffect(() => {
        const categories1 = new Array<StandardItemCategoryView>();
        const categories2 = new Array<StandardItemCategoryView>();
        if (storedStandardItemCategories && storedStandardItemCategories.length > 0) {
            for (let i = 0; i < storedStandardItemCategories.length; i++) {
                const category = storedStandardItemCategories[i];
                switch (category.level) {
                    case 1:
                        categories1.push({
                            displayId: category.displayId,
                            description: category.description,
                            level: category.level,
                            actions: '',
                            id: category.id,
                            masterReferenceId: category.masterReferenceId,
                            errors: [],
                            isNew: false,
                            canDelete: category.canDelete
                        });
                        break;
                    case 2:
                        categories2.push({
                            displayId: category.displayId,
                            description: category.description,
                            level: category.level,
                            actions: '',
                            masterReferenceId: category.masterReferenceId,
                            id: category.id,
                            errors: [],
                            isNew: false,
                            canDelete: category.canDelete
                        });
                        break;
                    default:
                        break;
                }
            }

            setStandardItem1Categories(categories1);
            setStandardItem2Categories(categories2);
        } else {
            setStandardItem1Categories([]);
            setStandardItem2Categories([]);
        }
    }, [storedStandardItemCategories])

    const saveStandardItemCategoryRow = useCallback((nodeToSave: IRowNode<StandardItemCategoryView>, level: number, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<StandardItemCategoryView>, column?: string }) => {
        let standardItemCategoryGridRef: React.RefObject<AgGridReact<StandardItemCategoryView>> | null = null;
        let setCurrentCategoryEditing: Dispatch<SetStateAction<{ node: IRowNode<StandardItemCategoryView> | undefined; column?: string | undefined; } | undefined>> = setCurrentCategory1Editing;
        let setCategoryErrors: Dispatch<SetStateAction<{ field: string; error: string; }[]>>;
        return new Promise<void>(async (resolve, reject) => {
            switch (level) {
                case 1:
                    standardItemCategoryGridRef = standardItemCategory1GridRef;
                    setCurrentCategoryEditing = setCurrentCategory1Editing;
                    setCategoryErrors = setCategory1Errors;
                    break;
                case 2:
                    standardItemCategoryGridRef = standardItemCategory2GridRef;
                    setCurrentCategoryEditing = setCurrentCategory2Editing;
                    setCategoryErrors = setCategory2Errors;
                    break;
                default:
                    break;
            }
            try {
                if (standardItemCategoryGridRef && standardItemCategoryGridRef.current) {
                    standardItemCategoryGridRef.current!.api.stopEditing();
                    if (!nodeToSave.data?.displayId) {
                        const error: ServerError = { data: { displayId: 'ID is required' } };
                        throw error;
                    }
                    if (!nodeToSave.data?.description) {
                        const error: ServerError = { data: { description: 'Description is required' } };
                        throw error;
                    }

                    if (nodeToSave.data) {
                        if (!nodeToSave.data.isNew) {
                            await updateStandardItemCategory({
                                standardItemCategoryId: nodeToSave.data.id,
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: nodeToSave.data.id,
                                    displayId: nodeToSave.data.displayId,
                                    description: nodeToSave.data.description,
                                    level: level
                                }
                            }).unwrap();
                        }
                        else {
                            await createStandardItemCategory({
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: uuidv4(),
                                    displayId: nodeToSave.data.displayId,
                                    description: nodeToSave.data.description,
                                    level: level
                                }
                            }).unwrap();
                        }

                        if (nodeToSave.rowPinned) {
                            standardItemCategoryGridRef.current!.api.applyTransaction({ add: [nodeToSave.data] });
                            nodeToSave.updateData({
                                displayId: '',
                                description: 'Enter Category Name',
                                id: undefined,
                                level: level,
                                errors: [],
                                isNew: true,
                                canDelete: true
                            });
                        }
                    }
                }
                resolve();
                if (toEditAfterSave) {
                    setCurrentCategoryEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                } else {
                    setCurrentCategoryEditing(undefined);
                }
            } catch (error: any) {
                let errorMessage = '';
                let column = 'displayId';
                if (error) {
                    if (error.data.displayId) {
                        errorMessage = error.data.displayId;
                        column = 'displayId';
                    } else if (error.data.description) {
                        errorMessage = error.data.description;
                        column = 'description';
                    }
                }

                if (errorMessage && typeof nodeToSave.rowIndex === 'number') {
                    if (nodeToSave.isRowPinned()) {
                        standardItemCategoryGridRef?.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: column,
                            rowPinned: 'top'
                        });
                    } else {
                        standardItemCategoryGridRef?.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: column,
                        });
                    }
                    setCategoryErrors([{ field: column, error: errorMessage }]);
                }
                reject(error);
            }
        });
    }, [createStandardItemCategory, props.estimateId, updateStandardItemCategory, user?.companyId, user?.organizationId])

    const saveStandardItemCategoryOnEnter = useCallback((params: SuppressKeyboardEventParams<StandardItemCategoryView>, level: number) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();
            saveStandardItemCategoryRow(params.node, level);
            return true;
        }
        return false;
    }, [saveStandardItemCategoryRow])

    const deleteStandardItemCategoryRow = useCallback((node: IRowNode<StandardItemCategoryView>) => {
        return new Promise<void>(async (resolve) => {
            if (node.data) {
                await deleteStandardItemCategory({
                    companyId: user?.companyId,
                    estimateId: props.estimateId,
                    orgId: user?.organizationId,
                    standardItemCategoryId: node.data?.id,
                });
            }
            resolve();
        });
    }, [deleteStandardItemCategory, props.estimateId, user?.companyId, user?.organizationId])

    const cancelStandardItemCategoryEditing = useCallback((node: IRowNode<StandardItemCategoryView>, level: number) => {
        let standardItemCategoryGridRef: React.RefObject<AgGridReact<StandardItemCategoryView>> | null = null;
        let setIsCategoryCancelClicked: Dispatch<SetStateAction<boolean>> = setIsCategory1CancelClicked;
        let setCurrentCategoryEditing: Dispatch<SetStateAction<{ node: IRowNode<StandardItemCategoryView> | undefined; column?: string | undefined; } | undefined>> = setCurrentCategory1Editing;
        switch (level) {
            case 1:
                standardItemCategoryGridRef = standardItemCategory1GridRef;
                setIsCategoryCancelClicked = setIsCategory1CancelClicked;
                setCurrentCategoryEditing = setCurrentCategory1Editing;
                break;
            case 2:
                standardItemCategoryGridRef = standardItemCategory2GridRef;
                setIsCategoryCancelClicked = setIsCategory2CancelClicked;
                setCurrentCategoryEditing = setCurrentCategory2Editing;
                break;
            default:
                break;
        }
        if (standardItemCategoryGridRef && storedStandardItemCategories) {
            standardItemCategoryGridRef.current!.api.stopEditing(true);
            if (node.rowPinned) {
                node.updateData({
                    level: level,
                    displayId: '',
                    description: 'Enter Category Name',
                    id: undefined,
                    isNew: true
                });
            } else {
                setIsCategoryCancelClicked(true);
                const category = storedStandardItemCategories?.find((v) => (v.id === node.data?.id));
                if (category) {
                    standardItemCategoryGridRef.current!.api.applyTransaction({ update: [{ ...category, isNew: false }] });
                }
                standardItemCategoryGridRef.current!.api.stopEditing(true);
            }
            setCurrentCategoryEditing(undefined);
        }
    }, [storedStandardItemCategories])

    const getStandardItem1CategoryColumnDefs = (): ColDef<StandardItemCategoryView>[] => {
        return [
            { field: 'id', hide: true, cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } },
            {
                field: 'displayId',
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                cellEditor: GenericIdEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    getMaxId: getMaxId,
                    field: "ID",
                    maxIdAdditionalProps: {
                        level: 1
                    }
                },
                headerName: 'ID',
                cellDataType: 'string',
                width: 85,
                sortable: true,
                suppressKeyboardEvent: (params) => saveStandardItemCategoryOnEnter(params, 1)
            },
            {
                field: 'description',
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                flex: 1,
                cellEditor: GenericTextEditCellRenderer,
                cellEditorParams: {
                    field: 'Description'
                },
                cellRenderer: (params: ICellRendererParams<StandardItemCategoryView>) => {
                    if (params.node.isRowPinned()) {
                        return <i>{params.node.data?.description}</i>
                    }
                    return params.node.data?.description;
                },
                suppressKeyboardEvent: (params) => saveStandardItemCategoryOnEnter(params, 1),
            },
            {
                field: 'actions',
                suppressKeyboardEvent: (params) => saveStandardItemCategoryOnEnter(params, 1),
                resizable: true,
                width: 60,
                editable: false,
                headerName: '',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRendererSelector: () => {
                    return {
                        component: GenericActionsCellRenderer,
                        params: {
                            deleteRecord: (node: IRowNode<StandardItemCategoryView>) => deleteStandardItemCategoryRow(node),
                            save: (node: IRowNode<StandardItemCategoryView>) => saveStandardItemCategoryRow(node, 1),
                            cancel: (node: IRowNode<StandardItemCategoryView>) => cancelStandardItemCategoryEditing(node, 1),
                            disabled: () => deleteDisabledRef.current
                        },
                    };
                },
            },
        ]
    }

    const getStandardItem2CategoryColumnDefs = (): ColDef<StandardItemCategoryView>[] => {
        return [
            { field: 'id', hide: true, cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } },
            {
                field: 'displayId',
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellEditor: GenericIdEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    getMaxId: getMaxId,
                    field: "ID",
                    maxIdAdditionalProps: {
                        level: 2
                    }
                },
                headerName: 'ID',
                cellDataType: 'string',
                width: 85,
                sortable: true,
                suppressKeyboardEvent: (params) => saveStandardItemCategoryOnEnter(params, 2)
            },
            {
                field: 'description',
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                cellRenderer: (params: ICellRendererParams<StandardItemCategoryView>) => {
                    if (params.node.isRowPinned()) {
                        return <i>{params.node.data?.description}</i>
                    }
                    return params.node.data?.description;
                },
                cellEditor: GenericTextEditCellRenderer,
                cellEditorParams: {
                    field: 'Description'
                },
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                flex: 1,
                suppressKeyboardEvent: (params) => saveStandardItemCategoryOnEnter(params, 2),
            },
            {
                field: 'actions',
                suppressKeyboardEvent: (params) => saveStandardItemCategoryOnEnter(params, 2),
                resizable: true,
                width: 60,
                editable: false,
                headerName: '',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRendererSelector: () => {
                    return {
                        component: GenericActionsCellRenderer,
                        params: {
                            deleteRecord: (node: IRowNode<StandardItemCategoryView>) => deleteStandardItemCategoryRow(node),
                            save: (node: IRowNode<StandardItemCategoryView>) => saveStandardItemCategoryRow(node, 2),
                            cancel: (node: IRowNode<StandardItemCategoryView>) => cancelStandardItemCategoryEditing(node, 2),
                            disabled: () => deleteDisabledRef.current,
                            deleteDisabledMessage: 'Deleting a category with items is not permitted.'
                        },
                    };
                },
            },
        ]
    }

    const onCategory1CellClicked = useCallback(async (event: CellClickedEvent) => {
        try {
            if (currentCategory1Editing?.node === event.node) {
                return;
            }
            if (!currentCategory1Editing?.node) {
                if (event.node.data?.masterReferenceId || editDisabledRef.current) return;

                if (isCategory1CancelClicked) {
                    setIsCategory1CancelClicked(false);
                    return;
                };

                if (event.column.getColId() === 'actions') {
                    return;
                }
                setCurrentCategory1Editing({ node: event.node, column: event.column.getColId() });
            } else {
                await saveStandardItemCategoryRow(currentCategory1Editing.node, 1, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) { }

    }, [currentCategory1Editing?.node, isCategory1CancelClicked, saveStandardItemCategoryRow])

    const onCategory2CellClicked = useCallback(async (event: CellClickedEvent) => {
        try {
            if (currentCategory2Editing?.node === event.node) {
                return;
            }
            if (!currentCategory2Editing?.node) {
                if (event.node.data?.masterReferenceId || editDisabledRef.current) return;

                if (isCategory2CancelClicked) {
                    setIsCategory2CancelClicked(false);
                    return;
                };

                if (event.column.getColId() === 'actions') {
                    return;
                }
                setCurrentCategory2Editing({ node: event.node, column: event.column.getColId() });
            } else {
                await saveStandardItemCategoryRow(currentCategory2Editing.node, 2, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) { }

    }, [currentCategory2Editing?.node, isCategory2CancelClicked, saveStandardItemCategoryRow])

    const [standardItemCategory1Defs] = useState<ColDef<StandardItemCategoryView>[]>(getStandardItem1CategoryColumnDefs());
    const [standardItemCategory2Defs] = useState<ColDef<StandardItemCategoryView>[]>(getStandardItem2CategoryColumnDefs());


    const defaultStandardItem1CategoryColDef = useMemo<ColDef>(() => {
        return {
            editable: true,
            resizable: true
        };
    }, []);

    const defaultStandardItem2CategoryColDef = useMemo<ColDef>(() => {
        return {
            editable: true,
            resizable: true
        };
    }, []);

    const createStandardItem1CategoryPinnedData = useMemo<StandardItemCategoryView[]>(() => {
        return [{ isNew: true, description: 'Enter Category Name', displayId: '', level: 1 }];
    }, [])

    const createStandardItem2CategoryPinnedData = useMemo<StandardItemCategoryView[]>(() => {
        return [{ isNew: true, description: 'Enter Category Name', displayId: '', level: 2 }];
    }, [])

    const onStandardItemCategory1RowEditingStarted = (event: RowEditingStartedEvent<StandardItemCategoryView>) => {
        setIsCategory1CancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }

    const onStandardItemCategory1RowEditingStopped = (event: RowEditingStoppedEvent<StandardItemCategoryView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }

    const onStandardItemCategory2RowEditingStarted = (event: RowEditingStartedEvent<StandardItemCategoryView>) => {
        setIsCategory2CancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }

    const onStandardItemCategory2RowEditingStopped = (event: RowEditingStoppedEvent<StandardItemCategoryView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }

    const getStandardItem1CategoryRowId = useCallback(function (params: GetRowIdParams<StandardItemCategoryView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const getStandardItem2SubCategoryRowId = useCallback(function (params: GetRowIdParams<StandardItemCategoryView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const onCategory1CellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelStandardItemCategoryEditing(e.node, 1);
            setIsCategory1CancelClicked(false);
        }
    }, [cancelStandardItemCategoryEditing]);

    const onCategory2CellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelStandardItemCategoryEditing(e.node, 2);
            setIsCategory1CancelClicked(false);
        }
    }, [cancelStandardItemCategoryEditing]);

    return <>{colors && <Box display="flex" height="100%">
        <Box className="ag-theme-alpine ag-theme-bidbow" height="100%" width="100%" padding="10px 20px 40px 20px">
            <Typography marginBottom="5px" variant="h5">Level 1 Categories</Typography>
            <AgGridReact<StandardItemCategoryView>
                ref={standardItemCategory1GridRef}
                editType={'fullRow'}
                rowData={standardItem1Categories}
                columnDefs={standardItemCategory1Defs}
                defaultColDef={defaultStandardItem1CategoryColDef}
                pinnedTopRowData={createStandardItem1CategoryPinnedData}
                onRowEditingStarted={onStandardItemCategory1RowEditingStarted}
                onRowEditingStopped={onStandardItemCategory1RowEditingStopped}
                getRowId={getStandardItem1CategoryRowId}
                onCellClicked={onCategory1CellClicked}
                onCellKeyDown={onCategory1CellKeyDown}
            />
        </Box>
        <Box className="ag-theme-alpine ag-theme-bidbow" height="100%" width="100%" padding="10px 20px 40px 20px">
            <Typography marginBottom="5px" variant="h5">Level 2 Categories</Typography>
            <AgGridReact<StandardItemCategoryView>
                ref={standardItemCategory2GridRef}
                editType={'fullRow'}
                rowData={standardItem2Categories}
                columnDefs={standardItemCategory2Defs}
                defaultColDef={defaultStandardItem2CategoryColDef}
                pinnedTopRowData={createStandardItem2CategoryPinnedData}
                onRowEditingStarted={onStandardItemCategory2RowEditingStarted}
                onRowEditingStopped={onStandardItemCategory2RowEditingStopped}
                getRowId={getStandardItem2SubCategoryRowId}
                onCellClicked={onCategory2CellClicked}
                onCellKeyDown={onCategory2CellKeyDown}
            />
        </Box>
    </Box>}

    </>;
}