import { Box, CircularProgress, useTheme } from "@mui/material";
import { FileDetails } from "Models/file";
import { useEffect, useMemo, useState } from "react";
import { useCompleteVariationMutation } from "State/Services/file";
import { useGetUserDetailsQuery } from "State/Services/user";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { tokens } from "theme";

export interface MergeProps {
    estimateId: string;
    fileDetails: FileDetails;
}

export default function Merge(props: MergeProps) {
    const [colors, setColors] = useState<any>();
    const theme = useTheme();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const { data: user } = useGetUserDetailsQuery();
    const [completeVariation] = useCompleteVariationMutation();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        completeVariation({
            companyId: user?.companyId,
            estimateId: props.estimateId,
            fileId: props.fileDetails?.id,
            orgId: user?.organizationId
        }).then(() => {
            setIsLoading(false);
        })
    }, [completeVariation, props, user?.companyId, user?.organizationId])

    return <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
        {isLoading &&
            <Box>
                <Box>Analyzing...</Box>
                <Box width='100%' display="flex" alignContent="center"
                    alignItems="center"
                    justifyContent="center">
                    <Box><CircularProgress color="primary" /></Box>
                </Box>
            </Box>
        }
        {!isLoading && colors &&
            <Box display="flex" flexDirection="column" alignItems="center">
                <CheckCircleIcon sx={{ color: colors.greenAccent[300], width: '4em', height: '4em' }} />
                <Box>Successfully imported.</Box>
            </Box>
        }
    </Box>
}