import { Alert, Autocomplete, Backdrop, Box, Breadcrumbs, Button, Chip, CircularProgress, Drawer, Link, TextField, Typography, useTheme } from "@mui/material";
import { BaseItem } from "Models/item";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import * as yup from 'yup';
import SubcontractorItemFilters from "./SubcontractorItemFilters";
import { AgGridReact } from "ag-grid-react";
import { useGetTagsQuery } from "State/Services/tag";
import { useGetUserDetailsQuery } from "State/Services/user";
import { Package, PackageItem, PackageItemDetailsView } from "Models/package";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { Tag, Vendor } from "Models/vendor";
import { useGetVendorsQuery } from "State/Services/vendor";
import { ColDef, ColGroupDef, Column, GetRowIdParams, IRowNode, RowClassParams } from "ag-grid-enterprise";
import { md5 } from "js-md5";
import { useCreatePackageMutation, useCreateSubcontractorPackageVendorMutation, useDeletePackageItemMutation, useDeletePackageVendorMutation, useGetPackageDetailsQuery, useGetSubcontractorPackageVendorsQuery, useLazyIsPackageNameDuplicateQuery, useUpdatePackageMutation } from "State/Services/subcontractor";
import SubcontractorPackageRateCellRenderer from "./SubcontractorPackageRateCellRenderer";
import SubcontractorPackageVendorHeader from "./SubcontractorPackageVendorHeaderRenderer";
import { SubcontractorAttachments } from "./SubcontractorAttachments";
import { SubcontractorPackageVendor } from "Models/subcontractor";
import SubcontractorRate from "./SubcontractorRate";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useConfirm } from "material-ui-confirm";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { clearSubcontractorState, selectSubcontractorState } from "State/subcontractorSlice";
import { useAppDispatch, useAppSelector } from "State/hooks";
import SubcontractorPackageDeleteCellRenderer from "./SubcontractorPackageDeleteCellRenderer";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import SubcontractorSelectCellRenderer from "./SubcontractorSelectCellRenderer";
import SubcontractorHeaderCellRenderer from "./SubcontractorHeaderCellRenderer";
import { CellClickedEvent, CellEditingStoppedEvent } from "ag-grid-community";
import { Errors } from "Models/errors";
import { clearSupplierState } from "State/supplierSlice";
import { rounder } from "Helpers/rounder";

const quotePackageValidationSchema = yup.object<PackageItemDetailsView>({
    id: yup
        .string()
        .optional(),
    name: yup
        .string()
        .required(),
});

export interface PackageProps {
    estimateId?: string;
    packageId?: string;
    backToList: () => void;
}

export default function SubcontractorPackage(props: PackageProps) {
    const dispatch = useAppDispatch();
    const subcontractorState = useAppSelector(selectSubcontractorState);
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const packageIdRef = useRef<string | undefined>(props?.packageId);
    const { data: user } = useGetUserDetailsQuery();
    const { data: packageDetails } = useGetPackageDetailsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', packageId: packageIdRef.current ?? '' }, { skip: !user?.companyId || !user?.organizationId || !packageIdRef.current });
    const { data: packageVendors } = useGetSubcontractorPackageVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', packageId: packageIdRef.current ?? '' }, { skip: !user?.companyId || !user?.organizationId || !packageIdRef.current })
    const { data: existingTags } = useGetTagsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: existingVendors } = useGetVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [createSubcontractorPackageVendor] = useCreateSubcontractorPackageVendorMutation();
    const [quoteItems, setQuoteItems] = useState<Array<PackageItemDetailsView>>([]);
    const gridRef = useRef<AgGridReact<PackageItemDetailsView>>(null);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const [filteredVendors, setFilteredVendors] = useState<Array<Vendor & { filterText: string }>>();
    const [selectedVendor, setSelectedVendor] = useState<Vendor & { filterText: string }>();
    const [quotePackage, setQuotePackage] = useState<PackageItemDetailsView>();
    const [createPackage] = useCreatePackageMutation();
    const [updatePackage] = useUpdatePackageMutation();
    const [addedVendorHashes, setAddedVendorHashes] = useState<Array<string>>([]);
    const [selectedPackageVendor, setSelectedPackageVendor] = useState<SubcontractorPackageVendor | undefined>();
    const [openSubcontractorPanel, setOpenSubcontractorPanel] = useState(false);
    const [openCalculateRate, setOpenCalculateRate] = useState(false);
    const [selectedItem, setSelectedItem] = useState<PackageItemDetailsView>();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [deletePackageVendor] = useDeletePackageVendorMutation();
    const confirm = useConfirm();
    const [ready, setReady] = useState(false);
    const [deletePackageItem] = useDeletePackageItemMutation();
    const deleteDisabledRef = useRef<boolean>();
    const hasAddEditAccessRef = useRef<boolean>();
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<PackageItemDetailsView> | undefined, column: string }>();
    const [pageError, setPageError] = useState<string | undefined>();
    const [isPackageNameDuplicate] = useLazyIsPackageNameDuplicateQuery();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                hasAddEditAccessRef.current = false;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.SubcontractorQuotes,
                    requiredPermissions: [502]
                });
                hasAddEditAccessRef.current = hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.SubcontractorQuotes,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            hasAddEditAccessRef.current = false;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column,
            });
        }
    }, [currentEditing])

    const breadcrumbs = [
        <Link
            key="1"
            onClick={props.backToList}
            underline="hover"
            sx={{ cursor: "pointer" }}
            color="primary"
        >
            Subcontractor Quotes
        </Link>,
        <Typography key="2" color="text.primary">
            {!packageIdRef.current ? 'New' : packageDetails?.name}
        </Typography>,
    ];

    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const quotePackageRef = useRef<any>();
    quotePackageRef.current = quotePackage;
    const packageVendorsRef = useRef<any>();
    packageVendorsRef.current = packageVendors;

    useEffect(() => {
        return () => {
            if (ready) {
                dispatch(clearSubcontractorState());
            }
        };
    }, [dispatch, ready]);

    const canDelete = useCallback((node: IRowNode<PackageItemDetailsView>) => {
        let canDelete = true;
        const items = packageDetails?.packageItems?.filter((i) => (i.itemId === node.data?.id));
        if (items && items.some((item) => (item.isChecked))) {
            canDelete = false;
        }
        return canDelete;
    }, [packageDetails?.packageItems])
    const canDeleteRef = useRef<any>();
    canDeleteRef.current = canDelete;

    const getBaseDefs = useCallback((): ColDef<PackageItemDetailsView>[] => {
        return [
            { field: 'id', hide: true },
            { field: 'displayId', headerName: "ID", pinned: "left", width: 90 },
            {
                field: 'itemName',
                headerName: 'Item',
                valueFormatter: (params) => {
                    if (params.node?.footer) {
                        return "Total"
                    }
                    return params.value;
                },
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                width: 250,
                pinned: "left"
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                width: 90,
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'actions',
                maxWidth: 80,
                headerName: 'Actions',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px", borderRight: `1px solid ${colors?.gray[800]}` } as any,
                cellRenderer: SubcontractorPackageDeleteCellRenderer,
                cellRendererParams: {
                    delete: (node: IRowNode<PackageItemDetailsView>) => deletePackageItemRef.current(node),
                    canDelete: (node: IRowNode<PackageItemDetailsView>) => { return canDeleteRef.current(node) },
                    disabled: () => deleteDisabledRef.current
                }
            },

        ];
    }, [colors?.gray])

    const closeCalculateRatePanel = useCallback(() => {
        setSelectedPackageVendor(undefined);
        setSelectedItem(undefined);
        setOpenCalculateRate(false);
    }, [])

    const calculateRate = useCallback((node: IRowNode<PackageItemDetailsView>, column: Column<PackageItemDetailsView>) => {
        const hash = column.getColId().split("_")[1];
        if (packageVendors && packageVendors.length > 0) {
            const packageVendor = packageVendors?.find((ven) => (md5(ven.vendorId) === hash));
            setSelectedPackageVendor(packageVendor);
            const item = packageDetails?.packageItems?.find((pItem) => (pItem.itemId === node.data?.id && pItem.vendorId === packageVendor?.vendorId));
            if (item) {
                setSelectedItem(item);
            }

            setOpenCalculateRate(true);
        }
    }, [packageDetails?.packageItems, packageVendors])
    const calculateRateRef = useRef<any>();
    calculateRateRef.current = calculateRate;

    const attach = useCallback((vendorHash: string) => {
        if (packageVendors && packageVendors.length > 0) {
            const packageVendor = packageVendors?.find((ven) => (md5(ven.vendorId) === vendorHash));
            setSelectedPackageVendor(packageVendor);
            setOpenSubcontractorPanel(true);
        }
    }, [packageVendors])
    const attachRef = useRef<any>();
    attachRef.current = attach;

    const clearCols = useCallback((vendorHash: string) => {
        const currentColDefs = gridRef.current!.api.getColumnDefs();
        const ungrouped = currentColDefs?.filter((col: ColGroupDef | ColDef) => !('groupId' in col));
        const colDefs = currentColDefs?.filter((col: ColGroupDef | ColDef) => 'groupId' in col && col.groupId !== vendorHash);
        if (colDefs) {
            gridRef.current!.api.setGridOption("columnDefs", ungrouped?.concat(colDefs));
        }
        setAddedVendorHashes(addedVendorHashes.filter(v => v !== vendorHash));
    }, [addedVendorHashes])

    const removePackageVendor = useCallback(async (vendorHash: string) => {
        try {
            setPageError(undefined);
            await confirm({ cancellationText: "No, cancel", confirmationText: "Yes, continue", description: "Are you sure? This will delete all quote details associated with this vendor.?", title: 'Delete confirmation' });
            if (user && packageVendors) {
                const packageVendor = packageVendors.find((ven) => (md5(ven.vendorId) === vendorHash));
                await deletePackageVendor({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    subcontractorPackageVendorId: packageVendor?.id,
                    packageId: packageVendor?.subcontractorPackageId
                });
                clearCols(vendorHash);
            }
        } catch (error) {
            setPageError(Errors.generic);
        }
    }, [clearCols, confirm, deletePackageVendor, packageVendors, props.estimateId, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const deletePackageVendorRef = useRef<any>();
    deletePackageVendorRef.current = removePackageVendor;

    const getNewColDefs = useCallback((vendorName?: string, vendorIdHash?: string): ColDef | ColGroupDef => {
        return {
            groupId: vendorIdHash,
            headerGroupComponent: SubcontractorPackageVendorHeader,
            headerGroupComponentParams: {
                vendorName: vendorName,
                attach: (vendorHash: string) => attachRef.current(vendorHash),
                estimateId: props.estimateId,
                vendorHash: vendorIdHash,
                packageVendors: () => packageVendorsRef.current,
                package: () => quotePackageRef.current,
                delete: (vendorHash: string) => deletePackageVendorRef.current(vendorHash),
                packageId: packageIdRef.current,
                disabled: deleteDisabledRef.current
            },
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
            children: [
                {
                    field: `vendor_id_${vendorIdHash}`,
                    hide: true,
                    width: 200,
                    editable: false,
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                },
                {
                    field: `rate_${vendorIdHash}`,
                    headerName: "Quoted Rate",
                    cellDataType: "number",
                    cellRendererSelector: (params) => {
                        if (!params.node.footer) {
                            return {
                                component: SubcontractorPackageRateCellRenderer,
                                params: {
                                    calculateRate: (node: IRowNode<PackageItemDetailsView>, column: Column<PackageItemDetailsView> | undefined) => calculateRateRef.current(node, column),
                                    package: () => quotePackageRef.current
                                }
                            }
                        }
                    },
                    width: 150,
                    editable: () => {
                        return !!hasAddEditAccessRef.current && !!packageIdRef.current
                    },
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                },
                {
                    field: `totalRate_${vendorIdHash}`,
                    headerName: "Rate",
                    width: 150,
                    valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                    cellDataType: "number",
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: `amount_${vendorIdHash}`,
                    headerName: "Amount",
                    width: 150,
                    valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                    cellDataType: "number",
                    aggFunc: 'sum',
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: `isChecked_${vendorIdHash}`,
                    headerComponent: SubcontractorHeaderCellRenderer,
                    headerComponentParams: {
                        disabled: () => !hasAddEditAccessRef.current || !packageIdRef.current,
                        checkAll: (checked: boolean, vendorId: string) => checkAllRef.current(checked, vendorId),
                        vendorId: vendorIdHash,
                    },
                    cellRendererSelector: (params) => {
                        if (!params.node.aggData) {
                            return {
                                component: SubcontractorSelectCellRenderer,
                                params: {
                                    vendorId: vendorIdHash,
                                    disabled: () => !hasAddEditAccessRef.current || !packageIdRef.current,
                                    checked: (checked: boolean, node: IRowNode<PackageItemDetailsView>, vendorId: string) => checkItemRef.current(checked, node, vendorId)
                                },
                            };
                        }
                    },
                    cellDataType: 'boolean',
                    width: 50,
                    editable: false,
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}`, justifyContent: "center" }
                },
            ]
        };
    }, [colors?.gray, estimate?.CompanyCurrency?.Currency?.minorUnit, props.estimateId])

    const attachVendorColumns = useCallback((vendor: { id: string, name: string }, vendors: string[], colDefs: any) => {
        const vendorIdHash = md5(vendor.id);
        if (!vendors.includes(vendorIdHash)) {
            vendors.push(vendorIdHash);
        }
        if (!colDefs?.some((col: any) => (col.children?.some((child: any) => child.field === `vendor_id_${vendorIdHash}`)))) {
            colDefs.push(getNewColDefs(vendor.name, vendorIdHash));
        }
    }, [getNewColDefs])

    const attachVendorColValues = useCallback((packageItem: { vendorId?: string, vendorName?: string, isChecked?: boolean, rate?: number, factor?: number, amount?: number, totalRate?: number }, qt: PackageItemDetailsView) => {
        if (packageItem.vendorId) {
            const vendorIdHash = md5(packageItem.vendorId);
            qt[`vendor_id_${vendorIdHash}`] = packageItem.vendorId;
            qt[`rate_${vendorIdHash}`] = packageItem.rate;
            qt[`isChecked_${vendorIdHash}`] = packageItem.isChecked;
            qt[`amount_${vendorIdHash}`] = (packageItem.amount) ? parseFloat(packageItem.amount.toFixed((estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)) : null;
            qt[`totalRate_${vendorIdHash}`] = packageItem.totalRate;
        }
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit])

    useEffect(() => {
        if (subcontractorState.packageId) {
            packageIdRef.current = subcontractorState.packageId;
        }
    }, [subcontractorState])

    useEffect(() => {
        if (existingVendors) {
            const subcontractorVendors = existingVendors.filter((v) => (v.type.includes("Subcontractor")));
            if (selectedTags && selectedTags.length > 0) {
                const tagIds = selectedTags.map(tag => tag.id);
                const filteredVendors = subcontractorVendors.filter(vendor =>
                    vendor?.tags?.some(tag => tagIds.includes(tag.id))
                );
                setFilteredVendors(filteredVendors.map((vendor) => ({ ...vendor, filterText: `${vendor.displayId} - ${vendor.name}` })))
            } else {
                setFilteredVendors(subcontractorVendors.map((vendor) => ({ ...vendor, filterText: `${vendor.displayId} - ${vendor.name}` })));
            }
        } else {
            setFilteredVendors([]);
        }
    }, [existingVendors, selectedTags])

    const [columnDefs] = useState<any>(getBaseDefs());

    const { values, handleSubmit, setFieldError, setFieldValue, handleBlur, touched, errors, setErrors } = useFormik<Package<PackageItemDetailsView>>({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: (quotePackage) ? {
            id: quotePackage.id ?? '',
            name: quotePackage.name ?? '',
        } : {
            id: '',
            name: '',
        },
        validationSchema: quotePackageValidationSchema,
        onSubmit: async (quotePackage) => {
            try {
                setPageError(undefined);
                let rowData: PackageItemDetailsView[] = [];
                gridRef.current!.api.forEachNode((node) => {
                    if (node.data) {
                        rowData.push(node.data);
                    }
                });
                if (user) {
                    dispatch(clearSubcontractorState());
                    setOpenBackdrop(true);
                    const items = new Array<PackageItem>();
                    const vendorIds = new Array<{ id: string }>();
                    addedVendorHashes.forEach((vendorHash) => {
                        if (existingVendors && existingVendors.length > 0) {
                            const vendor = existingVendors?.find((v) => (md5(v.id ?? '')) === vendorHash);
                            if (vendor?.id) {
                                vendorIds.push({ id: vendor.id });
                            }
                        }
                    });
                    rowData.forEach((row) => {
                        let item: PackageItem = {
                            itemId: row.id
                        };
                        if (addedVendorHashes.length > 0) {
                            addedVendorHashes.forEach((vendorHash) => {
                                items.push({
                                    ...item,
                                    isChecked: row[`isChecked_${vendorHash}`],
                                    rate: parseFloat(row[`rate_${vendorHash}`]),
                                    vendorId: row[`vendor_id_${vendorHash}`],
                                    totalRate: row[`totalRate_${vendorHash}`],
                                });
                            });
                        } else {
                            items.push(item);
                        }
                    });

                    await save(quotePackage, items, vendorIds);
                    setOpenBackdrop(false);
                }
            } catch (error: any) {
                setOpenBackdrop(false);
                if (error?.data?.message) {
                    setErrors({
                        name: error?.data?.message
                    });
                    return;
                }
                setPageError(Errors.generic);
            }
        },
    });

    const save = useCallback(async (quotePackage: Package<PackageItemDetailsView>,
        items: Array<PackageItem>, vendorIds: Array<{ id: string }>) => {
        if (user) {
            if (!quotePackage.id) {
                const createdPackage = await createPackage({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    body: {
                        name: quotePackage.name,
                        packageItems: items
                    }
                }).unwrap();
                if (vendorIds.length > 0 && items.length === 0) {
                    await createSubcontractorPackageVendor({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        packageId: createdPackage.id,
                        body: {
                            packageItems: [],
                            packageVendors: vendorIds
                        }
                    }).unwrap();
                }
                packageIdRef.current = createdPackage.id;
            } else {
                const updatedPackage = await updatePackage({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    packageId: quotePackage.id,
                    body: {
                        name: quotePackage.name,
                        packageItems: items,
                    }
                }).unwrap();
                packageIdRef.current = updatedPackage.id;
            }
        }
    }, [createPackage, createSubcontractorPackageVendor, props.estimateId, updatePackage, user])

    const setupGrid = useCallback(async () => {
        let shouldSave = false;
        setQuotePackage(packageDetails);
        if (gridRef.current!.api) {
            const qItems = new Array<PackageItemDetailsView>();
            const colDefs = gridRef.current!.api.getColumnDefs();
            const addedVends = new Array<string>();
            packageDetails?.vendors?.forEach((packageVendor) => {
                attachVendorColumns({
                    id: packageVendor.id,
                    name: packageVendor.name
                }, addedVends, colDefs);
            });
            packageDetails?.packageItems?.forEach((packageItem) => {
                const existing = qItems.find((item) => (item.id === packageItem.itemId));
                let qt: PackageItemDetailsView
                if (existing) {
                    qt = existing;
                    attachVendorColValues(packageItem, qt);
                } else {
                    qt = {
                        id: packageItem.itemId,
                        itemName: packageItem.itemName,
                        displayId: packageItem.itemDisplayId,
                        quantity: packageItem.quantity,
                        totalRate: packageItem.totalRate
                    };
                    attachVendorColValues(packageItem, qt);
                    qItems.push(qt);
                }
            });
            if (subcontractorState.option !== "none" && subcontractorState.items.length > 0) {
                subcontractorState.items.forEach((newItem) => {
                    if (newItem?.id && !qItems.some(i => i.id === newItem.id)) {
                        let qt: PackageItemDetailsView = {
                            id: newItem.id,
                            displayId: newItem.displayId,
                            quantity: newItem.quantity,
                            itemName: newItem.description,
                        };
                        if (addedVends.length > 0) {
                            addedVends.forEach((vendorIdHash) => {
                                const vendor = existingVendors?.find((v) => (v.id && md5(v.id) === vendorIdHash));
                                if (vendor) {
                                    qt[`vendor_id_${vendorIdHash}`] = vendor.id;
                                    qt[`rate_${vendorIdHash}`] = null;
                                    qt[`isChecked_${vendorIdHash}`] = false;
                                    qt[`amount_${vendorIdHash}`] = null;
                                    qt[`totalRate_${vendorIdHash}`] = null;
                                }
                            });
                        }
                        qItems.push(qt);
                    }
                });
                shouldSave = true;
            }
            setQuoteItems(qItems);
            setAddedVendorHashes(addedVends);
            gridRef.current!.api.setGridOption("columnDefs", colDefs);
            if (shouldSave && ready && packageDetails) {
                const items = new Array<PackageItem>();
                qItems.forEach((row) => {
                    let item: PackageItem = {
                        itemId: row.id
                    };
                    if (addedVends.length > 0) {
                        addedVends.forEach((vendorHash) => {
                            items.push({
                                ...item,
                                isChecked: row[`isChecked_${vendorHash}`],
                                rate: parseFloat(row[`rate_${vendorHash}`]),
                                vendorId: row[`vendor_id_${vendorHash}`],
                                totalRate: row[`totalRate_${vendorHash}`],
                            });
                        });
                    } else {
                        items.push(item);
                    }
                });
                await save(packageDetails, items, addedVends.map((vendor) => ({ id: vendor })));
                dispatch(clearSupplierState());
            }
            setReady(true);
        }
    }, [attachVendorColValues, attachVendorColumns, dispatch, existingVendors, packageDetails, ready, save, subcontractorState.items, subcontractorState.option])

    useEffect(() => {
        setupGrid();
    }, [setupGrid])

    const checkItem = useCallback(async (checked: boolean, node: IRowNode<PackageItemDetailsView>, vendorHash: string) => {
        try {
            setOpenBackdrop(true);
            const item = quoteItems.find((qItem) => (qItem.id === node.data?.id));
            if (item) {
                item[`isChecked_${vendorHash}`] = checked;
            }
            setQuoteItems(quoteItems);
            const pVendor = packageVendors?.find((pv) => (pv.subcontractorPackageId === packageIdRef.current && md5(pv.vendorId) === vendorHash));
            if (user && item && pVendor && values.id) {
                await updatePackage({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    packageId: values.id,
                    body: {
                        name: values.name,
                        packageItems: [{
                            ...item,
                            vendorId: item[`vendor_id_${vendorHash}`],
                            totalRate: item[`totalRate_${vendorHash}`],
                            itemId: item.id,
                            rate: parseFloat(item[`rate_${vendorHash}`] ?? '0'),
                            isChecked: checked
                        }],
                    }
                }).unwrap();
            }

            gridRef.current!.api.refreshCells({
                columns: [`isChecked_${vendorHash}`],
                rowNodes: [node],
                force: true
            });
            setOpenBackdrop(false);
        } catch (error: any) {
            setOpenBackdrop(false);
            if (error?.data?.message) {
                setErrors({
                    name: error?.data?.message
                });
                return;
            }
            setPageError(Errors.generic);
        }
    }, [packageVendors, props.estimateId, quoteItems, setErrors, updatePackage, user, values.id, values.name])
    const checkItemRef = useRef<any>();
    checkItemRef.current = checkItem;

    const checkAll = useCallback(async (checked: boolean, vendorHash: string) => {
        try {
            setOpenBackdrop(true);
            setPageError(undefined);
            const itemsToSave = new Array<PackageItem>();
            if (user) {
                quoteItems.forEach((item) => {
                    itemsToSave.push({
                        ...item,
                        itemId: item.id,
                        vendorId: item[`vendor_id_${vendorHash}`],
                        totalRate: item[`totalRate_${vendorHash}`],
                        rate: parseFloat(item[`rate_${vendorHash}`] ?? '0'),
                        isChecked: checked
                    });
                });
                await updatePackage({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    packageId: values.id,
                    body: {
                        name: values.name,
                        packageItems: itemsToSave,
                    }
                }).unwrap();
            }
            setOpenBackdrop(false);
        } catch (error: any) {
            setOpenBackdrop(false);
            if (error?.data?.message) {
                setErrors({
                    name: error?.data?.message
                });
                return;
            }
            setPageError(Errors.generic);
        }
    }, [props.estimateId, quoteItems, setErrors, updatePackage, user, values.id, values.name])
    const checkAllRef = useRef<any>();
    checkAllRef.current = checkAll;

    const deleteSubcontractorPackageItem = useCallback(async (node: IRowNode<PackageItemDetailsView>) => {
        try {
            setPageError(undefined);
            if (user) {
                await deletePackageItem({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    itemId: node.data?.id,
                    orgId: user.organizationId,
                    packageId: values.id,
                });
            }
        } catch (error) {
            setPageError(Errors.generic);
        }

    }, [deletePackageItem, values.id, props.estimateId, user])
    const deletePackageItemRef = useRef<any>();
    deletePackageItemRef.current = deleteSubcontractorPackageItem;

    const addItems = useCallback((items: Array<BaseItem>) => {
        let qItems = [...quoteItems];
        const itemToSave = new Array<PackageItemDetailsView>();
        items.forEach((item) => {
            if (item?.id && !qItems.some((i) => (i.id === item.id))) {
                let qt: PackageItemDetailsView = {
                    id: item.id,
                    displayId: item.displayId,
                    quantity: item.quantity,
                    itemName: item.description,
                };
                if (addedVendorHashes.length > 0) {
                    addedVendorHashes.forEach((vendorIdHash) => {
                        const vendor = existingVendors?.find((v) => (v.id && md5(v.id) === vendorIdHash));
                        if (vendor) {
                            qt[`vendor_id_${vendorIdHash}`] = vendor.id;
                            qt[`rate_${vendorIdHash}`] = null;
                            qt[`isChecked_${vendorIdHash}`] = false;
                            qt[`amount_${vendorIdHash}`] = null;
                            qt[`totalRate_${vendorIdHash}`] = null;
                        }
                    });
                }
                itemToSave.push(qt);
            }
        });
        qItems = qItems.concat(itemToSave);
        setQuoteItems(qItems);
        handleSubmit();
    }, [addedVendorHashes, existingVendors, handleSubmit, quoteItems])

    const addVendor = useCallback((vendor: Vendor, colDefs: any, rate: number | null = null) => {
        if (vendor.id) {
            let vendorIdHash = md5(vendor.id);
            if (!addedVendorHashes.includes(vendorIdHash)) {
                addedVendorHashes.push(vendorIdHash);
            }
            setAddedVendorHashes([...addedVendorHashes]);
            const qIts = [...quoteItems];
            qIts?.forEach((item) => {
                if (item?.id) {
                    item[`vendor_id_${vendorIdHash}`] = vendor.id;
                    item[`rate_${vendorIdHash}`] = rate;
                    item[`isChecked_${vendorIdHash}`] = false;
                    item[`amount_${vendorIdHash}`] = null;
                    item[`totalRate_${vendorIdHash}`] = null;
                }
            });
            setQuoteItems(qIts);
            colDefs.push(getNewColDefs(vendor.name, vendorIdHash));
        }
    }, [addedVendorHashes, getNewColDefs, quoteItems])

    const addSubcontractor = useCallback(async () => {
        try {
            const colDefs = gridRef.current!.api.getColumnDefs();
            const cols = gridRef.current!.api.getColumns();
            const vendorToAdd = new Array<Vendor & { filterText: string }>();
            if (selectedVendor) {
                if (!cols?.some((col) => (selectedVendor.id && col.getId() === `vendor_id_${md5(selectedVendor.id)}`))) {
                    vendorToAdd.push(selectedVendor);
                }
            } else if (filteredVendors && filteredVendors.length > 0) {
                for (let i = 0; i < filteredVendors.length; i++) {
                    const vendor = filteredVendors[i];
                    if (vendor.id) {
                        if (!cols?.some((col) => (vendor.id && col.getId() === `vendor_id_${md5(vendor.id)}`))) {
                            vendorToAdd.push(vendor);
                        }
                    }
                }
            }
            vendorToAdd.forEach((vendor) => {
                addVendor(vendor, colDefs);
            });
            gridRef.current!.api.setGridOption("columnDefs", colDefs);
            let rowData: PackageItemDetailsView[] = [];
            gridRef.current!.api.forEachNode((node) => {
                if (node.data) {
                    rowData.push(node.data);
                }
            });
            const items = new Array<PackageItem>();
            rowData.forEach((row) => {
                let item: PackageItem = {
                    itemId: row.id
                };
                if (addedVendorHashes.length > 0) {
                    addedVendorHashes.forEach((vendorHash) => {
                        if (vendorToAdd.some((vendor) => (md5(vendor.id ?? '') === vendorHash))) {
                            items.push({
                                ...item,
                                isChecked: row[`isChecked_${vendorHash}`],
                                rate: parseFloat(row[`rate_${vendorHash}`]),
                                vendorId: row[`vendor_id_${vendorHash}`],
                                totalRate: row[`totalRate_${vendorHash}`],
                            });
                        }
                    });
                } else {
                    items.push(item);
                }
            });
            if (packageIdRef.current) {
                await createSubcontractorPackageVendor({
                    estimateId: props.estimateId,
                    companyId: user?.companyId,
                    orgId: user?.organizationId,
                    packageId: packageIdRef.current,
                    body: {
                        packageItems: items,
                        packageVendors: vendorToAdd.map((vendor) => ({
                            id: vendor.id ?? '',
                        }))
                    }
                });
            }
        } catch (error) {
            setPageError(Errors.generic);
        }
    }, [addVendor, addedVendorHashes, createSubcontractorPackageVendor, filteredVendors, props.estimateId, selectedVendor, user?.companyId, user?.organizationId])

    const handleOnTagChanged = useCallback((event: React.SyntheticEvent, newTags: Tag[] | null) => {
        if (newTags && newTags.length > 0) {
            setSelectedTags(newTags);
        } else {
            setSelectedTags([]);
        }
    }, [])

    const getItemRowId = useCallback(function (params: GetRowIdParams<PackageItemDetailsView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const getFooterStyle = useCallback((params: RowClassParams<PackageItemDetailsView>) => {
        if (params.node.footer) {
            return { fontWeight: "bold" }
        }
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent<PackageItemDetailsView>) => {
        try {
            if (currentEditing?.node === event.node || !event.column.isCellEditable(event.node)) {
                return;
            }
            if (!currentEditing?.node) {
                if (event.node.data?.masterReferenceId || !hasAddEditAccessRef.current) return;

                if (event.column.getColId() === 'actions') {
                    return;
                }
            } else {
                gridRef.current!.api.stopEditing();
            }
            setCurrentEditing({ node: event.node, column: event.column.getColId() });
        } catch (error) { }
    }, [currentEditing?.node]);

    const onCellEditingStopped = useCallback(async (event: CellEditingStoppedEvent<PackageItemDetailsView>) => {
        try {
            const col = event.column.getColId();
            if (col.startsWith('rate_') && event.data) {
                const hash = col.split('rate_')[1];
                if (user && event.data) {
                    await updatePackage({
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        orgId: user.organizationId,
                        packageId: values.id,
                        body: {
                            name: values.name,
                            packageItems: [{
                                ...event.data,
                                vendorId: event.data[`vendor_id_${hash}`],
                                totalRate: event.data[`totalRate_${hash}`],
                                itemId: event.data.id,
                                rate: parseFloat(event.data[`rate_${hash}`]),
                                isChecked: event.data[`isChecked_${hash}`]
                            }],
                        }
                    }).unwrap();
                }
                setCurrentEditing(undefined);
            }
        } catch (error: any) {
            setOpenBackdrop(false);
            if (error?.data?.message) {
                setErrors({
                    name: error?.data?.message
                });
                return;
            }
            setPageError(Errors.generic);
        }

    }, [props.estimateId, setErrors, updatePackage, user, values.id, values.name])

    const onPackageBlur = useCallback(async (event: any) => {
        if (event?.target?.value && user) {
            const isDuplicate = await isPackageNameDuplicate({
                companyId: user.companyId ?? '',
                estimateId: props.estimateId ?? '',
                organizationId: user.organizationId,
                name: event.target.value,
                packageId: values.id
            }).unwrap();
            if (!isDuplicate) {
                setFieldValue('name', event.target.value);
                handleSubmit();
            }
            else {
                setFieldError('name', 'Duplicate package name.')
            }
        }
    }, [handleSubmit, isPackageNameDuplicate, props.estimateId, setFieldError, setFieldValue, user, values.id])

    const submitOnEnter = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }, [handleSubmit])

    return <>{colors && <Box height="100%">
        <Breadcrumbs
            sx={{
                " & .MuiBreadcrumbs-li": {
                    color: `${colors.gray[500]}`
                },
                " & .MuiBreadcrumbs-separator": {
                    color: `${colors.gray[700]}`
                }
            }}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
        >
            {breadcrumbs}
        </Breadcrumbs>
        <form onSubmit={handleSubmit} noValidate style={{ height: "100%" }}>
            <Box display="flex" justifyContent="space-between" padding="16px" flexDirection="column" gap="16px" height="100%">
                <Box display="flex" flexDirection="row" flex="0.2" marginRight="10px" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap="50px">
                        <label color={colors.gray[100]} style={{ width: "40px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Package:</label>
                        <TextField
                            size="small"
                            id="name"
                            sx={{ width: "245px" }}
                            name="name"
                            disabled={!hasAddEditAccessRef.current}
                            autoComplete="off"
                            placeholder="Package"
                            onChange={(e) => setFieldValue('name', e.target.value, false)}
                            onKeyDown={submitOnEnter}
                            onBlurCapture={onPackageBlur}
                            onBlur={handleBlur}
                            value={values.name}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name} />
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" flex="0.2" marginRight="10px" alignItems="center" gap="15px">
                    <Box display="flex" gap="50px" alignItems="center" flex="0.2">
                        <label color={colors.gray[100]} style={{ width: "40px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Subcontractors:</label>
                        {existingTags && <Autocomplete
                            fullWidth
                            size="small"
                            renderTags={(value: readonly Tag[], getTagProps) =>
                                value.map((option: Tag, index: number) => (
                                    <Chip variant="outlined" label={option.description} {...getTagProps({ index })} />
                                ))
                            }
                            multiple
                            disabled={!hasAddEditAccessRef.current}
                            limitTags={2}
                            sx={{ width: "245px" }}
                            value={selectedTags ?? []}
                            onChange={handleOnTagChanged}
                            getOptionLabel={(option) => option.id ?? ''}
                            isOptionEqualToValue={(option, tag) => option.id === tag.id}
                            options={[...existingTags]}
                            renderInput={(params) => <TextField placeholder='Select Tag' {...params} />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.description, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.description, matches);
                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />}
                    </Box>
                    <Box display="flex" gap="15px">
                        <Autocomplete
                            fullWidth
                            className="ag-input-field-input ag-text-field-input"
                            sx={{ width: "245px", height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                            size="small"
                            getOptionLabel={(option) => option.name ?? ''}
                            onChange={(event, value) => {
                                if (value) {
                                    setSelectedVendor(value);
                                } else {
                                    setSelectedVendor(undefined);
                                }
                            }}
                            disabled={!hasAddEditAccessRef.current}
                            options={filteredVendors ?? []}
                            isOptionEqualToValue={(option, tag) => option.id === tag.id}
                            value={selectedVendor ?? null}
                            renderInput={(params) => <TextField sx={{ height: "100%" }} placeholder='Select Subcontractor' {...params} />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.filterText ?? '', inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.filterText ?? '', matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    </Box>
                    <Box display="flex">
                        <Button disabled={!hasAddEditAccessRef.current || (selectedTags.length === 0 && !selectedVendor)} variant="contained" onClick={addSubcontractor}>Add</Button>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" flex="0.2" marginRight="10px" alignItems="center" gap="50px">
                    <label color={colors.gray[100]} style={{ width: "40px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Items:</label>
                    <SubcontractorItemFilters disabled={!hasAddEditAccessRef.current} estimateId={props.estimateId} addItems={addItems} />
                </Box>
                {pageError && <Box marginBottom="10px"><Alert severity="error">{pageError}</Alert></Box>}
                <Box style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
                    <AgGridReact<PackageItemDetailsView>
                        ref={gridRef}
                        rowData={quoteItems}
                        columnDefs={columnDefs}
                        getRowStyle={getFooterStyle}
                        stopEditingWhenCellsLoseFocus={true}
                        grandTotalRow="bottom"
                        animateRows={true}
                        onCellClicked={onCellClicked}
                        onCellEditingStopped={onCellEditingStopped}
                        getRowId={getItemRowId}
                        onGridReady={setupGrid}
                    />
                </Box>
            </Box>
        </form>
    </Box>}
        {selectedPackageVendor && props.estimateId && <Drawer
            anchor="right"
            open={openSubcontractorPanel}
            onClose={() => setSelectedPackageVendor(undefined)}
        >
            <SubcontractorAttachments disabled={!hasAddEditAccessRef.current} packageVendor={selectedPackageVendor} estimateId={props.estimateId} close={() => setOpenSubcontractorPanel(false)} />
        </Drawer>}
        {selectedPackageVendor && selectedItem && props.estimateId && <Drawer
            anchor="right"
            open={openCalculateRate}
            onClose={() => closeCalculateRatePanel()}
        >
            <SubcontractorRate disabled={!hasAddEditAccessRef.current} item={selectedItem} packageVendor={selectedPackageVendor} estimateId={props.estimateId} close={() => closeCalculateRatePanel()} />
        </Drawer>}
        <Backdrop
            sx={{ color: colors?.primary[1000], zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
}