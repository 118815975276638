import HotTable, { HotTableClass } from "@handsontable/react";
import { Alert, AlertTitle, Box, CircularProgress } from "@mui/material";
import { FileDetails } from "Models/file";
import { useEffect, useMemo, useRef } from "react";
import { useDifferencesQuery } from "State/Services/file";
import { useGetUserDetailsQuery } from "State/Services/user";
import { cloneDeep } from 'lodash';
import { renderDiff } from "./RenderDiffTable";

export interface DifferencesProps {
    fileDetails: FileDetails;
    estimateId: string;
    onDifferencesDone: () => void;
    onLoad: (hasErrors: boolean) => void;
}

export default function Differences(props: DifferencesProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: differenceInfo, isLoading } = useDifferencesQuery({
        companyId: (user && user.companyId) ? user.companyId : '',
        organizationId: (user && user.organizationId) ? user.organizationId : '',
        estimateId: (props && props.estimateId) ? props.estimateId : '',
        fileId: (props && props?.fileDetails?.id) ? props.fileDetails.id : ''
    });

    const ref = useRef<HotTableClass>(null);
    const hotDiffResultSettings = useMemo(() => ({
        minRows: 10,
        minCols: 10,
        minSpareCols: 0,
        minSpareRows: 0,
        colHeaders: false,
        contextMenu: false,
        rowHeaders: false,
        readOnly: true,
        renderAllRows: true,
        licenseKey: "5d349-5174b-78630-6f340-ad241",
    }), []);

    useEffect(() => {
        if (differenceInfo?.diffs) {
            ref.current?.hotInstance?.loadData(cloneDeep(differenceInfo.diffs));
        }
        props.onLoad(!!(differenceInfo?.duplicateError || differenceInfo?.missingError))
    }, [differenceInfo, props])

    return <Box width="100%"
        height="100%"
        marginTop="20px"
        display="flex"
        flexDirection="column"
        alignContent="center"
        alignItems="center"
        justifyContent="center">
        {isLoading &&
            <>
                <Box>Analyzing...</Box>
                <Box width='100%' display="flex" alignContent="center"
                    alignItems="center"
                    justifyContent="center">
                    <Box><CircularProgress color="primary" /></Box>
                </Box>
            </>
        }
        {(differenceInfo?.duplicateError || differenceInfo?.missingError) && <Box width="100%" marginBottom="5px" padding="5px">
            <Alert severity="error" sx={{}}>
                <AlertTitle>Errors</AlertTitle>
                {differenceInfo?.duplicateError && <Box>{differenceInfo?.duplicateError.message}</Box>}
                {differenceInfo?.missingError && <Box>{differenceInfo?.missingError.message}</Box>}
            </Alert>
        </Box>}
        <Box height="100%" width="100%" visibility={isLoading ? 'hidden' : 'visible'}>
            <HotTable
                ref={ref}
                data={[[""]]}
                height="500px"
                width="100%"
                settings={hotDiffResultSettings}
                renderer={renderDiff}
                className="diffhandsontable"
                stretchH="all"
            />
        </Box>

    </Box>
}