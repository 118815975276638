import { Box, useTheme } from "@mui/material";
import { ColDef, GetRowIdParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import { rounder } from "Helpers/rounder";
import { ActivityCostListReport, ActivityCostListReportView, ResourceReport, ResourceReportView } from "Models/report";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import { v4 as uuidv4 } from 'uuid';

export interface ActivityRateCostProps {
    estimateId: string | undefined;
    pageSize: number;
    activityReport: {
        results: Array<ActivityCostListReport>;
    }
}

export default function ActivityRateCost(props: ActivityRateCostProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const gridRef = useRef<AgGridReact<ActivityCostListReportView>>(null);
    const [rowData, setRowData] = useState<Array<ActivityCostListReportView>>([]);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });

    useEffect(() => {
        if (props.activityReport && props.activityReport.results.length > 0) {
            setRowData(props.activityReport.results.map((result) => {
                const unit = units?.find(u => u.id === result.unitId);
                return {
                    ...result,
                    unit: {
                        unitDescription: unit?.description,
                        unitId: unit?.id
                    },
                    id: uuidv4()
                }
            }));
        }
    }, [props.activityReport, units])

    const getBaseDefs = useCallback((): Array<ColDef<ActivityCostListReportView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'resourceId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'activityId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                headerName: "Output",
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'amount',
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unitRate',
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
        ];
    }, [colors?.gray, estimate?.CompanyCurrency?.Currency?.minorUnit])

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            field: 'displayId',
            resizable: true,
            headerName: 'ID',
            sort: 'asc',
            cellDataType: "text",
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            cellRendererParams: {
                suppressCount: true,
            }
        };
    }, [colors?.gray]);

    const getRowId = useCallback(function (params: GetRowIdParams<ActivityCostListReportView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const getRowStyle = (params: any) => {
        if (params.node?.data?.hierarchy?.length===1 || params.node?.data?.hierarchy?.length===2) {
            return { fontWeight: 'bold' };
        }
    };

    return <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
            <AgGridReact<ActivityCostListReportView>
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                getRowId={getRowId}
                getRowStyle={getRowStyle}
                paginationPageSize={props.pageSize}
                pagination={true}
                groupDefaultExpanded={-1}
                treeData={true}
                getDataPath={(data: ActivityCostListReportView) => (data.hierarchy)}
            />
        </Box>
    </Box>
}