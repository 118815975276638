import { Autocomplete, Box, Button, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { useGetUserDetailsQuery, useGetUsersForCompanyQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useCallback, useEffect, useRef, useState } from "react";
import { tokens } from "theme";
import CloseIcon from '@mui/icons-material/Close';
import { Estimate, EstimateUserRoleView } from "Models/estimate";
import { UserInfo } from "Models/user-details";
import AddIcon from '@mui/icons-material/Add';
import { ColDef, EditableCallbackParams, GetRowIdParams, IRowNode } from "ag-grid-enterprise";
import RoleCellRenderer from "./RoleCellRenderer";
import CancelDeleteRoleCellRenderer from "./DeleteRoleCellRenderer";
import { AgGridReact } from "ag-grid-react";
import { useAddUserToEstimateMutation, useDeleteEstimateUserMutation, useUpdateUserOnEstimateMutation } from "State/Services/estimate-permission";
import { EstimateRole } from "Models/estimate-permission";
import { Master } from "Models/master";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 2,
};

export interface SharingPermissionsProps {
    close: () => void;
    estimateId: string | undefined;
    isMaster: boolean | undefined;
    estimates?: Estimate[];
    masters?: Master[];
}

export default function SharingPermissions(props: SharingPermissionsProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: user } = useGetUserDetailsQuery();
    const { data: users } = useGetUsersForCompanyQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [selectedUser, setSelectedUser] = useState<UserInfo & { displayName: string } | null>(null);
    const [rowData, setRowData] = useState<Array<EstimateUserRoleView>>([]);
    const [usersFiltered, setUsersFiltered] = useState<Array<UserInfo & { displayName: string }>>([]);
    const [addEstimateUser] = useAddUserToEstimateMutation();
    const [deleteEstimateUser] = useDeleteEstimateUserMutation();
    const [updateEstimateUser] = useUpdateUserOnEstimateMutation();

    const onChangeRole = useCallback(async (node: IRowNode<EstimateUserRoleView>, role: EstimateRole) => {
        if (user) {
            await updateEstimateUser({
                companyId: user.companyId,
                orgId: user.organizationId,
                estimateId: props.estimateId,
                estimateUserRoleId: node.data?.id,
                body: {
                    estimateRole: role,
                    userId: node.data?.userId ?? ''
                }
            });
        }
    }, [props.estimateId, updateEstimateUser, user])
    const onChangeRoleRef = useRef<any>();
    onChangeRoleRef.current = onChangeRole;

    const deleteRole = useCallback(async (node: IRowNode<EstimateUserRoleView>) => {
        if (user) {
            await deleteEstimateUser({
                companyId: user.companyId,
                orgId: user.organizationId,
                estimateId: props.estimateId,
                estimateUserRoleId: node.data?.id,
            });
        }
    }, [deleteEstimateUser, props.estimateId, user])
    const deleteRoleRef = useRef<any>();
    deleteRoleRef.current = deleteRole;

    useEffect(() => {
        if (users && users?.length > 0) {
            if (!props.isMaster) {
                const estimate = props.estimates?.find((estimate) => (estimate.id === props.estimateId));
                const urs = users.filter(existingUser => !estimate?.EstimateUserRole?.some((userRole) => userRole.userId === existingUser.id));
                setUsersFiltered(urs.map(u => {
                    return {
                        ...u,
                        displayName: `${u.name} (${u.email})`
                    }
                }));
            } else {
                const master = props.masters?.find((estimate) => (estimate?.id === props.estimateId));
                const usrs = users.filter(existingUser => !master?.EstimateUserRole?.some((userRole) => userRole.userId === existingUser.id))
                setUsersFiltered(usrs.map(u => {
                    return {
                        ...u,
                        displayName: `${u.name} (${u.email})`
                    }
                }));
            }

        }
    }, [props.estimateId, props.estimates, props.isMaster, props.masters, user?.userId, users])

    useEffect(() => {
        const data = new Array<EstimateUserRoleView>();
        if (!props.isMaster) {
            const estimate = props.estimates?.find((estimate) => (estimate.id === props.estimateId));
            estimate?.EstimateUserRole?.forEach((role) => {
                data.push({
                    userId: role.userId,
                    actions: '',
                    id: role.id,
                    role: role.EstimateRole.name,
                    user: `${role?.User?.firstName} ${role?.User?.lastName} (${role.User.email})`
                });
            });
        } else {
            const master = props.masters?.find((estimate) => (estimate?.id === props.estimateId));
            master?.EstimateUserRole?.forEach((role) => {
                data.push({
                    userId: role.userId,
                    actions: '',
                    id: role.id,
                    role: role.EstimateRole.name,
                    user: `${role?.User?.firstName} ${role?.User?.lastName} (${role.User.email})`
                });
            });
        }
        setRowData(data);
    }, [props.estimateId, props.estimates, props.isMaster, props.masters])

    const isEditable = useCallback((params: EditableCallbackParams<EstimateUserRoleView, any>) => {
        return true;
    }, [])

    const [columnDefs] = useState<ColDef<EstimateUserRoleView>[]>([
        { field: 'id', hide: true },
        { field: 'userId', hide: true },
        {
            field: 'user',
            flex: 1,
            headerName: 'Name',
            valueFormatter: (params) => {
                return params.data?.userId === user?.userId ? 'Me' : params.data?.user ?? ''
            },
            editable: isEditable,
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'role',
            headerName: 'Role',
            editable: isEditable,
            cellRenderer: RoleCellRenderer,
            cellRendererParams: {
                onChange: async (node: IRowNode<EstimateUserRoleView>, role: string) => await onChangeRoleRef.current(node, role)
            },
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}`, padding: "0px" },
            flex: 1
        },
        {
            field: 'actions',
            resizable: true,
            width: 80,
            headerName: 'Actions',
            editable: isEditable,
            menuTabs: [],
            cellStyle: { textAlign: "left", padding: "0px" } as any,
            cellRenderer: CancelDeleteRoleCellRenderer,
            cellRendererParams: {
                delete: (node: IRowNode<EstimateUserRoleView>) => deleteRoleRef.current(node),
            }
        },
    ]);

    const handleClose = useCallback(() => {
        props.close();
    }, [props])

    const addUserToEstimate = useCallback(async () => {
        if (user) {
            await addEstimateUser({
                companyId: user?.companyId,
                orgId: user?.organizationId,
                estimateId: props.estimateId,
                body: {
                    estimateRole: EstimateRole.View,
                    userId: selectedUser?.id ?? ''
                }
            });
            setSelectedUser(null);
        }
    }, [addEstimateUser, props.estimateId, selectedUser?.id, user])

    const getRoleRowId = useCallback(function (params: GetRowIdParams<EstimateUserRoleView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    return <Box sx={style}>
        <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            marginBottom="10px"
            borderBottom={`1px solid ${colors?.gray[800]}`}>
            <Box padding="10px">
                {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Sharing & Permissions</Typography>}
            </Box>
            <Box>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="10px">
            <Box>
                <Box display="flex" alignItems="center" gap="10px"><Autocomplete
                    fullWidth
                    size="small"
                    getOptionLabel={(option) => option.displayName ?? ''}
                    onChange={(event, value) => {
                        setSelectedUser(value);
                    }}
                    options={usersFiltered}
                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                    value={selectedUser ?? null}
                    renderInput={(params) => <TextField name="user" sx={{ height: "100%" }} placeholder='Select User' {...params} />}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.displayName ?? '', inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(option.displayName ?? '', matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                />
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={addUserToEstimate}>Add</Button>
                </Box>
            </Box>
            <Box className="ag-theme-alpine ag-theme-bidbow" height="400px">
                <AgGridReact<EstimateUserRoleView>
                    rowData={rowData}
                    suppressClickEdit={true}
                    columnDefs={columnDefs}
                    getRowId={getRoleRowId}
                />
            </Box>
        </Box>
    </Box>
}