import { Autocomplete, Box } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { GridTextField } from "Components/GridTextField";
import { ResourceView } from "Models/resource";
import { ResourceSubCategory } from "Models/resource-category";
import { forwardRef, MutableRefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useGetResourceCategoriesQuery } from "State/Services/resource-category";
import { useGetUserDetailsQuery } from "State/Services/user";
import { CustomCellEditorProps } from "ag-grid-react";
import { BaseEditCellRenderer } from "Models/grid";

export interface ResourceListSubCategoryEditCellRender extends BaseEditCellRenderer {
    loadSubcategories: (categoryId?: string | undefined) => void;
}
export interface ResourceListSubCategoryEditCellRendererParams extends CustomCellEditorProps<ResourceView, string> {
    estimateId: string;
    setRef: (ref: MutableRefObject<any>) => void;
}

export default forwardRef(({ api, node, value, onValueChange, cellStartedEdit, setRef, estimateId }: ResourceListSubCategoryEditCellRendererParams, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedResourceCategories } = useGetResourceCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId, refetchOnMountOrArgChange: true });
    const [options, setOptions] = useState<Array<ResourceSubCategory>>([]);
    const [open, setOpen] = useState(false);
    const inputRef = useRef<any>();
    const [localValue, setLocalValue] = useState<ResourceSubCategory | null>();
    const categoryIdRef = useRef<string | null | undefined>(node.data?.categoryId);
    const subCategoryIdRef = useRef<string | null | undefined>(node.data?.subCategoryId);

    const updateValue = useCallback((val: ResourceSubCategory | null) => {
        onValueChange(val?.description);
        setLocalValue(val);
    }, [onValueChange]);

    const loadOptions = useCallback((categoryId: string | undefined) => {
        const category = storedResourceCategories?.find((cat) => cat.id === categoryId);
        if (category && category.resourceSubCategories && category.resourceSubCategories.length > 0) {
            setOptions(category.resourceSubCategories);
        } else {
            setOptions([]);
        }
    }, [storedResourceCategories])

    useEffect(() => {
        let valueToSet: ResourceSubCategory | null = null;
        const resCategory = storedResourceCategories?.find((resCategory) => (resCategory.id === categoryIdRef.current));
        if (resCategory && resCategory.resourceSubCategories && resCategory.resourceSubCategories.length > 0) {
            valueToSet = resCategory.resourceSubCategories.find((sub) => sub.id === subCategoryIdRef.current) ?? null;
        }
        updateValue(valueToSet);
        loadOptions(valueToSet?.resourceCategoryId);
        if (cellStartedEdit) {
            inputRef.current?.focus();
            inputRef.current?.select();
            handleOpen();
        }
    }, [cellStartedEdit, loadOptions, storedResourceCategories, updateValue])

    useImperativeHandle(ref, () => {
        return {
            setFocusOnAdd() {
                if (inputRef.current) {
                    inputRef.current.focus();
                    setOpen(true);
                }
            },
            loadSubcategories(categoryId: string | undefined) {
                loadOptions(categoryId);
                updateValue(null);
            }
        };
    });

    const onChange = useCallback((event: React.SyntheticEvent, newValue: ResourceSubCategory | null) => {
        if (newValue && options) {
            const resSubCategory = options.find((sub) => (sub.id === newValue.id));
            if (resSubCategory) {
                updateValue(resSubCategory);
                node.setDataValue('subCategoryId', resSubCategory.id);
            } else {
                updateValue(null);
                node.setDataValue('subCategoryId', undefined);
            }
        } else {
            setLocalValue(null);
            node.setDataValue('subCategoryId', undefined);
        }
    }, [node, options, updateValue])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (inputRef) {
            setRef(inputRef);
        }
    }, [setRef])

    return <Box height="100%">
        <Autocomplete
            fullWidth
            className="ag-input-field-input ag-text-field-input"
            sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
            value={localValue || null}
            size="small"
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            onOpen={handleOpen}
            open={open}
            onChange={onChange}
            options={options ?? []}
            getOptionLabel={(resCategory) => {
                if (typeof resCategory === 'string') {
                    return resCategory;
                }
                return resCategory.description ?? '';
            }}
            renderInput={(params) => <GridTextField sx={{ height: "100%" }} inputRef={inputRef} placeholder='Select Subcategory' {...params} />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.description ?? '', inputValue, {
                    insideWords: true,
                });
                const parts = parse(option.description ?? '', matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    </Box>
});