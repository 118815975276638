import { api } from './api'
import { EstimatePermission } from 'Models/estimate-permission';

export const estimatePermissionApi = api.injectEndpoints({
  endpoints: (build) => ({
    addUserToEstimate: build.mutation<void, Partial<{ body: EstimatePermission, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate-permission/${estimateId}`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Estimates', 'Masters', 'Estimate'],
    }),
    updateUserOnEstimate: build.mutation<void, Partial<{ body: EstimatePermission, orgId: string, companyId: string, estimateId: string, estimateUserRoleId: string }>>({
      query({ body, orgId, companyId, estimateId, estimateUserRoleId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate-permission/${estimateId}/estimate-user-role/${estimateUserRoleId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Estimates', 'Masters', 'Estimate'],
    }),
    deleteEstimateUser: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, estimateUserRoleId: string }>>({
      query({ orgId, companyId, estimateId, estimateUserRoleId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate-permission/${estimateId}/estimate-user-role/${estimateUserRoleId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['Estimates', 'Masters'],
    }),
  })
});


export const {
  useAddUserToEstimateMutation,
  useUpdateUserOnEstimateMutation,
  useDeleteEstimateUserMutation
} = estimatePermissionApi