import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import { Entity, EstimateView } from "Models/estimate";
import { useCallback, useEffect, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { hasSystemRolePermission } from "Helpers/role-permissions";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useGetAllMastersForCompanyQuery } from "State/Services/master";
import { hasEstimatePermission } from "Helpers/estimate-permissions";

export enum EstimateMenuOptions {
    edit = "edit",
    duplicate = "duplicate",
    master = "master",
    delete = "delete",
    changeCurrency = "change-currency",
    sharingPermissions = "sharing-permissions",
    new = "new",
    refresh = "refresh"
}

export interface ICancelCellRendererProps extends ICellRendererParams<EstimateView> {
    onAction: (node: IRowNode<EstimateView>, option: EstimateMenuOptions) => Promise<void>;
}

export default function SaveCancelEstimateCellRenderer(props: ICancelCellRendererProps) {
    const { node } = props;
    const { data: user } = useGetUserDetailsQuery();
    const { data: masters } = useGetAllMastersForCompanyQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const [sharingDisabled, setSharingDisabled] = useState(false);
    const [disableMasterActions, setDisableMasterActions] = useState<boolean>(true);
    const [disableCreateNewFromTemplate, setDisableCreateNewFromTemplate] = useState(true);

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onActionClick = (option: EstimateMenuOptions) => {
        props.onAction(node, option);
        handleMenuClose();
    }

    useEffect(() => {
        if (user && props.node.data) {
            const master = masters?.find(m => m.MasterEstimate?.id === props.node.data?.masterEstimateId);
            if (master?.EstimateUserRole && node.data?.EstimateUserRole) {
                const hasEstimateResourceEditAccess = hasEstimatePermission(user?.userId, node.data.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [503, 502]
                });
                const hasEstimateActivityEditAccess = hasEstimatePermission(user?.userId, node.data.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [503, 502]
                });
                const hasEstimateItemEditAccess = hasEstimatePermission(user?.userId, node.data.EstimateUserRole, {
                    entity: Entity.Items,
                    requiredPermissions: [503, 502]
                });
                const hasEstimateVendorEditAccess = hasEstimatePermission(user?.userId, node.data.EstimateUserRole, {
                    entity: Entity.Vendors,
                    requiredPermissions: [503, 502]
                });
                const hasMasterResourceEditAccess = hasEstimatePermission(user?.userId, master.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [501, 502, 503]
                });
                const hasMasterActivityEditAccess = hasEstimatePermission(user?.userId, master.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [501, 502, 503]
                });
                const hasMasterItemEditAccess = hasEstimatePermission(user?.userId, master.EstimateUserRole, {
                    entity: Entity.Items,
                    requiredPermissions: [501, 502, 503]
                });
                const hasMasterVendorEditAccess = hasEstimatePermission(user?.userId, master.EstimateUserRole, {
                    entity: Entity.Vendors,
                    requiredPermissions: [501, 502, 503]
                });
                setDisableCreateNewFromTemplate(!(hasEstimateResourceEditAccess && hasEstimateActivityEditAccess && hasEstimateItemEditAccess && hasEstimateVendorEditAccess && hasMasterResourceEditAccess && hasMasterActivityEditAccess && hasMasterItemEditAccess && hasMasterVendorEditAccess));
            }
            if (user.role === "Admin" || user.role === "Account Owner") {
                setActionsDisabled(false);
                setSharingDisabled(false);
                setDisableMasterActions(false);
                return;
            }
            const hasActionsAccess = hasSystemRolePermission(user, [101, 102]);
            setActionsDisabled(!(hasActionsAccess && user.userId === props.node.data.ownerId));
            const hasSharingAccess = hasSystemRolePermission(user, [103]);
            setSharingDisabled(!(hasSharingAccess && user.userId === props.node.data.ownerId));
            const hasMasterCreate = hasSystemRolePermission(user, [200]);
            setDisableMasterActions(!hasMasterCreate);
        }
    }, [masters, node.data?.EstimateUserRole, props.node, user])

    return (<>
        {!node.group && node.data &&
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={menuOpen ? 'long-menu' : undefined}
                    aria-expanded={menuOpen ? 'true' : undefined}
                    aria-haspopup="true"
                    disabled={actionsDisabled}
                    onClick={handleMenuClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(EstimateMenuOptions.edit)}>Edit</MenuItem>
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(EstimateMenuOptions.delete)}>Delete</MenuItem>
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(EstimateMenuOptions.duplicate)}>Duplicate</MenuItem>
                    <MenuItem disabled={disableMasterActions || !!props.data?.masterEstimateId} onClick={() => onActionClick(EstimateMenuOptions.master)}>Duplicate as template</MenuItem>
                    {node.data.MasterEstimate && <MenuItem disabled={actionsDisabled || disableCreateNewFromTemplate} onClick={() => onActionClick(EstimateMenuOptions.refresh)}>Create new from template</MenuItem>}
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(EstimateMenuOptions.changeCurrency)}>Change currency</MenuItem>
                    <MenuItem disabled={sharingDisabled} onClick={() => onActionClick(EstimateMenuOptions.sharingPermissions)}>Sharing & Permissions</MenuItem>
                </Menu>
            </Box>}
    </>
    );
};
