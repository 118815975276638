import { Box, useTheme } from "@mui/material";
import { ColDef, GetRowIdParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import { rounder } from "Helpers/rounder";
import { SupplierResourceReportQuotation, SupplierResourceReportQuotationView } from "Models/report";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import { v4 as uuidv4 } from 'uuid';

export interface ResourceQuotationsProps {
    estimateId: string | undefined;
    supplierQuotations: Array<SupplierResourceReportQuotation>;
}

export default function ResourceQuotations(props: ResourceQuotationsProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const gridRef = useRef<AgGridReact<SupplierResourceReportQuotationView>>(null);
    const [rowData, setRowData] = useState<Array<SupplierResourceReportQuotationView>>([]);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });

    useEffect(() => {
        if (props.supplierQuotations && props.supplierQuotations.length > 0) {
            setRowData(props.supplierQuotations.map((result) => {
                const unit = units?.find(u => u.id === result.unitId);
                return {
                    ...result,
                    unit: {
                        unitDescription: unit?.description,
                        unitId: unit?.id
                    },
                    id: uuidv4()
                }
            }));
        }
    }, [props.supplierQuotations, units])

    const getBaseDefs = useCallback((): Array<ColDef<SupplierResourceReportQuotationView>> => {
            return [
                {
                    field: 'id',
                    hide: true,
                    suppressColumnsToolPanel: true
                },
                {
                    field: 'resourceDisplayId',
                    headerName: 'ID',
                    hide: false,
                    cellRenderer: 'agGroupCellRenderer',
                    menuTabs: ["filterMenuTab", "generalMenuTab"],
                    width: 80,
                    valueFormatter: (params) => {
                        return (params.node?.group && params?.node?.allLeafChildren) ? params?.node?.allLeafChildren[0]?.data?.resourceDisplayId ?? '' : '';
                    },
                    cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: "selection",
                    cellDataType: 'boolean',
                    width: 50,
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}`, justifyContent: "center" }
                },
                {
                    field: 'description',
                    valueFormatter: (params) => {
                        return (params.node?.group && params?.node?.allLeafChildren) ? params?.node?.allLeafChildren[0]?.data?.description ?? '' : (params.data?.vendorName ?? '');
                    },
                    rowGroup: true,
                    showRowGroup: true,
                    flex: 300,
                    menuTabs: ["filterMenuTab", "generalMenuTab"],
                    cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: 'unit',
                    menuTabs: ["filterMenuTab", "generalMenuTab"],
                    width: 75,
                    valueFormatter: (params) => {
                        return (params.node?.group && params?.node?.allLeafChildren) ? params?.node?.allLeafChildren[0]?.data?.unit?.unitDescription ?? '' : '';
                    },
                    headerName: 'Unit',
                    cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: 'rate',
                    width: 175,
                    valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                    menuTabs: ["filterMenuTab", "generalMenuTab"],
                    cellDataType: "number",
                    cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: 'quantity',
                    width: 175,
                    menuTabs: ["filterMenuTab", "generalMenuTab"],
                    cellDataType: "number",
                    cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: 'amount',
                    width: 175,
                    valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                    menuTabs: ["filterMenuTab", "generalMenuTab"],
                    cellDataType: "number",
                    cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: 'vendorName',
                    hide: true
                },
                {
                    field: 'vendorDisplayId',
                    sort: 'asc',
                    hide: true
                },
            ];
        }, [colors?.gray, estimate?.CompanyCurrency?.Currency?.minorUnit])
    
        const [columnDefs] = useState<ColDef[]>(getBaseDefs());
    
        const getRowId = useCallback(function (params: GetRowIdParams<SupplierResourceReportQuotationView>) {
            if (params.data.id) {
                return params.data.id;
            }
            return '';
        }, []);
    
        const defaultColDef = useMemo<ColDef>(() => {
            return {
                resizable: true
            };
        }, []);
    
        const getRowStyle = (params: any) => {
            if (params.node?.group) {
                return { fontWeight: 'bold' };
            }
        };

        return <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
            <AgGridReact<SupplierResourceReportQuotationView>
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                getRowId={getRowId}
                suppressAggFuncInHeader={true}
                getRowStyle={getRowStyle}
                groupDefaultExpanded={-1}
                groupDisplayType={'custom'}
            />
        </Box>
        
    </Box>
}