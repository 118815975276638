import { Alert, Box, Button, Card, CardContent, TextField, Typography } from "@mui/material";
import { Errors } from "Models/errors";
import { useGetSettingsQuery, useUpdateMasterSettingsMutation } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from "react-dropzone";
import SplitButton, { MenuOption } from "Components/SplitButton";
import { useDownloadReportFileMutation, useExportDefaultTemplateMutation, useGetReportTemplateFilesQuery, useImportReportTemplateMutation } from "State/Services/report-file";
import CheckIcon from '@mui/icons-material/Check';

export default function Master() {
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [updateMasterSettings] = useUpdateMasterSettingsMutation();
    const [prefix, setPrefix] = useState<string>();
    const [downloadMenuItems, setDownloadMenuItems] = useState<Array<MenuOption>>([{ option: 'Download', disabled: false }, { option: 'Download Sample', disabled: false }]);
    const [pageError, setPageError] = useState<string | undefined>();
    const [downloadFile] = useDownloadReportFileMutation();
    const [exportDefaultTemplate] = useExportDefaultTemplateMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [importReportTemplate] = useImportReportTemplateMutation();
    const [showSuccess, setShowSuccess] = useState(false);
    const { data: templateFiles } = useGetReportTemplateFilesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const {
        open,
        acceptedFiles, getInputProps } = useDropzone({
            // Disable click and keydown behavior
            noClick: true,
            noKeyboard: true,
            accept: {
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
            }
        });

    useEffect(() => {
        if (settings) {
            setPrefix(settings.masterIdPrefix);
        }
    }, [settings])

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const formData = new FormData();
            if (acceptedFiles) {
                formData.append("file", acceptedFiles[0]);
                if (user && user.companyId) {
                    importReportTemplate({
                        companyId: user.companyId,
                        fileData: formData,
                        organizationId: user?.organizationId
                    });
                    setShowSuccess(true);
                }
            }
        }
    }, [acceptedFiles, importReportTemplate, user])

    const onPrefixChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setPrefix(event.target.value);
    }, [])

    const save = useCallback(async () => {
        try {
            if (user && prefix) {
                await updateMasterSettings({
                    orgId: user.organizationId,
                    companyId: user.companyId,
                    body: {
                        masterIdPrefix: prefix
                    }
                });
            }
        } catch (error) {
            setPageError(Errors.generic);
        }
    }, [prefix, updateMasterSettings, user])

    const handleDefaultDownload = useCallback(async () => {
        setShowSuccess(false);
        setIsLoading(true);
        if (templateFiles && templateFiles.length > 0) {
            await downloadFile({
                companyId: user?.companyId,
                fileId: templateFiles[0].id,
                organizationId: user?.organizationId
            });
        } else {
            await exportDefaultTemplate({
                companyId: user?.companyId,
                organizationId: user?.organizationId
            });
        }
        setIsLoading(false);
    }, [downloadFile, exportDefaultTemplate, templateFiles, user?.companyId, user?.organizationId])

    const onMenuItemClick = useCallback(async (option: string) => {
        setShowSuccess(false);
        switch (option) {
            case "Download":
                handleDefaultDownload();
                break;
            case "Download Sample":
                await exportDefaultTemplate({
                    companyId: user?.companyId,
                    organizationId: user?.organizationId
                });
                break;
            default:
                break;
        }
    }, [exportDefaultTemplate, handleDefaultDownload, user?.companyId, user?.organizationId])

    return <Box>
        {pageError && <Box margin="10px 20px 10px 20px"><Alert severity="error">{pageError}</Alert></Box>}
        <Box display="flex" flexDirection="column" width="23%">
            <Box display="flex" flexDirection="column" width="100%">
                <Card variant="outlined" style={{ width: "100%", margin: "10px" }}>
                    <CardContent>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                            Template Settings
                        </Typography>
                        <Box display="flex" width="100%" alignItems="center" gap="15px" paddingTop="10px">
                            <label htmlFor="prefix" style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>ID Prefix</label>
                            <TextField
                                sx={{ height: "40px" }}
                                size="small"
                                autoComplete="off"
                                name="prefix"
                                onChange={onPrefixChanged}
                                value={prefix}
                                placeholder="Prefix" />
                            <Button variant="contained" disabled={!prefix} onClick={save}>Save</Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            {/* <Box display="flex" flexDirection="column">
                <Card variant="outlined" style={{ width: "100%", margin: "10px" }}>
                    <CardContent>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                            Report Settings
                        </Typography>
                        {showSuccess && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                            Template uploaded successfully.
                        </Alert>}
                        <Box display="flex" width="100%" alignItems="center" gap="15px" paddingTop="10px">
                            <label htmlFor="template" style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Report Template</label>
                            <input {...getInputProps()} name="template" />
                            <Button type="button" color="primary" variant='contained' onClick={open} startIcon={<CloudUploadIcon />}>
                                Browse
                            </Button>
                            <SplitButton
                                variant="outlined"
                                showLoader={isLoading}
                                options={downloadMenuItems} buttonText={(templateFiles && templateFiles?.length > 0) ? "Download" : "Download Sample"}
                                onButtonClick={() => handleDefaultDownload()} onMenuItemClick={onMenuItemClick} />
                        </Box>
                    </CardContent>
                </Card>
            </Box> */}
        </Box>

    </Box>
}