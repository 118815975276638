import { Autocomplete, Box, Button, IconButton, TextField } from '@mui/material';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useGetStandardItemCategoriesQuery } from 'State/Services/standard-item-category';
import { StandardItemCategory } from 'Models/standard-item-category';

export interface FilterSettings {
    categoryDescription: string | undefined;
    searchText: string | undefined;
}

export interface FilterSettingsView extends FilterSettings {
    category?: StandardItemCategory;
}

export interface FiltersProps {
    estimateId?: string;
    onFilterChanged: (settings: FilterSettings) => void;
}

export default function ResourceCategoryFilters(props: FiltersProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: standardItemCategories } = useGetStandardItemCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId, refetchOnMountOrArgChange: true });
    const filterForm = useFormik<FilterSettingsView>({
        initialValues: {
            categoryDescription: undefined,
            searchText: undefined,
            category: undefined,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            submit(values);
        },
    });

    const submit = useCallback((values: FilterSettingsView) => {
        props.onFilterChanged({
            categoryDescription: values.category?.description,
            searchText: values.searchText
        });
    }, [props])

    const submitOnEnter = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            filterForm.handleSubmit();
        }
    }

    const resetForm = () => {
        filterForm.resetForm();
        filterForm.handleSubmit();
    }
    const clearField = useCallback((field: string) => {
        filterForm.setFieldValue(field, '');
        filterForm.handleSubmit();
    }, [filterForm]);

    const onCategoryChanged = useCallback((event: React.SyntheticEvent, newValue: StandardItemCategory | null) => {
        if (newValue?.description) {
            filterForm.setFieldValue("category", newValue);
        } else {
            filterForm.setFieldValue("category", undefined);
        }
        filterForm.handleSubmit();
    }, [filterForm])

    return <>
        <form onSubmit={filterForm.handleSubmit} autoComplete="off" style={{ display: "flex", flex: 1 }} noValidate>
            <Box width="100%" display="flex" alignItems="center">
                <Box flex="1.5">
                    {standardItemCategories &&
                        <Autocomplete
                            size="small"
                            value={filterForm.values.category ?? null}
                            options={standardItemCategories}
                            onKeyDown={submitOnEnter}
                            onChange={onCategoryChanged}
                            getOptionLabel={(option) => option.description ?? ''}
                            sx={{ width: "auto", marginRight: "10px" }}
                            renderInput={(params) => <TextField label="Categories" {...params} />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.description ?? '', inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.description ?? '', matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    }
                </Box>
                <Box flex="2">
                    <TextField
                        fullWidth
                        size="small"
                        value={filterForm.values.searchText}
                        label="Search"
                        variant="outlined"
                        onKeyDown={submitOnEnter}
                        name="searchText"
                        sx={{ marginRight: "10px" }}
                        onChange={filterForm.handleChange}
                        onBlur={filterForm.handleBlur}
                        InputProps={{
                            endAdornment: <IconButton aria-label="delete" size="small" onClick={() => clearField('searchText')}>
                                <ClearIcon />
                            </IconButton>,
                        }} />
                </Box>
                <Box flex="1">
                    <Button
                        sx={{ color: "rgba(0, 0, 0, 0.54)", width: "80px" }}
                        variant="text"
                        onClick={resetForm}
                        type="reset">
                        Clear all
                    </Button>
                </Box>
            </Box>
        </form>
    </>;
}