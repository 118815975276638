import { Alert, Autocomplete, Box, TextField, useTheme } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useFormik } from "formik";
import { Errors } from "Models/errors";
import { UnitView } from "Models/unit";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useGetActivityMaxIdQuery } from "State/Services/activity";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetItemIdQuery, useQuickCreateActivityDetailMutation } from "State/Services/item";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import * as yup from 'yup';
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import { useGetIndirectItemIdQuery, useQuickCreateActivityIndirectDetailMutation } from "State/Services/indirect-item";
export interface QuickActivity {
    displayId?: string;
    description?: string;
    totalQuantity?: number;
    quantityPerUnit?: number;
    unit?: UnitView;
}
export interface QuickAddEditActivityProps {
    inHeader: boolean;
    type?: string;
    estimateId: string;
    itemId: string;
    standardItemId?: string;
    subItemId?: string;
    activityCategoryId?: string;
    close: () => void;
    parent: 'direct' | 'indirect';
}

const quickActivityValidationSchema = yup.object<QuickActivity>({
    description: yup
        .string()
        .required(),
    displayId: yup
        .string()
        .required(),
    totalQuantity: yup
        .number()
        .required(),
    quantityPerUnit: yup
        .number()
        .required()
});

export default function QuickAddEditActivity(props: QuickAddEditActivityProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: maxId } = useGetActivityMaxIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: item } = useGetItemIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', itemId: props.itemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !props.itemId || props.parent !== 'direct' });
    const { data: indirectItem } = useGetIndirectItemIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', indirectItemId: props.itemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !props.itemId || props.parent !== 'indirect' });
    const [quickCreateActivityDetail] = useQuickCreateActivityDetailMutation();
    const [quickCreateActivityIndirectDetail] = useQuickCreateActivityIndirectDetailMutation();
    const [unitViews, setUnitViews] = useState<Array<UnitView>>([]);
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [colors, setColors] = useState<any>();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [pageError, setPageError] = useState<string | undefined>();
    const [saving, setSaving] = useState(false);
    const [unitSuffix, setUnitSuffix] = useState<string>('Item Unit');

    useEffect(() => {
        const itemToUse = (props.parent === 'direct') ? item : indirectItem;
        if (units && itemToUse && itemToUse.unitId) {
            const unit = units?.find(u => u.id === itemToUse.unitId);
            if (unit) {
                setUnitSuffix(`Quantity / ${unit.description}`);
            }
        } else if (itemToUse?.customUnit) {
            setUnitSuffix(`Quantity / ${itemToUse.customUnit}`);
        }
    }, [indirectItem, item, props.parent, units])

    useEffect(() => {
        const unitViews = units?.map((unit) => ({
            unitDescription: unit.description,
            unitId: unit.id
        }));
        if (unitViews) {
            setUnitViews(unitViews);
        }
    }, [units])

    const getDisplayId = useCallback(() => {
        const id = (maxId) ? (maxId + 1)?.toString() : '1';
        return estimate?.isMaster ? `${settings?.masterIdPrefix}-${id}` : id;
    }, [estimate?.isMaster, maxId, settings?.masterIdPrefix])

    const { values, setFieldValue, handleSubmit, handleChange, handleBlur, touched, errors } = useFormik<QuickActivity>({
        validateOnMount: true,
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: {
            description: '',
            displayId: getDisplayId(),
            unit: undefined,
            totalQuantity: undefined,
            quantityPerUnit: undefined
        },
        validationSchema: quickActivityValidationSchema,
        onSubmit: async (activity) => {
            try {
                if (user) {
                    setSaving(true);
                    if (props.parent === 'direct') {
                        await quickCreateActivityDetail({
                            companyId: user.companyId,
                            orgId: user.organizationId,
                            estimateId: props.estimateId,
                            itemId: props.itemId,
                            body: {
                                description: activity.description ?? '',
                                type: (props.inHeader) ? 'root-activity' : 'activity',
                                unitId: activity.unit?.unitId ?? '',
                                displayId: activity.displayId,
                                standardItemId: props.standardItemId,
                                subItemId: props.subItemId,
                                categoryId: props.activityCategoryId,
                                quantity: activity.totalQuantity,
                                quantityPerUnit: activity.quantityPerUnit,
                            }
                        }).unwrap();
                    } else if (props.parent === 'indirect') {
                        await quickCreateActivityIndirectDetail({
                            companyId: user.companyId,
                            orgId: user.organizationId,
                            estimateId: props.estimateId,
                            indirectItemId: props.itemId,
                            body: {
                                description: activity.description ?? '',
                                type: (props.inHeader) ? 'root-activity' : 'activity',
                                unitId: activity.unit?.unitId ?? '',
                                displayId: activity.displayId,
                                standardItemId: props.standardItemId,
                                indirectSubItemId: props.subItemId,
                                categoryId: props.activityCategoryId,
                                quantity: activity.totalQuantity,
                                quantityPerUnit: activity.quantityPerUnit,
                            }
                        }).unwrap();
                    }

                    props.close();
                }
            } catch (error: any) {
                if (error.status === 500) {
                    setPageError(Errors.generic);
                    return;
                }
                if (error && ('data' in error)) {
                    if (error.data?.description) {
                        setPageError(error.data?.description);
                    } else if (error.data.displayId) {
                        setPageError(error.data?.displayId);
                    } else if (error.data.page) {
                        setPageError(error.data.page);
                    }
                }
            }
            finally {
                setSaving(false);
            }
        },
    });

    const onQuantityPerUnitChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const itemQuantityToUse = (props.parent === 'direct') ? item?.quantity : indirectItem?.estimatedQuantity;
        if (itemQuantityToUse) {
            setFieldValue('totalQuantity', event.target.value ? parseFloat(event.target.value) * (itemQuantityToUse ?? 0) : undefined);
        }
        handleChange(event);
    }, [handleChange, indirectItem?.estimatedQuantity, item?.quantity, props.parent, setFieldValue])

    const onQuantityChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const itemQuantityToUse = (props.parent === 'direct') ? item?.quantity : indirectItem?.estimatedQuantity;
        if (itemQuantityToUse) {
            setFieldValue('quantityPerUnit', event.target.value ? parseFloat(event.target.value) / (itemQuantityToUse ?? 1) : undefined);
        }
        handleChange(event);
    }, [handleChange, indirectItem?.estimatedQuantity, item?.quantity, props.parent, setFieldValue])

    const unitComparer = useCallback((option: UnitView, value: UnitView) => {
        return option.unitId === value.unitId;
    }, [])

    const getContainerHeight = useCallback(() => {
        return pageError ? 116 : 68;
    }, [pageError]);

    return <Box padding="5px 5px 0px 5px" sx={{ width: 800, height: getContainerHeight() }} >
        {
            colors && <Box>
                <form onSubmit={handleSubmit} noValidate>
                    {pageError && <Box marginBottom="15px"><Alert sx={{ padding: 0 }} severity="error">{pageError}</Alert></Box>}
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" flexDirection="column" marginBottom="5px" flex="0.4" marginRight="5px">
                            <TextField
                                label="ID"
                                size="small"
                                id="displayId"
                                name="displayId"
                                autoComplete="off"
                                placeholder="ID"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.displayId}
                                error={touched.displayId && Boolean(errors.displayId)} />
                        </Box>
                        <Box display="flex" flexDirection="column" marginBottom="5px" flex="1" marginRight="5px">
                            <TextField
                                size="small"
                                id="description"
                                name="description"
                                label="Description"
                                placeholder="Description"
                                autoComplete="off"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.description && Boolean(errors.description)}
                                value={values.description} />
                        </Box>
                        {unitViews && unitViews.length > 0 && <Box display="flex" flexDirection="column" marginBottom="5px" flex="0.6" marginRight="5px">
                            <Autocomplete
                                fullWidth
                                className="ag-input-field-input ag-text-field-input"
                                sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                                size="small"
                                getOptionLabel={(option) => option.unitDescription ?? ''}
                                onChange={(event, value) => {
                                    setFieldValue('unit', value);
                                }}
                                options={unitViews}
                                isOptionEqualToValue={unitComparer}
                                value={values.unit ?? null}
                                renderInput={(params) => <TextField label="Unit" name="unit" sx={{ height: "100%" }} placeholder='Select/Enter Unit' {...params} />}
                                renderOption={(props, option, { inputValue }) => {
                                    const matches = match(option.unitDescription ?? '', inputValue, {
                                        insideWords: true,
                                    });
                                    const parts = parse(option.unitDescription ?? '', matches);

                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                            /></Box>
                        }
                        <Box display="flex" flexDirection="column" flex="0.6" marginBottom="5px" marginRight="5px">
                            <TextField
                                InputProps={{
                                    type: 'number'
                                }}
                                size="small"
                                id="quantityPerUnit"
                                label={unitSuffix}
                                name="quantityPerUnit"
                                autoComplete="off"
                                placeholder={unitSuffix}
                                InputLabelProps={{
                                    shrink: !!values.quantityPerUnit, // Shrinks the label if a value exists
                                }}
                                error={touched.quantityPerUnit && Boolean(errors.quantityPerUnit)}
                                onBlur={handleBlur}
                                onChange={onQuantityPerUnitChanged}
                                value={values.quantityPerUnit} />
                        </Box>
                        <Box display="flex" flexDirection="column" flex="0.6" marginBottom="5px" marginRight="5px">
                            <TextField
                                InputProps={{
                                    type: 'number'
                                }}
                                size="small"
                                id="totalQuantity"
                                label="Total Quantity"
                                name="totalQuantity"
                                autoComplete="off"
                                InputLabelProps={{
                                    shrink: !!values.totalQuantity, // Shrinks the label if a value exists
                                }}
                                placeholder='Total Quantity'
                                error={touched.totalQuantity && Boolean(errors.totalQuantity)}
                                onBlur={handleBlur}
                                onChange={onQuantityChanged}
                                value={values.totalQuantity} />
                        </Box>
                        <Box display="flex" flexDirection="column" flex="0.1" marginBottom="5px">
                            <LoadingButton
                                type="submit"
                                sx={{
                                    width: "30px"
                                }}
                                loading={saving}
                                endIcon={<SaveIcon />}
                                aria-label="save"
                                color="primary"
                                loadingPosition="end"
                                variant="contained">
                                Save
                            </LoadingButton>
                        </Box>
                    </Box>
                </form>
            </Box>
        }
    </Box>
}