import { Alert, Autocomplete, Box, Button, TextField, useTheme } from "@mui/material";
import { CompanyCurrencyView } from "Models/company-currency";
import { Currency } from "Models/currency";
import { useCreateCompanyCurrencyMutation, useDeleteCompanyCurrencyMutation, useGetCompanyCurrenciesQuery, useUpdateCompanyCurrencyMutation } from "State/Services/company-currency";
import { useGetCurrenciesQuery } from "State/Services/currency"
import { useGetUserDetailsQuery } from "State/Services/user";
import { CellClickedEvent, CellKeyDownEvent, ColGroupDef, GetRowIdParams, ICellEditor, RowEditingStartedEvent, RowEditingStoppedEvent, ValueFormatterParams } from "ag-grid-community";
import { ColDef, IRowNode, SuppressKeyboardEventParams } from "ag-grid-enterprise";
import { AgGridReact, getInstance } from "ag-grid-react";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import { useConfirm } from "material-ui-confirm";
import { v4 as uuidv4 } from 'uuid';
import { flushSync } from "react-dom";
import { hasSystemRolePermission } from "Helpers/role-permissions";
import { rounder } from "Helpers/rounder";
import { Errors } from "Models/errors";
import { BaseEditCellRenderer } from "Models/grid";
import GenericNumberEditCellRenderer from "Components/Shared/GenericNumberEditCellRenderer";
import GenericActionsCellRenderer from "Components/Shared/GenericActionsEditCellRenderer";

export default function Currencies() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: user } = useGetUserDetailsQuery();
    const [currencyExtended, setCurrencyExtended] = useState<Array<Currency & { currencyOption: string }>>([]);
    const { data: currencies } = useGetCurrenciesQuery(null);
    const [createCompanyCurrency] = useCreateCompanyCurrencyMutation();
    const [updateCompanyCurrency] = useUpdateCompanyCurrencyMutation();
    const { data: companyCurrencies } = useGetCompanyCurrenciesQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [primary, setPrimary] = useState<Currency & { currencyOption: string }>();
    const primaryRef = useRef<any>();
    primaryRef.current = primary;
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<CompanyCurrencyView> | undefined, column?: string }>();
    const [secondary, setSecondary] = useState<Currency & { currencyOption: string }>();
    const [secondaries, setSecondaries] = useState<Array<CompanyCurrencyView>>([]);
    const gridRef = useRef<AgGridReact<CompanyCurrencyView>>(null);
    const isCancelClickedRef = useRef<boolean>(false);
    const [disableAdd, setDisableAdd] = useState(false);
    const [deleteCompanyCurrency] = useDeleteCompanyCurrencyMutation();
    const confirm = useConfirm();
    const [hasAddEditAccess, setHasAddEditAccess] = useState(false);
    const hasAddEditAccessRef = useRef<boolean>();
    const [pageError, setPageError] = useState<string | undefined>();

    useEffect(() => {
        if (user) {
            const hasAccess = hasSystemRolePermission(user, [301]);
            setHasAddEditAccess(hasAccess);
            hasAddEditAccessRef.current = hasAccess;
        }
    }, [user])

    const defaultCurrencyColDef = useMemo<ColDef>(() => {
        return {
            editable: true
        };
    }, []);

    useEffect(() => {
        if (currencies && companyCurrencies) {
            const unfilteredCurrencies = currencies.map((currency) => ({ ...currency, currencyOption: `${currency.alphabeticalCode} - ${currency.currency}` }));
            const secondaryCurrencies = new Array<CompanyCurrencyView>();
            let primary: (Currency & { currencyOption: string }) | undefined;
            companyCurrencies?.forEach((companyCurrency) => {
                if (companyCurrency.isPrimary) {
                    primary = unfilteredCurrencies.find((curr) => (curr.id === companyCurrency.currencyId));
                } else {
                    secondaryCurrencies.push({
                        currencyId: companyCurrency.currencyId,
                        isPrimary: companyCurrency.isPrimary,
                        alphabeticalCode: companyCurrency.alphabeticalCode,
                        currency: companyCurrency.currency,
                        minorUnit: companyCurrency.minorUnit,
                        primaryToSecondaryRate: companyCurrency.primaryToSecondaryRate,
                        secondaryToPrimaryRate: companyCurrency.secondaryToPrimaryRate,
                        id: companyCurrency.id,
                        actions: '',
                        isNew: false,
                        canDelete: companyCurrency.canDelete
                    });
                }
            });
            const filtered = unfilteredCurrencies.filter((curr) => (curr.id !== primary?.id));
            setPrimary(primary);
            setCurrencyExtended(filtered);
            setSecondaries(secondaryCurrencies);
        }
    }, [companyCurrencies, currencies])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            setDisableAdd(true);
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node?.rowIndex,
                colKey: 'primaryToSecondaryRate',
            });
        } else {
            setDisableAdd(false);
        }
    }, [currentEditing])

    const addCompanyCurrency = useCallback(async () => {
        if (!secondary) return;
        if (currentEditing) {
            try {
                await confirm({ description: "You have unsaved changes. If you proceed, your current edits will be lost. Do you want to continue?" });
                gridRef.current!.api.stopEditing(true);
                if (currentEditing && currentEditing.node?.data) {
                    if (currentEditing.node.data?.id) {
                        const companyCurrencyBeforeEditing = secondaries?.find((sec) => (sec.id === currentEditing?.node?.data?.id));
                        if (companyCurrencyBeforeEditing) {
                            gridRef.current!.api.applyTransaction({ update: [{ ...companyCurrencyBeforeEditing }] });
                        } else {
                            gridRef.current!.api.applyTransaction({ remove: [currentEditing.node?.data] });
                        }
                    } else {
                        gridRef.current!.api.applyTransaction({ remove: [currentEditing.node?.data] });
                    }
                }
            } catch (error) {
                return;
            }
        }
        let addedCompanyCurrencyRow = gridRef.current!.api.applyTransaction({
            add: [{
                id: uuidv4(),
                isNew: true,
                isPrimary: false,
                currencyId: secondary.id,
                alphabeticalCode: secondary.alphabeticalCode,
                currency: secondary.currency,
                minorUnit: secondary.minorUnit,
                actions: '',
            }],
            addIndex: 0
        });
        if (addedCompanyCurrencyRow && addedCompanyCurrencyRow.add && addedCompanyCurrencyRow.add.length > 0 && addedCompanyCurrencyRow.add[0] && typeof addedCompanyCurrencyRow.add[0].rowIndex === 'number' && addedCompanyCurrencyRow.add[0].id) {
            if (addedCompanyCurrencyRow.add[0].id) {
                queueMicrotask(() => flushSync(() => {
                    if (addedCompanyCurrencyRow && addedCompanyCurrencyRow.add[0].id) {
                        const node = gridRef.current!.api.getRowNode(addedCompanyCurrencyRow.add[0].id);
                        if (node) {
                            gridRef.current!.api.ensureNodeVisible(node, "middle");
                            setCurrentEditing({ node: node, column: 'description' });
                        }
                    }
                }))
            }
        }
    }, [confirm, currentEditing, secondaries, secondary])

    const saveUpdateCompanyCurrency = useCallback(async (nodeToSave: IRowNode<CompanyCurrencyView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<CompanyCurrencyView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    if (nodeToSave.data.id) {
                        if (nodeToSave.data.isNew) {
                            await createCompanyCurrency({
                                companyId: user?.companyId,
                                orgId: user.organizationId,
                                body: {
                                    currencyId: nodeToSave.data.currencyId,
                                    isPrimary: false,
                                    primaryToSecondaryRate: nodeToSave.data.primaryToSecondaryRate,
                                    secondaryToPrimaryRate: nodeToSave.data.secondaryToPrimaryRate
                                }
                            }).unwrap();
                        } else {
                            await updateCompanyCurrency({
                                companyId: user?.companyId,
                                orgId: user.organizationId,
                                companyCurrencyId: nodeToSave.data.id,
                                body: {
                                    currencyId: nodeToSave.data.currencyId,
                                    isPrimary: false,
                                    primaryToSecondaryRate: nodeToSave.data.primaryToSecondaryRate,
                                    secondaryToPrimaryRate: nodeToSave.data.secondaryToPrimaryRate
                                }
                            }).unwrap();
                        }
                    }
                    if (toEditAfterSave) {
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    } else {
                        setCurrentEditing(undefined);
                    }
                    resolve();
                }
            } catch (error: any) {
                reject(error);
                setPageError(Errors.generic);
            }
        });
    }, [createCompanyCurrency, updateCompanyCurrency, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const saveUpdateCurrencyRef = useRef<any>();
    saveUpdateCurrencyRef.current = saveUpdateCompanyCurrency;

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<CompanyCurrencyView>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();

            const save = async () => {
                await saveUpdateCompanyCurrency(params.node);
            }
            save();
        }
        return true;
    }, [saveUpdateCompanyCurrency])

    const deleteCurrencyRow = useCallback((node: IRowNode<CompanyCurrencyView>) => {
        return new Promise<void>(async (resolve) => {
            try {
                if (node.data) {
                    await deleteCompanyCurrency({
                        companyId: user?.companyId,
                        companyCurrencyId: node.data.id,
                        orgId: user?.organizationId
                    });
                }
                resolve();
            } catch (error) {
                setPageError(Errors.generic);
            }
        });
    }, [deleteCompanyCurrency, user?.companyId, user?.organizationId])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const deleteCurrencyEditingRef = useRef<any>();
    deleteCurrencyEditingRef.current = deleteCurrencyRow;

    const cancelCurrencyEditing = useCallback((node: IRowNode<CompanyCurrencyView>) => {
        if (node && node.data) {
            if (!node.data.isNew) {
                isCancelClickedRef.current = true;
                gridRef.current!.api.stopEditing(true);
            } else {
                gridRef.current!.api.applyTransaction({ remove: [node.data] });
            }
            setCurrentEditing(undefined);
        }
    }, [])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const cancelCurrencyEditingRef = useRef<any>();
    cancelCurrencyEditingRef.current = cancelCurrencyEditing;

    const onPrimaryToSecondaryChange = useCallback((node: IRowNode<CompanyCurrencyView>, value: number | null) => {
        const instances = gridRef.current!.api.getCellEditorInstances({
            columns: ['secondaryToPrimaryRate']
        });
        if (instances.length > 0) {
            getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
                if (instance && instance.changeValue) {
                    if (value && !isNaN(value)) {
                        instance.changeValue(1 / value);
                    } else {
                        instance.changeValue('');
                    }
                }
            });
        }
    }, [])
    const onPrimaryToSecondaryChangeRef = useRef<any>();
    onPrimaryToSecondaryChangeRef.current = onPrimaryToSecondaryChange;

    const onSecondaryToPrimaryChange = useCallback((node: IRowNode<CompanyCurrencyView>, value: number | null) => {
        const instances = gridRef.current!.api.getCellEditorInstances({
            columns: ['primaryToSecondaryRate']
        });
        if (instances.length > 0) {
            getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
                if (instance && instance.changeValue) {
                    if (value && !isNaN(value)) {
                        instance.changeValue(1 / value);
                    } else {
                        instance.changeValue('');
                    }
                }
            });
        }
    }, [])
    const onSecondaryToPrimaryChangeRef = useRef<any>();
    onSecondaryToPrimaryChangeRef.current = onSecondaryToPrimaryChange;

    const getColDefs = useCallback((): (ColDef | ColGroupDef)[] => {
        return [
            {
                field: "id",
                hide: true,
                suppressKeyboardEvent: saveOnEnter,
            },
            {
                field: "currency",
                suppressKeyboardEvent: saveOnEnter,
                headerName: "Currency",
                editable: false,
                width: 150,
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: "alphabeticalCode",
                suppressKeyboardEvent: saveOnEnter,
                headerName: "Code",
                editable: false,
                width: 120,
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: "minorUnit",
                suppressKeyboardEvent: saveOnEnter,
                headerName: "Minor Unit",
                editable: false,
                width: 120,
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                headerName: "Exchange Rate Primary --> Secondary",
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                children: [
                    {
                        field: 'alphabeticalCode',
                        headerName: "Primary",
                        flex: 1,
                        suppressKeyboardEvent: saveOnEnter,
                        editable: false,
                        cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                        valueFormatter: (params: ValueFormatterParams<CompanyCurrencyView>) => (`1 ${primaryRef.current.alphabeticalCode} =`)
                    },
                    {
                        field: 'primaryToSecondaryRate',
                        headerName: "Rate",
                        flex: 1,
                        valueFormatter: p => rounder(p.value, 6),
                        editable: () => !!hasAddEditAccessRef.current,
                        suppressKeyboardEvent: saveOnEnter,
                        cellDataType: 'number',
                        cellEditor: GenericNumberEditCellRenderer,
                        cellEditorParams: {
                            field: 'Rate',
                            onChangeCallBack: (node: IRowNode<CompanyCurrencyView>, value: number | null) => onPrimaryToSecondaryChangeRef.current(node, value)
                        },
                        cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                    {
                        field: 'alphabeticalCode',
                        headerName: "Secondary",
                        flex: 1,
                        suppressKeyboardEvent: saveOnEnter,
                        editable: false,
                        cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                ]
            },
            {
                headerName: "Exchange Rate Secondary --> Primary",
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                children: [
                    {
                        field: 'alphabeticalCode',
                        headerName: "Secondary",
                        flex: 1,
                        suppressKeyboardEvent: saveOnEnter,
                        editable: false,
                        valueFormatter: (params: ValueFormatterParams<CompanyCurrencyView>) => (`1 ${params.value} =`),
                        cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                    {
                        field: 'secondaryToPrimaryRate',
                        headerName: "Rate",
                        flex: 1,
                        valueFormatter: p => rounder(p.value, 6),
                        editable: () => !!hasAddEditAccessRef.current,
                        suppressKeyboardEvent: saveOnEnter,
                        cellEditor: GenericNumberEditCellRenderer,
                        cellDataType: 'number',
                        cellEditorParams: {
                            field: 'Rate',
                            onChangeCallBack: (node: IRowNode<CompanyCurrencyView>, value: number | null) => onSecondaryToPrimaryChangeRef.current(node, value)
                        },
                        cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                    {
                        field: 'alphabeticalCode',
                        headerName: "Primary",
                        suppressKeyboardEvent: saveOnEnter,
                        editable: false,
                        flex: 1,
                        valueFormatter: (params: ValueFormatterParams<CompanyCurrencyView>) => (primaryRef.current.alphabeticalCode),
                        cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                ]
            },
            {
                field: 'actions',
                suppressKeyboardEvent: (params) => saveOnEnter(params),
                resizable: true,
                editable: false,
                headerName: '',
                maxWidth: 57,
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRendererSelector: () => {
                    return {
                        component: GenericActionsCellRenderer,
                        params: {
                            deleteRecord: (node: IRowNode<CompanyCurrencyView>) => deleteCurrencyEditingRef.current(node),
                            save: (node: IRowNode<CompanyCurrencyView>) => saveUpdateCurrencyRef.current(node),
                            cancel: (node: IRowNode<CompanyCurrencyView>) => cancelCurrencyEditingRef.current(node),
                            disabled: () => !hasAddEditAccessRef.current,
                            deleteDisabledMessage: 'Deleting a currency associated with resources is not permitted.'
                        },
                    };
                },
            },
        ]
    }, [colors?.gray, saveOnEnter])

    const [companyCurrencyColumnDefs] = useState<(ColDef | ColGroupDef)[]>(getColDefs());

    const savePrimaryCurrency = useCallback(async (event: any, currency: Currency & { currencyOption: string } | null) => {
        try {
            if (user && currency) {
                if (primary) {
                    const primaryCompanyCurrency = companyCurrencies?.find((companyCurrency) => (companyCurrency.currencyId === primary.id));
                    if (primaryCompanyCurrency) {
                        await updateCompanyCurrency({
                            companyCurrencyId: primaryCompanyCurrency.id,
                            companyId: user?.companyId,
                            orgId: user?.organizationId,
                            body: {
                                id: primaryCompanyCurrency.id,
                                currencyId: currency.id,
                                isPrimary: true
                            }
                        });
                    }

                } else {
                    setPrimary(currency);
                    await createCompanyCurrency({
                        companyId: user?.companyId,
                        orgId: user?.organizationId,
                        body: {
                            currencyId: currency.id,
                            isPrimary: true
                        }
                    });
                }
            }
        } catch (error) {
            setPageError(Errors.generic);
        }

    }, [companyCurrencies, createCompanyCurrency, primary, updateCompanyCurrency, user])

    const onCellClicked = useCallback(async (event: CellClickedEvent) => {
        if (isCancelClickedRef.current) {
            isCancelClickedRef.current = false;
            return;
        };

        if (!hasAddEditAccess) return;

        if (currentEditing?.node === event.node) {
            return;
        }
        if (event.column.getColId() === 'actions') {
            return;
        }

        if (!currentEditing?.node) {
            setCurrentEditing({ node: event.node, column: event.column.getColId() });
        } else {
            await saveUpdateCompanyCurrency(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
        }
    }, [currentEditing?.node, hasAddEditAccess, saveUpdateCompanyCurrency])

    const getUnitRowId = useCallback(function (params: GetRowIdParams<CompanyCurrencyView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const onUnitRowEditingStarted = useCallback((event: RowEditingStartedEvent<CompanyCurrencyView>) => {
        isCancelClickedRef.current = false;
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onUnitRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent<CompanyCurrencyView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelCurrencyEditing(e.node);
            isCancelClickedRef.current = false;
        }
    }, [cancelCurrencyEditing]);

    return <Box display="flex" padding="20px" flexDirection="column" height="100%">
        <Box display="flex" alignItems="center" gap="15px" marginBottom="40px">
            <Box display="flex" alignItems="center" gap="15px" width="500px">
                <Box>
                    Primary Currency:
                </Box>
                <Box flex="1">
                    {currencyExtended && currencyExtended.length > 0 && <Autocomplete
                        size="small"
                        fullWidth
                        disabled={disableAdd || !hasAddEditAccess}
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        onChange={savePrimaryCurrency}
                        options={currencyExtended}
                        getOptionLabel={(option) => option.currencyOption ?? ''}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        value={primary ?? null}
                        renderInput={(params) => <TextField name="currency" sx={{ height: "100%" }} placeholder='Select Currency' {...params} />}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.currencyOption ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.currencyOption ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                    }
                </Box>
            </Box>
        </Box>
        {primary &&
            <Box display="flex" flexDirection="column" height="100%">
                <Box display="flex" alignItems="center" gap="15px" marginBottom="20px">
                    <Box display="flex" alignItems="center" gap="15px" width="500px">
                        <Box>
                            Secondary Currencies:
                        </Box>
                        <Box flex="1">
                            {currencyExtended && currencyExtended.length > 0 && <Autocomplete
                                size="small"
                                fullWidth
                                disabled={disableAdd || !hasAddEditAccess}
                                className="ag-input-field-input ag-text-field-input"
                                sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                                onChange={(event, value) => {
                                    if (value) {
                                        setSecondary(value);
                                    }
                                }}
                                options={currencyExtended}
                                getOptionLabel={(option) => option.currencyOption ?? ''}
                                isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                value={secondary ?? null}
                                renderInput={(params) => <TextField name="Seccurrency" sx={{ height: "100%" }} placeholder='Select Currency' {...params} />}
                                renderOption={(props, option, { inputValue }) => {
                                    const matches = match(option.currencyOption ?? '', inputValue, {
                                        insideWords: true,
                                    });
                                    const parts = parse(option.currencyOption ?? '', matches);

                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                            />
                            }
                        </Box>
                    </Box>
                    <Box>
                        <Button onClick={addCompanyCurrency} variant="contained" disabled={disableAdd || !secondary || !hasAddEditAccess}>Add Currency</Button>
                    </Box>
                </Box>
                {pageError && <Box marginBottom="10px"><Alert severity="error">{pageError}</Alert></Box>}
                <Box width="100%" height={pageError ? "calc(100% - 203px)" : "calc(100% - 145px)"} className="ag-theme-alpine ag-theme-bidbow">
                    <AgGridReact<CompanyCurrencyView>
                        ref={gridRef}
                        editType={'fullRow'}
                        rowData={secondaries}
                        defaultColDef={defaultCurrencyColDef}
                        columnDefs={companyCurrencyColumnDefs}
                        onRowEditingStarted={onUnitRowEditingStarted}
                        onRowEditingStopped={onUnitRowEditingStopped}
                        onCellClicked={onCellClicked}
                        getRowId={getUnitRowId}
                        onCellKeyDown={onCellKeyDown}
                    />
                </Box>
            </Box>}
    </Box>
}