import { api } from './api'
import { BaseVendor, BulkVendorDto, ErroredVendor, Vendor } from 'Models/vendor';


export const vendorApi = api.injectEndpoints({
    endpoints: (build) => ({
        getVendors: build.query<Array<Vendor>, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/vendor` }),
            providesTags: ['Vendors']
        }),
        getMaxVendorId: build.query<{ maxId: number }, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/vendor/max-id` }),
            providesTags: ['MaxVendorId']
        }),
        updateVendor: build.mutation<BaseVendor, Partial<{ body: BaseVendor, orgId: string, companyId: string, estimateId: string, vendorId: string }>>({
            query({ body, orgId, companyId, vendorId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/vendor/${vendorId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: (result) => (result ? ['Vendors', 'MaxVendorId', 'Tags'] : [])
        }),
        createVendor: build.mutation<BaseVendor, Partial<{ body: BaseVendor, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/vendor`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: (result) => (result ? ['Vendors', 'MaxVendorId', 'Tags'] : [])
        }),
        syncVendor: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, vendorId: string, templateVendorId: string }>>({
            query({ orgId, companyId, estimateId, vendorId, templateVendorId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/vendor/${vendorId}/from-template-vendor/${templateVendorId}`,
                    method: 'PUT',
                    body: {},
                }
            },
            invalidatesTags: ['Vendors', 'MaxVendorId', 'Tags']
        }),
        pushVendorToTemplate: build.mutation<BaseVendor, Partial<{ orgId: string, companyId: string, estimateId: string, vendorId: string, masterEstimateId: string }>>({
            query({ orgId, companyId, estimateId, vendorId, masterEstimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/vendor/${vendorId}/to-template-estimate/${masterEstimateId}`,
                    method: 'PUT',
                    body: {},
                }
            },
            invalidatesTags: ['Vendors', 'MaxVendorId', 'Tags']
        }),
        bulkUpdateVendor: build.mutation<null, Partial<{ body: BulkVendorDto, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/vendor/bulk`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Vendors', 'MaxVendorId'],
        }),
        deleteVendor: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, vendorId: string }>>({
            query({ orgId, companyId, estimateId, vendorId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/vendor/${vendorId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result) => (result ? ['Vendors', 'MaxVendorId'] : []),
        }),
        importVendors: build.mutation<Array<ErroredVendor>, { organizationId: string, companyId: string, estimateId: string, fileData: FormData }>({
            query({ organizationId, companyId, estimateId, fileData }: { organizationId: string, companyId: string, estimateId: string, fileData: FormData }) {
                return {
                    url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/vendor/import`,
                    method: 'POST',
                    body: fileData,
                };
            }
        }),
        exportImportVendorSample: build.mutation<any, Partial<{ organizationId: string, companyId: string, estimateId: string }>>({
            query({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) {
              return {
                url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/vendor/export/import-sample`,
                method: "POST",
                responseHandler: async (response: any) => window.open(window.URL.createObjectURL(await response.blob()), '_blank'),
                cache: "no-cache",
              };
            },
          }),
    })
});

export const {
    useExportImportVendorSampleMutation,
    useImportVendorsMutation,
    usePushVendorToTemplateMutation,
    useSyncVendorMutation,
    useCreateVendorMutation,
    useDeleteVendorMutation,
    useBulkUpdateVendorMutation,
    useLazyGetMaxVendorIdQuery,
    useGetVendorsQuery,
    useUpdateVendorMutation
} = vendorApi