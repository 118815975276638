import { Box } from '@mui/material';
import { CustomHeaderProps } from 'ag-grid-react';
import { useEffect, useState } from 'react';
import { SubcontractorPackageVendor } from 'Models/subcontractor';
import { useGetSubcontractorPackageVendorsQuery } from 'State/Services/subcontractor';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { md5 } from 'js-md5';
export interface SubcontractorQuotesVendorHeaderRendererProps extends CustomHeaderProps {
    vendorName: string;
    vendorHash: string;
    estimateId: string;
    packageVendors: () => SubcontractorPackageVendor[];
    packageId: string;
}
export default function SubcontractorQuotesVendorHeaderRenderer(props: SubcontractorQuotesVendorHeaderRendererProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: packageVendors } = useGetSubcontractorPackageVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', packageId: props.packageId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.packageId })
    const [packageVendor, setPackageVendor] = useState<SubcontractorPackageVendor>();

    useEffect(() => {
        const packageVendors = props.packageVendors();
        if (packageVendors) {
            const packageVendor = packageVendors.find((ven) => (md5(ven.vendorId) === props.vendorHash));
            if (packageVendor) {
                setPackageVendor(packageVendor);
            }
        }
    }, [props, props.packageVendors, packageVendors])

    return <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
        <Box alignContent="center" display="flex">
            {props.vendorName}
        </Box>
        <Box width="200px">Factor: {packageVendor?.factor ?? 'Not set'}</Box>
    </Box>
}