import * as yup from 'yup';
import { useFormik } from "formik";
import { useGetCategoriesQuery } from 'State/Services/category';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { Box, Autocomplete, TextField, Button, IconButton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import ClearIcon from '@mui/icons-material/Clear';
import { IRowNode } from 'ag-grid-community';
import { ItemView } from 'Models/item';
export interface FilterSettings {
    category: string | undefined;
    description: string | undefined;
    idFrom: number | undefined;
    idTo: number | undefined;
}

export interface FilterProps {
    estimateId: string;
    onFilterChanged: (settings: FilterSettings) => void;
    editing?: IRowNode<ItemView> | undefined;
    relativeWidthPercent?: string | undefined;
}

const filterValidationSchema = yup.object<FilterSettings>({
    category: yup
        .string(),
    idFrom: yup
        .number().typeError("Must be numeric").positive('Must be positive').integer().nullable(),
    description: yup
        .string(),
    idTo: yup
        .number().typeError("Must be numeric").positive('Must be positive').integer().nullable()
        .when('idFrom', (idFroms, schema) => {
            return schema.test({
                test: (idTo) => {
                    if (!idTo || !idFroms[0]) {
                        return true;
                    }
                    return !!idFroms[0] && !!idTo && idTo > idFroms[0];
                },
                message: "Must be > From ID"
            })
        }),
});

export function Filters(props: FilterProps) {
    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
    const { data: user } = useGetUserDetailsQuery();
    const { data: categories } = useGetCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId, refetchOnMountOrArgChange: true  });
    const {handleSubmit, values, resetForm, setFieldValue, touched, errors, handleChange, handleBlur } = useFormik<FilterSettings>({
        initialValues: {
            category: undefined,
            idFrom: undefined,
            idTo: undefined,
            description: undefined
        },
        enableReinitialize: true,
        validationSchema: filterValidationSchema,
        onSubmit: (values) => {
            props.onFilterChanged(values);
        },
    });

    const submitOnEnter = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }, [handleSubmit])

    const resetFilterForm = useCallback(() => {
        resetForm({
            values: {
                category: undefined,
                idFrom: undefined,
                idTo: undefined,
                description: undefined
            }
        });
        setSelectedCategory(undefined);
        props.onFilterChanged({
            category: undefined,
            idFrom: undefined,
            idTo: undefined,
            description: undefined
        });
        handleSubmit();
    }, [handleSubmit, props, resetForm])

    const handleOnCategoryChanged = useCallback((event: React.SyntheticEvent, newValue: string | null) => {
        if (newValue && categories && categories.length > 0) {
            const cat = categories.find((cat) => (cat.category === newValue));
            if (cat) {
                setFieldValue('category', cat.category);
                setSelectedCategory(cat.category);
            } else {
                setFieldValue('category', '');
                setSelectedCategory(undefined);
            }
        } else {
            setFieldValue('category', '');
            setSelectedCategory(undefined);
        }
        handleSubmit();
    }, [categories, handleSubmit, setFieldValue])

    const clearField = useCallback((field: string) => {
        setFieldValue(field, '');
        handleSubmit();
    }, [handleSubmit, setFieldValue]);

    return <>
        <form noValidate onSubmit={handleSubmit} style={{ display: "flex" }} autoComplete="off">
            <Box margin="10px" marginLeft="5px" marginRight="0px" display="flex" width={props.relativeWidthPercent ?? "50%"}>
                <Box display="flex" marginLeft="10px">
                    <TextField
                        size="small"
                        value={values.idFrom ?? ''}
                        label="From ID"
                        variant="outlined"
                        onKeyDown={submitOnEnter}
                        name="idFrom"
                        sx={{ marginRight: "10px", width: "120px" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!!props?.editing}
                        InputProps={{
                            type: 'number',
                            endAdornment: <IconButton aria-label="delete" disabled={!!props?.editing} size="small" onClick={() => clearField('idFrom')}>
                                <ClearIcon />
                            </IconButton>,
                        }}
                        error={touched.idFrom && Boolean(errors.idFrom)}
                        helperText={touched.idFrom && errors.idFrom} />
                    <TextField
                        size="small"
                        label="To ID"
                        value={values.idTo ?? ''}
                        variant="outlined"
                        disabled={!!props?.editing}
                        name="idTo"
                        InputProps={{
                            type: 'number',
                            endAdornment: <IconButton disabled={!!props?.editing} aria-label="delete" size="small" onClick={() => clearField('idTo')}>
                                <ClearIcon />
                            </IconButton>,
                        }}
                        sx={{ marginRight: "10px", width: "120px" }}
                        onKeyDown={submitOnEnter}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.idTo && Boolean(errors.idTo)}
                        helperText={touched.idTo && errors.idTo} />
                </Box>
                <Box width="100%" display="flex">
                    {categories && categories.length > 0 &&
                        <Autocomplete
                            fullWidth
                            disabled={!!props?.editing}
                            sx={{ marginRight: "10px" }}
                            value={selectedCategory || null}
                            size="small"
                            onChange={handleOnCategoryChanged}
                            onKeyDown={submitOnEnter}
                            options={categories.map((option) => option.category)}
                            renderInput={(params) => <TextField {...params} name="category" InputProps={{
                                ...params.InputProps
                            }} label="Category" />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    }
                    <TextField
                        fullWidth
                        size="small"
                        value={values.description}
                        label="Search"
                        variant="outlined"
                        onKeyDown={submitOnEnter}
                        name="description"
                        disabled={!!props?.editing}
                        sx={{ marginRight: "10px" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                            endAdornment: <IconButton aria-label="delete" disabled={!!props?.editing} size="small" onClick={() => clearField('description')}>
                                <ClearIcon />
                            </IconButton>,
                        }} />
                </Box>
                <Box display="flex">
                    <Button
                        sx={{ color: "rgba(0, 0, 0, 0.54)", width: "80px" }}
                        disabled={!!props?.editing}
                        variant="text"
                        onClick={resetFilterForm}
                        type="reset">
                        Clear All
                    </Button>
                </Box>
            </Box>

        </form>

    </>;
}