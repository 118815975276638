import { AppendStandardItemDto, BaseStandardItem, FilteredStandardItem, StandardItemActivityResource, StandardItemRateCalculated } from 'Models/standard-item';
import { api } from './api'
import { FilterField } from 'Models/resource';
import { IServerSideGetRowsRequest } from 'ag-grid-enterprise';

export const standardItemApi = api.injectEndpoints({
    endpoints: (build) => ({
        createStandardItem: build.mutation<BaseStandardItem, Partial<{ body: BaseStandardItem, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['StandardItems', 'StandardItemCategories'],
        }),
        getStandardItemRows: build.mutation<{ results: Array<StandardItemRateCalculated>, totalCount: number }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item/paged`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['StandardItems'],
        }),
        syncStandardItem: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, standardItemId: string, templateStandardItemId: string }>>({
            query({ orgId, companyId, estimateId, standardItemId, templateStandardItemId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item/${standardItemId}/from-template-standard-item/${templateStandardItemId}`,
                    method: 'PUT',
                    body: {},
                }
            },
            invalidatesTags: ['Pricing', 'StandardItems', 'IndirectItemDetails', 'ItemDetails', 'StandardItemCategories'],
        }),
        pushStandardItemToTemplate: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, standardItemId: string, masterEstimateId: string }>>({
            query({ orgId, companyId, estimateId, standardItemId, masterEstimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item/${standardItemId}/to-template-estimate/${masterEstimateId}`,
                    method: 'PUT',
                    body: {},
                }
            },
            invalidatesTags: ['Pricing', 'StandardItems', 'IndirectItemDetails', 'ItemDetails', 'StandardItemCategories'],
        }),
        appendStandardItemActivityResource: build.mutation<null, Partial<{ body: AppendStandardItemDto, orgId: string, companyId: string, estimateId: string, standardItemId: string }>>({
            query({ body, orgId, companyId, estimateId, standardItemId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item/${standardItemId}/append`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Pricing', 'StandardItems', 'StandardItemActivityResources', 'IndirectItemDetails', 'ItemDetails', 'Resources', 'Activities']
        }),
        getStandardItemByFilter: build.query<Array<FilteredStandardItem>, { organizationId: string, companyId: string, estimateId: string, filterText: string, field: FilterField, categoryId?: string }>({
            query: ({ organizationId, companyId, filterText, estimateId, field, categoryId }: { organizationId: string, companyId: string, estimateId: string, filterText: string, field: FilterField, categoryId?: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/standard-item/field/${field}/filter/${btoa(filterText)}?categoryId=${categoryId ?? ''}` }),
            transformResponse: (response: Array<FilteredStandardItem>) => { return [...response] },
            providesTags: ['StandardItems']
        }),
        getStandardItemPaged: build.query<Array<FilteredStandardItem>, { organizationId: string, companyId: string, estimateId: string, skip: number, take: number, categoryId?: string }>({
            query: ({ organizationId, companyId, skip, estimateId, take, categoryId }: { organizationId: string, companyId: string, estimateId: string, skip: number, take: number, categoryId?: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/standard-item/paged?skip=${skip}&take=${take}&categoryId=${categoryId ?? ''}` }),
            transformResponse: (response: Array<FilteredStandardItem>) => { return [...response] },
            providesTags: ['StandardItems']
        }),
        updateStandardItem: build.mutation<BaseStandardItem, Partial<{ body: BaseStandardItem, orgId: string, companyId: string, estimateId: string, standardItemId: string }>>({
            query({ body, orgId, companyId, estimateId, standardItemId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item/${standardItemId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['Pricing', 'StandardItems', 'IndirectItemDetails', 'ItemDetails', 'StandardItemCategories'],
        }),
        createStandardItemActivityResource: build.mutation<StandardItemActivityResource, Partial<{ body: StandardItemActivityResource, orgId: string, companyId: string, estimateId: string, standardItemId: string }>>({
            query({ body, orgId, companyId, estimateId, standardItemId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item/${standardItemId}/resource-activity`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Pricing', 'StandardItems', 'StandardItemActivityResources', 'IndirectItemDetails', 'ItemDetails', 'Resources', 'Activities'],
        }),
        updateStandardItemActivityResource: build.mutation<StandardItemActivityResource, Partial<{ body: StandardItemActivityResource, orgId: string, companyId: string, estimateId: string, standardItemId: string }>>({
            query({ body, orgId, companyId, estimateId, standardItemId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item/${standardItemId}/resource-activity`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['Pricing', 'StandardItems', 'StandardItemActivityResources', 'IndirectItemDetails', 'ItemDetails'],
        }),
        deleteStandardItemActivity: build.mutation<StandardItemActivityResource, Partial<{ orgId: string, companyId: string, estimateId: string, standardItemId: string, standardItemActivityId: string }>>({
            query({ orgId, companyId, estimateId, standardItemId, standardItemActivityId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item/${standardItemId}/activity/${standardItemActivityId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Pricing', 'StandardItems', 'StandardItemActivityResources', 'IndirectItemDetails', 'ItemDetails', 'Activities'],
        }),
        deleteStandardItemResource: build.mutation<StandardItemActivityResource, Partial<{ orgId: string, companyId: string, estimateId: string, standardItemId: string, standardItemResourceId: string }>>({
            query({ orgId, companyId, estimateId, standardItemId, standardItemResourceId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item/${standardItemId}/resource/${standardItemResourceId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Pricing', 'StandardItems', 'StandardItemActivityResources', 'IndirectItemDetails', 'ItemDetails', 'Resources'],
        }),
        deleteStandardItem: build.mutation<StandardItemActivityResource, Partial<{ orgId: string, companyId: string, estimateId: string, standardItemId: string }>>({
            query({ orgId, companyId, estimateId, standardItemId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item/${standardItemId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Pricing', 'StandardItems', 'Resources', 'Activities'],
        }),
        getStandardItemActivitiesResources: build.query<Array<StandardItemActivityResource>, { organizationId: string, companyId: string, estimateId: string, standardItemId: string }>({
            query: ({ organizationId, companyId, estimateId, standardItemId }: { organizationId: string, companyId: string, estimateId: string, standardItemId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/standard-item/${standardItemId}/resources-activities` }),
            providesTags: ['StandardItemActivityResources']
        }),
        getStandardItemMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/standard-item/max-id` }),
            providesTags: ['StandardItems']
        }),
        getStandardItemById: build.query<StandardItemRateCalculated, { organizationId: string, companyId: string, estimateId: string, standardItemId: string }>({
            query: ({ organizationId, companyId, estimateId, standardItemId }: { organizationId: string, companyId: string, estimateId: string, standardItemId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/standard-item/byId/${standardItemId}` }),
            providesTags: ['StandardItem']
        }),
    })
});

export const {
    usePushStandardItemToTemplateMutation,
    useSyncStandardItemMutation,
    useLazyGetStandardItemByIdQuery,
    useGetStandardItemRowsMutation,
    useAppendStandardItemActivityResourceMutation,
    useGetStandardItemByFilterQuery,
    useLazyGetStandardItemPagedQuery,
    useLazyGetStandardItemMaxIdQuery,
    useCreateStandardItemMutation,
    useUpdateStandardItemMutation,
    useGetStandardItemMaxIdQuery,
    useCreateStandardItemActivityResourceMutation,
    useUpdateStandardItemActivityResourceMutation,
    useDeleteStandardItemActivityMutation,
    useDeleteStandardItemResourceMutation,
    useGetStandardItemActivitiesResourcesQuery,
    useDeleteStandardItemMutation
} = standardItemApi