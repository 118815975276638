import { Box, useTheme } from "@mui/material";
import { ColDef, GetRowIdParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import { rounder } from "Helpers/rounder";
import { groupBy, keyBy } from "lodash";
import { ItemListReport, ItemListReportView, ResourceAllocationView } from "Models/item-list-report";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import ResourceSelector, { ReportResource } from "./ResourceSelector";
import { useGetUnitsQuery } from "State/Services/unit";
export interface ResourceAllocationProps {
    estimateId: string | undefined;
    pageSize: number;
    itemBreakDownReport: {
        results: Array<ItemListReport>;
    },
    selectedResource?: ReportResource;
}

export default function ResourceAllocation(props: ResourceAllocationProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<ResourceAllocationView>>(null);
    const [resourcesGrouped, setResourcesGrouped] = useState<Array<ResourceAllocationView>>([]);
    const [rowData, setRowData] = useState<Array<ResourceAllocationView>>([]);
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    
    useEffect(() => {
        if (props.itemBreakDownReport && props.itemBreakDownReport.results.length > 0) {
            const itemMap: { [id: string]: ItemListReport } = keyBy(props.itemBreakDownReport.results.filter(r => r.type === "item"), (i) => {
                return i.itemId;
            });
            const finalResourceList = new Array<ResourceAllocationView>();
            const resources = props.itemBreakDownReport.results.filter(r => r.type === "resource");
            const grouped = groupBy(resources, (res) => (res.itemId));
            
            Object.keys(grouped).forEach((itemId) => {
                const groupedItem = grouped[itemId];
                const resourceGrouped = groupBy(groupedItem, (groupItem) => (groupItem.resourceId));
                Object.keys(resourceGrouped).forEach((resourceId) => {
                    const resources = resourceGrouped[resourceId];
                    const totalAmount = resources.reduce((sum, value) => (sum + (value.basicRateAmountTotal ?? 0)), 0);
                    const totalEstimatedAmount = resources.reduce((sum, value) => (sum + (value.basicRateEstimatedAmountTotal ?? 0)), 0);
                    const totalQuantity = resources.reduce((sum, value) => (sum + value.totalQuantity), 0);
                    const totalEstimatedQuantity = resources.reduce((sum, value) => (sum + value.totalEstimatedQuantity), 0);
                    const unit = units?.find(u => u.id === itemMap[itemId].unitId);
                    const resourceUnit = units?.find(u => u.id === resources[0].unitId);
                    if (!finalResourceList.some(f => f.id===resourceId)){
                        finalResourceList.push({
                            ...resources[0],
                            id: resourceId,
                            unit: {
                                unitDescription: resourceUnit?.description,
                                unitId: resourceUnit?.id
                            },
                            amount: resources[0].basicFactoredRate ?? 0,
                            displayId: resources[0].displayId,
                            hierarchy: [resourceId],
                        });
                    }
                    finalResourceList.push({
                        ...resources[0],
                        id: itemId,
                        description: itemMap[itemId].description,
                        amount: totalAmount/itemMap[itemId].totalQuantity,
                        displayId: itemMap[itemId].displayId,
                        totalAmount: totalAmount,
                        totalEstimatedAmount: totalEstimatedAmount,
                        totalQuantity: totalQuantity,
                        totalEstimatedQuantity: totalEstimatedQuantity,
                        hierarchy: [resourceId, itemId],
                        unit: {
                            unitDescription: unit?.description,
                            unitId: unit?.id
                        },
                        unitId: itemMap[itemId].unitId,
                        customUnit: itemMap[itemId].customUnit,
                        quantity: itemMap[itemId].quantity,
                        itemBoqId: itemMap[itemId].itemBoqId,
                        page: itemMap[itemId].page,
                        itemEstimatedQuantity: itemMap[itemId].itemEstimatedQuantity,
                        itemQuantity: itemMap[itemId].itemQuantity,
                    });
                });
            });
            setResourcesGrouped(finalResourceList);
        }
    }, [props.itemBreakDownReport, units])

    const getBaseDefs = useCallback((): Array<ColDef<ResourceAllocationView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'resourceId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                headerName: 'ID',
                cellRenderer: 'agGroupCellRenderer',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 80,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
                showRowGroup: true,
            },
            {
                field: 'page',
                width: 80,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'itemBoqId',
                headerName: 'Item',
                width: 80,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'itemQuantity',
                headerName: 'Item Qty',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'itemEstimatedQuantity',
                headerName: 'Item Est. Qty',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'amount',
                headerName: 'Rate/Unit',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalQuantity',
                headerName: 'Res Qty',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                aggFunc: 'sum',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalEstimatedQuantity',
                headerName: 'Res Est. Qty',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                aggFunc: 'sum',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalAmount',
                headerName: 'Amount',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                aggFunc: 'sum',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalEstimatedAmount',
                headerName: 'Est. Amount',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                aggFunc: 'sum',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
        ];
    }, [colors?.gray, settings?.quantityDecimals])

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const getRowId = useCallback(function (params: GetRowIdParams<ResourceAllocationView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const search = useCallback((resource: ReportResource) => {
        setRowData(resourcesGrouped.filter(r => r.resourceId===resource.id))
    }, [resourcesGrouped])

    const getRowStyle = (params: any) => {
        if (params.node.group) {
            return { fontWeight: 'bold' };
        }
    };

    useEffect(() =>{
        if (props.selectedResource){
            search(props.selectedResource);
        }else{
            setRowData([]);
        }
    }, [props.selectedResource, search])

    return <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box display="flex" alignItems="center" width="calc(30% + 20px)" marginRight="10px">
            {props.estimateId && props.selectedResource && <ResourceSelector estimateId={props.estimateId} selectedResource={props.selectedResource} onSelect={search}/>}
        </Box>
        <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
            <AgGridReact<ItemListReportView>
                ref={gridRef}
                rowData={rowData}
                getRowStyle={getRowStyle}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                getRowId={getRowId}
                groupDisplayType={'custom'}
                treeData={true}
                groupDefaultExpanded={-1}
                getDataPath={(item) => item.hierarchy}
            />
        </Box>
    </Box>
}