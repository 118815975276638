
import { ActivityCostListReport, ActivityListReport, BaseReportResource, CategorySubcategoryTotal, CategoryTotal, ItemActivityCostListReport, ReportEstimate, ResourceListReport, ResourceReport, StandardItemCostReport, StandardItemReport, SubcontractorItemReport, SubcontractorItemReportQuotation, SubcontractorItemReportQuote, SupplierResourceReport, SupplierResourceReportQuotation } from 'Models/report';
import { api } from './api'
import { IServerSideGetRowsRequest } from 'ag-grid-enterprise';
import { IndirectItemListReport, ItemListReport, ItemPriceListReport } from 'Models/item-list-report';

export const reportApi = api.injectEndpoints({
  endpoints: (build) => ({
    
    getEstimatesForReport: build.query<Array<ReportEstimate>, {organizationId: string, companyId: string}>({
      query: ({organizationId, companyId}: {organizationId: string, companyId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimates` }),
      providesTags: ['ReportEstimates']
    }),
    getProjectResourceLevel3Report: build.query<Array<BaseReportResource>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/projectResourceLevel3` }),
    }),
    getDirectProjectResourceLevel3Report: build.query<Array<BaseReportResource>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/directProjectResourceLevel3` }),
    }),
    getDirectProjectResourceLevel2Report: build.query<Array<CategorySubcategoryTotal>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/directProjectResourceLevel2` }),
    }),
    getDirectProjectResourceLevel1Report: build.query<Array<CategoryTotal>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/directProjectResourceLevel1` }),
    }),
    getIndirectProjectResourceLevel3Report: build.query<Array<BaseReportResource>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/indirectProjectResourceLevel3` }),
    }),
    getIndirectProjectResourceLevel2Report: build.query<Array<CategorySubcategoryTotal>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/indirectProjectResourceLevel2` }),
    }),
    getIndirectProjectResourceLevel1Report: build.query<Array<CategoryTotal>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/indirectProjectResourceLevel1` }),
    }),
    getProjectResourceLevel2Report: build.query<Array<CategorySubcategoryTotal>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/projectResourceLevel2` }),
    }),
    getProjectResourceLevel1Report: build.query<Array<CategoryTotal>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/projectResourceLevel1` }),
    }),
    getItemReportLevel3: build.mutation<{ results: Array<ItemListReport>; totalCount: number, categories: Array<string> }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/report/estimate/${estimateId}/itemListReportLevel3`,
          method: 'POST',
          body,
        }
      }
    }),
    getItemReportLevel1: build.mutation<{ results: Array<ItemListReport> }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/report/estimate/${estimateId}/itemListReportLevel1`,
          method: 'POST',
          body,
        }
      }
    }),
    getItemPriceReport: build.mutation<{ results: Array<ItemPriceListReport>, categories: Array<string> }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/report/estimate/${estimateId}/itemPriceReport`,
          method: 'POST',
          body,
        }
      }
    }),
    getIndirectItemReportLevel3: build.mutation<{ results: Array<IndirectItemListReport>; totalCount: number, categories: Array<string> }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/report/estimate/${estimateId}/indirectItemListReportLevel3`,
          method: 'POST',
          body,
        }
      }
    }),
    getIndirectItemReportLevel1: build.mutation<{ results: Array<IndirectItemListReport> }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/report/estimate/${estimateId}/indirectItemListReportLevel1`,
          method: 'POST',
          body,
        }
      }
    }),
    getResourceCostList: build.query<{ results: Array<ResourceReport> }, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/resource-cost-list` }),
      providesTags: ['Resources']
    }),
    getResourceList: build.query<{ results: Array<ResourceListReport> }, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/resource-list` }),
      providesTags: ['Resources']
    }),
    getActivityCostList: build.query<{ results: Array<ActivityCostListReport> }, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/activity-cost-list` }),
      providesTags: ['Activities']
    }),
    getActivityList: build.query<{ results: Array<ActivityListReport> }, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/activity-list` }),
      providesTags: ['Activities']
    }),
    getStandardItemCostList: build.query<{ results: Array<StandardItemCostReport> }, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/standard-item-cost-list` }),
      providesTags: ['StandardItems']
    }),
    getStandardItemList: build.query<{ results: Array<StandardItemReport> }, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/standard-item-list` }),
      providesTags: ['StandardItems']
    }),
    getItemActivityCostList: build.query<{ results: Array<ItemActivityCostListReport> }, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/item-activity-cost-list` }),
      providesTags: ['Activities']
    }),
    getSubcontractorQuotes: build.query<SubcontractorItemReport, { organizationId: string, companyId: string, estimateId: string, packageId: string }>({
      query: ({ organizationId, companyId, estimateId, packageId }: { organizationId: string, companyId: string, estimateId: string, packageId?: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/subcontractor-package/${packageId}/subcontractor-quotes` }),
      providesTags: ['SubcontractorPackageVendors', 'SubPackages'],
    }),
    getSubcontractorQuotations: build.query<Array<SubcontractorItemReportQuotation>, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/subcontractor-quotations` }),
      providesTags: ['SubcontractorPackageVendors', 'SubPackages'],
    }),
    getSupplierQuotes: build.query<SupplierResourceReport, { organizationId: string, companyId: string, estimateId: string, packageId: string }>({
      query: ({ organizationId, companyId, estimateId, packageId }: { organizationId: string, companyId: string, estimateId: string, packageId?: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/supplier-package/${packageId}/supplier-quotes` }),
      providesTags: ['SupplierPackages', 'Vendors'],
    }),
    getSupplierQuotations: build.query<Array<SupplierResourceReportQuotation>, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/supplier-quotations` }),
      providesTags: ['SupplierPackages', 'Vendors'],
    }),
  })
});

export const {
  useLazyGetSupplierQuotationsQuery,
  useLazyGetSubcontractorQuotationsQuery,
  useLazyGetSupplierQuotesQuery,
  useLazyGetSubcontractorQuotesQuery,
  useLazyGetItemActivityCostListQuery,
  useLazyGetStandardItemListQuery,
  useLazyGetStandardItemCostListQuery,
  useLazyGetActivityListQuery,
  useLazyGetActivityCostListQuery,
  useLazyGetResourceListQuery,
  useGetIndirectItemReportLevel3Mutation,
  useGetItemReportLevel3Mutation,
  useGetEstimatesForReportQuery,
  useLazyGetEstimatesForReportQuery,
  useLazyGetProjectResourceLevel3ReportQuery,
  useLazyGetProjectResourceLevel2ReportQuery,
  useLazyGetProjectResourceLevel1ReportQuery,
  useLazyGetDirectProjectResourceLevel3ReportQuery,
  useLazyGetIndirectProjectResourceLevel3ReportQuery,
  useLazyGetDirectProjectResourceLevel1ReportQuery,
  useLazyGetDirectProjectResourceLevel2ReportQuery,
  useLazyGetIndirectProjectResourceLevel1ReportQuery,
  useLazyGetIndirectProjectResourceLevel2ReportQuery,
  useGetItemPriceReportMutation,
  useGetItemReportLevel1Mutation,
  useGetIndirectItemReportLevel1Mutation,
  useLazyGetResourceCostListQuery
} = reportApi