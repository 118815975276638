import { Autocomplete, Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { IndirectItemCategory } from "Models/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { tokens } from "theme";
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetIndirectItemCategoriesQuery } from "State/Services/indirect-item-category";

export interface SelectedCategories {
    category1?: IndirectItemCategory;
    category2?: IndirectItemCategory;
    category3?: IndirectItemCategory;
    category4?: IndirectItemCategory
}

interface CategoryEditProps {
    noOfLevels: number | undefined;
    estimateId: string | undefined;
    category1Id: number | undefined;
    category2Id: number | undefined;
    category3Id: number | undefined;
    category4Id: number | undefined;
    close: () => void;
    save: (selectedCategories: SelectedCategories) => Promise<void>;
}

export default function IndirectItemCategoryEdit(props: CategoryEditProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const { data: indirectItemCategories } = useGetIndirectItemCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId, refetchOnMountOrArgChange: true });
    const [level1Categories, setLevel1Categories] = useState<Array<IndirectItemCategory> | undefined>();
    const [level2Categories, setLevel2Categories] = useState<Array<IndirectItemCategory> | undefined>();
    const [level3Categories, setLevel3Categories] = useState<Array<IndirectItemCategory> | undefined>();
    const [level4Categories, setLevel4Categories] = useState<Array<IndirectItemCategory> | undefined>();
    const [colors, setColors] = useState<any>();
    const [selectedCategory1, setSelectedCategory1] = useState<IndirectItemCategory | null | undefined>();
    const [selectedCategory2, setSelectedCategory2] = useState<IndirectItemCategory | null | undefined>();
    const [selectedCategory3, setSelectedCategory3] = useState<IndirectItemCategory | null | undefined>();
    const [selectedCategory4, setSelectedCategory4] = useState<IndirectItemCategory | null | undefined>();
    const [isSaving, setIsSaving] = useState(false);
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);

    useEffect(() => {
        if (props.noOfLevels) {

            switch (props.noOfLevels) {
                case 1:
                    setLevel1Categories(indirectItemCategories?.filter((cat) => {
                        return cat.level === 1;
                    }));
                    if (props.category1Id) {
                        const cat = indirectItemCategories?.find((category) => (category.id === props.category1Id && category.level === 1));
                        if (cat) {
                            setSelectedCategory1(cat);
                        }
                    }
                    break;
                case 2:
                    setLevel1Categories(indirectItemCategories?.filter((cat) => {
                        return cat.level === 1;
                    }));
                    if (props.category1Id) {
                        const cat = indirectItemCategories?.find((category) => (category.id === props.category1Id && category.level === 1));
                        if (cat) {
                            setSelectedCategory1(cat);
                        }
                    }
                    setLevel2Categories(indirectItemCategories?.filter((cat) => {
                        return cat.level === 2;
                    }));
                    if (props.category2Id) {
                        const cat = indirectItemCategories?.find((category) => (category.id === props.category2Id && category.level === 2));
                        if (cat) {
                            setSelectedCategory2(cat);
                        }
                    }
                    break;
                case 3:
                    setLevel1Categories(indirectItemCategories?.filter((cat) => {
                        return cat.level === 1;
                    }));
                    if (props.category1Id) {
                        const cat = indirectItemCategories?.find((category) => (category.id === props.category1Id && category.level === 1));
                        if (cat) {
                            setSelectedCategory1(cat);
                        }
                    }
                    setLevel2Categories(indirectItemCategories?.filter((cat) => {
                        return cat.level === 2;
                    }));
                    if (props.category2Id) {
                        const cat = indirectItemCategories?.find((category) => (category.id === props.category2Id && category.level === 2));
                        if (cat) {
                            setSelectedCategory2(cat);
                        }
                    }
                    setLevel3Categories(indirectItemCategories?.filter((cat) => {
                        return cat.level === 3;
                    }));
                    if (props.category3Id) {
                        const cat = indirectItemCategories?.find((category) => (category.id === props.category3Id && category.level === 3));
                        if (cat) {
                            setSelectedCategory3(cat);
                        }
                    }
                    break;
                case 4:
                    setLevel1Categories(indirectItemCategories?.filter((cat) => {
                        return cat.level === 1;
                    }));
                    if (props.category1Id) {
                        const cat = indirectItemCategories?.find((category) => (category.id === props.category1Id && category.level === 1));
                        if (cat) {
                            setSelectedCategory1(cat);
                        }
                    }
                    setLevel2Categories(indirectItemCategories?.filter((cat) => {
                        return cat.level === 2;
                    }));
                    if (props.category2Id) {
                        const cat = indirectItemCategories?.find((category) => (category.id === props.category2Id && category.level === 2));
                        if (cat) {
                            setSelectedCategory2(cat);
                        }
                    }
                    setLevel3Categories(indirectItemCategories?.filter((cat) => {
                        return cat.level === 3;
                    }));
                    if (props.category3Id) {
                        const cat = indirectItemCategories?.find((category) => (category.id === props.category3Id && category.level === 3));
                        if (cat) {
                            setSelectedCategory3(cat);
                        }
                    }
                    setLevel4Categories(indirectItemCategories?.filter((cat) => {
                        return cat.level === 4;
                    }));
                    if (props.category4Id) {
                        const cat = indirectItemCategories?.find((category) => (category.id === props.category4Id && category.level === 4));
                        if (cat) {
                            setSelectedCategory4(cat);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }, [indirectItemCategories, props.category1Id, props.category2Id, props.category3Id, props.category4Id, props.noOfLevels])

    const handleCategoryChanged = useCallback((event: React.SyntheticEvent<Element, Event>, value: IndirectItemCategory | null, level: number) => {
        switch (level) {
            case 1:
                setSelectedCategory1(value);
                break;
            case 2:
                setSelectedCategory2(value);
                break;
            case 3:
                setSelectedCategory3(value);
                break;
            case 4:
                setSelectedCategory4(value);
                break;
            default:
                break;
        }
    }, [])

    const save = useCallback(async () => {
        try {
            setIsSaving(true);
            let selectedCategories: SelectedCategories = {};
            switch (props.noOfLevels) {
                case 1:
                    if (selectedCategory1) {
                        selectedCategories.category1 = selectedCategory1;
                    }
                    break;
                case 2:
                    if (selectedCategory1) {
                        selectedCategories.category1 = selectedCategory1;
                    }
                    if (selectedCategory2) {
                        selectedCategories.category2 = selectedCategory2;
                    }
                    break;
                case 3:
                    if (selectedCategory1) {
                        selectedCategories.category1 = selectedCategory1;
                    }
                    if (selectedCategory2) {
                        selectedCategories.category2 = selectedCategory2;
                    }
                    if (selectedCategory3) {
                        selectedCategories.category3 = selectedCategory3;
                    }
                    break;
                case 4:
                    if (selectedCategory1) {
                        selectedCategories.category1 = selectedCategory1;
                    }
                    if (selectedCategory2) {
                        selectedCategories.category2 = selectedCategory2;
                    }
                    if (selectedCategory3) {
                        selectedCategories.category3 = selectedCategory3;
                    }
                    if (selectedCategory4) {
                        selectedCategories.category4 = selectedCategory4;
                    }
                    break;
                default:
                    break;
            }

            await props.save(selectedCategories);
            props.close();
        } catch (error) {
            console.log(error);
        } finally {
            setIsSaving(false);
        }
    }, [props, selectedCategory1, selectedCategory2, selectedCategory3, selectedCategory4])

    return <>{colors &&
        <Box padding="0px 16px 16px 16px" width="100%" height="100%">
            <Box padding="12px 16px" display="flex" alignItems="center">
                <Typography fontSize="13px" fontWeight="600" lineHeight="140%" fontStyle="normal">Indirect Item Categories</Typography>
            </Box>
            <Box display="flex" flexDirection="column" padding="0px 16px 16px 16px" gap="12px">
                {level1Categories &&
                    <Box>
                        <label htmlFor="category1" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Category 1</label>
                        <Autocomplete
                            size="small"
                            value={selectedCategory1 ?? null}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                            getOptionLabel={(option) => option.category ?? ""}
                            options={level1Categories}
                            onChange={(event, value) => handleCategoryChanged(event, value, 1)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} name="category1" />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.category, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.category, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    </Box>
                }
                {level2Categories &&
                    <Box>
                        <label htmlFor="category2" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Category 2</label>

                        <Autocomplete
                            size="small"
                            value={selectedCategory2 ?? null}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                            getOptionLabel={(option) => option.category ?? ""}
                            options={level2Categories}
                            onChange={(event, value) => handleCategoryChanged(event, value, 2)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} name="category2" />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.category, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.category, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />

                    </Box>
                }
                {level3Categories &&
                    <Box>
                        <label htmlFor="category3" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Category 3</label>
                        <Autocomplete
                            size="small"
                            value={selectedCategory3 ?? null}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                            getOptionLabel={(option) => option.category ?? ""}
                            options={level3Categories}
                            onChange={(event, value) => handleCategoryChanged(event, value, 3)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} name="category3" />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.category, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.category, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    </Box>
                }
                {level4Categories &&
                    <Box>
                        <label htmlFor="category4" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Category 4</label>
                        <Autocomplete
                            size="small"
                            value={selectedCategory4 ?? null}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                            getOptionLabel={(option) => option.category ?? ""}
                            options={level4Categories}
                            onChange={(event, value) => handleCategoryChanged(event, value, 4)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} name="category4" />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.category, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.category, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    </Box>
                }

            </Box>
            <Box paddingLeft="50px" paddingRight="50px" display="flex" justifyContent="space-between">
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    disabled={isSaving}
                    startIcon={!isSaving ? <SaveIcon /> : <CircularProgress sx={{ color: "white" }} size="1rem" />}
                    onClick={save}>Save</Button>
                <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={props.close}
                    sx={{ backgroundColor: `${colors?.gray[1000]}` }}
                >Cancel</Button>
            </Box>
        </Box>
    }
    </>;
}