import { ActivityCategory } from 'Models/activity-category';
import { api } from './api'

export const activityCategoryApi = api.injectEndpoints({
    endpoints: (build) => ({
        getActivityCategories: build.query<Array<ActivityCategory>, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/activity-category` }),
            providesTags: ['ActivityCategories']
        }),
        getActivityCategoryMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/activity-category/max-id` }),
            providesTags: ['ActivityCategories']
        }),
        createActivityCategory: build.mutation<ActivityCategory, Partial<{ body: ActivityCategory, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity-category`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: (result) => (result ? ['ActivityCategories'] : []),
        }),
        updateActivityCategory: build.mutation<ActivityCategory, Partial<{ body: ActivityCategory, orgId: string, companyId: string, estimateId: string, activityCategoryId: string }>>({
            query({ body, orgId, companyId, estimateId, activityCategoryId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity-category/${activityCategoryId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: (result) => (result ? ['ActivityCategories'] : []),
        }),
        deleteActivityCategory: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, activityCategoryId: string }>>({
            query({ orgId, companyId, estimateId, activityCategoryId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/activity-category/${activityCategoryId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result) => (result ? ['ActivityCategories'] : []),
        }),
    })
});

export const {
    useGetActivityCategoriesQuery,
    useLazyGetActivityCategoriesQuery,
    useLazyGetActivityCategoryMaxIdQuery,
    useCreateActivityCategoryMutation,
    useDeleteActivityCategoryMutation,
    useUpdateActivityCategoryMutation
} = activityCategoryApi