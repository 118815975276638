import { Autocomplete, FormControl, TextField } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useGetActivitiesByFilterQuery, useLazyGetActivitiesPagedQuery } from "State/Services/activity";
import { useGetUserDetailsQuery } from "State/Services/user";

export interface ReportActivity {
    id?: string;
    description: string;
    displayId?: string;
}

export interface ActivitySelectorProps {
    selectedActivity?: ReportActivity;
    estimateId?: string;
    onSelect: (resource: ReportActivity) => void;
}

export default function ActivitySelector(props: ActivitySelectorProps) {
    const [open, setOpen] = useState<boolean>(false);
    const inputRef = useRef<any>();
    const { data: user } = useGetUserDetailsQuery();
    const [getActivitiesPaged] = useLazyGetActivitiesPagedQuery();
    const [options, setOptions] = useState<Array<ReportActivity>>([]);
    const [searchValue, setSearchValue] = useState<ReportActivity>(props.selectedActivity ? { id: props.selectedActivity.id, description: props.selectedActivity.description } : { id: '', description: '' });
    const { data: filteredActivities } = useGetActivitiesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.description.length > 1) });

    useEffect(() => {
        if (filteredActivities && filteredActivities.length > 0) {
            setOptions(filteredActivities.map((act) => ({
                description: `${act.displayId} - ${act.description}`,
                id: act.activityId,
                displayId: act.displayId
            })));
        }
    }, [filteredActivities])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: ReportActivity) => {
        if (newValue) {
            setSearchValue(newValue);
            props.onSelect(newValue);
        } else {
            setSearchValue({ id: '', description: '' });
            props.onSelect({ id: '', description: '' });
        }
        setOpen(false);
    }, [props]);

    const setDefaultActivities = useCallback(async () => {
        const response = await getActivitiesPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true);
        if ('data' in response) {
            const results = response.data;
            if (results && results.length > 0) {
                setOptions(results.map((res) => ({
                    description: `${res.displayId} - ${res.description}`,
                    id: res.activityId,
                    displayId: res.displayId
                })));
            }
        }
    }, [getActivitiesPaged, props, user])

    useEffect(() => {
        setDefaultActivities();
    }, [setDefaultActivities])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            setSearchValue({ description: newValue });
        } else {
            setSearchValue({ description: '' });
            setOpen(false);
            await setDefaultActivities();
        }
    }, [setDefaultActivities])

    return <FormControl sx={{ width: "100%", height: 45, marginBottom: "10px" }} size="small">
        <Autocomplete
            fullWidth
            sx={{ height: "100%" }}
            disableClearable
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={searchValue}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.description}
            filterOptions={(x) => x}
            size="small"
            onChange={onChange}
            onInputChange={onInputChange}
            options={options}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Select Activity"
                    sx={{ '& .MuiInputBase-root': { height: 45 } }}
                    inputRef={inputRef}
                    placeholder='Search...' />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.description, inputValue, { insideWords: true });
                const parts = parse(option.description, matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    </FormControl>
}