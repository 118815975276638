import Handsontable from "handsontable";
import Cell from "./Cell";

export function renderDiff(
  instance: any,
  td: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: Handsontable.CellValue,
  cellProperties: Handsontable.CellProperties
) {
  var tt = new Cell(value, instance);
  const coopy = (window as any).coopy;
  const daff = (window as any).daff;
  var view = new coopy.SimpleView();
  var cell = daff.DiffRender.renderCell(tt, view, col, row);
  var className = cell.category;
  var value2 = cell.pretty_value;
  if (className !== "") {
    td.className = className;
  }

  td.innerHTML = value2;
  return value2;
}