import { Box, useTheme } from "@mui/material";
import { ColDef, GetRowIdParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import { rounder } from "Helpers/rounder";
import { groupBy, keyBy } from "lodash";
import { ActivityAllocationView, ItemListReport, ItemListReportView } from "Models/item-list-report";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import { useGetUnitsQuery } from "State/Services/unit";
import ActivitySelector, { ReportActivity } from "./ActivitySelector";

export interface ActivityAllocationProps {
    estimateId: string | undefined;
    pageSize: number;
    itemBreakDownReport: {
        results: Array<ItemListReport>;
    },
    selectedActivity?: ReportActivity;
}

export default function ActivityAllocation(props: ActivityAllocationProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<ActivityAllocationView>>(null);
    const [activitiesGrouped, setActivitiesGrouped] = useState<Array<ActivityAllocationView>>([]);
    const [rowData, setRowData] = useState<Array<ActivityAllocationView>>([]);
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });

    useEffect(() => {
        if (props.itemBreakDownReport && props.itemBreakDownReport.results.length > 0) {
            const itemMap: { [id: string]: ItemListReport } = keyBy(props.itemBreakDownReport.results.filter(r => r.type === "item"), (i) => {
                return i.itemId;
            });
            const finalActivityList = new Array<ActivityAllocationView>();
            const activities = props.itemBreakDownReport.results.filter(r => r.type === "activity");
            const grouped = groupBy(activities, (res) => (res.itemId));

            Object.keys(grouped).forEach((itemId) => {
                const groupedItem = grouped[itemId];
                const activityGrouped = groupBy(groupedItem, (groupItem) => (groupItem.activityId));
                let totalQuantity = 0;
                Object.keys(activityGrouped).forEach((act) => {
                    totalQuantity = totalQuantity + activityGrouped[act].reduce((sum, value) => (sum + (value.totalQuantity)), 0)
                });
                Object.keys(activityGrouped).forEach((activityId) => {
                    const activities = activityGrouped[activityId];
                    const totalAmount = activities.reduce((sum, value) => (sum + (value.totalAmount ?? 0)), 0);
                    const unit = units?.find(u => u.id === itemMap[itemId].unitId);
                    const activityUnit = units?.find(u => u.id === activities[0].unitId);
                    const existing = finalActivityList.find(f => f.id === activityId);
                    if (!existing) {
                        finalActivityList.push({
                            ...activities[0],
                            id: activityId,
                            unit: {
                                unitDescription: activityUnit?.description,
                                unitId: activityUnit?.id
                            },
                            amount: activities[0].rate,
                            totalQuantity: activities[0].totalQuantity,
                            displayId: activities[0].displayId,
                            hierarchy: [activityId]
                        });
                    }else{
                        existing.totalQuantity += activities[0].totalQuantity; 
                    }
                    const quantityFactor = activities[0].quantityWithFactor / activities[0].quantity;
                    finalActivityList.push({
                        ...activities[0],
                        description: itemMap[itemId].description,
                        amount: activities[0].quantityWithFactor / activities[0].quantity,
                        displayId: itemMap[itemId].displayId,
                        totalAmount: totalAmount,
                        output: itemMap[itemId].itemQuantity,
                        actQuantity: activities[0].quantity,
                        totalQuantity: activities[0].quantity * itemMap[itemId].itemQuantity * quantityFactor,
                        id: itemId,
                        hierarchy: [activityId, itemId],
                        unit: {
                            unitDescription: unit?.description,
                            unitId: unit?.id
                        },
                        unitId: itemMap[itemId].unitId,
                        customUnit: itemMap[itemId].customUnit,
                        itemBoqId: itemMap[itemId].itemBoqId,
                        page: itemMap[itemId].page,
                        itemEstimatedQuantity: itemMap[itemId].itemEstimatedQuantity,
                        itemQuantity: itemMap[itemId].itemQuantity,
                    });
                });
            });
            setActivitiesGrouped(finalActivityList);
        }
    }, [props.itemBreakDownReport, units])

    const getBaseDefs = useCallback((): Array<ColDef<ActivityAllocationView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'activityId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                headerName: 'ID',
                cellRenderer: 'agGroupCellRenderer',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 80,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
                showRowGroup: true,
            },
            {
                field: 'page',
                width: 80,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'itemBoqId',
                headerName: 'Item',
                width: 80,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'output',
                headerName: 'Output\nItem Qty',
                headerStyle: { whiteSpace: 'pre-line' },
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'actQuantity',
                headerName: 'Act. Qty.',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'amount',
                headerName: 'Rate\nFactor',
                headerStyle: { whiteSpace: 'pre-line' },
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalQuantity',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalAmount',
                headerName: 'Amount',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                aggFunc: 'sum',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            }
        ];
    }, [colors?.gray, settings?.quantityDecimals])

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const getRowId = useCallback(function (params: GetRowIdParams<ActivityAllocationView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const search = useCallback((activity: ReportActivity) => {
        console.log(activitiesGrouped.filter(r => r.activityId === activity.id))
        setRowData(activitiesGrouped.filter(r => r.activityId === activity.id))
    }, [activitiesGrouped])

    const getRowStyle = (params: any) => {
        if (params.node.group) {
            return { fontWeight: 'bold' };
        }
    };

    useEffect(() => {
        if (props.selectedActivity) {
            search(props.selectedActivity);
        } else {
            setRowData([]);
        }
    }, [props.selectedActivity, search])

    return <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box display="flex" alignItems="center" width="calc(30% + 20px)" marginRight="10px">
            {props.estimateId && props.selectedActivity && <ActivitySelector estimateId={props.estimateId} selectedActivity={props.selectedActivity} onSelect={search} />}
        </Box>
        <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
            <AgGridReact<ItemListReportView>
                ref={gridRef}
                rowData={rowData}
                getRowStyle={getRowStyle}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                getRowId={getRowId}
                groupDisplayType={'custom'}
                paginationPageSize={props.pageSize}
                treeData={true}
                groupDefaultExpanded={-1}
                getDataPath={(item) => item.hierarchy}
            />
        </Box>
    </Box>
}