class Cell {
  value: string;
  instance: any;

  constructor(value: string, instance: any) {
    this.value = value;
    this.instance = instance;
  }

  getCell(x: number, y: number): string {
    const rawValue = this.instance.getDataAtCell(y, x);

    if (rawValue === null || rawValue === undefined || rawValue === "") {
      return " "; // Return a placeholder for empty values
    }

    if (typeof rawValue === "number") {
      return rawValue.toString(10); // Explicitly convert numbers to base-10 strings
    }

    return String(rawValue); // Convert all other types to strings
  }

  getValue(): string {
    return this.value;
  }
}

export default Cell;
