import React, {
    MutableRefObject,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Autocomplete, Box } from '@mui/material';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { useGetUnitsQuery } from 'State/Services/unit';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { GridTextField } from 'Components/GridTextField';
import { useGetItemCodesQuery } from 'State/Services/item-code';
import { CustomCellEditorProps } from 'ag-grid-react';

export interface ICodeCellEditorParams extends CustomCellEditorProps {
    setRef: (ref: MutableRefObject<any>) => void;
    estimateId: string;
}

export default forwardRef(({ value, onValueChange, estimateId, setRef }: ICodeCellEditorParams, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: itemCodes } = useGetItemCodesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const inputRef = useRef<any>();
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value;
            },
            focusIn() {
                inputRef.current.focus();
                handleOpen();
            },
            setFocusOnAdd() {
                if (inputRef.current) {
                    inputRef.current.focus();
                    setOpen(true);
                }
            },
        };
    });

    useEffect(() =>{
        if (inputRef){
            setRef(inputRef);
        }
    }, [setRef])

    
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Box height="100%">
            {units && units.length > 0 && <Autocomplete
                fullWidth
                freeSolo
                className="ag-input-field-input ag-text-field-input"
                sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                value={value || null}
                size="small"
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                    if (newValue) {
                        if (itemCodes) {
                            const itemCode = itemCodes?.find((itemCode) => (itemCode.code === newValue));
                            if (itemCode) {
                                onValueChange(itemCode.code);
                            } else {
                                onValueChange(newValue);
                            }
                        }
                    } else {
                        onValueChange('');
                    }
                }}
                onInputChange={(event: React.SyntheticEvent, newValue: string | null) => {
                    if (newValue) {
                        if (itemCodes) {
                            const itemCode = itemCodes?.find((itemCode) => (itemCode.code === newValue));
                            if (itemCode) {
                                onValueChange(itemCode.code);
                            } else {
                                onValueChange(newValue);
                            }
                        }
                    } else {
                        onValueChange('');
                    }
                }}
                options={itemCodes?.map((itemCode) => itemCode.code)??[]}
                renderInput={(params) => <GridTextField sx={{ height: "100%" }} inputRef={inputRef} placeholder='Select item code' {...params} />}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option, inputValue, {
                        insideWords: true,
                    });
                    const parts = parse(option, matches);

                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
            }
        </Box>
    );
});
