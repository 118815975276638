
import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Box, TextField, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { Errors } from 'Models/errors';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppendItemDetailMutation, useGetItemIdQuery, useQuickAddResourceDetailMutation } from 'State/Services/item';
import { useGetUnitsQuery } from 'State/Services/unit';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { tokens } from 'theme';
import * as yup from 'yup';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FilteredResource } from './ResourceAppend';
import { useGetResourcesByFilterQuery, useLazyGetResourcesPagedQuery } from 'State/Services/resource';
import AddIcon from '@mui/icons-material/Add';
import { FilterField } from 'Models/resource';
import SplitButton, { MenuOption } from 'Components/SplitButton';
import { AddOptions } from 'Models/button-options';
import { useAppendIndirectItemDetailMutation, useGetIndirectItemIdQuery, useQuickAddResourceIndirectDetailMutation } from 'State/Services/indirect-item';
export interface QuickAddResource {
    addedResourceId: string;
    totalQuantity?: number;
    quantityPerUnit?: number;
}

export interface QuickAppendResourceProps {
    inHeader: boolean;
    type?: string;
    estimateId: string;
    resourceId?: string;
    itemId: string;
    standardItemId?: string;
    activityId?: string;
    subItemId?: string;
    itemActivityId?: string;
    itemResourceId?: string;
    resourceSubCategoryId?: string;
    close: () => void;
    parent: 'direct' | 'indirect';
}

export default function QuickAppendResource(props: QuickAppendResourceProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const { data: item } = useGetItemIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', itemId: props.itemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !props.itemId || props.parent !== 'direct' });
    const { data: indirectItem } = useGetIndirectItemIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', indirectItemId: props.itemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !props.itemId || props.parent !== 'indirect' });
    const [colors, setColors] = useState<any>();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [pageError, setPageError] = useState<string | undefined>();
    const [saving, setSaving] = useState(false);
    const [unitSuffix, setUnitSuffix] = useState<string>('Item Unit');
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [quickAddResourceDetail] = useQuickAddResourceDetailMutation();
    const [quickAddResourceIndirectDetail] = useQuickAddResourceIndirectDetailMutation();
    const [open, setOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<FilteredResource & { unitDescription?: string }>({ id: '', description: '', disableSubElements: false });
    const { data: filteredResources } = useGetResourcesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '', subCategoryId: props.resourceSubCategoryId }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.description.length > 1) });
    const [options, setOptions] = useState<Array<FilteredResource & { unitDescription?: string }>>([]);
    const [getResourcesPaged] = useLazyGetResourcesPagedQuery();
    const [addMenuItems, setAddMenuItems] = useState<Array<MenuOption>>([{ option: AddOptions.AddElement, disabled: false }, { option: AddOptions.AddSubElements, disabled: false }]);
    const [appendItemDetail] = useAppendItemDetailMutation();
    const [appendIndirectItemDetail] = useAppendIndirectItemDetailMutation();

    useEffect(() => {
        if (filteredResources && filteredResources.length > 0) {
            const filtered = filteredResources.filter((resource) => (resource.resourceId !== props.resourceId));
            setOptions(filtered.map((res) => ({
                description: `${res.displayId} - ${res.description}`,
                id: res.resourceId,
                displayId: res.displayId,
                unitDescription: units?.find(u => u.id === res.unitId)?.description,
                disableSubElements: !res.isComposite
            })));
        }
    }, [filteredResources, props.resourceId, units])

    useEffect(() => {
        const itemToUse = (props.parent === 'direct') ? item : indirectItem;
        if (units && itemToUse && itemToUse.unitId) {
            const unit = units?.find(u => u.id === itemToUse.unitId);
            if (unit) {
                setUnitSuffix(`Quantity / ${unit.description}`);
            }
        } else if (itemToUse?.customUnit) {
            setUnitSuffix(`Quantity / ${itemToUse.customUnit}`);
        }
    }, [indirectItem, item, props.parent, units])

    useEffect(() => {
        setSearchValue({ description: '' });
        setOpen(false);
    }, [props.resourceSubCategoryId])

    const quickAddResourceValidationSchema = yup.object<QuickAddResource>({
        quantityPerUnit: yup
            .number()
            .when([], (fields, schema) => {
                if (!props.inHeader && !!props.resourceId && searchValue?.unitDescription === 'PCT') {
                    return schema.notRequired();
                }
                return schema.required("Quantity is required");
            }),
        totalQuantity: yup
            .number()
            .when([], (fields, schema) => {
                if (!props.inHeader && !!props.resourceId && searchValue?.unitDescription === 'PCT') {
                    return schema.notRequired();
                }
                return schema.required("Total quantity is required");
            }),
    });

    const { values, setFieldValue, handleSubmit, handleChange, handleBlur, touched, errors } = useFormik<QuickAddResource>({
        validateOnMount: true,
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: {
            addedResourceId: '',
            totalQuantity: undefined,
            quantityPerUnit: undefined
        },
        validationSchema: quickAddResourceValidationSchema,
        onSubmit: async (resource) => {
            try {
                if (user && searchValue.id) {
                    setSaving(true);
                    if (props.parent === 'direct') {
                        await quickAddResourceDetail({
                            companyId: user.companyId,
                            orgId: user.organizationId,
                            estimateId: props.estimateId,
                            itemId: props.itemId,
                            body: {
                                addedResourceId: searchValue.id,
                                quantity: resource.totalQuantity ?? 0,
                                activityId: props.activityId,
                                standardItemId: props.standardItemId,
                                resourceId: props.resourceId,
                                itemResourceId: props.itemResourceId,
                                itemActivityId: props.itemActivityId,
                                subItemId: props.subItemId,
                                quantityPerUnit: resource.quantityPerUnit ?? 0
                            }
                        }).unwrap();
                    } else if (props.parent === 'indirect') {
                        await quickAddResourceIndirectDetail({
                            companyId: user.companyId,
                            orgId: user.organizationId,
                            estimateId: props.estimateId,
                            indirectItemId: props.itemId,
                            body: {
                                addedResourceId: searchValue.id,
                                quantity: resource.totalQuantity ?? 0,
                                activityId: props.activityId,
                                standardItemId: props.standardItemId,
                                resourceId: props.resourceId,
                                indirectItemResourceId: props.itemResourceId,
                                indirectItemActivityId: props.itemActivityId,
                                indirectSubItemId: props.subItemId,
                                quantityPerUnit: resource.quantityPerUnit ?? 0
                            }
                        }).unwrap();
                    }
                    props.close();
                }
            } catch (error: any) {
                if (error.status === 500) {
                    setPageError(Errors.generic);
                    return;
                }
                if (error && ('data' in error)) {
                    if (error.data?.description) {
                        setPageError(error.data?.description);
                    } else if (error.data.displayId) {
                        setPageError(error.data?.displayId);
                    } else if (error.data.page) {
                        setPageError(error.data.page);
                    } else if (error.data.message) {
                        setPageError(error.data.message);
                    }
                }
            }
            finally {
                setSaving(false);
            }
        },
    });

    const onQuantityPerUnitChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const itemQuantityToUse = (props.parent === 'direct') ? item?.quantity : indirectItem?.estimatedQuantity;
        if (itemQuantityToUse) {
            setFieldValue('totalQuantity', event.target.value ? parseFloat(event.target.value) * (itemQuantityToUse ?? 0) : undefined);
        }
        handleChange(event);
    }, [handleChange, indirectItem?.estimatedQuantity, item?.quantity, props.parent, setFieldValue])

    const onQuantityChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const itemQuantityToUse = (props.parent === 'direct') ? item?.quantity : indirectItem?.estimatedQuantity;
        if (item?.quantity) {
            setFieldValue('quantityPerUnit', event.target.value ? parseFloat(event.target.value) / (itemQuantityToUse ?? 1) : undefined);
        }
        handleChange(event);
    }, [handleChange, indirectItem?.estimatedQuantity, item?.quantity, props.parent, setFieldValue])

    const setDefaultResources = useCallback(async () => {
        const response = await getResourcesPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            subCategoryId: props.resourceSubCategoryId,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true);
        if ('data' in response) {
            const results = response.data;
            if (results && results.length > 0) {
                const filtered = results.filter((resource) => (resource.resourceId !== props.resourceId));
                setOptions(filtered.map((res) => ({
                    description: `${res.displayId} - ${res.description}`,
                    id: res.resourceId,
                    displayId: res.displayId,
                    unitDescription: units?.find(u => u.id === res.unitId)?.description,
                    disableSubElements: !res.isComposite
                })));
            }
        }
    }, [getResourcesPaged, props, units, user])

    useEffect(() => {
        setDefaultResources();
    }, [setDefaultResources])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            setSearchValue({ description: newValue });
        } else {
            setSearchValue({ description: '' });
            setOpen(false);
            await setDefaultResources();
        }
    }, [setDefaultResources])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: FilteredResource & { unitDescription?: string }) => {
        if (newValue) {
            setSearchValue(newValue);
            const menuOption = addMenuItems.find((menuItem) => (menuItem.option === AddOptions.AddSubElements));
            if (menuOption) {
                menuOption.disabled = !!newValue.disableSubElements;
                setAddMenuItems([...addMenuItems]);
            }
        } else {
            setSearchValue({ id: '', description: '', disableSubElements: false });
        }
        setOpen(false);
    }, [addMenuItems]);

    const getContainerHeight = useCallback(() => {
        return pageError ? 116 : 68;
    }, [pageError]);

    const handleClose = useCallback(async (option?: string) => {
        try {
            setPageError(undefined);
            if (props.itemId) {
                if (props.parent === 'direct') {
                    await appendItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        itemId: props.itemId,
                        body: {
                            addOption: option === "Add sub-elements" ? 'sub-element' : 'element',
                            id: searchValue.id,
                            type: 'resource'
                        }
                    }).unwrap();
                } else if (props.parent === 'indirect') {
                    await appendIndirectItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        indirectItemId: props.itemId,
                        body: {
                            addOption: option === "Add sub-elements" ? 'sub-element' : 'element',
                            id: searchValue.id,
                            type: 'resource'
                        }
                    }).unwrap();
                }
                props.close();
            }
        } catch (error: any) {
            if (error && ('data' in error)) {
                if (error.data.page) {
                    setPageError(error.data.page);
                }
            }
        }
        finally {
            setSaving(false);
        }

    }, [appendIndirectItemDetail, appendItemDetail, props, searchValue.id, user?.companyId, user?.organizationId]);

    return <Box padding="5px 5px 0px 5px" sx={{ width: props.inHeader ? 505 : 700, height: getContainerHeight() }} >
        {
            colors && <Box>
                <form onSubmit={handleSubmit} noValidate>
                    {pageError && <Box marginBottom="15px"><Alert sx={{ padding: 0 }} severity="error">{pageError}</Alert></Box>}
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" flexDirection="column" marginBottom="5px" flex="1" marginRight="5px">
                            <Autocomplete
                                sx={{ '& .MuiTextField-root': { height: "1.4375em" } }}
                                disableClearable
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                value={searchValue}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.description}
                                filterOptions={(x) => x}
                                size="small"
                                onChange={onChange}
                                onInputChange={onInputChange}
                                options={options}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        sx={{ '& .MuiInputBase-root': { height: 45 } }}
                                        placeholder='Search Resource...' />}
                                renderOption={(props, option, { inputValue }) => {
                                    const matches = match(option.description, inputValue, { insideWords: true });
                                    const parts = parse(option.description, matches);

                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                            />
                        </Box>
                        {!props.inHeader && <><Box display="flex" flexDirection="column" flex="0.5" marginBottom="5px" marginRight="5px">
                            <TextField
                                InputProps={{
                                    type: 'number'
                                }}
                                size="small"
                                id="quantityPerUnit"
                                label={unitSuffix}
                                name="quantityPerUnit"
                                autoComplete="off"
                                placeholder={unitSuffix}
                                disabled={!props.inHeader && !!props.resourceId && searchValue?.unitDescription === 'PCT'}
                                InputLabelProps={{
                                    shrink: !!values.quantityPerUnit, // Shrinks the label if a value exists
                                }}
                                error={touched.quantityPerUnit && Boolean(errors.quantityPerUnit)}
                                onBlur={handleBlur}
                                onChange={onQuantityPerUnitChanged}
                                value={values.quantityPerUnit} />
                        </Box>
                            <Box display="flex" flexDirection="column" flex="0.5" marginBottom="5px" marginRight="5px">
                                <TextField
                                    InputProps={{
                                        type: 'number'
                                    }}
                                    size="small"
                                    id="totalQuantity"
                                    label="Total Quantity"
                                    name="totalQuantity"
                                    autoComplete="off"
                                    disabled={!props.inHeader && !!props.resourceId && searchValue?.unitDescription === 'PCT'}
                                    InputLabelProps={{
                                        shrink: !!values.totalQuantity, // Shrinks the label if a value exists
                                    }}
                                    placeholder='Total Quantity'
                                    error={touched.totalQuantity && Boolean(errors.totalQuantity)}
                                    onBlur={handleBlur}
                                    onChange={onQuantityChanged}
                                    value={values.totalQuantity} />
                            </Box></>}
                        <Box display="flex" flexDirection="column" flex="0.1" marginBottom="5px">
                            <>{props.inHeader ? <SplitButton
                                options={addMenuItems}
                                disablePortal={false}
                                buttonText="Add"
                                onButtonClick={handleClose}
                                onMenuItemClick={handleClose} /> : <LoadingButton
                                    type="submit"
                                    sx={{
                                        width: "30px"
                                    }}
                                    loading={saving}
                                    endIcon={<AddIcon />}
                                    aria-label="save"
                                    color="primary"
                                    loadingPosition="end"
                                    variant="contained">
                                Add
                            </LoadingButton>}</>
                        </Box>
                    </Box>
                </form>
            </Box>
        }
    </Box>
}