import { api } from './api'
import { ErroredImportResourceCategory, ErroredImportResourceSubCategory, ResourceCategory, ResourceSubCategory } from 'Models/resource-category';


export const resourceCategoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getResourceCategories: build.query<Array<ResourceCategory>, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource-category` }),
      providesTags: ['ResourceCategories']
    }),
    getResourceCategoryMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource-category/max-id` }),
      providesTags: ['ResourceCategories']
    }),
    getResourceSubCategoryMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource-subcategory/max-id` }),
      providesTags: ['ResourceCategories']
    }),
    createResourceCategory: build.mutation<ResourceCategory, Partial<{ body: ResourceCategory, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource-category`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['ResourceCategories'] : []),
    }),
    updateResourceCategory: build.mutation<ResourceCategory, Partial<{ body: ResourceCategory, orgId: string, companyId: string, estimateId: string, resourceCategoryId: string }>>({
      query({ body, orgId, companyId, estimateId, resourceCategoryId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource-category/${resourceCategoryId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['ResourceCategories'] : []),
    }),
    importResourceCategory: build.mutation<Array<ErroredImportResourceCategory>, { organizationId: string, companyId: string, estimateId: string, fileData: FormData }>({
      query({ organizationId, companyId, estimateId, fileData }: { organizationId: string, companyId: string, estimateId: string, fileData: FormData }) {
        return {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource-category/import-category`,
          method: 'POST',
          body: fileData,
        };
      }
    }),
    importResourceSubCategory: build.mutation<Array<ErroredImportResourceSubCategory>, { organizationId: string, companyId: string, estimateId: string, fileData: FormData }>({
      query({ organizationId, companyId, estimateId, fileData }: { organizationId: string, companyId: string, estimateId: string, fileData: FormData }) {
        return {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource-subcategory/import-subcategory`,
          method: 'POST',
          body: fileData,
        };
      }
    }),
    exportResourceCategoryImportSample: build.mutation<any, Partial<{ organizationId: string, companyId: string, estimateId: string }>>({
      query({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) {
        return {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource-category/export/import-resource-category-sample`,
          method: "POST",
          responseHandler: async (response: any) => window.open(window.URL.createObjectURL(await response.blob()), '_blank'),
          cache: "no-cache",
        };
      },
    }),
    exportResourceSubCategoryImportSample: build.mutation<any, Partial<{ organizationId: string, companyId: string, estimateId: string }>>({
      query({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) {
        return {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource-subcategory/export/import-resource-subcategory-sample`,
          method: "POST",
          responseHandler: async (response: any) => window.open(window.URL.createObjectURL(await response.blob()), '_blank'),
          cache: "no-cache",
        };
      },
    }),
    createResourceSubCategory: build.mutation<ResourceSubCategory, Partial<{ body: ResourceSubCategory, orgId: string, companyId: string, estimateId: string, resourceCategoryId: string }>>({
      query({ body, orgId, companyId, estimateId, resourceCategoryId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource-category/${resourceCategoryId}/resource-subcategory`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['ResourceCategories'] : []),
    }),
    updateResourceSubCategory: build.mutation<ResourceSubCategory, Partial<{ body: ResourceSubCategory, orgId: string, companyId: string, estimateId: string, resourceCategoryId: string, resourceSubCategoryId: string }>>({
      query({ body, orgId, companyId, estimateId, resourceCategoryId, resourceSubCategoryId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource-category/${resourceCategoryId}/resource-subcategory/${resourceSubCategoryId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['ResourceCategories'] : []),
    }),
    deleteResourceCategory: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, resourceCategoryId: string }>>({
      query({ orgId, companyId, estimateId, resourceCategoryId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource-category/${resourceCategoryId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result) => (result ? ['ResourceCategories'] : []),
    }),
    deleteResourceSubCategory: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, resourceCategoryId: string, resourceSubCategoryId: string }>>({
      query({ orgId, companyId, estimateId, resourceCategoryId, resourceSubCategoryId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource-category/${resourceCategoryId}/resource-subcategory/${resourceSubCategoryId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result) => (result ? ['ResourceCategories'] : []),
    }),
  })
});

export const {
  useExportResourceCategoryImportSampleMutation,
  useExportResourceSubCategoryImportSampleMutation,
  useImportResourceCategoryMutation,
  useImportResourceSubCategoryMutation,
  useCreateResourceCategoryMutation,
  useLazyGetResourceCategoriesQuery,
  useLazyGetResourceCategoryMaxIdQuery,
  useLazyGetResourceSubCategoryMaxIdQuery,
  useCreateResourceSubCategoryMutation,
  useUpdateResourceCategoryMutation,
  useUpdateResourceSubCategoryMutation,
  useGetResourceCategoriesQuery,
  useDeleteResourceCategoryMutation,
  useDeleteResourceSubCategoryMutation
} = resourceCategoryApi