import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import { useCallback, useEffect, useRef, useState } from "react";
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import { useGetUserDetailsQuery } from "State/Services/user";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetEstimateByMasterEstimateIdQuery } from "State/Services/master";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";

export interface IOutOfSyncCellRendererProps extends ICellRendererParams {
    sync: (node: IRowNode) => Promise<void>;
    push: (node: IRowNode) => Promise<void>;
    estimateId: string;
    entity: Entity;
}

export default function OutOfSyncCellRenderer(props: IOutOfSyncCellRendererProps) {
    const { node } = props;
    const [isSaving, setIsSaving] = useState(false);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: masterEstimate } = useGetEstimateByMasterEstimateIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', masterId: estimate?.masterEstimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimate?.masterEstimateId });
    const syncDisabledRef = useRef<boolean>();
    const pushDisabledRef = useRef<boolean>();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole && masterEstimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                syncDisabledRef.current = true;
                pushDisabledRef.current = true;
            } else {
                const hasEstimateEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: props.entity,
                    requiredPermissions: [502]
                });
                const hasSyncAccess = hasEstimatePermission(user?.userId, masterEstimate.EstimateUserRole, {
                    entity: props.entity,
                    requiredPermissions: [501, 502, 503]
                });
                const hasPushAccess = hasEstimatePermission(user?.userId, masterEstimate.EstimateUserRole, {
                    entity: props.entity,
                    requiredPermissions: [502, 503]
                });
                syncDisabledRef.current = !hasEstimateEditAccess || !hasSyncAccess;
                pushDisabledRef.current = !hasEstimateEditAccess || !hasPushAccess;
            }
        } else {
            syncDisabledRef.current = true;
            pushDisabledRef.current = true;
        }
        props.api.refreshCells({
            columns: ["outOfSync"],
            rowNodes: [node],
            force: true
        });
    }, [estimate, estimate?.EstimateUserRole, masterEstimate?.EstimateUserRole, node, props.api, props.entity, user])

    const sync = useCallback(async () => {
        setIsSaving(true);
        await props.sync(node);
        setIsSaving(false);
    }, [node, props])

    const push = useCallback(async () => {
        setIsSaving(true);
        await props.push(node);
        setIsSaving(false);
    }, [node, props])

    return (<>
        {node.data &&
            <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center">
                {node.data?.outOfSync && node.data?.masterReferenceId &&
                    <Tooltip title={syncDisabledRef.current ? 'Action not authorized.' : ''}>
                        <span>
                            <IconButton aria-label="sync" onClick={() => sync()} disabled={syncDisabledRef.current}>
                                {isSaving ? <CircularProgress size="20px" /> : <SyncOutlinedIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>
                }
                {!node.data?.masterReferenceId &&
                    <Tooltip title={!node.data.canPush ? 'One or more nested elements does not exist in the template' : ((pushDisabledRef.current) ? 'Action not authorized.' : '')}>
                        <span>
                            <IconButton aria-label="push" onClick={() => push()} disabled={!node.data.canPush || pushDisabledRef.current}>
                                {isSaving ? <CircularProgress size="20px" /> : <ArrowCircleUpOutlinedIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>

                }
            </Box>}
    </>
    );
};
