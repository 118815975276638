import { Avatar, Box, Button, Link, useTheme } from "@mui/material";
import { ICellRendererParams, IRowNode } from "ag-grid-enterprise";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { ItemDetailView } from "Models/item";
import { PanelState } from "Models/panel";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";

export interface ItemDetailDescriptionCellRendererProps extends ICellRendererParams<ItemDetailView> {
    setSelectedActivityId: (id?: string) => void;
    setSelectedResourceId: (id?: string) => void;
    setSelectedStandardItemId: (id?: string) => void;
    setPanelState: (state: PanelState) => void;
    setValue: (val: string) => void;
    onDuplicate: (node: IRowNode<ItemDetailView>) => Promise<void>;
    estimateId: string;
}

export default function ItemDetailDescriptionCellRenderer(props: ItemDetailDescriptionCellRendererProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [isHovered, setIsHovered] = useState(false);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const editDisabledRef = useRef<boolean>();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
            } else {
                const hasItemEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [502]
                });
                const hasResourceEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasItemEditAccess || !hasResourceEditAccess;
            }
        } else {
            editDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const showFullSidepanel = useCallback((position: "hidden" | "half" | "full") => {
        switch (position) {
            case "hidden":
                props.api.setColumnsVisible(["unit", "quantityPerUnit", "quantity", "factor", "rate", "amount", "actions"], true);
                break;
            case "half":
                props.api.setColumnsVisible(["unit", "quantityPerUnit", "quantity", "factor", "rate", "amount", "actions"], false);
                break;
            default:
                break;
        }
        props.setPanelState({ position: { state: position } });
    }, [props])

    const onDuplicateClick = useCallback(async () => {
        await props.onDuplicate(props.node);
    }, [props]);

    return <Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} display="flex" flexDirection="row" alignContent="center" height="100%" alignItems="center">
        {props.data?.type === "item" && <Avatar sx={{ bgcolor: colors.purpleAccent[500], marginRight: "5px", width: "22px", height: "15px", fontSize: "smaller" }} variant="rounded">I</Avatar>}
        {props.data?.type === "activity" && <Avatar sx={{ bgcolor: colors.primary[300], marginRight: "5px", width: "22px", height: "15px", fontSize: "smaller" }} variant="rounded">{props.data.isFromSubcontractor ? 'S' : ''}A</Avatar>}
        {props.data?.type === "resource" && <Avatar sx={{ bgcolor: colors.blueAccent[500], marginRight: "5px", width: "22px", height: "15px", fontSize: "smaller" }} variant="rounded">{props.data.isFromSubcontractor ? 'S' : ''}R</Avatar>}
        {props.data?.type === "subcontractor" && <Avatar sx={{ bgcolor: colors.blueAccent[500], marginRight: "5px", width: "22px", height: "15px", fontSize: "smaller" }} variant="rounded">S</Avatar>}
        {props.data?.type !== "subcontractor" && <Link
            className="Ignore-Click"
            component="button"
            variant="body2"
            onClick={() => {
                showFullSidepanel("half");
                switch (props.data?.type) {
                    case "activity":
                        props.setValue("Activities");
                        props.setSelectedActivityId(props.data?.activityId);
                        break;
                    case "resource":
                        props.setValue("Resources");
                        props.setSelectedResourceId(props.data?.resourceId);
                        break;
                    case "item":
                        props.setValue("Items");
                        props.setSelectedStandardItemId(props.data?.standardItemId);
                        break;
                    default:
                        break;
                }
            }}
        >
            {props.value}
        </Link>}
        {isHovered && props.data?.type==='activity' && !editDisabledRef.current &&
            <Button
                onClick={onDuplicateClick}
                className="Ignore-Click"
                variant="outlined"
                size="small"
                sx={{
                    minWidth: "auto",
                    width: "auto",
                    padding: "1px",
                    marginLeft: "5px",
                    fontSize: "10px",
                }}>Duplicate</Button>}
        {props.data?.type === "subcontractor" && <span>{props.value}</span>}</Box>
}