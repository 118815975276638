import { Autocomplete, Box, Link, TextField, useTheme } from "@mui/material";
import { ColDef, IServerSideGetRowsParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { ItemFilters, ItemFilterSettings } from "Components/Items/ItemFilters";
import { rounder } from "Helpers/rounder";
import { Entity, Estimate } from "Models/estimate";
import { EstimateItemView } from "Models/item";
import { PanelState } from "Models/panel";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetAllEstimatesForCompanyQuery, useGetEstimateQuery, useGetPagedItemsForEstimateMutation } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { AdvancedFilterModel, GetRowIdParams, GridReadyEvent, ICellRendererParams, IServerSideDatasource } from "ag-grid-community";
import {
    Link as RouterLink,
} from 'react-router-dom';
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
export interface ItemListProps {
    estimateId: string | undefined;
    onDrop?: (item: EstimateItemView) => void;
    panelState: PanelState;
}

export default function ItemList(props: ItemListProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimates } = useGetAllEstimatesForCompanyQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [selectedEstimate, setSelectedEstimate] = useState<Estimate>();
    const gridRef = useRef<AgGridReact<EstimateItemView>>(null);
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 67px)', width: '100%' }), []);
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [getPagedItems] = useGetPagedItemsForEstimateMutation();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
            }
        } else {
            setHasViewAccess(true);
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const onEstimateChanged = useCallback((event: React.SyntheticEvent, newValue: Estimate | null) => {
        if (newValue?.name) {
            setSelectedEstimate(newValue);
            if (gridRef.current) {
                gridRef.current!.api.onFilterChanged();
            }
        } else {
            setSelectedEstimate(undefined);
        }
    }, [])

    const onFilterChanged = useCallback((filterSettings: ItemFilterSettings) => {
        let filters: AdvancedFilterModel = {} as any;
        if (filterSettings.category1) {
            const catFilter = {
                category1: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category1.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.category2) {
            const catFilter = {
                category2: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category2.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.category3) {
            const catFilter = {
                category3: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category3.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.category4) {
            const catFilter = {
                category4: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category4.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.description) {
            const descriptionFilter = {
                description: {
                    filterType: 'text',
                    type: 'contains',
                    filter: filterSettings.description
                }
            };
            filters = { ...filters, ...descriptionFilter };
        }
        gridRef.current!.api.setFilterModel(filters);
        gridRef.current!.api.onFilterChanged();
    }, [])

    const getBaseDefs = useCallback((): Array<ColDef<EstimateItemView>> => {
        return [
            {
                field: 'id',
                hide: true,
                filter: 'agTextColumnFilter',
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                headerName: 'ID',
                hide: false,
                filter: 'agTextColumnFilter',
                sort: 'asc',
                dndSource: (props.onDrop ? true : false),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 95,
                autoHeight: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            },
            {
                field: 'description',
                headerName: 'Description',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                filter: 'agTextColumnFilter',
                cellRenderer: (params: ICellRendererParams<EstimateItemView>) => {
                    return <Link
                        to={`/organization/${user?.organizationId}/company/${user?.companyId}/estimate/${selectedEstimate?.id}/item/${params.data?.id}`}
                        sx={{ textAlign: 'left' }}
                        component={RouterLink}
                        target="_blank"
                        className="Ignore-Click"
                        variant="body2">{params.data?.description}</Link>
                },
                flex: 1,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                width: 125,
                aggFunc: 'sum',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                width: 175,
                sortable: false,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
        ];
    }, [colors?.gray, estimate?.CompanyCurrency?.Currency?.minorUnit, props.onDrop, selectedEstimate?.id, settings?.quantityDecimals, user?.companyId, user?.organizationId])

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(getBaseDefs());
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    useEffect(() => {
        let colDefs = new Array<ColDef>();
        const baseDefs = getBaseDefs();
        switch (estimate?.noOfLevels.toString()) {
            case "0":
                colDefs = [
                    ...baseDefs
                ];
                break;
            case "1":
                baseDefs.splice(3, 0,
                    {
                        field: 'category1',
                        hide: true,
                        filter: 'agTextColumnFilter',
                    },
                );
                colDefs = [
                    ...baseDefs,
                ];
                break;
            case "2":
                baseDefs.splice(3, 0,
                    {
                        field: 'category1',
                        hide: true,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'category2',
                        hide: true,
                        filter: 'agTextColumnFilter',
                    },
                );
                colDefs = [
                    ...baseDefs
                ];
                break;
            case "3":
                baseDefs.splice(3, 0,
                    {
                        field: 'category1',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category2',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category3',
                        hide: true,
                        filter: true,
                    },
                    );
                colDefs = [
                    ...baseDefs
                ];
                break;
            case "4":
                baseDefs.splice(3, 0,
                    {
                        field: 'category1',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category2',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category3',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category4',
                        hide: true,
                        filter: true,
                    },
                    );
                colDefs = [
                    ...baseDefs
                ];
                break;
            default:
                break;
        }
        setColumnDefs(colDefs);
    }, [colors?.gray, estimate?.noOfLevels, getBaseDefs, props.estimateId])

    const getItems = useCallback(async (params: IServerSideGetRowsParams<EstimateItemView>) => {
        try {
            if (user) {
                const response = await getPagedItems({
                    companyId: user.companyId,
                    estimateId: selectedEstimate?.id ?? '',
                    orgId: user.organizationId,
                    body: {
                        ...params.request
                    }
                }).unwrap();
                if (params?.request && selectedEstimate) {
                    const rows = new Array<EstimateItemView>();
                    response.results.forEach((item) => {
                        rows.push({
                            type: "estimate-item",
                            estimateId: selectedEstimate.id,
                            category1: item.category1,
                            category2: item.category2,
                            category3: item.category3,
                            category4: item.category4,
                            category1Id: item.category1Id,
                            category2Id: item.category2Id,
                            category3Id: item.category3Id,
                            category4Id: item.category4Id,
                            category1DisplayId: item.category1DisplayId,
                            category2DisplayId: item.category2DisplayId,
                            category3DisplayId: item.category3DisplayId,
                            category4DisplayId: item.category4DisplayId,
                            description: item.description,
                            displayId: item.displayId,
                            quantity: item.quantity,
                            id: item.id,
                            rate: item.rate,
                            unit: {
                                unitDescription: item.customUnit,
                                unitId: item.unitId
                            },
                        });
                    });

                    params.success({
                        rowData: rows,
                        rowCount: response.totalCount
                    });
                }
            }
        } catch (error) { }

    }, [getPagedItems, selectedEstimate, user]);
    const getItemsRef = useRef<(params: IServerSideGetRowsParams<EstimateItemView>) => void>(() => (null));
    getItemsRef.current = getItems;

    const createServerSideDatasource = useCallback((): IServerSideDatasource => {
        return {
            getRows: (params) => getItemsRef.current(params),
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        const datasource = createServerSideDatasource();
        params.api!.setGridOption("serverSideDatasource", datasource);
    }, [createServerSideDatasource]);

    const getRowId = useCallback(function (params: GetRowIdParams<EstimateItemView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const gridDragOver = useCallback((event: any) => {
        const dragSupported = event.dataTransfer.types.length;
        if (dragSupported) {
            event.dataTransfer.dropEffect = 'copy';
            event.preventDefault();
        }
    }, []);

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }
    

    return <Box height="100%" width="100%">
        <Box display="flex" padding="20px 10px 10px 10px">
            <Box flex="0.5">
                <Autocomplete
                    size="small"
                    fullWidth
                    value={selectedEstimate ?? null}
                    options={estimates?.filter(e => e.id !== props.estimateId) ?? []}
                    onChange={onEstimateChanged}
                    getOptionLabel={(option) => option.name ?? ''}
                    sx={{ marginRight: "10px" }}
                    renderInput={(params) => <TextField label="Estimates" {...params} />}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.name ?? '', inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(option.name ?? '', matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                />
            </Box>
            {selectedEstimate && <Box flex="0.5">
                <ItemFilters estimateId={selectedEstimate.id} onFilterChanged={onFilterChanged} hideSearch={true} />
            </Box>}
        </Box>
        {!selectedEstimate?.id && <Box display="flex" alignItems="center" sx={{ background: colors.gray[1000] }} justifyContent="center" width="100%" height="calc(100% - 195px)">Please select an estimate.</Box>}
        {selectedEstimate?.id && <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle} onDragOver={gridDragOver}>
            <AgGridReact<EstimateItemView>
                ref={gridRef}
                animateRows={false}
                suppressScrollOnNewData={true}
                rowDragManaged={true}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                rowModelType={"serverSide"}
                getRowId={getRowId}
                pagination={true}
                paginationPageSize={15}
                defaultColDef={defaultColDef}
                cacheBlockSize={15}
                paginationPageSizeSelector={[15, 25, 50, 100]}
            />
        </Box>}
    </Box>
}