import { EstimateCategory } from 'Models/estimate';
import { api } from './api'

export const categoryApi = api.injectEndpoints({
    endpoints: (build) => ({
        createCategory: build.mutation<EstimateCategory, Partial<{ body: EstimateCategory, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/category`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['EstimateCategories', 'Analysis', 'Items'],
        }),
        getCategoryMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string, level: number }>({
            query: ({ organizationId, companyId, estimateId, level }: { organizationId: string, companyId: string, estimateId: string, level: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/category/level/${level}/max-id` }),
            providesTags: ['EstimateCategories']
        }),
        updateCategory: build.mutation<EstimateCategory, Partial<{ body: EstimateCategory, orgId: string, companyId: string, estimateId: string, categoryId: number }>>({
            query({ body, orgId, companyId, estimateId, categoryId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/category/${categoryId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['EstimateCategories', 'Analysis', 'Pricing'],
        }),
        deleteCategory: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, categoryId: number, deleteEmptyItems: boolean }>>({
            query({ orgId, companyId, estimateId, categoryId, deleteEmptyItems }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/category/${categoryId}?deleteEmptyItems=${(deleteEmptyItems) ? true : false}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['EstimateCategories', 'Analysis', 'Items'],
        }),
        getNestedCategories: build.query<Array<EstimateCategory>, { organizationId: string, companyId: string, estimateId: string, categoryId: number, currentLevel: number }>({
            query: ({ organizationId, companyId, estimateId, categoryId, currentLevel }: { organizationId: string, companyId: string, estimateId: string, categoryId: number, currentLevel: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/category/${categoryId}/level/${currentLevel}` }),
            providesTags: ['EstimateCategories']
        }),
        getCategories: build.query<Array<EstimateCategory>, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/category` }),
            providesTags: ['EstimateCategories']
        }),
    })
});

export const {
    endpoints: { createCategory, updateCategory, getCategories },
} = categoryApi

export const {
    useLazyGetCategoryMaxIdQuery,
    useLazyGetNestedCategoriesQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useGetCategoriesQuery,
    useLazyGetCategoriesQuery,
    useDeleteCategoryMutation
} = categoryApi