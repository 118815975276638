import { Box, FormControl, OutlinedInput, InputAdornment, List, ListItem, useTheme } from "@mui/material";
import { HtmlTooltip } from "Components/HtmlToolltip";
import { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from "react";
import { tokens } from "theme";
import WarningIcon from '@mui/icons-material/Warning';
import type { CustomCellEditorProps } from "ag-grid-react";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { QueryActionCreatorResult, QueryDefinition } from "@reduxjs/toolkit/query";
import { BaseEditCellRenderer } from "Models/grid";

export interface VendorIdEditCellRendererProps extends CustomCellEditorProps<any, string> {
    field: string;
    estimateId: string;
    getMaxId: (request: { companyId: string, organizationId: string, estimateId: string }) => QueryActionCreatorResult<QueryDefinition<void, any, string, any>>;
    maxIdAdditionalProps?: any;
}

export default forwardRef(({ node, value, eventKey, onValueChange, field, cellStartedEdit, getMaxId, estimateId, column, maxIdAdditionalProps }: VendorIdEditCellRendererProps, ref) => {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const initialValue = useRef<string | null | undefined>(value);
    const [error, setError] = useState<string | undefined>(undefined);
    const refInput = useRef<HTMLInputElement>(null);
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: user?.companyId ?? '', organizationId: user?.organizationId ?? '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: estimate } = useGetEstimateQuery({ companyId: user?.companyId ?? '', organizationId: user?.organizationId ?? '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });

    useEffect(() => {
        let startValue;
        let highlightAllOnFocus = true;

        if (eventKey === "Backspace" || eventKey === "Delete") {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (eventKey && eventKey.length === 1) {
            // if a letter was pressed, we start with the letter
            startValue = eventKey;
            highlightAllOnFocus = false;
        } else {
            // otherwise we start with the current value
            startValue = initialValue.current;
            if (eventKey === "F2") {
                highlightAllOnFocus = false;
            }
        }
        if (startValue == null) {
            startValue = "";
        }
        if (!startValue) {
            let prefix = estimate?.isMaster ? `${settings?.masterIdPrefix}-` : "";
            getMaxId({
                companyId: user?.companyId ?? "",
                estimateId: estimateId,
                organizationId: user?.organizationId ?? "",
                ...(maxIdAdditionalProps && {...maxIdAdditionalProps})
            }).unwrap().then((maxId: number) => {
                onValueChange(maxId ? `${prefix}${(maxId + 1).toString()}` : `${prefix}1`);
            });
        }

        onValueChange(startValue);

        if (cellStartedEdit) {
            refInput.current?.focus();
            if (highlightAllOnFocus) {
                refInput.current?.select();
            }
        }
    }, [cellStartedEdit, estimate?.isMaster, estimateId, eventKey, getMaxId, maxIdAdditionalProps, onValueChange, settings?.masterIdPrefix, user?.companyId, user?.organizationId]);

    useImperativeHandle<any, BaseEditCellRenderer>(ref, () => {
        return {
            setError(message: string) {
                if (message) {
                    setError(message);
                }
            },
            setFocusOnAdd() {
                if (refInput.current) {
                    refInput.current.focus();
                }
            },
            getValue() {
                return value;
            },
        };
    });

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        onValueChange(event.target.value);
        if (!event.target.value) {
            setError('ID is required');
        }
        else {
            setError(undefined);
        }
    }, [onValueChange])

    return (
        <Box sx={{ width: '100%', height: "100% !important" }}>
            <FormControl sx={{ height: 'inherit', width: "100%" }}>
                <OutlinedInput
                    sx={{ height: "inherit", backgroundColor: "white" }}
                    className="ag-input-field-input ag-text-field-input"
                    placeholder={field}
                    onChange={onChange}
                    endAdornment={error &&
                        <InputAdornment position="end">
                            <HtmlTooltip color={colors?.redAccent[500]}
                                title={
                                    <List sx={{ listStyleType: 'disc', pl: 1 }}>
                                        <ListItem
                                            sx={{
                                                padding: 0,
                                                textAlign: "center",
                                                listStyleType: "disc",
                                                display: "list-item",
                                            }}
                                        >{error}</ListItem>
                                    </List>
                                }>
                                <WarningIcon sx={{ color: `${colors?.redAccent[500]}`, cursor: "pointer" }} />
                            </HtmlTooltip>
                        </InputAdornment>
                    }
                    value={value || ""}
                    inputRef={refInput}
                />
            </FormControl>
        </Box>
    );
});
