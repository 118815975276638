import { Autocomplete, Box, FormControl, IconButton, Link, Modal, TextField, Typography, useTheme } from "@mui/material";
import SplitButton, { MenuOption } from "Components/SplitButton";
import { AddOptions } from "Models/button-options";
import { FilterField } from "Models/resource";
import { useAppendResourceMutation, useGetResourcesByFilterQuery, useLazyGetResourcesPagedQuery } from "State/Services/resource";
import { useGetUserDetailsQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { tokens } from "theme";
import CloseIcon from '@mui/icons-material/Close';
import AffectedResources, { AffectedResource } from "./AffectedResources";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    height: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 2,
};
export interface ResourceAppendProps {
    estimateId: string | undefined;
    resourceId: string | undefined;
    disabled: boolean;
}

export interface FilteredResource {
    id?: string;
    description: string;
    displayId?: string;
    disableSubElements?: boolean;
}

export function ResourceAppend(props: ResourceAppendProps) {
    const { data: user } = useGetUserDetailsQuery();
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [searchValue, setSearchValue] = useState<FilteredResource>({ id: '', description: '', disableSubElements: false });
    const [options, setOptions] = useState<Array<FilteredResource>>([]);
    const [open, setOpen] = useState<boolean>(false);
    const { data: filteredResources } = useGetResourcesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.description.length > 1) });
    const [getResourcesPaged] = useLazyGetResourcesPagedQuery();
    const inputRef = useRef<any>();
    const [addMenuItems, setAddMenuItems] = useState<Array<MenuOption>>([{ option: AddOptions.AddElement, disabled: false }, { option: AddOptions.AddSubElements, disabled: false }]);
    const [appendResource] = useAppendResourceMutation();
    const [error, setError] = useState('');
    const [customErrors, setCustomErrors] = useState<Array<AffectedResource>>([]);
    const [openAffectedResources, setOpenAffectedResources] = useState(false);

    useEffect(() => {
        if (filteredResources && filteredResources.length > 0) {
            const filtered = filteredResources.filter((resource) => (resource.resourceId !== props.resourceId));
            setOptions(filtered.map((res) => ({
                description: `${res.displayId} - ${res.description}`,
                id: res.resourceId,
                displayId: res.displayId,
                disableSubElements: !res.isComposite
            })));
        }
    }, [filteredResources, props.resourceId])

    const setDefaultResources = useCallback(async () => {
        const response = await getResourcesPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true);
        if ('data' in response) {
            const results = response.data;
            if (results && results.length > 0) {
                const filtered = results.filter((resource) => (resource.resourceId !== props.resourceId));
                setOptions(filtered.map((res) => ({
                    description: `${res.displayId} - ${res.description}`,
                    id: res.resourceId,
                    displayId: res.displayId,
                    disableSubElements: !res.isComposite
                })));
            }
        }
    }, [getResourcesPaged, props, user])

    useEffect(() => {
        setDefaultResources();
    }, [setDefaultResources])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        setError('');
        setCustomErrors([]);
        if (newValue) {
            setSearchValue({ description: newValue });
        } else {
            setSearchValue({ description: '' });
            setOpen(false);
            await setDefaultResources();
        }
    }, [setDefaultResources])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: FilteredResource) => {
        setError('');
        setCustomErrors([]);
        if (newValue) {
            setSearchValue(newValue);
            const menuOption = addMenuItems.find((menuItem) => (menuItem.option === AddOptions.AddSubElements));
            if (menuOption) {
                menuOption.disabled = !!newValue.disableSubElements;
                setAddMenuItems([...addMenuItems]);
            }
        } else {
            setSearchValue({ id: '', description: '', disableSubElements: false });
        }
        setOpen(false);
    }, [addMenuItems]);

    const handleClose = useCallback(async (option?: string) => {
        try {
            if (user) {
                await appendResource({
                    orgId: user.organizationId,
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    resourceId: props.resourceId,
                    body: {
                        addOption: option === "Add sub-elements" ? 'sub-element' : 'element',
                        id: searchValue.id
                    }
                }).unwrap();
                setSearchValue({ id: '', description: '' });
            }
        } catch (error: any) {
            if (error && error.data && error.data.description) {
                setError(error.data.description);
                if (error.data.customErrors && error.data.customErrors.length > 0) {
                    setCustomErrors(error.data.customErrors);
                } else {
                    setCustomErrors([]);
                }
            }
        }
    }, [appendResource, props.estimateId, props.resourceId, searchValue.id, user]);

    const showResources = useCallback(() => {
        setOpenAffectedResources(true);
    }, [])

    const handleCloseAffectedResourcesModal = useCallback(() => {
        setOpenAffectedResources(false);
    }, [])

    return <>
        <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
                <FormControl sx={{ width: 250, height: 45, marginRight: "10px" }} size="small">
                    <Autocomplete
                        fullWidth
                        sx={{ height: "100%" }}
                        disableClearable
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        value={searchValue}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.description}
                        filterOptions={(x) => x}
                        size="small"
                        disabled={props.disabled}
                        onChange={onChange}
                        onInputChange={onInputChange}
                        options={options}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                sx={{ '& .MuiInputBase-root': { height: 45 } }}
                                inputRef={inputRef}
                                placeholder='Search...' />}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.description, inputValue, { insideWords: true });
                            const parts = parse(option.description, matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                </FormControl>
                <FormControl>
                    <SplitButton disabled={!searchValue.id} buttonHeight="35px" options={addMenuItems} buttonText="Add" onButtonClick={handleClose} onMenuItemClick={handleClose} />
                </FormControl>
            </Box>
            {error && <Box color={colors.redAccent[400]}>{error}{(customErrors && customErrors.length > 0) ? <>Click <Link href="#" underline="hover" onClick={showResources}>here</Link> to view the list of affected resources.</> : ''}</Box>}
        </Box>
        <Modal
            open={openAffectedResources}
            onClose={handleCloseAffectedResourcesModal}
            aria-labelledby="categories-modal"
            aria-describedby="categories-modal-description"
        >
            <Box sx={{ ...modalStyle }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Affected Resources</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="delete" onClick={handleCloseAffectedResourcesModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <AffectedResources resources={customErrors} />
            </Box>
        </Modal></>
}