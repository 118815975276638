import { Box, FormControl, OutlinedInput, InputAdornment, List, ListItem, useTheme } from "@mui/material";
import { HtmlTooltip } from "Components/HtmlToolltip";
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { tokens } from "theme";
import WarningIcon from '@mui/icons-material/Warning';
import type { CustomCellEditorProps } from "ag-grid-react";
import { BaseEditCellRenderer } from "Models/grid";

export interface GenericTextEditCellRendererProps extends CustomCellEditorProps {
    field: string;
}

export default forwardRef(({ value, eventKey, onValueChange, field, cellStartedEdit }: GenericTextEditCellRendererProps, ref) => {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const initialValue = useRef<number | null>(value);
    const [error, setError] = useState<string | undefined>(undefined);
    const refInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        let startValue;
        let highlightAllOnFocus = true;

        if (eventKey === "Backspace" || eventKey === "Delete") {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (eventKey && eventKey.length === 1) {
            // if a letter was pressed, we start with the letter
            startValue = eventKey;
            highlightAllOnFocus = false;
        } else {
            // otherwise we start with the current value
            startValue = initialValue.current;
            if (eventKey === "F2") {
                highlightAllOnFocus = false;
            }
        }
        if (startValue == null) {
            startValue = "";
        }

        onValueChange(startValue);

        if (cellStartedEdit) {
            refInput.current?.focus();
            if (highlightAllOnFocus) {
                refInput.current?.select();
            }
        }
    }, [cellStartedEdit, eventKey, onValueChange]);

    useImperativeHandle<any, BaseEditCellRenderer>(ref, () => {
        return {
            setError(message: string) {
                if (message) {
                    setError(message);
                }
            },
            setFocusOnAdd() {
                if (refInput.current) {
                    refInput.current.focus();
                }
            },
            getValue() {
                return value;
            },
        };
    });

    return (
        <Box sx={{ width: "100%", height: "100% !important" }}>
            <FormControl sx={{ height: 'inherit', width: "100%" }}>
                <OutlinedInput
                    sx={{ height: "inherit", backgroundColor: "white" }}
                    className="ag-input-field-input ag-text-field-input"
                    placeholder={field}
                    onChange={(event) => {
                        onValueChange(event.target.value);
                    }}
                    endAdornment={error &&
                        <InputAdornment position="end">
                            <HtmlTooltip color={colors?.redAccent[500]}
                                title={
                                    <List sx={{ listStyleType: 'disc', pl: 1 }}>
                                        <ListItem
                                            sx={{
                                                padding: 0,
                                                textAlign: "center",
                                                listStyleType: "disc",
                                                display: "list-item",
                                            }}
                                        >{error}</ListItem>
                                    </List>
                                }>
                                <WarningIcon sx={{ color: `${colors?.redAccent[500]}`, cursor: "pointer" }} />
                            </HtmlTooltip>
                        </InputAdornment>
                    }
                    value={value || ""}
                    inputRef={refInput}
                />
            </FormControl>
        </Box>
    );
});
