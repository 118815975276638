import { Alert, AlertTitle, Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useMemo, useState } from "react";
import { tokens } from "theme";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useImportResourceSubCategoryMutation } from "State/Services/resource-category";
import { ErroredImportResourceSubCategory } from "Models/resource-category";


export interface ImportResourcesSubCategoryModalProps {
    estimateId?: string;
    close: () => void;
}

export default function ImportResourceSubCategoryModal(props: ImportResourcesSubCategoryModalProps) {
    const [colors, setColors] = useState<any>();
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 48px)', width: '100%' }), []);
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [importResourceSubCategory] = useImportResourceSubCategoryMutation();
    const [isUploading, setIsUploading] = useState(false);
    const [pageError, setPageError] = useState<string>();
    const [erroredImportResourceSubCategories, setErroredImportResourceSubCategories] = useState<Array<ErroredImportResourceSubCategory>>([]);
    const {
        open,
        acceptedFiles, getInputProps } = useDropzone({
            // Disable click and keydown behavior
            noClick: true,
            noKeyboard: true,
            accept: {
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
            }
        });
    const [columnDefs] = useState<ColDef<ErroredImportResourceSubCategory>[]>([
        {
            field: 'displayId',
            resizable: true,
            headerName: 'ID',
            hide: false,
            sort: 'asc',
            width: 95,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
        },
        {
            field: 'description',
            flex: 0.4,
            resizable: true,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'resourceCategoryId',
            headerName: 'Category ID',
            flex: 0.4,
            resizable: true,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'reason',
            flex: 1,
            autoHeight: true,
            resizable: true,
            wrapText: true,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}`, color: 'red' }
        },
    ]);

    useEffect(() => {
        console.log("useeffect fired!", props);
        if (acceptedFiles && acceptedFiles.length > 0) {
            const formData = new FormData();
            if (acceptedFiles) {
                formData.append("file", acceptedFiles[0]);
                if (user && user.companyId && props.estimateId) {
                    setIsUploading(true);
                    importResourceSubCategory({
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        fileData: formData,
                        organizationId: user?.organizationId
                    }).unwrap().then((erroredImportResourceSubCategory) => {
                        if (erroredImportResourceSubCategory && erroredImportResourceSubCategory.length > 0) {
                            setErroredImportResourceSubCategories(erroredImportResourceSubCategory);
                        } else {
                            props.close();
                        }
                    }).catch((error) => {
                        console.log(error);
                        if ('data' in error) {
                            setPageError(error.data.message);
                        }
                    }).finally(() => {
                        setIsUploading(false);
                    });
                }
            }
        }
    }, [acceptedFiles, importResourceSubCategory, props, user])

    return <>
        {pageError && <Box width="100%" padding="5px">
            <Alert severity="error">
                <AlertTitle>Error:</AlertTitle>
                {pageError && <Box>{pageError}</Box>}
            </Alert>
        </Box>}
        <Box height="100%" display="flex" alignItems="center" justifyContent="center" margin="25px 50px">
            {isUploading && <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                <CircularProgress color="primary" />
            </Box>
            }
            {!isUploading && erroredImportResourceSubCategories.length === 0 && <Box display="flex" flexDirection="column" marginBottom="10px" alignItems="center" justifyContent="center">
                <Typography variant="h5" sx={{ color: `${colors?.primary[300]}` }}>Select a file to import</Typography>
                <input {...getInputProps()} />
                <Button type="button" color="primary" variant='contained' onClick={open} startIcon={<CloudUploadIcon />}>
                    Browse
                </Button>
            </Box>}
            {erroredImportResourceSubCategories.length > 0 && <Box height="500px" width="80vw" display="flex" flexDirection="column" marginBottom="10px" alignItems="center" justifyContent="center">
                <Box className="ag-theme-alpine ag-theme-bidbow inner-col"
                    style={gridStyle}>
                    <AgGridReact<ErroredImportResourceSubCategory>
                        animateRows={false}
                        columnDefs={columnDefs}
                        rowData={erroredImportResourceSubCategories}
                    />
                </Box>
            </Box>}
        </Box></>
}