import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { IRowNode } from "ag-grid-community";
import { useCallback, useState } from "react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { CustomCellEditorProps } from "ag-grid-react";

export interface IGenericActionsCellRendererProps extends CustomCellEditorProps {
    deleteRecord: (node: IRowNode) => Promise<void>;
    disabled: () => boolean;
    save: (node: IRowNode) => Promise<void>;
    cancel: (node: IRowNode) => void;
    deleteDisabledMessage: string;
}

export default function GenericActionsCellRenderer({ api, cancel, node, deleteRecord, save, disabled, deleteDisabledMessage }: IGenericActionsCellRendererProps) {
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    let editingCells = api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
        return cell.rowIndex === node.rowIndex;
    });

    const deleteRow = async () => {
        try {
            if (isCurrentRowEditing) {
                cancel(node);
            } else {
                setIsDeleting(true);
                await deleteRecord(node);
                setIsDeleting(false);
            }
        } catch (error) {
            setIsDeleting(false);
            console.log(error);
        }
    }

    const saveRec = async () => {
        try {
            setIsSaving(true);
            await save(node);
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
        }
    }

    const shouldDisable = useCallback(() => {
        const canDelete = Object.hasOwn(node.data, 'canDelete') ? node.data?.canDelete : true;
        if (isCurrentRowEditing) {
            return { state: false, message: "Cancel" }
        }

        if (!canDelete || !!node.data.masterReferenceId || (typeof disabled==='function' && disabled())) {
            return { state: true, message: deleteDisabledMessage }
        }

        return { state: false, message: "Delete" }

    }, [deleteDisabledMessage, disabled, isCurrentRowEditing, node.data])

    return (<>
        {node.data &&
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                {isCurrentRowEditing && !isSaving &&
                    <IconButton aria-label="save" color="primary" onClick={saveRec}>
                        <SaveIcon />
                    </IconButton>
                }
                {(isSaving || isDeleting) &&
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
                {!isDeleting && (isCurrentRowEditing || !node.isRowPinned()) &&
                    <Tooltip title={shouldDisable().message}>
                        <span>
                            <IconButton aria-label="delete" onClick={deleteRow} disabled={shouldDisable().state}>
                                {isCurrentRowEditing && <CloseIcon />}
                                {!isCurrentRowEditing && <DeleteOutlineIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>
                }
            </Box>}
    </>
    );
};
