import { Box, Typography, useTheme } from "@mui/material";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { EntityNotFound } from "Models/item";
import { useCallback, useEffect, useMemo, useState } from "react";
import { tokens } from "theme";

export interface ItemDetailsNotFoundProps {
    close: () => void;
    entities: Array<EntityNotFound>;
}

export default function ItemDetailsNotFound(props: ItemDetailsNotFoundProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 15px)', width: '100%' }), []);

    const defaultColDef = useMemo<ColDef<EntityNotFound>>(() => {
        return {
            resizable: true
        };
    }, []);

    useEffect(() => {
        console.log(props.entities);
    }, [props.entities])

    const getBaseDefs = useCallback((): Array<ColDef<EntityNotFound>> => {
        return [
            {
                field: 'type',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'description',
                flex: 1,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
        ];
    }, [colors?.gray])

    return <Box padding="0px 15px 15px 15px" height="100%">
        <Box>
            <Typography>The following items are missing from the current estimate.</Typography>
        </Box>
        <Box style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
            <AgGridReact<EntityNotFound>
                defaultColDef={defaultColDef}
                rowData={props.entities}
                columnDefs={getBaseDefs()}
            />
        </Box>
    </Box>
}