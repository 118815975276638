import {
    forwardRef,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Box, FormControl, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { tokens } from 'theme';
import { UserInfoView } from 'Models/user-details';
import { CustomCellEditorProps } from 'ag-grid-react';
export interface UserRoleEditCellRendererParams extends CustomCellEditorProps<UserInfoView, string> {
    estimateId: string;
}

export default forwardRef(({ value, onValueChange }: UserRoleEditCellRendererParams, ref) => {
    const [colors, setColors] = useState<any>();
    const theme = useTheme();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const refInput = useRef<any>(null);
    const [open, setOpen] = useState(false);

    const onChange = (event: SelectChangeEvent<string>) => {
        onValueChange(event.target.value);
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            setFocusOnAdd() {
                if (refInput.current) {
                    refInput.current.focus();
                    handleOpen();
                }
            }
        };
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>{colors && <Box sx={{ width: "100%", height: "100% !important" }}>
            <FormControl variant="standard" sx={{ height: 'inherit', width: "100%" }}>
                <Select
                    labelId="user-role-label"
                    value={value ?? 'Estimator'}
                    open={open}
                    ref={refInput}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    onChange={onChange}
                    input={<OutlinedInput sx={{ height: "inherit", backgroundColor: "white" }} />}
                >
                    {["Estimator", "Template Manager", "Admin"].map((name) => (
                        <MenuItem key={name} value={name}>
                            <ListItemText primary={name} sx={{ marginLeft: "2px" }} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>}
        </>
    );
});