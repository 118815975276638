import { Alert, Autocomplete, Backdrop, Box, Breadcrumbs, Button, Chip, CircularProgress, Drawer, Link, TextField, Typography, useTheme } from '@mui/material';
import { SupplierPackageInfoView, SupplierPackageResource, SupplierPackageResourceDetailsView, SupplierPackage } from 'Models/package';
import { SupplierPackageVendor } from 'Models/supplier';
import { Tag, Vendor } from 'Models/vendor';
import { useGetEstimateQuery } from 'State/Services/estimate';
import { useCreateSupplierPackageMutation, useCreateSupplierPackageVendorMutation, useDeleteSupplierPackageVendorMutation, useDeleteSupplierResourceMutation, useGetSupplierPackageDetailsQuery, useGetSupplierPackageVendorsQuery, useLazyIsPackageNameDuplicateQuery, useUpdateSupplierPackageMutation } from 'State/Services/supplier';
import { useGetTagsQuery } from 'State/Services/tag';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { useGetVendorsQuery } from 'State/Services/vendor';
import { ColDef, ColGroupDef, Column, GetRowIdParams, IRowNode, RowClassParams } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { md5 } from 'js-md5';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { tokens } from 'theme';
import * as yup from 'yup';
import SupplierPackageVendorHeader from './SupplierPackageVendorHeaderRenderer';
import SupplierPackageRateCellRenderer from './SupplierPackageRateCellRenderer';
import { useAppDispatch, useAppSelector } from 'State/hooks';
import { clearSupplierState, selectSupplierState } from 'State/supplierSlice';
import { useFormik } from 'formik';
import { FilteredResource } from 'Models/resource';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SupplierResourceFilter from './SupplierResourceFilter';
import { SupplierAttachments } from './SupplierAttachments';
import SupplierPackageCheckedCellRenderer from './SupplierPackageCheckedCellRenderer';
import SupplierPackageCheckedHeader from './SupplierPackageCheckedHeader';
import SupplierRate from './SupplierRate';
import SupplierPackageDeleteCellRenderer from './SupplierPackageResourceDeleteCellRenderer';
import { hasEstimatePermission } from 'Helpers/estimate-permissions';
import { Entity } from 'Models/estimate';
import { CellClickedEvent, CellEditingStoppedEvent } from 'ag-grid-community';
import { Errors } from 'Models/errors';
import { rounder } from 'Helpers/rounder';

export interface SupplierPackageProps {
    estimateId?: string;
    packageId?: string;
    backToList: () => void;
}

const quoteSupplierPackageValidationSchema = yup.object<SupplierPackageInfoView>({
    id: yup
        .string()
        .optional(),
    name: yup
        .string()
        .required(),
});

export default function SupplierPckg(props: SupplierPackageProps) {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const supplierState = useAppSelector(selectSupplierState);
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const packageIdRef = useRef<string | undefined>(props?.packageId);
    const { data: user } = useGetUserDetailsQuery();
    const { data: packageDetails } = useGetSupplierPackageDetailsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', packageId: packageIdRef.current ?? '' }, { skip: !user?.companyId || !user?.organizationId || !packageIdRef.current });
    const { data: packageVendors } = useGetSupplierPackageVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', packageId: packageIdRef.current ?? '' }, { skip: !user?.companyId || !user?.organizationId || !packageIdRef.current })
    const { data: existingTags } = useGetTagsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: existingVendors } = useGetVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [quoteResources, setQuoteResources] = useState<Array<SupplierPackageResourceDetailsView>>([]);
    const gridRef = useRef<AgGridReact<SupplierPackageResourceDetailsView>>(null);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const [filteredVendors, setFilteredVendors] = useState<Array<Vendor & { filterText: string }>>();
    const [selectedVendor, setSelectedVendor] = useState<Vendor & { filterText: string }>();
    const [quotePackage, setQuotePackage] = useState<SupplierPackageResourceDetailsView>();
    const [createPackage] = useCreateSupplierPackageMutation();
    const [updatePackage] = useUpdateSupplierPackageMutation();
    const [addedVendorHashes, setAddedVendorHashes] = useState<Array<string>>([]);
    const [selectedPackageVendor, setSelectedPackageVendor] = useState<SupplierPackageVendor | undefined>();
    const [openSupplierPanel, setOpenSupplierPanel] = useState(false);
    const [openCalculateRate, setOpenCalculateRate] = useState(false);
    const [selectedResource, setSelectedResource] = useState<SupplierPackageResource>();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [deletePackageVendor] = useDeleteSupplierPackageVendorMutation();
    const confirm = useConfirm();
    const [ready, setReady] = useState(false);
    const [deleteResource] = useDeleteSupplierResourceMutation();
    const deleteDisabledRef = useRef<boolean>();
    const hasAddEditAccessRef = useRef<boolean>();
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<SupplierPackageResourceDetailsView> | undefined, column: string }>();
    const [createSupplierPackageVendor] = useCreateSupplierPackageVendorMutation();
    const [pageError, setPageError] = useState<string | undefined>();
    const [isPackageNameDuplicate] = useLazyIsPackageNameDuplicateQuery();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                hasAddEditAccessRef.current = false;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.SupplierQuotes,
                    requiredPermissions: [502]
                });
                hasAddEditAccessRef.current = hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.SupplierQuotes,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            hasAddEditAccessRef.current = false;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column,
            });
        }
    }, [currentEditing])

    const breadcrumbs = [
        <Link
            key="1"
            onClick={props.backToList}
            underline="hover"
            sx={{ cursor: "pointer" }}
            color="primary"
        >
            Supplier Quotes
        </Link>,
        <Typography key="2" color="text.primary">
            {!packageIdRef.current ? 'New' : packageDetails?.name}
        </Typography>,
    ];

    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const quotePackageRef = useRef<any>();
    quotePackageRef.current = quotePackage;
    const packageVendorsRef = useRef<any>();
    packageVendorsRef.current = packageVendors;

    useEffect(() => {
        return () => {
            if (ready) {
                dispatch(clearSupplierState());
            }
        };
    }, [dispatch, ready]);

    const attach = useCallback((vendorHash: string) => {
        if (packageVendors && packageVendors.length > 0) {
            const packageVendor = packageVendors?.find((ven) => (md5(ven.vendorId) === vendorHash));
            setSelectedPackageVendor(packageVendor);
            setOpenSupplierPanel(true);
        }
    }, [packageVendors])
    const attachRef = useRef<any>();
    attachRef.current = attach;

    const clearCols = useCallback((vendorHash: string) => {
        const currentColDefs = gridRef.current!.api.getColumnDefs();
        const ungrouped = currentColDefs?.filter((col: ColGroupDef | ColDef) => !('groupId' in col));
        const colDefs = currentColDefs?.filter((col: ColGroupDef | ColDef) => 'groupId' in col && col.groupId !== vendorHash);
        if (colDefs) {
            gridRef.current!.api.setGridOption("columnDefs", ungrouped?.concat(colDefs));
        }
        setAddedVendorHashes(addedVendorHashes.filter(v => v !== vendorHash));
    }, [addedVendorHashes])

    const removePackageVendor = useCallback(async (packageVendor: SupplierPackageVendor | null, vendorHash: string, local: boolean) => {
        try {
            setPageError(undefined);
            await confirm({ cancellationText: "No, cancel", confirmationText: "Yes, continue", description: "Are you sure? This will delete all quote details associated with this vendor.?", title: 'Delete confirmation' });
            if (local) {
                clearCols(vendorHash);
            } else {
                if (user) {
                    await deletePackageVendor({
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        orgId: user.organizationId,
                        supplierPackageVendorId: packageVendor?.id,
                        packageId: packageVendor?.supplierPackageId
                    });
                    clearCols(vendorHash);
                }
            }
        } catch (error) {
            setPageError(Errors.generic);
        }

    }, [clearCols, confirm, deletePackageVendor, props.estimateId, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const deletePackageVendorRef = useRef<any>();
    deletePackageVendorRef.current = removePackageVendor;

    const closeCalculateRatePanel = useCallback(() => {
        setSelectedPackageVendor(undefined);
        setSelectedResource(undefined);
        setOpenCalculateRate(false);
    }, [])

    const calculateRate = useCallback((node: IRowNode<SupplierPackageInfoView>, column: Column<SupplierPackageInfoView>) => {
        const hash = column.getColId().split("_")[1];
        if (packageVendors && packageVendors.length > 0) {
            const packageVendor = packageVendors?.find((ven) => (md5(ven.vendorId) === hash));
            setSelectedPackageVendor(packageVendor);
            const resource = packageDetails?.packageResources?.find((pResource) => (pResource.resourceId === node.data?.id && pResource.vendorId === packageVendor?.vendorId));
            if (resource) {
                setSelectedResource(resource);
            }

            setOpenCalculateRate(true);
        }
    }, [packageDetails?.packageResources, packageVendors])
    const calculateRateRef = useRef<any>();
    calculateRateRef.current = calculateRate;

    const getNewColDefs = useCallback((vendorName?: string, vendorIdHash?: string): ColDef | ColGroupDef => {
        return {
            groupId: vendorIdHash,
            headerGroupComponent: SupplierPackageVendorHeader,
            headerGroupComponentParams: {
                vendorName: vendorName,
                attach: (vendorHash: string) => attachRef.current(vendorHash),
                estimateId: props.estimateId,
                vendorHash: vendorIdHash,
                packageVendors: () => packageVendorsRef.current,
                package: () => quotePackageRef.current,
                delete: (packageVendor: SupplierPackageVendor | null, vendorHash: string, local: boolean) => deletePackageVendorRef.current(packageVendor, vendorHash, local),
                packageId: packageIdRef.current,
                disabled: deleteDisabledRef.current
            },
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
            children: [
                {
                    field: `vendor_id_${vendorIdHash}`,
                    hide: true,
                    width: 200,
                    editable: false,
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                },
                {
                    field: `rate_${vendorIdHash}`,
                    headerName: "Quoted Rate",
                    valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                    cellDataType: "number",
                    cellRendererSelector: (params) => {
                        if (!params.node.footer) {
                            return {
                                component: SupplierPackageRateCellRenderer,
                                params: {
                                    calculateRate: (node: IRowNode<SupplierPackageResourceDetailsView>, column: Column<SupplierPackageResourceDetailsView> | undefined) => calculateRateRef.current(node, column),
                                    package: () => quotePackageRef.current
                                }
                            }
                        }
                    },
                    width: 150,
                    editable: () => !!hasAddEditAccessRef.current && !!packageIdRef.current,
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                },
                {
                    field: `totalRate_${vendorIdHash}`,
                    headerName: "Rate",
                    width: 150,
                    valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                    cellDataType: "number",
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: `amount_${vendorIdHash}`,
                    headerName: "Amount",
                    width: 150,
                    valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                    cellDataType: "number",
                    aggFunc: 'sum',
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                },
                {
                    field: `isChecked_${vendorIdHash}`,
                    headerName: "",
                    cellRendererSelector: (params) => {
                        if (!params.node.aggData) {
                            return {
                                component: SupplierPackageCheckedCellRenderer,
                                params: {
                                    onSelect: (node: IRowNode<SupplierPackageResourceDetailsView>, column: Column<SupplierPackageResourceDetailsView>) => onSelectRef.current(node, column),
                                    disabled: () => !hasAddEditAccessRef.current || !packageIdRef.current
                                },
                            };
                        }
                    },
                    headerComponent: SupplierPackageCheckedHeader,
                    headerComponentParams: {
                        onSelectAll: (col: Column<SupplierPackageResourceDetailsView>) => onSelectAllRef.current(col),
                        vendorHash: vendorIdHash,
                        checked: false,
                        disabled: () => !hasAddEditAccessRef.current || !packageIdRef.current,
                    },
                    editable: false,
                    width: 50,
                    cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
                },
            ]
        };
    }, [colors?.gray, estimate?.CompanyCurrency?.Currency?.minorUnit, props.estimateId])

    const attachVendorColumns = useCallback((vendor: { id: string, name: string }, vendors: string[], colDefs: any) => {
        const vendorIdHash = md5(vendor.id);
        if (!vendors.includes(vendorIdHash)) {
            vendors.push(vendorIdHash);
        }
        if (!colDefs?.some((col: any) => (col.children?.some((child: any) => child.field === `vendor_id_${vendorIdHash}`)))) {
            colDefs.push(getNewColDefs(vendor.name, vendorIdHash));
        }
    }, [getNewColDefs])

    const attachDynamicCols = useCallback((packageResource: { vendorId?: string, vendorName?: string, isChecked?: boolean, rate?: number, factor?: number, amount?: number, totalRate?: number }, qt: SupplierPackageResourceDetailsView, colDefs: any, vendors: string[]) => {
        if (packageResource.vendorId) {
            const vendorIdHash = md5(packageResource.vendorId);
            qt[`vendor_id_${vendorIdHash}`] = packageResource.vendorId;
            qt[`rate_${vendorIdHash}`] = packageResource.rate;
            qt[`isChecked_${vendorIdHash}`] = packageResource.isChecked;
            qt[`amount_${vendorIdHash}`] = (packageResource.amount) ? parseFloat(packageResource.amount.toFixed((estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)) : null;
            qt[`totalRate_${vendorIdHash}`] = packageResource.totalRate;
        }
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit])

    useEffect(() => {
        if (supplierState.packageId) {
            packageIdRef.current = supplierState.packageId;
        }
    }, [supplierState])

    useEffect(() => {
        if (existingVendors) {
            const supplierVendors = existingVendors.filter((v) => (v.type.includes("Supplier")));
            if (selectedTags && selectedTags.length > 0) {
                const tagIds = selectedTags.map(tag => tag.id);
                const filteredVendors = supplierVendors.filter(vendor =>
                    vendor?.tags?.some(tag => tagIds.includes(tag.id))
                );
                setFilteredVendors(filteredVendors.map((vendor) => ({ ...vendor, filterText: `${vendor.displayId} - ${vendor.name}` })))
            } else {
                setFilteredVendors(supplierVendors.map((vendor) => ({ ...vendor, filterText: `${vendor.displayId} - ${vendor.name}` })));
            }
        } else {
            setFilteredVendors([]);
        }
    }, [existingVendors, selectedTags])

    const canDelete = useCallback((node: IRowNode<SupplierPackageResourceDetailsView>) => {
        let canDelete = true;
        const item = packageDetails?.packageResources?.find((i) => (i.resourceId === node.data?.id));
        if (item && item.isChecked) {
            canDelete = false;
        }
        return canDelete;
    }, [packageDetails?.packageResources])
    const canDeleteRef = useRef<any>();
    canDeleteRef.current = canDelete;

    const [columnDefs] = useState<any>([
        { field: 'id', hide: true },
        { field: 'displayId', cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }, headerName: "ID", pinned: "left", width: 90 },
        {
            field: 'resourceName',
            headerName: 'Resource',
            valueFormatter: (params: any) => {
                if (params.node?.footer) {
                    return "Total"
                }
                return params.value;
            },
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }, width: 250, pinned: "left"
        },
        {
            field: 'quantity',
            editable: () => !!hasAddEditAccessRef.current && !!packageIdRef.current,
            headerName: 'Quantity',
            width: 90,
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'actions',
            maxWidth: 80,
            headerName: 'Actions',
            menuTabs: [],
            cellStyle: { textAlign: "left", padding: "0px", borderRight: `1px solid ${colors?.gray[800]}` } as any,
            cellRenderer: SupplierPackageDeleteCellRenderer,
            cellRendererParams: {
                delete: (node: IRowNode<SupplierPackageResourceDetailsView>) => deleteSupplierPackageResourceRef.current(node),
                canDelete: (node: IRowNode<SupplierPackageResourceDetailsView>) => { return canDeleteRef.current(node) },
                disabled: () => deleteDisabledRef.current
            }
        },
    ] as ColDef<SupplierPackageResourceDetailsView>[]);

    const { values, handleSubmit, handleBlur, setFieldError, setFieldValue, touched, errors, setErrors } = useFormik<SupplierPackage<SupplierPackageResourceDetailsView>>({
        enableReinitialize: true,
        validateOnMount: true,
        validateOnBlur: true,
        initialValues: (quotePackage) ? {
            id: quotePackage.id ?? '',
            name: quotePackage.name ?? '',
        } : {
            id: '',
            name: '',
        },
        validationSchema: quoteSupplierPackageValidationSchema,
        onSubmit: async (quotePackage) => {
            try {
                let rowData: any[] = [];
                gridRef.current!.api.forEachNode((node) => {
                    rowData.push(node.data);
                });
                if (user) {
                    dispatch(clearSupplierState());
                    setOpenBackdrop(true);
                    const resources = new Array<SupplierPackageResource>();
                    const vendorIds = new Array<{ id: string }>();
                    addedVendorHashes.forEach((vendorHash) => {
                        if (existingVendors && existingVendors.length > 0) {
                            const vendor = existingVendors?.find((v) => (md5(v.id ?? '')) === vendorHash);
                            if (vendor?.id) {
                                vendorIds.push({ id: vendor.id });
                            }
                        }
                    });
                    rowData.forEach((row) => {
                        let resource: SupplierPackageResource = {
                            resourceId: row.id
                        };
                        if (addedVendorHashes.length > 0) {
                            addedVendorHashes.forEach((vendorHash) => {
                                resources.push({
                                    quantity: row.quantity ? parseFloat(row?.quantity.toString() ?? "0") : undefined,
                                    resourceId: row.id,
                                    isChecked: row[`isChecked_${vendorHash}`],
                                    rate: parseFloat(row[`rate_${vendorHash}`]),
                                    vendorId: row[`vendor_id_${vendorHash}`],
                                    totalRate: row[`totalRate_${vendorHash}`],
                                });
                            });
                        } else {
                            resources.push(resource);
                        }
                    });

                    await save(quotePackage, resources, vendorIds);
                    setOpenBackdrop(false);
                }
            } catch (error: any) {
                setOpenBackdrop(false);
                if (error?.data?.message) {
                    setErrors({
                        name: error?.data?.message
                    });
                    return;
                }
                setPageError(Errors.generic);
            }
        },
    });

    const save = useCallback(async (quotePackage: SupplierPackage<SupplierPackageResourceDetailsView>,
        resources: Array<SupplierPackageResource>,
        vendorIds: Array<{ id: string }>) => {
        if (user) {
            if (!quotePackage.id) {
                const createdPackage = await createPackage({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    body: {
                        name: quotePackage.name,
                        packageResources: resources,
                    }
                }).unwrap();
                if (vendorIds.length > 0 && resources.length === 0) {
                    await createSupplierPackageVendor({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        packageId: createdPackage.id,
                        body: {
                            packageResources: [],
                            packageVendors: vendorIds
                        }
                    }).unwrap();
                }
                packageIdRef.current = createdPackage.id;
            } else {
                const updatedPackage = await updatePackage({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    packageId: quotePackage.id,
                    body: {
                        name: quotePackage.name,
                        packageResources: resources,
                    }
                }).unwrap();
                packageIdRef.current = updatedPackage.id;
            }
        }
    }, [createPackage, createSupplierPackageVendor, props.estimateId, updatePackage, user])

    const setupGrid = useCallback(async () => {
        let shouldSave = false;
        setQuotePackage(packageDetails);
        if (gridRef.current!.api) {
            const qResources = new Array<SupplierPackageResourceDetailsView>();
            const colDefs = gridRef.current!.api.getColumnDefs();
            const addedVends = new Array<string>();
            packageDetails?.vendors?.forEach((packageVendor) => {
                attachVendorColumns({
                    id: packageVendor.id,
                    name: packageVendor.name
                }, addedVends, colDefs);
            });
            packageDetails?.packageResources?.forEach((packageResource) => {
                const existing = qResources.find((resource) => (resource.id === packageResource.resourceId));
                let qt: SupplierPackageResourceDetailsView
                if (existing) {
                    qt = existing;
                    attachDynamicCols(packageResource, qt, colDefs, addedVends);
                } else {
                    qt = {
                        id: packageResource.resourceId,
                        resourceName: packageResource.resourceName,
                        displayId: packageResource.resourceDisplayId,
                        quantity: packageResource.quantity,
                        totalRate: packageResource.totalRate,
                    };
                    attachDynamicCols(packageResource, qt, colDefs, addedVends);
                    qResources.push(qt);
                }
            });
            if (supplierState.option !== "none" && supplierState.resources.length > 0) {
                supplierState.resources.forEach((newResource) => {
                    if (newResource?.id && !qResources.some(i => i.id === newResource.id)) {
                        let qt: SupplierPackageResourceDetailsView = {
                            id: newResource.id,
                            displayId: newResource.displayId,
                            quantity: undefined,
                            resourceName: newResource.description,
                        };
                        if (addedVends.length > 0) {
                            addedVends.forEach((vendorIdHash) => {
                                const vendor = existingVendors?.find((v) => (v.id && md5(v.id) === vendorIdHash));
                                if (vendor) {
                                    qt[`vendor_id_${vendorIdHash}`] = vendor.id;
                                    qt[`rate_${vendorIdHash}`] = null;
                                    qt[`isChecked_${vendorIdHash}`] = false;
                                    qt[`amount_${vendorIdHash}`] = null;
                                    qt[`totalRate_${vendorIdHash}`] = null;
                                }
                            });
                        }
                        qResources.push(qt);
                    }
                });
                shouldSave = true;
            }
            setQuoteResources(qResources);
            setAddedVendorHashes(addedVends);
            gridRef.current!.api.setGridOption("columnDefs", colDefs);
            if (shouldSave && ready && packageDetails) {
                const resources = new Array<SupplierPackageResource>();
                qResources.forEach((row) => {
                    let resource: SupplierPackageResource = {
                        resourceId: row.id
                    };
                    if (addedVends.length > 0) {
                        addedVends.forEach((vendorHash) => {
                            resources.push({
                                quantity: row.quantity ? parseFloat(row?.quantity.toString() ?? "0") : undefined,
                                resourceId: row.id,
                                isChecked: row[`isChecked_${vendorHash}`],
                                rate: parseFloat(row[`rate_${vendorHash}`]),
                                vendorId: row[`vendor_id_${vendorHash}`],
                                totalRate: row[`totalRate_${vendorHash}`],
                            });
                        });
                    } else {
                        resources.push(resource);
                    }
                });
                await save(packageDetails, resources, addedVends.map((vendor) => ({ id: vendor })));
            }
            setReady(true);
        }
    }, [attachDynamicCols, attachVendorColumns, existingVendors, packageDetails, ready, save, supplierState.option, supplierState.resources])

    useEffect(() => {
        setupGrid();
    }, [setupGrid])

    const onSelect = useCallback(async (node: IRowNode<SupplierPackageResourceDetailsView>, column: Column<SupplierPackageResourceDetailsView>) => {
        try {
            setOpenBackdrop(true);
            const res = quoteResources.find((qr) => (qr.resourceId === node.data?.resourceId));
            const col = column.getColId();
            if (node.data && res && user) {
                // Uncheck all
                for (const prop in res) {
                    if (prop.startsWith("isChecked_")) {
                        node.data[prop] = false;
                    }
                }
                node.data[col] = true;
                const hash = col.split('isChecked_')[1] ?? '';
                await updatePackage({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    packageId: values.id,
                    body: {
                        name: values.name,
                        packageResources: [{
                            ...node.data,
                            vendorId: node.data[`vendor_id_${hash}`],
                            totalRate: node.data[`totalRate_${hash}`],
                            rate: parseFloat(node.data[`rate_${hash}`]),
                            isChecked: node.data[`isChecked_${hash}`],
                            quantity: node.data.quantity ? parseFloat(node.data?.quantity.toString() ?? "0") : undefined,
                            resourceId: node.data.id,
                        }],
                    }
                }).unwrap();
                gridRef.current!.api.applyTransaction({ update: [node.data] });
                setOpenBackdrop(false);
            }
        } catch (error: any) {
            setOpenBackdrop(false);
            if (error?.data?.message) {
                setErrors({
                    name: error?.data?.message
                });
                return;
            }
            setPageError(Errors.generic);
        }
    }, [props.estimateId, quoteResources, setErrors, updatePackage, user, values.id, values.name])
    const onSelectRef = useRef<any>();
    onSelectRef.current = onSelect;

    const onSelectAll = useCallback(async (column: Column<SupplierPackageResourceDetailsView>) => {
        try {
            setOpenBackdrop(true);
            const colId = column.getColId();
            const vendorHash = colId.split("_")[1];
            if (vendorHash && user) {
                const packageResources = new Array<SupplierPackageResourceDetailsView>();
                gridRef.current!.api.forEachNode((node) => {
                    if (node.data) {
                        for (const prop in node.data) {
                            if (prop.startsWith("isChecked_")) {
                                node.data[prop] = prop === `isChecked_${vendorHash}`;
                            }
                        }
                        packageResources.push(node.data);
                        gridRef.current!.api.applyTransaction({ update: [node.data] });
                    }
                });

                await updatePackage({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    packageId: values.id,
                    body: {
                        name: values.name,
                        packageResources: packageResources.map((packageResource) => ({
                            ...packageResource,
                            vendorId: packageResource[`vendor_id_${vendorHash}`],
                            totalRate: packageResource[`totalRate_${vendorHash}`],
                            rate: parseFloat(packageResource[`rate_${vendorHash}`]),
                            isChecked: packageResource[`isChecked_${vendorHash}`],
                            quantity: packageResource.quantity ? parseFloat(packageResource?.quantity.toString() ?? "0") : undefined,
                            resourceId: packageResource.id,
                        }))
                    }
                }).unwrap();
                const newDefs = gridRef.current!.api.getColumnDefs()?.map((colDef: ColDef | ColGroupDef) => {
                    if ('groupId' in colDef) {
                        colDef.children = colDef.children.map((colChild: ColDef) => {
                            if (colChild.field?.startsWith("isChecked_")) {
                                return {
                                    ...colChild,
                                    headerComponentParams: {
                                        onSelectAll: (col: Column<SupplierPackageResourceDetailsView>) => onSelectAllRef.current(col),
                                        vendorHash: vendorHash,
                                        checked: colChild.field === colId
                                    }
                                }
                            }
                            return colChild;
                        });

                        return colDef;
                    }

                    return colDef;
                });

                gridRef.current!.api.setGridOption('columnDefs', newDefs);
            }
            setOpenBackdrop(false);
        } catch (error: any) {
            setOpenBackdrop(false);
            if (error?.data?.message) {
                setErrors({
                    name: error?.data?.message
                });
                return;
            }
            setPageError(Errors.generic);
        }
    }, [props.estimateId, setErrors, updatePackage, user, values.id, values.name])
    const onSelectAllRef = useRef<any>();
    onSelectAllRef.current = onSelectAll;

    const deleteSupplierPackageResource = useCallback(async (node: IRowNode<SupplierPackageResourceDetailsView>) => {
        try {
            if (user) {
                await deleteResource({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    resourceId: node.data?.id,
                    orgId: user.organizationId,
                    packageId: values.id,
                });
            }
        } catch (error) {
            setPageError(Errors.generic);
        }
    }, [deleteResource, values.id, props.estimateId, user])
    const deleteSupplierPackageResourceRef = useRef<any>();
    deleteSupplierPackageResourceRef.current = deleteSupplierPackageResource;

    const addResources = useCallback((resources: Array<FilteredResource>) => {
        const qResources = [...quoteResources];
        resources.forEach((resource) => {
            if (resource?.resourceId && !qResources.some((r) => (r.id === resource.resourceId))) {
                let qt: SupplierPackageResourceDetailsView = {
                    id: resource.resourceId,
                    displayId: resource.displayId,
                    quantity: undefined,
                    resourceName: resource.description,
                };
                if (addedVendorHashes.length > 0) {
                    addedVendorHashes.forEach((vendorIdHash) => {
                        const vendor = existingVendors?.find((v) => (v.id && md5(v.id) === vendorIdHash));
                        if (vendor) {
                            qt[`vendor_id_${vendorIdHash}`] = vendor.id;
                            qt[`rate_${vendorIdHash}`] = null;
                            qt[`isChecked_${vendorIdHash}`] = false;
                            qt[`amount_${vendorIdHash}`] = null;
                            qt[`totalRate_${vendorIdHash}`] = null;
                        }
                    });
                }
                qResources.push(qt);
            }
        });
        setQuoteResources(qResources);
        handleSubmit();
    }, [addedVendorHashes, existingVendors, handleSubmit, quoteResources])

    const addVendor = useCallback((vendor: Vendor, colDefs: any, rate: number | null = null) => {
        if (vendor.id) {
            let vendorIdHash = md5(vendor.id);
            if (!addedVendorHashes.includes(vendorIdHash)) {
                addedVendorHashes.push(vendorIdHash);
            }
            setAddedVendorHashes([...addedVendorHashes]);
            const qRes = [...quoteResources];
            qRes?.forEach((resource) => {
                if (resource?.id) {
                    resource[`vendor_id_${vendorIdHash}`] = vendor.id;
                    resource[`rate_${vendorIdHash}`] = rate;
                    resource[`isChecked_${vendorIdHash}`] = false;
                    resource[`amount_${vendorIdHash}`] = null;
                    resource[`totalRate_${vendorIdHash}`] = null;
                }
            });
            setQuoteResources(qRes);
            colDefs.push(getNewColDefs(vendor.name, vendorIdHash));
        }
    }, [addedVendorHashes, getNewColDefs, quoteResources])

    const addSupplier = useCallback(async () => {
        try {
            const colDefs = gridRef.current!.api.getColumnDefs();
            const cols = gridRef.current!.api.getColumns();
            const vendorToAdd = new Array<Vendor & { filterText: string }>();
            if (selectedVendor) {
                if (!cols?.some((col) => (selectedVendor.id && col.getId() === `vendor_id_${md5(selectedVendor.id)}`))) {
                    vendorToAdd.push(selectedVendor);
                }
            } else if (filteredVendors && filteredVendors.length > 0) {
                for (let i = 0; i < filteredVendors.length; i++) {
                    const vendor = filteredVendors[i];
                    if (vendor.id) {
                        if (!cols?.some((col) => (vendor.id && col.getId() === `vendor_id_${md5(vendor.id)}`))) {
                            vendorToAdd.push(vendor);
                        }
                    }
                }
            }
            vendorToAdd.forEach((vendor) => {
                addVendor(vendor, colDefs);
            });
            gridRef.current!.api.setGridOption("columnDefs", colDefs);
            let rowData: SupplierPackageResourceDetailsView[] = [];
            gridRef.current!.api.forEachNode((node) => {
                if (node.data) {
                    rowData.push(node.data);
                }
            });
            const resources = new Array<SupplierPackageResource>();
            rowData.forEach((row) => {
                let resource: SupplierPackageResource = {
                    resourceId: row.id
                };
                if (addedVendorHashes.length > 0) {
                    addedVendorHashes.forEach((vendorHash) => {
                        if (vendorToAdd.some((vendor) => (md5(vendor.id ?? '') === vendorHash))) {
                            resources.push({
                                ...resource,
                                quantity: row.quantity ? parseFloat(row?.quantity.toString() ?? "0") : undefined,
                                resourceId: row.id,
                                isChecked: row[`isChecked_${vendorHash}`],
                                rate: parseFloat(row[`rate_${vendorHash}`]),
                                vendorId: row[`vendor_id_${vendorHash}`],
                                totalRate: row[`totalRate_${vendorHash}`],
                            });
                        }
                    });
                } else {
                    resources.push(resource);
                }
            });
            if (packageIdRef.current) {
                await createSupplierPackageVendor({
                    companyId: user?.companyId,
                    estimateId: props.estimateId,
                    orgId: user?.organizationId,
                    packageId: packageIdRef.current,
                    body: {
                        packageResources: resources,
                        packageVendors: vendorToAdd.map((vendor) => ({
                            id: vendor.id ?? '',
                        }))
                    }
                }).unwrap();
            }
        } catch (error: any) {
            setPageError(Errors.generic);
        }
    }, [addVendor, addedVendorHashes, createSupplierPackageVendor, filteredVendors, props.estimateId, selectedVendor, user?.companyId, user?.organizationId])

    const handleOnTagChanged = useCallback((event: React.SyntheticEvent, newTags: Tag[] | null) => {
        if (newTags && newTags.length > 0) {
            setSelectedTags(newTags);
        } else {
            setSelectedTags([]);
        }
    }, [])

    const getResourceRowId = useCallback(function (params: GetRowIdParams<SupplierPackageResourceDetailsView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const getFooterStyle = useCallback((params: RowClassParams<SupplierPackageResourceDetailsView>) => {
        if (params.node.footer) {
            return { fontWeight: "bold" }
        }
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent<SupplierPackageResourceDetailsView>) => {
        try {
            if (currentEditing?.node === event.node || !event.column.isCellEditable(event.node)) {
                return;
            }
            if (!currentEditing?.node) {
                if (event.node.data?.masterReferenceId || !hasAddEditAccessRef.current) return;

                if (event.column.getColId() === 'actions') {
                    return;
                }
            } else {
                gridRef.current!.api.stopEditing();
            }
            setCurrentEditing({ node: event.node, column: event.column.getColId() });
        } catch (error) { }
    }, [currentEditing?.node]);

    const onCellEditingStopped = useCallback(async (event: CellEditingStoppedEvent<SupplierPackageResourceDetailsView>) => {
        try {
            const col = event.column.getColId();
            if (event.data) {
                const hash = col.split('rate_')[1] ?? '';
                if (user && event.data) {
                    await updatePackage({
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        orgId: user.organizationId,
                        packageId: values.id,
                        body: {
                            name: values.name,
                            packageResources: [{
                                ...event.data,
                                vendorId: event.data[`vendor_id_${hash}`],
                                totalRate: event.data[`totalRate_${hash}`],
                                rate: parseFloat(event.data[`rate_${hash}`]),
                                isChecked: event.data[`isChecked_${hash}`],
                                quantity: event.data.quantity ? parseFloat(event.data?.quantity.toString() ?? "0") : undefined,
                                resourceId: event.data.id,
                            }],
                        }
                    }).unwrap();
                }
                setCurrentEditing(undefined);
            }
        } catch (error: any) {
            setOpenBackdrop(false);
            if (error?.data?.message) {
                setErrors({
                    name: error?.data?.message
                });
                return;
            }
            setPageError(Errors.generic);
        }
    }, [props.estimateId, setErrors, updatePackage, user, values.id, values.name])

    const onPackageBlur = useCallback(async (event: any) => {
        if (event?.target?.value && user) {
            const isDuplicate = await isPackageNameDuplicate({
                companyId: user.companyId ?? '',
                estimateId: props.estimateId ?? '',
                organizationId: user.organizationId,
                name: event.target.value,
                packageId: values.id
            }).unwrap();
            if (!isDuplicate) {
                setFieldValue('name', event.target.value);
                handleSubmit();
            }
            else {
                setFieldError('name', 'Duplicate package name.')
            }
        }
    }, [handleSubmit, isPackageNameDuplicate, props.estimateId, setFieldError, setFieldValue, user, values.id])

    const submitOnEnter = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    }, [handleSubmit])

    return <>{colors && <Box height="100%">
        <Breadcrumbs
            sx={{
                " & .MuiBreadcrumbs-li": {
                    color: `${colors.gray[500]}`
                },
                " & .MuiBreadcrumbs-separator": {
                    color: `${colors.gray[700]}`
                }
            }}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
        >
            {breadcrumbs}
        </Breadcrumbs>
        <form onSubmit={handleSubmit} noValidate style={{ height: "100%" }}>
            <Box display="flex" justifyContent="space-between" padding="16px" flexDirection="column" gap="16px" height="100%">
                <Box display="flex" flexDirection="row" flex="0.2" marginRight="10px" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap="50px">
                        <label color={colors.gray[100]} style={{ width: "40px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Package:</label>
                        <TextField
                            size="small"
                            id="name"
                            sx={{ width: "245px" }}
                            name="name"
                            disabled={!hasAddEditAccessRef.current}
                            autoComplete="off"
                            placeholder="Package"
                            onBlurCapture={onPackageBlur}
                            onBlur={handleBlur}
                            onChange={(e) => setFieldValue('name', e.target.value, false)}
                            onKeyDown={submitOnEnter}
                            value={values.name}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name} />
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" flex="0.2" marginRight="10px" alignItems="center" gap="15px">
                    <Box display="flex" gap="50px" alignItems="center" flex="0.2">
                        <label color={colors.gray[100]} style={{ width: "40px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Suppliers:</label>
                        {existingTags && <Autocomplete
                            fullWidth
                            size="small"
                            renderTags={(value: readonly Tag[], getTagProps) =>
                                value.map((option: Tag, index: number) => (
                                    <Chip variant="outlined" label={option.description} {...getTagProps({ index })} />
                                ))
                            }
                            multiple
                            disabled={!hasAddEditAccessRef.current}
                            limitTags={2}
                            sx={{ width: "245px" }}
                            value={selectedTags ?? []}
                            onChange={handleOnTagChanged}
                            isOptionEqualToValue={(option, tag) => option.id === tag.id}
                            options={[...existingTags]}
                            renderInput={(params) => <TextField placeholder='Select Tag' {...params} />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.description, inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.description, matches);
                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />}
                    </Box>
                    <Box display="flex" gap="15px">
                        <Autocomplete
                            fullWidth
                            className="ag-input-field-input ag-text-field-input"
                            sx={{ width: "245px", height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                            size="small"
                            disabled={!hasAddEditAccessRef.current}
                            getOptionLabel={(option) => option.name ?? ''}
                            onChange={(event, value) => {
                                if (value) {
                                    setSelectedVendor(value);
                                } else {
                                    setSelectedVendor(undefined);
                                }
                            }}
                            options={filteredVendors ?? []}
                            isOptionEqualToValue={(option, tag) => option.id === tag.id}
                            value={selectedVendor ?? null}
                            renderInput={(params) => <TextField sx={{ height: "100%" }} placeholder='Select Supplier' {...params} />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.filterText ?? '', inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.filterText ?? '', matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    </Box>
                    <Box display="flex">
                        <Button disabled={!hasAddEditAccessRef.current || (selectedTags.length === 0 && !selectedVendor)} variant="contained" onClick={addSupplier}>Add</Button>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" flex="0.2" marginRight="10px" alignItems="center" gap="50px">
                    <label color={colors.gray[100]} style={{ width: "40px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Resources:</label>
                    <SupplierResourceFilter disabled={!hasAddEditAccessRef.current} estimateId={props.estimateId} addResources={addResources} />
                </Box>
                {pageError && <Box marginBottom="10px"><Alert severity="error">{pageError}</Alert></Box>}
                <Box style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
                    <AgGridReact<SupplierPackageResourceDetailsView>
                        ref={gridRef}
                        rowData={quoteResources}
                        columnDefs={columnDefs}
                        getRowStyle={getFooterStyle}
                        stopEditingWhenCellsLoseFocus={true}
                        grandTotalRow="bottom"
                        singleClickEdit={true}
                        animateRows={true}
                        onCellClicked={onCellClicked}
                        onCellEditingStopped={onCellEditingStopped}
                        getRowId={getResourceRowId}
                        onGridReady={setupGrid}
                    />
                </Box>
            </Box>
        </form>
    </Box>}
        {selectedPackageVendor && props.estimateId && <Drawer
            anchor="right"
            open={openSupplierPanel}
            onClose={() => setSelectedPackageVendor(undefined)}
        >
            <SupplierAttachments disabled={!hasAddEditAccessRef.current} packageVendor={selectedPackageVendor} estimateId={props.estimateId} close={() => setOpenSupplierPanel(false)} />
        </Drawer>}
        {selectedPackageVendor && selectedResource && props.estimateId && <Drawer
            anchor="right"
            open={openCalculateRate}
            onClose={() => closeCalculateRatePanel()}
        >
            <SupplierRate disabled={!hasAddEditAccessRef.current} resource={selectedResource} packageVendor={selectedPackageVendor} estimateId={props.estimateId} close={() => closeCalculateRatePanel()} />
        </Drawer>}
        <Backdrop
            sx={{ color: colors?.primary[1000], zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
}