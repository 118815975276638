import React, { useCallback, useEffect, useRef, useState } from 'react';
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';
import type { CustomHeaderProps } from 'ag-grid-react';
import { Box, IconButton, Popover } from '@mui/material';
import QuickCreate from 'Components/QuickCreate';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { useGetEstimateQuery } from 'State/Services/estimate';
import { hasEstimatePermission } from 'Helpers/estimate-permissions';
import { Entity } from 'Models/estimate';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import QuickAdd from 'Components/QuickAdd';
import { NavItem } from 'Models/nav';
export interface IndirectItemDetailInnerHeaderProps extends CustomHeaderProps {
    indirectItemId: string;
    estimateId: string;
    previousIndirectItem: NavItem;
    onClickAdd: () => void;
}

export default function IndirectItemDetailInnerHeader(props: IndirectItemDetailInnerHeaderProps) {
    const [quickCreateAnchorEl, setQuickCreateAnchorEl] = useState<any>(null);
    const [currentSort, setCurrentSort] = useState('none');
    const refButton = useRef(null);
    const quickCreateAnchor = useRef();
    const quickAddAnchor = useRef();
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const editDisabledRef = useRef<boolean>();
    const [quickAddAnchorEl, setQuickAddAnchorEl] = useState<any>(null);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
            } else {
                const hasItemEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Indirect,
                    requiredPermissions: [504]
                });
                const hasResourceEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [502]
                });
                const hasActivityEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [502]
                });
                const hasStandardItemEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Items,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasItemEditAccess || !hasResourceEditAccess || !hasActivityEditAccess || !hasStandardItemEditAccess;
            }
        } else {
            editDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const handleQuickCreateClose = () => {
        setQuickCreateAnchorEl(null);
        return;
    };

    const handleQuickAddClose = () => {
        setQuickAddAnchorEl(null);
        return;
    };

    const quickCreateOpen = Boolean(quickCreateAnchorEl);
    const quickAddOpen = Boolean(quickAddAnchorEl);

    const onSortChanged = useCallback(() => {
        let sort = props.column.getSort();
        if (sort === 'asc') {
            setCurrentSort('asc');
        } else if (sort === 'desc') {
            setCurrentSort('desc');
        } else {
            setCurrentSort('none');
        }
    }, [props.column]);

    useEffect(() => {
        props.column.addEventListener('sortChanged', onSortChanged);
        onSortChanged();
    }, [onSortChanged, props.column]);

    const onSortRequested = useCallback((event: any) => {
        if (event.target.className.includes('Ignore-Click')) return;
        switch (currentSort) {
            case 'asc':
                props.setSort('desc');
                break;
            case 'desc':
                props.setSort(null);
                break;
            default:
            case 'none':
                props.setSort('asc');
                break;
        }
    }, [currentSort, props]);

    const onMenuClicked = useCallback((event: any) => {
        props.showColumnMenu(refButton.current!);
    }, [props]);

    return (<>
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={onSortRequested}>
            <Box display="flex">{props.displayName}
                {currentSort === 'asc' && <Box paddingLeft="6px">
                    <i className="ag-icon ag-icon-asc"></i>
                </Box>}
                {currentSort === 'desc' && <Box paddingLeft="6px">
                    <i className="ag-icon ag-icon-desc"></i>
                </Box>}
            </Box>
            <Box justifyContent="end">
                {!editDisabledRef.current && <IconButton
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e) => {
                            e.stopPropagation();
                            setQuickCreateAnchorEl(quickCreateAnchor.current);
                            return;
                        };
                    }}
                    color="primary" 
                    aria-label="create"
                    size="small">
                    <FiberNewRoundedIcon />
                </IconButton>}
                <IconButton
                    ref={(ref) => {
                        if (!ref) return;
                        ref.onclick = (e) => {
                            e.stopPropagation();
                            setQuickAddAnchorEl(e.currentTarget);
                        };
                    }}
                    color="primary" 
                    aria-label="add"
                    size="small">
                    <AddBoxRoundedIcon />
                </IconButton>
                <IconButton
                    ref={refButton} onClick={onMenuClicked}>
                    <i className="ag-icon ag-icon-menu Ignore-Click"></i>
                </IconButton>
            </Box>
        </Box>
        {!editDisabledRef.current && <>
            <Popover
                open={quickCreateOpen}
                anchorEl={quickCreateAnchorEl}
                onClose={handleQuickCreateClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}>
                <QuickCreate
                    type='item'
                    itemId={props.indirectItemId}
                    parent="indirect"
                    estimateId={props.estimateId}
                    inHeader={true}
                    close={handleQuickCreateClose} />
            </Popover>
            <Box ref={quickCreateAnchor}></Box>
        </>}
        {!editDisabledRef.current && <>
            <Popover
                open={quickAddOpen}
                anchorEl={quickAddAnchorEl}
                onClose={handleQuickAddClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}>
                <QuickAdd
                    type='item'
                    itemId={props.indirectItemId}
                    estimateId={props.estimateId}
                    inHeader={true}
                    close={handleQuickAddClose} 
                    parent='indirect'
                    previousItem={props.previousIndirectItem} />
            </Popover>
            <Box ref={quickAddAnchor}></Box>
        </>}
    </>
    );
};