import { Package, PackageInfo, PackageItem, PackageItemDetails } from 'Models/package';
import { api } from './api'
import { AppendSubcontractor, SubcontractorActivityResource, SubcontractorPackageVendor, SubcontractorPackageVendorFile } from 'Models/subcontractor';


export const subcontractorApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPackageDetails: build.query<Package<PackageItemDetails>, { organizationId: string, companyId: string, estimateId: string, packageId: string }>({
            query: ({ organizationId, companyId, estimateId, packageId }: { organizationId: string, companyId: string, estimateId: string, packageId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}` }),
            providesTags: ['SubPackages']
        }),
        getPackages: build.query<Array<PackageInfo>, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/subcontractor/packages` }),
            providesTags: ['SubPackages']
        }),
        updatePackage: build.mutation<Package<PackageItem>, Partial<{ body: Package<PackageItem>, orgId: string, companyId: string, estimateId: string, packageId: string }>>({
            query({ body, orgId, companyId, packageId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['SubPackages', 'Pricing', 'ItemDetails', 'SubcontractorPackageVendors', 'Vendors'],
        }),
        createPackage: build.mutation<Package<PackageItem>, Partial<{ body: Package<PackageItem>, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['SubPackages', 'Vendors'],
        }),
        uploadSubcontractorPackageFile: build.mutation<SubcontractorPackageVendorFile, { organizationId: string, companyId: string, estimateId: string, subcontractorPackageVendorId: string, description: string, fileData: FormData }>({
            query({ organizationId, companyId, estimateId, fileData, subcontractorPackageVendorId, description }: { organizationId: string, companyId: string, estimateId: string, subcontractorPackageVendorId: string, description: string, fileData: FormData }) {
                return {
                    url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/subcontractor/package-vendor/${subcontractorPackageVendorId}/upload?description=${description}`,
                    method: 'POST',
                    body: fileData,
                };
            },
            invalidatesTags: ['SubcontractorPackageVendorFiles'],
        }),
        getSubcontractorPackageFiles: build.query<Array<SubcontractorPackageVendorFile>, { organizationId: string, companyId: string, estimateId: string, subcontractorPackageVendorId: string }>({
            query: ({ organizationId, companyId, estimateId, subcontractorPackageVendorId }: { organizationId: string, companyId: string, estimateId: string, subcontractorPackageVendorId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/subcontractor/package-vendor/${subcontractorPackageVendorId}/files` }),
            providesTags: ['SubcontractorPackageVendorFiles']
        }),
        getSubcontractorPackageVendors: build.query<Array<SubcontractorPackageVendor>, { organizationId: string, companyId: string, estimateId: string, packageId: string }>({
            query: ({ organizationId, companyId, estimateId, packageId }: { organizationId: string, companyId: string, estimateId: string, packageId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/vendors` }),
            providesTags: ['SubcontractorPackageVendors']
        }),
        isPackageNameDuplicate: build.query<boolean, { organizationId: string, companyId: string, estimateId: string, name: string, packageId?: string }>({
            query: ({ organizationId, companyId, estimateId, name, packageId }: { organizationId: string, companyId: string, estimateId: string, name: string, packageId?: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/name/${name}?packageId=${packageId}` })
        }),
        updatePackageVendor: build.mutation<SubcontractorPackageVendor, Partial<{ body: SubcontractorPackageVendor, orgId: string, companyId: string, estimateId: string, subcontractorPackageVendorId: string }>>({
            query({ body, orgId, companyId, subcontractorPackageVendorId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package-vendor/${subcontractorPackageVendorId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['SubcontractorPackageVendors', 'SubPackages', 'Pricing', 'ItemDetails'],
        }),
        updateSubcontractorItem: build.mutation<PackageItem, Partial<{ body: PackageItem, orgId: string, companyId: string, estimateId: string, subcontractorPackageVendorId: string, itemId: string, packageId: string }>>({
            query({ body, orgId, companyId, subcontractorPackageVendorId, estimateId, itemId, packageId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/package-vendor/${subcontractorPackageVendorId}/item/${itemId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['SubPackages', 'Pricing', 'ItemDetails'],
        }),
        deletePackageItem: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, packageId: string, itemId: string }>>({
            query({ orgId, companyId, estimateId, packageId, itemId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/item/${itemId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['SubPackages']
        }),
        downloadPackageVendorFile: build.mutation<any, Partial<{ organizationId: string, companyId: string, estimateId: string, subcontractorPackageVendorId: string, packageVendorFileId: string }>>({
            query({ organizationId, companyId, estimateId, subcontractorPackageVendorId, packageVendorFileId }: { organizationId: string, companyId: string, estimateId: string, fileId: string, subcontractorPackageVendorId: string, packageVendorFileId: string }) {
                return {
                    url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/subcontractor/package-vendor/${subcontractorPackageVendorId}/file/${packageVendorFileId}/download`,
                    method: "POST",
                    responseHandler: async (response: any) => window.open(window.URL.createObjectURL(await response.blob()), '_blank'),
                    cache: "no-cache",
                };
            },
        }),
        deletePackageVendorFile: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, subcontractorPackageVendorId: string, packageVendorFileId: string }>>({
            query({ orgId, companyId, estimateId, subcontractorPackageVendorId, packageVendorFileId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package-vendor/${subcontractorPackageVendorId}/file/${packageVendorFileId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['SubcontractorPackageVendorFiles']
        }),
        deletePackageVendor: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, packageId: string, subcontractorPackageVendorId: string }>>({
            query({ orgId, companyId, estimateId, packageId, subcontractorPackageVendorId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/package-vendor/${subcontractorPackageVendorId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['SubcontractorPackageVendorFiles', 'SubcontractorResourcesActivities', 'SubPackages', 'SubcontractorPackageVendors', 'Vendors']
        }),
        getSubcontractorResourcesActivities: build.query<Array<SubcontractorActivityResource>, { organizationId: string, companyId: string, estimateId: string, packageId: string, subcontractorPackageVendorId: string, itemId: string }>({
            query: ({ organizationId, companyId, estimateId, packageId, subcontractorPackageVendorId, itemId }: { organizationId: string, companyId: string, estimateId: string, packageId: string, subcontractorPackageVendorId: string, itemId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/package-vendor/${subcontractorPackageVendorId}/item/${itemId}` }),
            providesTags: ['SubcontractorResourcesActivities']
        }),
        appendSubcontractorResourcesActivities: build.mutation<null, Partial<{ body: AppendSubcontractor, orgId: string, companyId: string, estimateId: string, packageId: string, subcontractorPackageVendorId: string, itemId: string }>>({
            query({ body, orgId, companyId, subcontractorPackageVendorId, estimateId, packageId, itemId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/package-vendor/${subcontractorPackageVendorId}/item/${itemId}/append`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['SubcontractorResourcesActivities', 'ItemDetails'],
        }),
        updateSubcontractorResourcesActivities: build.mutation<null, Partial<{ body: SubcontractorActivityResource, orgId: string, companyId: string, estimateId: string, packageId: string, subcontractorPackageVendorId: string, itemId: string }>>({
            query({ body, orgId, companyId, subcontractorPackageVendorId, estimateId, packageId, itemId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/package-vendor/${subcontractorPackageVendorId}/item/${itemId}/resources-activities`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['SubcontractorResourcesActivities', 'ItemDetails'],
        }),
        deleteSubcontractorResource: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, packageId: string, subcontractorPackageVendorId: string, itemId: string, resourceId: string }>>({
            query({ orgId, companyId, estimateId, packageId, subcontractorPackageVendorId, itemId, resourceId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/package-vendor/${subcontractorPackageVendorId}/item/${itemId}/resource/${resourceId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['SubcontractorResourcesActivities', 'ItemDetails']
        }),
        deleteSubcontractorActivity: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, packageId: string, subcontractorPackageVendorId: string, itemId: string, activityId: string }>>({
            query({ orgId, companyId, estimateId, packageId, subcontractorPackageVendorId, itemId, activityId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/package-vendor/${subcontractorPackageVendorId}/item/${itemId}/activity/${activityId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['SubcontractorResourcesActivities', 'ItemDetails']
        }),
        clearSubcontractorActivityResource: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, packageId: string, subcontractorPackageVendorId: string, itemId: string }>>({
            query({ orgId, companyId, estimateId, packageId, subcontractorPackageVendorId, itemId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/package-vendor/${subcontractorPackageVendorId}/item/${itemId}/clear`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Pricing', 'ItemDetails', 'Activities', 'Resources', 'StandardItems']
        }),
        deleteSubcontractorPackage: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, packageId: string }>>({
            query({ orgId, companyId, estimateId, packageId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['SubPackages']
        }),
        createSubcontractorPackageVendor: build.mutation<void, Partial<{
            body: {
                packageVendors: Array<{ id: string }>,
                packageItems: Array<PackageItem>
            }, 
            orgId: string, 
            companyId: string, 
            estimateId: string, 
            packageId: string
        }>>({
            query({ body, orgId, companyId, estimateId, packageId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/subcontractor/package/${packageId}/vendor`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['SubPackages', 'SubcontractorPackageVendors', 'Vendors'],
        }),
    })
});

export const {
    useLazyIsPackageNameDuplicateQuery,
    useCreateSubcontractorPackageVendorMutation,
    useDeleteSubcontractorPackageMutation,
    useDeletePackageItemMutation,
    useDeletePackageVendorMutation,
    useUpdateSubcontractorItemMutation,
    useClearSubcontractorActivityResourceMutation,
    useDeleteSubcontractorActivityMutation,
    useDeleteSubcontractorResourceMutation,
    useGetSubcontractorResourcesActivitiesQuery,
    useAppendSubcontractorResourcesActivitiesMutation,
    useUpdateSubcontractorResourcesActivitiesMutation,
    useDeletePackageVendorFileMutation,
    useDownloadPackageVendorFileMutation,
    useUpdatePackageVendorMutation,
    useGetSubcontractorPackageVendorsQuery,
    useGetSubcontractorPackageFilesQuery,
    useUploadSubcontractorPackageFileMutation,
    useGetPackagesQuery,
    useCreatePackageMutation,
    useGetPackageDetailsQuery,
    useLazyGetPackageDetailsQuery,
    useUpdatePackageMutation
} = subcontractorApi