import { Alert, AlertTitle, Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useMemo, useState } from "react";
import { tokens } from "theme";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useImportVendorsMutation } from "State/Services/vendor";
import { ErroredVendor } from "Models/vendor";

export interface ImportVendorsModalProps {
    estimateId?: string;
    close: () => void;
}

export default function ImportVendorsModal(props: ImportVendorsModalProps) {
    const [colors, setColors] = useState<any>();
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 48px)', width: '100%' }), []);
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [importVendors] = useImportVendorsMutation();
    const [isUploading, setIsUploading] = useState(false);
    const [pageError, setPageError] = useState<string>();
    const [erroredVendors, setErroredVendors] = useState<Array<ErroredVendor>>([]);
    const {
        open,
        acceptedFiles, getInputProps } = useDropzone({
            // Disable click and keydown behavior
            noClick: true,
            noKeyboard: true,
            accept: {
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
            }
        });
    const [columnDefs] = useState<ColDef<ErroredVendor>[]>([
        {
            field: "displayId",
            resizable: true,
            headerName: "ID",
            width: 120,
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
        },
        {
            field: "name",
            flex: 1,
            headerName: "Name",
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
        },
        {
            field: "contact",
            headerName: "Contact",
            width: 200,
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
        },
        {
            field: "address",
            headerName: "Address",
            width: 200,
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
        },
        {
            field: "phone",
            headerName: "Phone",
            width: 200,
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
        },
        {
            field: "email",
            headerName: "Email",
            width: 200,
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
        },
        {
            field: "type",
            headerName: "Type",
            width: 180,
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } as any
        },
        {
            field: 'reason',
            flex: 1,
            autoHeight: true,
            resizable: true,
            wrapText: true,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}`, color: 'red' }
        },
    ]);

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const formData = new FormData();
            if (acceptedFiles) {
                formData.append("file", acceptedFiles[0]);
                if (user && user.companyId && props.estimateId) {
                    setIsUploading(true);
                    importVendors({
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        fileData: formData,
                        organizationId: user?.organizationId
                    }).unwrap().then((erroredImportVendors) => {
                        if (erroredImportVendors && erroredImportVendors.length > 0) {
                            setErroredVendors(erroredImportVendors);
                        } else {
                            props.close();
                        }
                    }).catch((error) => {
                        console.log(error);
                        if ('data' in error) {
                            setPageError(error.data.message);
                        }
                    }).finally(() => {
                        setIsUploading(false);
                    });
                }
            }
        }
    }, [acceptedFiles, importVendors, props, props.estimateId, user])

    return <>
        {pageError && <Box width="100%" padding="5px">
            <Alert severity="error">
                <AlertTitle>Error:</AlertTitle>
                {pageError && <Box>{pageError}</Box>}
            </Alert>
        </Box>}
        <Box height="100%" display="flex" alignItems="center" justifyContent="center" margin="25px 50px">
            {isUploading && <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                <CircularProgress color="primary" />
            </Box>
            }
            {!isUploading && erroredVendors.length === 0 && <Box display="flex" flexDirection="column" marginBottom="10px" alignItems="center" justifyContent="center">
                <Typography variant="h5" sx={{ color: `${colors?.primary[300]}` }}>Select a file to import</Typography>
                <input {...getInputProps()} />
                <Button type="button" color="primary" variant='contained' onClick={open} startIcon={<CloudUploadIcon />}>
                    Browse
                </Button>
            </Box>}
            {erroredVendors.length > 0 && <Box height="500px" width="80vw" display="flex" flexDirection="column" marginBottom="10px" alignItems="center" justifyContent="center">
                <Box className="ag-theme-alpine ag-theme-bidbow inner-col"
                    style={gridStyle}>
                    <AgGridReact<ErroredVendor>
                        animateRows={false}
                        columnDefs={columnDefs}
                        rowData={erroredVendors}
                    />
                </Box>
            </Box>}
        </Box></>
}