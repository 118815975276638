import {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, FormControl, InputAdornment, List, ListItem, OutlinedInput, useTheme } from '@mui/material';
import { tokens } from 'theme';
import { HtmlTooltip } from 'Components/HtmlToolltip';
import { UnitExtended } from 'Models/unit';
import { CustomCellEditorProps } from 'ag-grid-react';

export const UnitEditCellRenderer = forwardRef(({ value, onValueChange }: CustomCellEditorProps<UnitExtended, string>,ref) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [colors, setColors] = useState<any>();
    const theme = useTheme();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const refInput = useRef<any>(null);

    useImperativeHandle(ref, () => {
        return {
            setFocusOnAdd(){
                if (refInput.current){
                    refInput.current.focus();
                }
            },
            setError(message: string) {
                if (message) {
                    setError(message);
                }
            }
        };
    });

    const onChange = useCallback(async (value: string) => {
        onValueChange(value ?? null);
        if (!value) {
            setError('Unit is required');
        }
        else {
            setError(undefined);
        }
    }, [onValueChange])

    return (
        <Box sx={{ width: "100%", height: "100% !important" }}>
            <FormControl sx={{ height: 'inherit', width: "100%" }}>
                <OutlinedInput
                    sx={{ height: "inherit", backgroundColor: "white" }}
                    className="ag-input-field-input ag-text-field-input"
                    placeholder="Unit"
                    onChange={(event) => onChange(event.target.value)}
                    endAdornment={error &&
                        <InputAdornment position="end">
                            <HtmlTooltip color={colors?.redAccent[500]}
                                title={
                                    <List sx={{ listStyleType: 'disc', pl: 1 }}>
                                        <ListItem
                                            sx={{
                                                padding: 0,
                                                textAlign: "center",
                                                listStyleType: "disc",
                                                display: "list-item",
                                            }}
                                        >{error}</ListItem>
                                    </List>
                                }>
                                <WarningIcon sx={{ color: `${colors?.redAccent[500]}`, cursor: "pointer" }} />
                            </HtmlTooltip>
                        </InputAdornment>
                    }
                    value={value}
                    inputRef={refInput}
                />
            </FormControl>
        </Box>
    );
});
