import { Box, Button, CircularProgress, IconButton, Link, TextField, Typography, useTheme } from "@mui/material";
import { SubcontractorPackageVendor, SubcontractorPackageVendorFileView } from "Models/subcontractor";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useGetVendorsQuery } from "State/Services/vendor";
import { ColDef, ICellRendererParams, IRowNode } from "ag-grid-enterprise";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { tokens } from "theme";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeletePackageVendorFileMutation, useDownloadPackageVendorFileMutation, useGetSubcontractorPackageFilesQuery, useUpdatePackageVendorMutation, useUploadSubcontractorPackageFileMutation } from "State/Services/subcontractor";
import { AgGridReact } from "ag-grid-react";
import { Vendor } from "Models/vendor";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export interface SubcontractorAttachmentsProps {
    estimateId: string;
    packageVendor: SubcontractorPackageVendor;
    close: () => void;
    disabled: boolean;
}

export function SubcontractorAttachments(props: SubcontractorAttachmentsProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isUploading, setIsUploading] = useState(false);
    const { data: user } = useGetUserDetailsQuery();
    const { data: existingVendors } = useGetVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const { data: files } = useGetSubcontractorPackageFilesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', subcontractorPackageVendorId: props.packageVendor.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !props.packageVendor.id });
    const [vendor, setVendor] = useState<Vendor>();
    const [factor, setFactor] = useState<number>();
    const [remarks, setRemarks] = useState<string>();
    const [updatePackageVendor] = useUpdatePackageVendorMutation();
    const [uploadFile] = useUploadSubcontractorPackageFileMutation();
    const [downloadFile] = useDownloadPackageVendorFileMutation();
    const [deletePackageFile] = useDeletePackageVendorFileMutation();
    const [isSaving, setIsSaving] = useState(false);
    const [rowData, setRowData] = useState<Array<SubcontractorPackageVendorFileView>>([]);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [factorError, setFactorError] = useState<string>();

    const {
        open,
        acceptedFiles, getInputProps } = useDropzone({
            // Disable click and keydown behavior
            noClick: true,
            noKeyboard: true,
            accept: {
                'application/pdf': ['.pdf'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
            }
        });

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const formData = new FormData();
            if (acceptedFiles) {
                formData.append("file", acceptedFiles[0]);
                if (user && user.companyId) {
                    setIsUploading(true);
                    uploadFile({
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        fileData: formData,
                        subcontractorPackageVendorId: props.packageVendor.id,
                        description: '',
                        organizationId: user?.organizationId
                    }).then((response) => {
                        if ('data' in response) {

                        } else {
                            const error = response.error;
                            console.error(error);
                        }
                        setIsUploading(false);
                    });
                }
            }
        }
    }, [acceptedFiles, props, props.estimateId, uploadFile, user])

    useEffect(() => {
        if (existingVendors && existingVendors.length > 0 && props.packageVendor) {
            const vendor = existingVendors?.find((ven: Vendor) => (ven.id === props.packageVendor.vendorId));
            setVendor(vendor);
        }

    }, [existingVendors, props])

    useEffect(() => {
        if (files && files.length > 0) {
            const rows = new Array<SubcontractorPackageVendorFileView>();
            files.forEach((fl) => {
                rows.push({
                    actions: '',
                    description: fl.description,
                    extension: fl.extension,
                    id: fl.id,
                    originalName: fl.originalName,
                    name: fl.name
                });
            });
            setRowData(rows);
        } else {
            setRowData([]);
        }
    }, [files])

    useEffect(() => {
        if (props.packageVendor) {
            setFactor(props.packageVendor.factor);
            setRemarks(props.packageVendor.remarks);
        }
    }, [props.packageVendor])

    const saveSubcontractorDetails = useCallback(async () => {
        try {
            setFactorError(undefined);
            if (user && props.packageVendor) {
                setIsSaving(true);
                await updatePackageVendor({
                    companyId: user.companyId,
                    orgId: user.organizationId,
                    estimateId: props.estimateId,
                    subcontractorPackageVendorId: props.packageVendor.id,
                    body: {
                        factor: factor,
                        remarks: remarks ?? '',
                        id: props.packageVendor.id,
                        vendorId: props.packageVendor.vendorId,
                        subcontractorPackageId: props.packageVendor.subcontractorPackageId
                    }
                }).unwrap();
                setIsSaving(false);
                props.close();
            }
        } catch (error: any) {
            setIsSaving(false);
            if (error?.data?.factor) {
                setFactorError(error?.data?.factor);
            }
        }

    }, [factor, props, remarks, updatePackageVendor, user])

    const onFactorChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const factor = parseFloat(event.target.value);
        if (!isNaN(factor)) {
            setFactor(factor);
        }else{
            setFactor(undefined);
        }
    }, [])

    const onRemarksChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setRemarks(event.target.value);
    }, [])

    const deleteFile = useCallback(async (node: IRowNode<SubcontractorPackageVendorFileView>) => {
        if (node && user && props.packageVendor) {
            setIsUploading(true);
            await deletePackageFile({
                companyId: user?.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId,
                packageVendorFileId: node.data?.id,
                subcontractorPackageVendorId: props.packageVendor.id
            });
            setIsUploading(false);
        }
    }, [deletePackageFile, props.estimateId, props.packageVendor, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const deleteFileRef = useRef<any>();
    deleteFileRef.current = deleteFile;

    const [columnDefs] = useState<ColDef<SubcontractorPackageVendorFileView>[]>([
        { field: 'id', hide: true },
        {
            field: 'originalName', headerName: 'File name', flex: 1, resizable: true,
            cellRenderer: (params: ICellRendererParams<SubcontractorPackageVendorFileView>) => (<>{params.data &&
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center" height="100%">
                    <Link component="button" variant="body2" onClick={() => selectFileRef.current(params.node)}>{params.value}</Link>
                </Box>
            }</>)
        },
        {
            headerName: 'Actions',
            width: 130,
            resizable: true,
            cellRenderer: (params: ICellRendererParams<SubcontractorPackageVendorFileView>) => (<>{params.data &&
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center" height="100%">
                    <IconButton disabled={props.disabled} onClick={() => deleteFileRef.current(params.node)}><DeleteIcon /></IconButton>
                </Box>
            }</>)
        },
    ]);

    const selectFile = useCallback(async (node: IRowNode<SubcontractorPackageVendorFileView>) => {
        if (node && user && props.packageVendor) {
            setIsUploading(true);
            await downloadFile({
                companyId: user?.companyId,
                estimateId: props.estimateId,
                organizationId: user.organizationId,
                packageVendorFileId: node.data?.id,
                subcontractorPackageVendorId: props.packageVendor.id
            });
            setIsUploading(false);
        }
    }, [downloadFile, props.estimateId, props.packageVendor, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const selectFileRef = useRef<any>();
    selectFileRef.current = selectFile;

    return <>{colors && vendor && <Box width="40vw" height="100%">
        <Box display="flex" justifyContent="space-between" paddingTop="16px" paddingBottom="16px" paddingLeft="24px" paddingRight="24px" borderBottom={`1px solid ${colors.gray[800]}`}>
            <Box>
                <Typography component="div" sx={{ display: "flex" }}>
                    <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }}>{vendor.name}</Box>
                </Typography>
            </Box>
            <Box>
                <Button disabled={props.disabled} color="primary" variant="contained" onClick={saveSubcontractorDetails}>{isSaving ? <CircularProgress sx={{ color: "white" }} size="1rem" /> : 'Save'}</Button>
            </Box>
        </Box>
        <Box display="flex" gap="10px" padding="16px" flexDirection="column" height="calc(100% - 250px)">
            <Box display="flex" flexDirection="row" flex="0.2" marginRight="10px" alignItems="center" gap="16px">
                <label color={colors.gray[100]} style={{ width: "40px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Factor:</label>
                <TextField
                    error={factorError ? true : false}
                    helperText={factorError ?? undefined}
                    size="small"
                    value={factor}
                    autoComplete="off"
                    placeholder="Factor"
                    disabled={props.disabled}
                    inputProps={{ type: "number" }}
                    onChange={onFactorChange}
                />
            </Box>
            <Box display="flex" flexDirection="row" flex="0.2" marginRight="10px" alignItems="center" gap="16px">
                <label color={colors.gray[100]} style={{ width: "40px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Remarks:</label>
                <TextField
                    fullWidth
                    size="small"
                    value={remarks}
                    autoComplete="off"
                    multiline
                    disabled={props.disabled}
                    rows={4}
                    placeholder="Remarks"
                    onChange={onRemarksChange}
                />
            </Box>
            <Box width="100%" padding="25px 10px 10px 10px" height="100%" display="flex">
                {isUploading && <Box sx={{ color: 'gray', backgroundColor: colors?.gray[800] }} display="flex" alignItems="center" justifyContent="center" width="100%">
                    <CircularProgress color="primary" />
                </Box>
                }
                {!isUploading && <>
                    <Box display="flex" flexDirection="column" flex="1">
                        <Box marginBottom="10px">
                            <input {...getInputProps()} />
                            <Button disabled={props.disabled} type="button" color="primary" variant='contained' onClick={open} startIcon={<CloudUploadIcon />}>
                                Browse
                            </Button>
                        </Box>
                        <div style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
                            <AgGridReact<SubcontractorPackageVendorFileView>
                                rowData={rowData}
                                columnDefs={columnDefs}
                                animateRows={true}
                            />
                        </div>
                    </Box>
                </>
                }
            </Box>
        </Box>
    </Box>}</>;
}