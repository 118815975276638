import { Box, Button, IconButton, Link, Modal, Typography, useTheme } from "@mui/material";
import { ActivityRateCalculated, ActivityView, BaseActivity } from "Models/activity";
import { useLazyGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef, ICellRendererParams, IRowNode, IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-enterprise";
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import { v4 as uuidv4 } from 'uuid';
import { AgGridReact, getInstance } from "ag-grid-react";
import AddActivity from "./AddEditActivity";
import { useCreateActivityMutation, useDeleteActivityMutation, useGetActivityRowsMutation, useLazyGetActivityByIdQuery, useLazyGetActivityMaxIdQuery, usePushActivityToTemplateMutation, useSyncActivityMutation, useUpdateActivityMutation } from "State/Services/activity";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import AddEditActivityCategory from "./AddEditActivityCategory";
import CloseIcon from '@mui/icons-material/Close';
import { useLazyGetActivityCategoriesQuery } from "State/Services/activity-category";
import ActivityCategoryFilters, { FilterSettings } from "./ActivityCategoryFilters";
import AddIcon from '@mui/icons-material/Add';
import { useConfirm } from "material-ui-confirm";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { rounder } from "Helpers/rounder";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { CellClickedEvent, RowEditingStartedEvent, RowEditingStoppedEvent, CellKeyDownEvent, FilterChangedEvent, GetRowIdParams, GridReadyEvent, PaginationChangedEvent, SortChangedEvent, SuppressKeyboardEventParams, ICellEditor } from "ag-grid-community";
import { PanelState } from "Models/panel";
import SplitButton, { MenuOption } from "Components/SplitButton";
import { ActivityCategory } from "Models/activity-category";
import { Unit } from "Models/unit";
import { ServerError } from "Models/error-info";
import ActivityListCategoryEditCellRenderer from "./ActivityListCategoryEditCellRenderer";
import { flushSync } from "react-dom";
import OutOfSyncCellRenderer from "Components/Shared/OutOfSyncCellRender";
import GenericTextEditCellRenderer from "Components/Shared/GenericTextEditCellRenderer";
import GenericNumberEditCellRenderer from "Components/Shared/GenericNumberEditCellRenderer";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import GenericUnitEditCellRenderer from "Components/Shared/GenericUnitEditCellRenderer";
import GenericActionsCellRenderer from "Components/Shared/GenericActionsEditCellRenderer";
import GenericIdEditCellRenderer from "Components/Shared/GenericIdEditCellRenderer";
import { BaseEditCellRenderer } from "Models/grid";
export interface ActivityProps {
    activityId?: string;
    estimateId: string | undefined;
    onDrop?: (activity: ActivityView) => void;
    panelState: PanelState;
}

const importModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    height: "50%",
    bgcolor: 'background.paper',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    borderRadius: "5px",
    display: 'flex',
    flexDirection: "column"
};

export default function ActivityList(props: ActivityProps) {
    const confirm = useConfirm();
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [getUnits] = useLazyGetUnitsQuery();
    const [deleteActivity] = useDeleteActivityMutation();
    const [units, setUnits] = useState<Array<Unit>>();
    const [showAdd, setShowAdd] = useState(false);
    const [editActivity, setEditActivity] = useState<ActivityView>();
    const gridRef = useRef<AgGridReact<ActivityView>>(null);
    const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
    const [getActivityCategories] = useLazyGetActivityCategoriesQuery();
    const [seed, setSeed] = useState<number>();
    const keyToUse = useMemo(() => { return seed }, [seed]);
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();
    const [getActivityRows] = useGetActivityRowsMutation();
    const [getActivityById] = useLazyGetActivityByIdQuery();
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<ActivityView> | undefined, column?: string }>();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [currentActivityRows, setCurrentActivityRows] = useState<Array<BaseActivity>>([]);
    const [activityCategories, setActivityCategories] = useState<Array<ActivityCategory>>([]);
    const [saveActivity] = useCreateActivityMutation();
    const [updateActivity] = useUpdateActivityMutation();
    const [errors, setErrors] = useState<Array<{ field: string, error: string }>>([]);
    const [unitRef, setUnitRef] = useState<MutableRefObject<any> | undefined>();
    const [categoryRef, setCategoryRef] = useState<MutableRefObject<any> | undefined>();
    const [addMenuItems] = useState<Array<MenuOption>>([{ option: 'New inline', disabled: false }, { option: 'New in form', disabled: false }]);
    const [syncActivity] = useSyncActivityMutation();
    const [pushToTemplate] = usePushActivityToTemplateMutation();
    const [pageError, setPageError] = useState<string | undefined>();
    const [getMaxId] = useLazyGetActivityMaxIdQuery();

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((errorDetails) => {
                const instances = gridRef.current!.api.getCellEditorInstances({
                    columns: [errorDetails.field]
                });
                if (instances.length > 0) {
                    getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
                        if (instance && instance.setError) {
                            instance.setError(errorDetails.error);
                        }
                    });
                }
            });
        }
    }, [errors])

    const getActivities = useCallback(async (params: IServerSideGetRowsParams<ActivityView>) => {
        if (user) {
            let storedActivityCategories = new Array<ActivityCategory>();
            let storedUnits = new Array<Unit>();
            const response = await getActivityRows({
                companyId: user.companyId,
                orgId: user.organizationId,
                estimateId: props.estimateId,
                body: { ...params.request }
            }).unwrap();
            if (activityCategories && activityCategories.length > 0) {
                storedActivityCategories = activityCategories
            } else {
                storedActivityCategories = await getActivityCategories({
                    companyId: user.companyId ?? '',
                    estimateId: props.estimateId ?? '',
                    organizationId: user.organizationId
                }, true).unwrap();
                setActivityCategories(storedActivityCategories);
            }
            if (units && units.length > 0) {
                storedUnits = units
            } else {
                storedUnits = await getUnits({
                    companyId: user.companyId ?? '',
                    organizationId: user.organizationId ?? ''
                }, true).unwrap();
                setUnits(storedUnits);
            }
            if (params?.request) {
                const rows = new Array<ActivityView>();
                setCurrentActivityRows(response.results);
                response.results.forEach((act) => {
                    const unit = storedUnits?.find((unit) => (unit.id === act.unitId));
                    let category = undefined;
                    if (act.categoryId && storedActivityCategories) {
                        category = storedActivityCategories.find((cat) => (cat.id === act.categoryId));
                    }
                    rows.push({
                        id: act.id,
                        description: act.description,
                        displayId: act.displayId,
                        rate: act.rate,
                        output: act.output,
                        masterReferenceId: act.masterReferenceId,
                        categoryId: act.categoryId,
                        unitId: act.unitId,
                        unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined,
                        type: "activity",
                        categoryDescription: category?.description,
                        canDelete: act.canDelete,
                        isNew: false,
                        outOfSync: act.outOfSync,
                        canPush: act.canPush
                    });
                });
                params.success({
                    rowData: rows,
                    rowCount: response.totalCount
                });
            }
        }
    }, [activityCategories, getActivityCategories, getActivityRows, getUnits, props.estimateId, units, user])
    const getActivitiesRef = useRef<(params: IServerSideGetRowsParams<ActivityView>) => void>(() => (null));
    getActivitiesRef.current = getActivities;

    const createServerSideDatasource = useCallback((): IServerSideDatasource => {
        return {
            getRows: (params) => getActivitiesRef.current(params),
        };
    }, []);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const handleOpenCategoriesModal = () => {
        setOpenCategoriesModal(true);
    };
    const handleCloseCategoriesModal = async () => {
        setOpenCategoriesModal(false);
    };

    const showHideCols = useCallback(() => {
        if (gridRef.current?.api) {
            gridRef.current!.api.setColumnsVisible(["categoryDescription"], props.panelState.position.state !== "half");
            if (!estimate?.isMaster && estimate?.masterEstimateId) {
                gridRef.current!.api.setColumnsVisible(["outOfSync"], true)
            }
        }
    }, [estimate?.isMaster, estimate?.masterEstimateId, props.panelState.position.state])

    useEffect(() => {
        showHideCols();
    }, [showHideCols])

    const switchToEdit = useCallback((activity: ActivityView | undefined) => {
        if (activity) {
            setEditActivity(activity);
            setShowAdd(true);
        }
    }, [])

    const navigateToActivity = useCallback(() => {
        if (props.activityId && user) {
            getActivityById({
                companyId: user.companyId ?? '',
                estimateId: props.estimateId ?? '',
                organizationId: user.organizationId,
                activityId: props.activityId
            }).then((response) => {
                setSeed(Math.random());
                switchToEdit(response.data);
            })
        }
    }, [getActivityById, props.activityId, props.estimateId, switchToEdit, user])

    useEffect(() => {
        navigateToActivity();
    }, [navigateToActivity])

    const edit = useCallback((node: IRowNode<ActivityView>) => {
        switchToEdit(node.data);
    }, [switchToEdit])

    const deleteRow = useCallback((node: IRowNode<ActivityView>) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await confirm({ description: `Are you sure you want to delete the record?`, title: 'Delete confirmation' });
                if (user && node.data) {
                    await deleteActivity({
                        activityId: node.data.id,
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        orgId: user.organizationId
                    });
                    gridRef.current!.api.onFilterChanged();
                }

                resolve();
            } catch (error) {
                reject('Cancelled by user.');
            }
        });
    }, [confirm, deleteActivity, props.estimateId, user]);

    const cancelEditing = useCallback((node: IRowNode<ActivityView>) => {
        if (node && node.data) {
            setIsCancelClicked(true);
            const activity = currentActivityRows?.find((c) => (c.id === node.data?.id));
            if (activity) {
                const unit = units?.find((unit) => (unit.id === activity.unitId));
                let category = undefined;
                if (activity.categoryId && activityCategories) {
                    category = activityCategories.find((cat) => (cat.id === activity.categoryId));
                }
                node.updateData({
                    ...activity,
                    type: 'activity',
                    unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined,
                    categoryDescription: category?.description
                });
            }
            gridRef.current!.api.stopEditing(true);
            if (node.data.isNew) {
                gridRef.current!.api.applyServerSideTransaction({ remove: [node.data] });
            }

            setCurrentEditing(undefined);
        }
    }, [activityCategories, currentActivityRows, units])
    const cancelEditingRef = useRef<any>();
    cancelEditingRef.current = cancelEditing;

    const saveRow = useCallback(async (nodeToSave: IRowNode<ActivityView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<ActivityView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && props.estimateId && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    if (!nodeToSave.data.displayId) {
                        const error: ServerError = { data: { displayId: 'ID is required' } };
                        throw error;
                    }
                    if (!nodeToSave.data.description) {
                        const error: ServerError = { data: { description: 'Description is required' } };
                        throw error;
                    }
                    if (nodeToSave.data.id) {
                        let activityResponse: ActivityRateCalculated;
                        if (nodeToSave.data.isNew) {
                            activityResponse = await saveActivity({
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: nodeToSave.data.id,
                                    output: nodeToSave.data.output ? parseFloat(nodeToSave.data.output.toString()) : undefined,
                                    description: nodeToSave.data.description,
                                    displayId: nodeToSave.data.displayId,
                                    unitId: nodeToSave.data.unit?.unitId,
                                    categoryId: nodeToSave.data.categoryId,
                                }
                            }).unwrap();
                        } else {
                            activityResponse = await updateActivity({
                                activityId: nodeToSave.data?.id,
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: nodeToSave.data.id,
                                    output: nodeToSave.data.output ? parseFloat(nodeToSave.data.output.toString()) : undefined,
                                    description: nodeToSave.data.description,
                                    displayId: nodeToSave.data.displayId,
                                    unitId: nodeToSave.data.unit?.unitId,
                                    categoryId: nodeToSave.data.categoryId,
                                }
                            }).unwrap();
                        }
                        nodeToSave.setDataValue('rate', activityResponse.rate);
                        nodeToSave.setDataValue('isNew', false);
                        gridRef.current!.api.refreshServerSide();
                    }
                    resolve();
                    if (toEditAfterSave?.nodeToEditAfterSave) {
                        if (toEditAfterSave?.nodeToEditAfterSave?.data?.masterReferenceId) {
                            setCurrentEditing(undefined);
                            return;
                        }
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    }
                    else {
                        setCurrentEditing(undefined);
                    }
                }
            } catch (error: any) {
                if (error && error.data) {
                    if (typeof nodeToSave.rowIndex === 'number') {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: 'displayId',
                        });
                    }
                    const errors = new Array<{ field: string, error: string }>();
                    if (error?.data?.displayId) {
                        errors.push({ field: 'displayId', error: error.data.displayId });
                    }
                    if (error?.data?.output) {
                        errors.push({ field: 'output', error: error.data.output });
                    }
                    if (error?.data?.description) {
                        errors.push({ field: 'description', error: error.data.description });
                    }
                    if (error?.data?.name) {
                        errors.push({ field: 'name', error: error.data.name });
                    }
                    if (error?.data?.unitId) {
                        errors.push({ field: 'unit', error: error.data.unitId });
                    }
                    setErrors(errors);
                }
                reject(error);
            }
        });
    }, [props.estimateId, saveActivity, updateActivity, user])
    const saveEditingRef = useRef<any>();
    saveEditingRef.current = saveRow;

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<ActivityView>) => {
        if (params.event.key === 'Enter' && params.node) {
            if (params.event.target === unitRef?.current ||
                params.event.target === categoryRef?.current) {
                return true;
            }
            params.event.stopPropagation();
            const save = async () => {
                try {
                    await saveRow(params.node);
                } catch (error) {

                }
            }
            save();
        }
        return true;
    }, [categoryRef, saveRow, unitRef])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const saveOnEnterRef = useRef<any>();
    saveOnEnterRef.current = saveOnEnter;

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelEditing]);

    const sync = useCallback(async (node: IRowNode<ActivityView>) => {
        setPageError(undefined);
        if (node && node.data && user) {
            await syncActivity({
                companyId: user.companyId,
                orgId: user.organizationId,
                estimateId: props.estimateId,
                activityId: node.data.id,
                templateActivityId: node.data.masterReferenceId
            });
            gridRef.current!.api.refreshServerSide();
        }
    }, [props.estimateId, syncActivity, user])
    const syncRef = useRef<any>();
    syncRef.current = sync;

    const push = useCallback(async (node: IRowNode<ActivityView>) => {
        try {
            setPageError(undefined);
            if (node && node.data && user && estimate) {
                await pushToTemplate({
                    companyId: user.companyId,
                    orgId: user.organizationId,
                    estimateId: props.estimateId,
                    activityId: node.data.id,
                    masterEstimateId: estimate.masterEstimateId
                }).unwrap();
                gridRef.current!.api.refreshServerSide({
                    purge: true
                });
                gridRef.current!.api.refreshCells({
                    columns: ["outOfSync"],
                    rowNodes: [node],
                    force: true
                });
            }
        } catch (error: any) {
            console.log("error", error);
            if (error && 'data' in error) {
                setPageError(error.data.message);
            }
        }

    }, [estimate, props.estimateId, pushToTemplate, user])
    const pushRef = useRef<any>();
    pushRef.current = push;

    const getPanelBaseDefs = useCallback((): Array<ColDef<ActivityView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'type',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'categoryId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'isNew',
                cellDataType: 'boolean',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                resizable: true,
                headerName: 'ID',
                hide: false,
                dndSource: props.panelState.position.state === "half",
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                sort: 'asc',
                cellEditor: GenericIdEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    getMaxId: getMaxId,
                    field: "ID"
                },
                filter: 'agTextColumnFilter',
                editable: (params) => {
                    return !params.data?.masterReferenceId && !editDisabledRef.current;
                },
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                showRowGroup: true,
                width: 120,
                autoHeight: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            },
            {
                field: 'description',
                flex: 1,
                cellEditor: GenericTextEditCellRenderer,
                cellEditorParams: {
                    field: 'Description'
                },
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                filter: 'agTextColumnFilter',
                cellRenderer: (params: ICellRendererParams<ActivityView>) => (<>{params.data && <Link component="button" onClick={() => edit(params.node)} variant="body2">{params.data.description}</Link>}</>),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'categoryDescription',
                headerName: 'Category',
                hide: true,
                width: 200,
                cellEditor: ActivityListCategoryEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    setRef: setCategoryRef,
                },
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                filter: 'agTextColumnFilter',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                width: 75,
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                cellEditor: GenericUnitEditCellRenderer,
                cellEditorParams: {
                    setRef: setUnitRef,
                },
                filter: 'agTextColumnFilter',
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'output',
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    field: 'Output'
                },
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                resizable: true,
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                width: 175,
                sortable: false,
                editable: false,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'outOfSync',
                width: 65,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                hide: true,
                editable: false,
                cellDataType: "boolean",
                headerName: 'Sync',
                cellRenderer: OutOfSyncCellRenderer,
                cellRendererParams: {
                    sync: (node: IRowNode<ActivityView>) => syncRef.current(node),
                    push: (node: IRowNode<ActivityView>) => pushRef.current(node),
                    estimateId: props.estimateId,
                    entity: Entity.Activities
                },
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'actions',
                resizable: true,
                width: 80,
                sortable: false,
                editable: false,
                headerName: 'Actions',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRenderer: GenericActionsCellRenderer,
                cellRendererParams: {
                    deleteRecord: deleteRow,
                    disabled: () => deleteDisabledRef.current,
                    save: (node: IRowNode<ActivityView>) => saveEditingRef.current(node),
                    cancel: (node: IRowNode<ActivityView>) => cancelEditingRef.current(node),
                    deleteDisabledMessage: "Deleting a activity associated with other elements is not permitted."
                }
            },
        ];
    }, [colors?.gray, deleteRow, edit, estimate?.CompanyCurrency?.Currency?.minorUnit, getMaxId, props.estimateId, props.panelState.position.state, settings?.quantityDecimals])

    const [panelColumnDefs] = useState<ColDef[]>(getPanelBaseDefs());

    const gridDragOver = useCallback((event: any) => {
        const dragSupported = event.dataTransfer.types.length;

        if (dragSupported) {
            event.dataTransfer.dropEffect = 'copy';
            event.preventDefault();
        }
    }, []);

    const addNewActivityInForm = useCallback(() => {
        setShowAdd(showAdd => !showAdd);
        setEditActivity(undefined);
    }, [])

    const getRowId = useCallback(function (params: GetRowIdParams<ActivityView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        const datasource = createServerSideDatasource();
        params.api!.setGridOption("serverSideDatasource", datasource);
        showHideCols();
    }, [createServerSideDatasource, showHideCols])

    const filterChanged = useCallback(async (filterSettings: FilterSettings) => {
        setPageError(undefined);
        let filters = {};
        if (filterSettings.categoryDescription) {
            const catFilter = {
                categoryDescription: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.categoryDescription
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.searchText) {
            const descriptionFilter = {
                description: {
                    filterType: 'text',
                    type: 'contains',
                    filter: filterSettings.searchText
                }
            };
            filters = { ...filters, ...descriptionFilter };
        }
        gridRef.current!.api.setFilterModel(filters);
        gridRef.current!.api.onFilterChanged();
    }, [])

    const backToList = useCallback(() => {
        setShowAdd(showAdd => !showAdd);
    }, [])

    const resetAndAddNew = useCallback((categoryId: string | undefined) => {
        setSeed(Math.random());
        setEditActivity({
            categoryId: categoryId
        });
    }, [])

    const onRowEditingStarted = useCallback((event: RowEditingStartedEvent<ActivityView>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent<ActivityView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent<ActivityView>) => {
        try {
            if (isCancelClicked) {
                setIsCancelClicked(false);
                return;
            };

            if (editDisabledRef.current) {
                setCurrentEditing(undefined);
                return;
            };

            if (currentEditing?.node === event.node) {
                return;
            }
            if (event.column.getColId() === 'actions' || event.column.getColId() === 'outOfSync') {
                return;
            }
            setPageError(undefined);
            if (!currentEditing?.node) {
                if (event.column.isCellEditable(event.node)) {
                    setCurrentEditing({ node: event.node, column: event.column.getColId() });
                } else {
                    setCurrentEditing({ node: event.node, column: undefined });
                }
            } else {
                await saveRow(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) {

        }
    }, [currentEditing?.node, isCancelClicked, saveRow])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column ?? 'displayId',
            });
        }
    }, [currentEditing])

    const addActivityInline = useCallback(async () => {
        setPageError(undefined);
        if (currentEditing) {
            try {
                await confirm({ description: "You have unsaved changes. If you proceed, your current edits will be lost. Do you want to continue?" });
                gridRef.current!.api.stopEditing(true);
                if (currentEditing && currentEditing.node?.data) {
                    if (currentEditing.node.data.id) {
                        const activityBeforeEditing = currentActivityRows?.find((activity) => (activity.id === currentEditing?.node?.data?.id));
                        if (activityBeforeEditing) {
                            gridRef.current!.api.applyServerSideTransaction({ update: [{ ...activityBeforeEditing }] });
                        } else {
                            gridRef.current!.api.applyServerSideTransaction({ remove: [currentEditing.node.data] });
                        }
                    } else {
                        gridRef.current!.api.applyServerSideTransaction({ remove: [currentEditing.node.data] });
                    }
                }
            } catch (error) {
                return;
            }
        }
        const filters = gridRef.current!.api.getFilterModel();
        let category: ActivityCategory | undefined;
        if (filters?.categoryDescription && filters.categoryDescription?.filter) {
            category = activityCategories.find((cat) => (cat.description === filters.categoryDescription.filter));
        }
        const currentPage = gridRef.current!.api.paginationGetCurrentPage();
        const currentPageSize = gridRef.current!.api.paginationGetPageSize();
        let activityRow = gridRef.current!.api.applyServerSideTransaction({
            add: [{
                id: uuidv4(),
                description: '',
                displayId: undefined,
                rate: undefined,
                output: undefined,
                masterReferenceId: undefined,
                isNew: true,
                canDelete: true,
                categoryId: (category) ? category.id : undefined,
                unitId: undefined,
                unit: undefined,
                type: "activity",
                categoryDescription: (category) ? category.description : undefined,
            }],
            addIndex: currentPage * currentPageSize
        });
        if (activityRow && activityRow.add && activityRow.add.length > 0 && activityRow.add[0] && typeof activityRow.add[0].rowIndex === 'number' && activityRow.add[0].id) {
            if (activityRow.add[0].id) {
                queueMicrotask(() => flushSync(() => {
                    if (activityRow && activityRow.add && activityRow.add[0].id) {
                        const node = gridRef.current!.api.getRowNode(activityRow.add[0].id);
                        if (node) {
                            gridRef.current!.api.ensureNodeVisible(node, "middle");
                            setCurrentEditing({ node: node, column: 'displayId' });
                        }
                    }
                }))
            }
        }
    }, [activityCategories, confirm, currentActivityRows, currentEditing])

    const handleClose = useCallback((option?: string) => {
        switch (option) {
            case 'New in form':
                addNewActivityInForm();
                break;
            case 'New inline':
            default:
                addActivityInline();
                break;
        }
    }, [addNewActivityInForm, addActivityInline])

    const onPageChanged = useCallback((event: PaginationChangedEvent<ActivityView>) => {
        setPageError(undefined);
        if (currentEditing && currentEditing.node?.data && event.newPage) {
            cancelEditing(currentEditing.node);
        }
    }, [cancelEditing, currentEditing])

    const onPageFilterSortChanged = useCallback((event: SortChangedEvent<ActivityView> | FilterChangedEvent<ActivityView>) => {
        setPageError(undefined);
        if (currentEditing && currentEditing.node?.data) {
            cancelEditing(currentEditing.node);
        }
    }, [cancelEditing, currentEditing])

    return <><Box height="100%">
        {!showAdd && <><Box marginTop="10px" marginBottom="10px" display="flex" alignItems="center" justifyContent="space-between">
            <SplitButton
                disabled={editDisabledRef.current}
                buttonHeight="32px"
                options={addMenuItems}
                buttonText="New"
                buttonIcon={<AddIcon />}
                onButtonClick={handleClose}
                onMenuItemClick={handleClose} />
            <Box flex="1" marginLeft="20px">
                <ActivityCategoryFilters estimateId={props.estimateId} onFilterChanged={filterChanged} />
            </Box>
            <Button
                variant="outlined"
                startIcon={<CategoryOutlinedIcon />}
                onClick={handleOpenCategoriesModal}>Categories</Button>
        </Box>
            {pageError &&
                <Typography variant="body1" color={colors.redAccent[500]}>
                    {pageError}
                </Typography>
            }
            <Box
                className="ag-theme-alpine ag-theme-bidbow inner-col"
                style={{ height: (pageError) ? 'calc(100% - 68px)' : 'calc(100% - 48px)', width: '100%' }}
                onDragOver={gridDragOver}>
                <AgGridReact<ActivityView>
                    ref={gridRef}
                    animateRows={false}
                    editType={'fullRow'}
                    suppressScrollOnNewData={true}
                    rowDragManaged={true}
                    columnDefs={panelColumnDefs}
                    onGridReady={onGridReady}
                    getRowId={getRowId}
                    rowModelType={"serverSide"}
                    pagination={true}
                    cacheBlockSize={50}
                    paginationPageSize={50}
                    paginationPageSizeSelector={false}
                    onRowEditingStarted={onRowEditingStarted}
                    onRowEditingStopped={onRowEditingStopped}
                    onCellKeyDown={onCellKeyDown}
                    onCellClicked={onCellClicked}
                    onPaginationChanged={onPageChanged}
                    onSortChanged={onPageFilterSortChanged}
                    onFilterChanged={onPageFilterSortChanged}
                />
            </Box>
        </>}
        {showAdd && props.estimateId && <AddActivity key={keyToUse} resetAndAddNew={resetAndAddNew} backToList={backToList} activity={editActivity} estimateId={props.estimateId} panelState={props.panelState} />}
    </Box>
        <Modal
            open={openCategoriesModal}
            onClose={handleCloseCategoriesModal}
            aria-labelledby="categories-modal"
            aria-describedby="categories-modal-description"
        >
            <Box sx={{ ...importModalStyle }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Activity Categories</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="delete" onClick={handleCloseCategoriesModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <AddEditActivityCategory estimateId={props.estimateId} />
            </Box>
        </Modal>
    </>;

}