import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import { ColDef, Column, ExcelExportParams, GetRowIdParams, GridApi, IRowNode, ProcessCellForExportParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { rounder } from "Helpers/rounder";
import { IndirectItemListReport, IndirectItemListReportView } from "Models/item-list-report";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import { v4 as uuidv4 } from 'uuid';
import { format } from "date-fns";
import { TableCell, TDocumentDefinitions } from "pdfmake/interfaces";
import { useGetUnitsQuery } from "State/Services/unit";
import pdfMake from 'pdfmake/build/pdfmake';
import fullLogo from 'Images/logo.png';
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";

// Row colors
const HEADER_ROW_COLOR = '#f8f8f8';
const EVEN_ROW_COLOR = '#fcfcfc';
const ODD_ROW_COLOR = '#E6E6E6';

const PDF_INNER_BORDER_COLOR = '#dde2eb';
const PDF_OUTER_BORDER_COLOR = '#babfc7';
export interface IndirectItemBreakdownLevel3Props {
    estimateId: string | undefined;
    pageSize: number;
    indirectItemBreakDownReport: {
        results: Array<IndirectItemListReport>;
    }
}

export default function IndirectItemBreakdownLevel3(props: IndirectItemBreakdownLevel3Props) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<IndirectItemListReportView>>(null);
    const [rowData, setRowData] = useState<Array<IndirectItemListReportView>>([]);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    useEffect(() => {
        if (props.indirectItemBreakDownReport && props.indirectItemBreakDownReport.results.length > 0) {
            setRowData(props.indirectItemBreakDownReport.results.map((result) => ({
                ...result,
                unit: {
                    unitDescription: result.customUnit,
                    unitId: result.unitId
                },
                id: uuidv4()
            })));
        }
    }, [props.indirectItemBreakDownReport])

    const getBaseDefs = useCallback((): Array<ColDef<IndirectItemListReportView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'resourceId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'activityId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'subItemId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'order',
                hide: true,
                suppressColumnsToolPanel: true,
                sort: 'asc'
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalQuantity',
                headerName: 'Quantity',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalAmount',
                headerName: 'Amount',
                cellDataType: "number",
                aggFunc: params => {
                    if (params.rowNode.level >= 0) {
                        return params?.data?.totalAmount;
                    }
                    let total = 0;
                    params.values.forEach(value => total += value);
                    return total;
                },
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                }
            },
        ];
    }, [colors?.gray, settings?.quantityDecimals, estimate?.CompanyCurrency?.Currency?.minorUnit])

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            field: 'displayId',
            resizable: true,
            headerName: 'ID',
            cellDataType: "text",
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            cellRendererParams: {
                suppressCount: true,
            }
        };
    }, [colors?.gray]);

    const getRowId = useCallback(function (params: GetRowIdParams<IndirectItemListReportView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const getRowStyle = (params: any) => {
        if (params.node?.data?.hierarchy?.length===1) {
            return { fontWeight: 'bold' };
        }
    };

    const getHeaderToExport = useCallback((gridApi: GridApi<IndirectItemListReportView>) => {
        const columns = gridApi.getAllDisplayedColumns();
        return columns.map((column) => {
            const { field, sort } = column.getColDef();
            let headerCell = { text: '', bold: false, margin: [] as Array<number>, alignment: '' };
            if (field) {
                // Enables export when row grouping
                const headerName = column.getColDef().headerName ?? field;
                const headerNameUppercase =
                    headerName[0].toUpperCase() + headerName.slice(1);
                const cellStyle = column.getColDef().cellStyle;
                headerCell = {
                    text: headerNameUppercase + (sort ? ` (${sort})` : ''),
                    alignment: ((cellStyle && 'textAlign' in cellStyle) && cellStyle?.textAlign) ? cellStyle?.textAlign as string : 'left',
                    // styles
                    bold: true,
                    margin: [0, 12, 0, 0],
                };
            }

            return headerCell;
        });
    }, []);

    const getCellToExport = useCallback((column: Column<IndirectItemListReportView>, node: IRowNode<IndirectItemListReportView>): TableCell | undefined => {
        let cellText = gridRef.current!.api.getCellValue({
            colKey: column,
            rowNode: node
        });
        switch (column.getColDef().cellDataType) {
            case "number":
                cellText = rounder(cellText, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)
                break;
            default:
                break;
        }
        const cellStyle = column.getColDef().cellStyle;

        return {
            text: cellText,
            colSpan: (node.group && column.getColId() === 'displayId') ? 5 : 1,
            alignment: ((cellStyle && 'textAlign' in cellStyle) && cellStyle?.textAlign) ?? 'left',
            fontSize: 10,
            // styles
            ...column.getColDef().cellStyle,
        };
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit]);

    const getRowsToExport = useCallback((gridApi: GridApi<IndirectItemListReportView>) => {
        const columns = gridApi.getAllDisplayedColumns();
        const rowsToExport = new Array<TableCell[]>();

        gridApi.forEachNodeAfterFilterAndSort((node) => {
            let row = new Array<TableCell>();
            for (let i = 0; i < columns.length; i++) {
                const column = columns[i];
                const cells = getCellToExport(column, node);
                if (cells)
                    row.push(cells);
            }
            if (row.length > 0) {
                rowsToExport.push([...row]);
            }
        });

        return rowsToExport;
    }, [getCellToExport]);

    const createLayout = useCallback((numberOfHeaderRows: number) => ({
        fillColor: (rowIndex: number) => {
            if (rowIndex < numberOfHeaderRows) {
                return HEADER_ROW_COLOR;
            }
            return rowIndex % 2 === 0 ? EVEN_ROW_COLOR : ODD_ROW_COLOR;
        },
        paddingTop: function (rowIndex: number, node: any) {
            return rowIndex === 0 ? 2 : 8;
        },
        paddingBottom: function (rowIndex: number, node: any) {
            return rowIndex === 0 ? 2 : 8;
        },
        //vLineHeight not used here.
        vLineWidth: (rowIndex: number, node: any) =>
            rowIndex === 0 || rowIndex === node.table.widths.length ? 1 : 0,
        hLineColor: (rowIndex: number, node: any) =>
            rowIndex === 0 || rowIndex === node.table.body.length
                ? PDF_OUTER_BORDER_COLOR
                : PDF_INNER_BORDER_COLOR,
        vLineColor: (rowIndex: number, node: any) =>
            rowIndex === 0 || rowIndex === node.table.widths.length
                ? PDF_OUTER_BORDER_COLOR
                : PDF_INNER_BORDER_COLOR,
    }), []);

    const convertImageToBase64 = useCallback(async () => {
        return new Promise((resolve, reject) => {
            try {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.src = fullLogo;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    const ctx = canvas.getContext('2d');
                    ctx!.fillStyle = 'white';
                    ctx!.fillRect(0, 0, canvas.width, canvas.height);
                    ctx!.drawImage(img, 0, 0);
                    const base64data = canvas.toDataURL('image/png', 1.0);
                    resolve(base64data);
                };
            } catch (error) {
                reject(error);
            }
        });
    }, [])

    const getDocument = useCallback(async (gridApi: GridApi<IndirectItemListReportView>): Promise<TDocumentDefinitions> => {
        const headerRow = getHeaderToExport(gridApi);
        const rows = getRowsToExport(gridApi);
        const logo = await convertImageToBase64();
        return {
            pageOrientation: 'landscape' as any, // can also be 'portrait'
            pageMargins: [10, 20] as any,
            content: [
                {
                    style: 'header',
                    columns: [
                        {
                            image: logo as string,
                            width: 196,
                            height: 60

                        },
                    ]
                },
                {
                    columns: [
                        {
                            text: `Project: ${estimate?.name}`,
                            style: 'headerInfo'
                        },
                        {
                            text: `Date: ${format(new Date().getTime(), 'MM/dd/yyyy')}`,
                            style: 'headerDate'
                        }
                    ]
                },
                {
                    table: {
                        // the number of header rows
                        headerRows: 1,

                        // the width of each column, can be an array of widths
                        widths: [50, '*', 50, 80, 80, 50],
                        // widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        // all the rows to display, including the header rows
                        body: [headerRow, ...rows],

                        // Header row is 40px, other rows are 15px
                        heights: (rowIndex: number) => (rowIndex === 0 ? 40 : 15),
                    },
                    layout: createLayout(1),
                },
            ],
            styles: {
                headerDate: {
                    alignment: 'right' as any,
                },
                header: {
                    marginBottom: 20,
                },
                alignCenter:
                {
                    alignment: 'center'
                },
                headerInfo: {
                    marginTop: 5,
                    marginBottom: 15,
                    fontSize: 12,
                    alignment: 'left'
                }
            },
            defaultStyle: {
                columnGap: 20
            }

        };
    }, [convertImageToBase64, createLayout, estimate?.name, getHeaderToExport, getRowsToExport]);

    const exportToPDF = useCallback(async () => {
        setIsGeneratingPdf(true);
        if (gridRef.current!.api) {
            const doc = await getDocument(gridRef.current!.api);
            pdfMake.createPdf(doc).download();
        }
        setIsGeneratingPdf(false);
    }, [getDocument]);

    return <Box display="flex" flexDirection="column" width="100%" height="100%">
        {/* <Box display="flex" justifyContent="flex-end" gap="20px" marginBottom="20px">
            <Button variant="outlined" onClick={exportToPDF}>Download PDF {isGeneratingPdf && <CircularProgress sx={{ marginLeft: "10px" }} size="1rem" />}</Button>
        </Box> */}
        <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
            <AgGridReact<IndirectItemListReportView>
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                getRowId={getRowId}
                getRowStyle={getRowStyle}
                paginationPageSize={props.pageSize}
                pagination={true}
                groupDefaultExpanded={-1}
                treeData={true}
                grandTotalRow="bottom"
                getDataPath={(data: any) => (data.hierarchy)}
            />
        </Box>

    </Box>
}