import { Box, useTheme } from "@mui/material";
import { SubcontractorActivityResource, SubcontractorActivityResourceView, SubcontractorPackageVendor } from "Models/subcontractor";
import SubcontractorResourceActivityAppend from "./SubcontractorResourceActivityAppend";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import { useGetUnitsQuery } from "State/Services/unit";
import { AgGridReact, getInstance } from "ag-grid-react";
import { ColDef, IRowNode, SuppressKeyboardEventParams } from "ag-grid-enterprise";
import { useConfirm } from "material-ui-confirm";
import { rounder } from "Helpers/rounder";
import { CellClickedEvent, CellKeyDownEvent, GetRowIdParams, ICellEditor, RowEditingStartedEvent, RowEditingStoppedEvent } from "ag-grid-community";
import { useGetSubcontractorResourcesActivitiesQuery, useUpdateSubcontractorResourcesActivitiesMutation } from "State/Services/subcontractor";
import { PackageItemDetails } from "Models/package";
import { sort } from "Helpers/sort";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import { BaseEditCellRenderer } from "Models/grid";
import GenericNumberEditCellRenderer from "Components/Shared/GenericNumberEditCellRenderer";
import GenericActionsCellRenderer from "Components/Shared/GenericActionsEditCellRenderer";

export interface SubcontractorResourceActivityProps {
    estimateId: string;
    packageVendor: SubcontractorPackageVendor;
    item: PackageItemDetails;
    setSubcontractorActivitiesResources: (subcontractorItemActivitiesResources: Array<SubcontractorActivityResourceView>) => void;
    deleteResource: (resourceId: string) => Promise<void>;
    deleteActivity: (activityId: string) => Promise<void>;
    disabled: boolean;
}

const SubcontractorResourceActivity = (props: SubcontractorResourceActivityProps) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 32px)', width: '100%' }), []);
    const theme = useTheme();
    const [colors, setColors] = useState<any>(tokens(theme.palette.mode));
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<SubcontractorActivityResourceView>>(null);
    const [rowData, setRowData] = useState<Array<SubcontractorActivityResourceView>>([]);
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<SubcontractorActivityResourceView> | undefined, column?: string }>();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const confirm = useConfirm();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const { data: storedSubcontractorItemActivitiesResources } = useGetSubcontractorResourcesActivitiesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '', itemId: props.item.itemId ?? '', packageId: props.packageVendor.subcontractorPackageId ?? '', subcontractorPackageVendorId: props.packageVendor.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !props.item.itemId || !props.packageVendor.subcontractorPackageId || !props.packageVendor.id });
    const [updateSubcontractorItemActivity] = useUpdateSubcontractorResourcesActivitiesMutation();
    const [errors, setErrors] = useState<Array<{ field: string, error: string }>>([]);

    useEffect(() => {      
        if (errors.length > 0) {
            errors.forEach((errorDetails) => {
                const instances = gridRef.current!.api.getCellEditorInstances({
                    columns: [errorDetails.field]
                });
                if (instances.length > 0) {
                    getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) =>{
                        if (instance && instance.setError) {
                            instance.setError(errorDetails.error);
                        }
                    });
                }
            });
        }
    }, [errors])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const calculateAmount = useCallback((SubcontractorActivityResource: SubcontractorActivityResource) => {
        let amount: number | undefined = undefined;
        if (!SubcontractorActivityResource.quantityPerUnit || !SubcontractorActivityResource.rate) return;

        if (SubcontractorActivityResource.factor) {
            amount = SubcontractorActivityResource.quantityPerUnit * SubcontractorActivityResource.rate * SubcontractorActivityResource.factor;
        } else {
            amount = SubcontractorActivityResource.quantityPerUnit * SubcontractorActivityResource.rate;
        }

        return amount;
    }, [])

    useEffect(() => {
        if (storedSubcontractorItemActivitiesResources && units) {
            const rows = new Array<SubcontractorActivityResourceView>();
            storedSubcontractorItemActivitiesResources.forEach((det: SubcontractorActivityResource) => {
                const unit = units?.find((unit) => (unit.id === det.unitId));
                const amount = calculateAmount(det);
                rows.push({
                    id: det.id,
                    description: det.description,
                    displayId: det.displayId,
                    amount: amount,
                    unitId: det.unitId,
                    unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined,
                    quantityPerUnit: det.quantityPerUnit,
                    type: det.type,
                    activityId: det.activityId,
                    factor: det.factor,
                    resourceId: det.resourceId,
                    actions: "",
                    rate: det.rate,
                    isNew: false,
                    selected: true
                });
            });
            rows.sort(sort);
            setRowData(rows);
            props.setSubcontractorActivitiesResources(rows);
        } else {
            setRowData([]);
            props.setSubcontractorActivitiesResources([]);
        }
    }, [calculateAmount, props, storedSubcontractorItemActivitiesResources, units])

    const saveStandardItemActivityResource = useCallback(async (nodeToSave: IRowNode<SubcontractorActivityResourceView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<SubcontractorActivityResourceView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && props.estimateId && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    if (nodeToSave.data.id) {
                        await updateSubcontractorItemActivity({
                            companyId: user?.companyId,
                            estimateId: props.estimateId,
                            itemId: props.item.itemId,
                            packageId: props.packageVendor.subcontractorPackageId,
                            subcontractorPackageVendorId: props.packageVendor.id,
                            orgId: user.organizationId,
                            body: {
                                activityId: nodeToSave.data.activityId ?? undefined,
                                resourceId: nodeToSave.data.resourceId ?? undefined,
                                quantityPerUnit: nodeToSave.data.quantityPerUnit,
                                factor: nodeToSave.data.factor,
                                type: nodeToSave.data.type
                            }
                        }).unwrap();
                    }
                    resolve();
                    if (toEditAfterSave) {
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    } else {
                        setCurrentEditing(undefined);
                    }
                }
            } catch (error: any) {
                if (error && error.data) {
                    if (typeof nodeToSave.rowIndex === 'number') {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: 'displayId',
                        });
                    }
                    if (error.data.factor) {
                        setErrors([{ field: 'factor', error: error.data.factor }]);
                    }
                }
                resolve();
            }
        });
    }, [props.estimateId, props.item.itemId, props.packageVendor.id, props.packageVendor.subcontractorPackageId, updateSubcontractorItemActivity, user])

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<SubcontractorActivityResourceView>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();
            saveStandardItemActivityResource(params.node);
            return true;
        }
        return false;
    }, [saveStandardItemActivityResource])

    const cancelEditing = useCallback((node: IRowNode<SubcontractorActivityResourceView>) => {
        if (node && node.data) {
            setIsCancelClicked(true);
            const activityResource = storedSubcontractorItemActivitiesResources?.find((c) => (c.id === node.data?.id));
            if (activityResource) {
                const unit = units?.find((unit) => (unit.id === activityResource.unitId));
                const amount = calculateAmount(activityResource);
                gridRef.current!.api.applyTransaction({ update: [{ ...activityResource, amount: amount, unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined, isNew: false }] });
            }
            gridRef.current!.api.stopEditing(true);
            setCurrentEditing(undefined);
        }
    }, [calculateAmount, storedSubcontractorItemActivitiesResources, units])

    const deleteRow = useCallback((node: IRowNode<SubcontractorActivityResourceView>) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await confirm({ description: `Are you sure you want to delete the record?`, title: 'Delete confirmation' });
                if (user && node.data) {
                    switch (node.data.type) {
                        case "resource":
                            if (node.data.resourceId) {
                                await props.deleteResource(node.data.resourceId);
                            }
                            break;
                        case "activity":
                            if (node.data.activityId) {
                                await props.deleteActivity(node.data.activityId);
                            }
                            break;
                        default:
                            break;
                    }
                }
                resolve();
            } catch (error) {
                reject('Cancelled by user.');
            }
        });
    }, [confirm, props, user])

    const onFactorChange = useCallback((node: IRowNode<SubcontractorActivityResourceView>, value: number | null) => {
        let quantityPerUnit: number | undefined;
        const instances = gridRef.current!.api.getCellEditorInstances({
            columns: ['quantityPerUnit']
        });
        if (!node.data?.rate) {
            node.setDataValue('amount', undefined);
            return;
        };

        if (isNaN(parseFloat(node.data.rate.toString()))) {
            node.setDataValue('amount', undefined);
            return;
        };
        getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
            if (instance) {
                quantityPerUnit = instance.getValue();
                if (!quantityPerUnit) {
                    node.setDataValue('amount', undefined);
                    return;
                }

                if (isNaN(parseFloat(quantityPerUnit.toString()))) {
                    node.setDataValue('amount', undefined);
                    return;
                }
                let amount: number | undefined = undefined;
                if (node.data?.rate) {
                    if (value && !isNaN(value)) {
                        amount = quantityPerUnit * parseFloat(node.data.rate.toString()) * value;
                    } else {
                        amount = quantityPerUnit * parseFloat(node.data.rate.toString());
                    }
                }
                node.setDataValue('amount', amount);
            }
        });
    }, [])
    const onFactorChangeRef = useRef<any>();
    onFactorChangeRef.current = onFactorChange;

    const onQuantityPerUnitChange = useCallback((node: IRowNode<SubcontractorActivityResourceView>, value: number | null) => {
        let factor: number | undefined;
        const instances = gridRef.current!.api.getCellEditorInstances({
            columns: ['factor']
        });
        if (value && isNaN(value)) {
            node.setDataValue('amount', undefined);
            return;
        };

        if (!node.data?.rate) {
            node.setDataValue('amount', undefined);
            return;
        };

        if (isNaN(parseFloat(node.data.rate.toString()))) {
            node.setDataValue('amount', undefined);
            return;
        };
        getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
            if (instance && value && node.data?.rate) {
                factor = instance.getValue();
                let amount: number | undefined = undefined;
                if (factor && !isNaN(factor)) {
                    amount = value * parseFloat(node.data.rate.toString()) * factor;
                } else {
                    amount = value * parseFloat(node.data.rate.toString());
                }
                node.setDataValue('amount', amount);
            }
        });
    }, [])
    const onQuantityPerUnitChangeRef = useRef<any>();
    onQuantityPerUnitChangeRef.current = onQuantityPerUnitChange;

    const getBaseDefs = useCallback((): Array<ColDef<SubcontractorActivityResourceView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'activityId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'resourceId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                resizable: true,
                headerName: 'ID',
                hide: false,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                showRowGroup: true,
                width: 80,
                autoHeight: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
                editable: (params) => (params.data?.isNew ?? false),
                suppressKeyboardEvent: saveOnEnter,
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                resizable: true,
                editable: (params) => (params.data?.isNew ?? false),
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                resizable: true,
                width: 80,
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                editable: false,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantityPerUnit',
                headerName: 'Quantity / Unit',
                width: 135,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    field: 'Quantity / Unit',
                    onChangeCallBack: (node: IRowNode<SubcontractorActivityResourceView>, value: number | null) => onQuantityPerUnitChangeRef.current(node, value)
                },
                resizable: true,
                editable: true,
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'factor',
                headerName: 'Factor',
                width: 90,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                suppressKeyboardEvent: saveOnEnter,
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    field: 'Factor',
                    onChangeCallBack: (node: IRowNode<SubcontractorActivityResourceView>, value: number | null) => onFactorChangeRef.current(node, value)
                },
                resizable: true,
                editable: true,
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                suppressKeyboardEvent: saveOnEnter,
                width: 80,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: false,
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'amount',
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                width: 100,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                editable: false,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                }
            },
            {
                field: 'actions',
                resizable: true,
                width: 80,
                headerName: 'Actions',
                editable: false,
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRenderer: GenericActionsCellRenderer,
                cellRendererParams: {
                    save: saveStandardItemActivityResource,
                    cancel: cancelEditing,
                    deleteRecord: deleteRow,
                    disabled: () => props.disabled
                }
            },
        ]
    }, [colors?.gray, saveOnEnter, props.disabled, saveStandardItemActivityResource, cancelEditing, deleteRow, settings?.quantityDecimals, estimate?.CompanyCurrency?.Currency?.minorUnit]);

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const onRowEditingStarted = useCallback((event: RowEditingStartedEvent<SubcontractorActivityResourceView>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent<SubcontractorActivityResourceView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent<SubcontractorActivityResourceView>) => {
        if (isCancelClicked) {
            setIsCancelClicked(false);
            return;
        };

        if (currentEditing?.node === event.node || props.disabled) {
            return;
        }

        if (event.column.getColId() === 'actions') {
            return;
        }

        if (!currentEditing?.node) {
            if (event.column.isCellEditable(event.node)) {
                setCurrentEditing({ node: event.node, column: event.column.getColId() });
            } else {
                setCurrentEditing({ node: event.node, column: undefined });
            }
        } else {
            await saveStandardItemActivityResource(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
        }
    }, [currentEditing?.node, isCancelClicked, props.disabled, saveStandardItemActivityResource])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column ?? 'quantityPerUnit',
            });
        }
    }, [currentEditing, props])

    const onCellKeyDown = useCallback((e: CellKeyDownEvent<SubcontractorActivityResourceView>) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelEditing]);

    const getRowId = useCallback(function (params: GetRowIdParams<SubcontractorActivityResourceView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    return <><Box height="100%">
        <Box paddingBottom="5px" display="flex" alignItems="center" width="500px">
            <SubcontractorResourceActivityAppend disabled={props.disabled} item={props.item} packageVendor={props.packageVendor} estimateId={props.estimateId} />
        </Box>
        <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
            <AgGridReact<SubcontractorActivityResourceView>
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                editType={'fullRow'}
                defaultColDef={defaultColDef}
                suppressClickEdit={true}
                onRowEditingStarted={onRowEditingStarted}
                onRowEditingStopped={onRowEditingStopped}
                onCellClicked={onCellClicked}
                onCellKeyDown={onCellKeyDown}
                getRowId={getRowId}
            />
        </Box>
    </Box></>
}

export default memo(SubcontractorResourceActivity);