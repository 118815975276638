import { Box, Button, Chip, IconButton, useTheme } from "@mui/material";
import { ICellRendererParams } from "ag-grid-enterprise";
import { ItemView } from "Models/item";
import { useCallback, useEffect, useState } from "react";
import { useGetCategoriesQuery } from "State/Services/category";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ReactComponent as Level2Icon } from 'Icons/level2Icon.svg';
import { ReactComponent as Level3Icon } from 'Icons/level3Icon.svg';
import { ReactComponent as Level4Icon } from 'Icons/level4Icon.svg';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { tokens } from "theme";

export interface ItemsGroupedRendererParams extends ICellRendererParams<ItemView> {
    levels: string;
    estimateId: string;
}

export default function ItemsGroupedRenderer(props: ItemsGroupedRendererParams) {
    const [displayCategoryId, setDisplayCategoryId] = useState<number | undefined>();
    const [repetitions, setRepetitions] = useState<string | undefined>();
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedCategories } = useGetCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId, refetchOnMountOrArgChange: true  });
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { node: currentNode, value } = props;
    const [expanded, setExpanded] = useState(currentNode.expanded);

    useEffect(() =>{
        if (storedCategories) {
            const cat = storedCategories.find((c) => (c.category === props.value));
            if (cat) {
                setDisplayCategoryId(cat.displayId);
                setRepetitions(cat.repetitions?.toString());
            }
        }
    }, [props.value, storedCategories])

    const onClick = useCallback(() => currentNode.setExpanded(!currentNode.expanded), [currentNode]);
    
    const expandListener = (event: any) => {
        setExpanded(event.node.expanded);
    }
    currentNode.removeEventListener('expandedChanged', expandListener);
    currentNode.addEventListener('expandedChanged', expandListener);

    const displayRepetitions = useCallback(() => {
        let display = '';

        if (displayCategoryId) {
            display = (displayCategoryId && displayCategoryId.toString()) ? `${display}${String(displayCategoryId).padStart(2, '0')} - ${value}` : `${display}${value}`;
        }

        if (repetitions && parseFloat(repetitions)>1) {
            display = `${display} (${repetitions}) `;
        }
        return display;
    }, [displayCategoryId, repetitions, value])


    return <Box display="flex" justifyContent="space-between"
    style={{
        paddingLeft: (currentNode.group) ? `${currentNode.level * 15}px` : "10px",
    }}>
    <Box display="flex">
        <>
            {currentNode.group && (
                <IconButton size="small" onClick={onClick}>
                    <PlayArrowRoundedIcon
                        style={{
                            cursor: 'pointer',
                            transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                            display: 'inline-block',
                            color: `${colors.gray[600]}`
                        }}
                    >
                        &rarr;
                    </PlayArrowRoundedIcon>
                </IconButton>
            )}
            {currentNode.group && <Button sx={{ cursor: "default", paddingLeft: "0px" }}>
                <Box>
                    {currentNode.level === 0 && <Chip label={displayRepetitions()} sx={{ borderRadius: '8px', backgroundColor: colors.primary[300], color: 'white', fontWeight: 'bold' }} size="small" />}
                    {currentNode.level === 1 &&
                        <Box fontWeight="bold" color={`${colors.gray[100]}`} display="flex" flexDirection="row" >
                            <Box display="flex" marginRight="6px"><Level2Icon /></Box>
                            {(displayCategoryId && displayCategoryId.toString()) ? `${String(displayCategoryId).padStart(2, '0')} - ${value}` : `${value}`}
                        </Box>
                    }
                    {currentNode.level === 2 &&
                        <Box fontWeight="bold" color={`${colors.gray[100]}`} display="flex" flexDirection="row">
                            <Box display="flex" marginRight="6px"><Level3Icon /></Box>
                            {(displayCategoryId && displayCategoryId.toString()) ? `${String(displayCategoryId).padStart(2, '0')} - ${value}` : `${value}`}
                        </Box>
                    }
                    {currentNode.level === 3 &&
                        <Box fontWeight="bold" color={`${colors.gray[100]}`} display="flex" flexDirection="row">
                            <Box display="flex" marginRight="6px"><Level4Icon /></Box>
                            {(displayCategoryId && displayCategoryId.toString()) ? `${String(displayCategoryId).padStart(2, '0')} - ${value}` : `${value}`}
                        </Box>
                    }
                </Box>
            </Button>}
            {!currentNode.group && <Box color={`${colors.gray[100]}`}>{value}</Box>}
        </>
    </Box>
</Box>
}