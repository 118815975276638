const isCharNumeric = (charStr: string) => {
    return !!/^[0-9.]$/.test(charStr);
};

export const isNumericKey = (event: any) => {
    const charStr = event.key;
    return isCharNumeric(charStr);
};

export const getParsedValue = (value: string) => {
    const sanitizedVal = value === null ? '' : String(value).replace(/[^\d.]/g, '');
    const floatVal = sanitizedVal.replace(/(\..*)\./g, '$1');
    const parsed = floatVal === '' ? null : parseFloat(floatVal);

    return parsed;
};


export const isBackspaceOrDelete = (event: any) => {
    return event.key === "Backspace" || event.key === "Delete";
};

export const isLeftOrRight = (event: any) => {
    return ["ArrowLeft", "ArrowRight"].indexOf(event.key) > -1;
};