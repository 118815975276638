import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, Typography, useTheme } from "@mui/material";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import SummarizeIcon from '@mui/icons-material/SummarizeOutlined';
import { useCallback, useEffect, useState } from "react";
import { useGetIndirectItemReportLevel1Mutation, useGetIndirectItemReportLevel3Mutation, useGetItemPriceReportMutation, useGetItemReportLevel1Mutation, useGetItemReportLevel3Mutation, useLazyGetActivityCostListQuery, useLazyGetActivityListQuery, useLazyGetDirectProjectResourceLevel1ReportQuery, useLazyGetDirectProjectResourceLevel2ReportQuery, useLazyGetDirectProjectResourceLevel3ReportQuery, useLazyGetEstimatesForReportQuery, useLazyGetIndirectProjectResourceLevel1ReportQuery, useLazyGetIndirectProjectResourceLevel2ReportQuery, useLazyGetIndirectProjectResourceLevel3ReportQuery, useLazyGetItemActivityCostListQuery, useLazyGetProjectResourceLevel1ReportQuery, useLazyGetProjectResourceLevel2ReportQuery, useLazyGetProjectResourceLevel3ReportQuery, useLazyGetResourceCostListQuery, useLazyGetResourceListQuery, useLazyGetStandardItemCostListQuery, useLazyGetStandardItemListQuery, useLazyGetSubcontractorQuotationsQuery, useLazyGetSubcontractorQuotesQuery, useLazyGetSupplierQuotationsQuery, useLazyGetSupplierQuotesQuery } from "State/Services/report";
import { ActivityCostListReport, ActivityListReport, BaseReportResource, CategorySubcategoryTotal, CategoryTotal, ItemActivityCostListReport, ReportEstimate, ResourceListReport, ResourceReport, StandardItemCostReport, StandardItemReport, SubcontractorItemReport, SubcontractorItemReportQuotation, SupplierResourceReport, SupplierResourceReportQuotation } from "Models/report";
import ProjectResourceLevel3 from "./ProjectResourceLevel3";
import ProjectResourceLevel2 from "./ProjectResourceLevel2";
import ProjectResourceLevel1 from "./ProjectResourceLevel1";
import { useParams } from "react-router-dom";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { useGetEstimateQuery } from "State/Services/estimate";
import ProjectPriceList from "./ProjectPriceList";
import ProjectListReportDecimals from "./ProjectListReportDecimals";
import ItemBreakdownLevel3 from "./ItemBreakdownLevel3";
import { IndirectItemListReport, ItemListReport, ItemPriceListReport } from "Models/item-list-report";
import ItemBreakdownByResourceCategoryLevel3 from "./ItemBreakdownByResourceCategoryLevel3";
import IndirectItemBreakdownLevel3 from "./IndirectItemBreakdownLevel3";
import IndirectItemBreakdownByResourceCategoryLevel3 from "./IndirectItemBreakdownByResourceCategoryLevel3";
import ItemPriceBreakdownByResourceCategory from "./ItemPriceBreakdownByResourceCategory";
import ItemPriceBreakdownByResourceCategoryPercentage from "./ItemPriceBreakdownByResourceCategoryPercentage";
import ItemPriceBreakdownByResourceCategoryRate from "./ItemPriceBreakdownByResourceCategoryRate";
import ProjectPriceListEstQuantity from "./ProjectPriceListEstQuantity";
import ProjectPriceListCombined from "./ProjectPriceListCombined";
import ReportRateCost from "./ResourceRateCost";
import ResourceRateList from "./ResourceRateList";
import ActivityRateCost from "./ActivityRateCost";
import ActivityRateList from "./ActivityRateList";
import StandardItemRateCost from "./StandardItemRateCost";
import StandardItemRateList from "./StandardItemRateList";
import ProjectCostPriceListCombined from "./ProjectCostPriceListCombined";
import ItemActivityRateList from "./ItemActivityRateList";
import ResourceAllocation from "./ResourceAllocation";
import ResourceSelector, { ReportResource } from "./ResourceSelector";
import ActivityAllocation from "./ActivityAllocation";
import ActivitySelector, { ReportActivity } from "./ActivitySelector";
import SubcontractorPackageSelector, { ReportSubcontractorPackage } from "./SubcontractorPackageSelector";
import SubcontractorQuotesReport from "./SubcontractorQuotesReport";
import SupplierPackageSelector, { ReportSupplierPackage } from "./SupplierPackageSelector";
import SupplierQuotesReport from "./SupplierQuotesReport";
import { useGetUnitsQuery } from "State/Services/unit";
import SubcontractorQuotations from "./SubcontractorQuotations";
import SupplierQuotations from "./SupplierQuotations";
import ItemQuotations from "./ItemQuotations";
import ResourceQuotations from "./ResourceQuotations";

const searchModalStyle = {
    backgroundColor: 'white',
    borderRadius: '5px',
    display: 'flex',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "300",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 2,
};

export default function ReportMain() {
    const { estimateId } = useParams();
    const { data: user } = useGetUserDetailsQuery();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [source, setSource] = useState<string | null>(null);
    const { data: fullEstimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const [estimate, setEstimate] = useState<ReportEstimate | null>(null);
    const [estimates, setEstimates] = useState<Array<ReportEstimate>>([]);
    const [getEstimates] = useLazyGetEstimatesForReportQuery();
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [reportType, setReportType] = useState<string | null>(null);
    const [report, setReport] = useState<string | null>(null);
    const [reportOptions, setReportOptions] = useState<Array<{ id: string, name: string }>>([]);
    const [generateProjectResourceLevel3Report] = useLazyGetProjectResourceLevel3ReportQuery();
    const [generateProjectResourceLevel2Report] = useLazyGetProjectResourceLevel2ReportQuery();
    const [generateProjectResourceLevel1Report] = useLazyGetProjectResourceLevel1ReportQuery();
    const [generateIndirectProjectResourceLevel3Report] = useLazyGetIndirectProjectResourceLevel3ReportQuery();
    const [generateDirectProjectResourceLevel3Report] = useLazyGetDirectProjectResourceLevel3ReportQuery();
    const [generateIndirectProjectResourceLevel2Report] = useLazyGetIndirectProjectResourceLevel2ReportQuery();
    const [generateDirectProjectResourceLevel2Report] = useLazyGetDirectProjectResourceLevel2ReportQuery();
    const [generateIndirectProjectResourceLevel1Report] = useLazyGetIndirectProjectResourceLevel1ReportQuery();
    const [generateDirectProjectResourceLevel1Report] = useLazyGetDirectProjectResourceLevel1ReportQuery();
    const [baseReportResources, setBaseReportResources] = useState<Array<BaseReportResource>>([]);
    const [directReportResources, setDirectReportResources] = useState<Array<BaseReportResource>>();
    const [indirectReportResources, setIndirectReportResources] = useState<Array<BaseReportResource>>([]);
    const [categorySubcategoryTotals, setCategorySubcategoryTotals] = useState<Array<CategorySubcategoryTotal>>([]);
    const [categoryTotals, setCategoryTotals] = useState<Array<CategoryTotal>>([]);
    const [directCategorySubcategoryTotals, setDirectCategorySubcategoryTotals] = useState<Array<CategorySubcategoryTotal>>([]);
    const [directCategoryTotals, setDirectCategoryTotals] = useState<Array<CategoryTotal>>([]);
    const [indirectCategorySubcategoryTotals, setIndirectCategorySubcategoryTotals] = useState<Array<CategorySubcategoryTotal>>([]);
    const [indirectCategoryTotals, setIndirectCategoryTotals] = useState<Array<CategoryTotal>>([]);
    const [hasEstimate, setHasEstimate] = useState(false);
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const [selectorOpen, setSelectorOpen] = useState(false);
    const handleSelectorOpen = () => setSelectorOpen(true);
    const [activitySelectorOpen, setActivitySelectorOpen] = useState(false);
    const handleActivitySelectorOpen = () => setActivitySelectorOpen(true);
    const [subcontractorPackageSelectorOpen, setSubcontractorPackageSelectorOpen] = useState(false);
    const handleSubcontractorPackageSelectorOpen = () => setSubcontractorPackageSelectorOpen(true);
    const [supplierPackageSelectorOpen, setSupplierPackageSelectorOpen] = useState(false);
    const handleSupplierPackageSelectorOpen = () => setSupplierPackageSelectorOpen(true);
    const [selectedResource, setSelectedResource] = useState<ReportResource>();
    const [selectedActivity, setSelectedActivity] = useState<ReportActivity>();
    const [selectedSubcontractorPackage, setSelectedSubcontractorPackage] = useState<ReportSubcontractorPackage>();
    const [selectedSupplierPackage, setSelectedSupplierPackage] = useState<ReportSupplierPackage>();
    const [decimals, setDecimals] = useState<number>();
    const [showReport, setShowReport] = useState<boolean>(false);
    const [getItemReport] = useGetItemReportLevel3Mutation();
    const [getIndirectItemReport] = useGetIndirectItemReportLevel3Mutation();
    const [getItemPriceReport] = useGetItemPriceReportMutation();
    const [getItemReportLevel1] = useGetItemReportLevel1Mutation();
    const [getIndirectItemReportLevel1] = useGetIndirectItemReportLevel1Mutation();
    const [getResourceCostList] = useLazyGetResourceCostListQuery();
    const [getResourceList] = useLazyGetResourceListQuery();
    const [getActivityCostList] = useLazyGetActivityCostListQuery();
    const [getActivityList] = useLazyGetActivityListQuery();
    const [getStandardItemCostList] = useLazyGetStandardItemCostListQuery();
    const [getStandardItemList] = useLazyGetStandardItemListQuery();
    const [getItemActivityCostList] = useLazyGetItemActivityCostListQuery();
    const [getSubcontractorQuotes] = useLazyGetSubcontractorQuotesQuery();
    const [getSupplierQuotes] = useLazyGetSupplierQuotesQuery();
    const [getSubcontractorQuotations] = useLazyGetSubcontractorQuotationsQuery();
    const [getSupplierQuotations] = useLazyGetSupplierQuotationsQuery();

    const [subcontractorItemReportQuote, setSubcontractorItemReportQuote] = useState<SubcontractorItemReport>();
    const [subcontractorItemReportQuotation, setSubcontractorItemReportQuotation] = useState<Array<SubcontractorItemReportQuotation>>();
    const [supplierResourceReportQuotation, setSupplierResourceReportQuotation] = useState<Array<SupplierResourceReportQuotation>>();
    const [supplierResourceReportQuote, setSupplierResourceReportQuote] = useState<SupplierResourceReport>();

    const [itemActivityCostList, setItemActivityCostList] = useState<{
        results: Array<ItemActivityCostListReport>;
    }>();
    const [standardItemList, setStandardItemList] = useState<{
        results: Array<StandardItemReport>;
    }>();
    const [standardItemReportCost, setStandardItemReportCost] = useState<{
        results: Array<StandardItemCostReport>;
    }>();
    const [activityReportList, setActivityReportList] = useState<{
        results: Array<ActivityListReport>;
    }>();
    const [activityReportCost, setActivityReportCost] = useState<{
        results: Array<ActivityCostListReport>;
    }>();
    const [resourceReportCost, setResourceReportCost] = useState<{
        results: Array<ResourceReport>;
    }>();
    const [resourceReportList, setResourceReportList] = useState<{
        results: Array<ResourceListReport>;
    }>();
    const [itemBreakDownLevel1, setItemBreakDownLevel1] = useState<{
        results: Array<ItemListReport>;
    }>();
    const [indirectItemBreakDownLevel1, setIndirectItemBreakDownLevel1] = useState<{
        results: Array<IndirectItemListReport>;
    }>();
    const [itemBreakDownLevel3, setItemBreakDownLevel3] = useState<{
        results: Array<ItemListReport>;
        totalCount: number;
        categories: Array<string>;
    }>();
    const [itemPriceBreakDownLevel3, setItemPriceBreakDownLevel3] = useState<{
        results: Array<ItemPriceListReport>;
        categories: Array<string>;
    }>();
    const [indirectItemBreakDownLevel3, setIndirectItemBreakDownLevel3] = useState<{
        results: Array<IndirectItemListReport>;
        totalCount: number;
        categories: Array<string>;
    }>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user && fullEstimate?.EstimateUserRole) {
            if (fullEstimate?.EstimateUserRole?.length === 0) {
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, fullEstimate.EstimateUserRole, {
                    entity: Entity.Reports,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
            }
        } else {
            setHasViewAccess(true);
        }
        setIsReady(true);
    }, [estimate, fullEstimate, user])

    const clearData = useCallback(() => {
        setBaseReportResources([]);
        setCategorySubcategoryTotals([]);
        setCategoryTotals([]);
        setItemBreakDownLevel3(undefined);
        setItemBreakDownLevel1(undefined);
        setIndirectItemBreakDownLevel3(undefined);
        setItemPriceBreakDownLevel3(undefined);
        setIndirectItemBreakDownLevel1(undefined);
        setDecimals(undefined);
        setResourceReportList(undefined);
        setResourceReportCost(undefined);
        setActivityReportCost(undefined);
        setActivityReportList(undefined);
        setStandardItemReportCost(undefined);
        setStandardItemList(undefined);
        setItemActivityCostList(undefined);
        setSelectedResource(undefined);
        setSelectedActivity(undefined);
        setSubcontractorItemReportQuote(undefined);
        setSubcontractorItemReportQuotation(undefined);
        setSupplierResourceReportQuotation(undefined);
        setSupplierResourceReportQuote(undefined);
        setDirectReportResources(undefined);
    }, [])

    const onSourceChange = useCallback(async (source: string | null) => {
        clearData();
        setSource(source);
        setEstimate(null);
        setReportType(null);
        if (user && user.companyId && user.organizationId) {
            const estimateResponse = await getEstimates({
                companyId: user.companyId,
                organizationId: user.organizationId
            });
            if (estimateResponse.data) {
                setEstimates(estimateResponse.data);
            }
        }
    }, [clearData, getEstimates, user])

    const onEstimateChange = useCallback((estimateId: string) => {
        clearData();
        const estimate = estimates.find((est) => (est.id === estimateId));
        if (estimate) {
            setEstimate(estimate);
        }
    }, [clearData, estimates])

    const onReportTypeChange = useCallback((reportType: string | null) => {
        setShowReport(false);
        setReport(null);
        clearData();
        setReportType(reportType);
        switch (reportType) {
            case "vendors":
                setReportOptions([{
                    id: "subcontractorQuotes",
                    name: "Subcontractor Quotes"
                },
                {
                    id: "subcontractorQuotations",
                    name: "Quotes by Subcontractor"
                },
                {
                    id: "itemQuotations",
                    name: "Item Quotations"
                },
                {
                    id: "supplierQuotes",
                    name: "Supplier Quotes"
                },
                {
                    id: "supplierQuotations",
                    name: "Quotes by Supplier"
                },
                {
                    id: "resourceQuotations",
                    name: "Resource Quotations"
                },
                ]);
                break;
            case "allocationSearch":
                setReportOptions([{
                    id: "resourceAllocation",
                    name: "Resource Allocation"
                },
                {
                    id: "activityAllocation",
                    name: "Activity Allocation"
                }
                ]);
                break;
            case "library":
                setReportOptions([
                    {
                        id: "resourceReportList",
                        name: "Resource List"
                    },
                    {
                        id: "resourceReportCost",
                        name: "Resource Cost"
                    },
                    {
                        id: "activityReportList",
                        name: "Activity List"
                    },
                    {
                        id: "activityReportCost",
                        name: "Activity Cost"
                    },
                    {
                        id: "standardItemReportList",
                        name: "Standard Item List"
                    },
                    {
                        id: "standardItemReportCost",
                        name: "Standard Item Cost"
                    }
                ]);
                break;
            case "directResources":
                setReportOptions([{
                    id: "directResourceLevel1",
                    name: "Direct Resources Level 1"
                },
                {
                    id: "directResourceLevel2",
                    name: "Direct Resources Level 2"
                },
                {
                    id: "directResourceLevel3",
                    name: "Direct Resources Level 3"
                },]);
                break;
            case "indirectResources":
                setReportOptions([{
                    id: "indirectResourceLevel1",
                    name: "Indirect Resources Level 1"
                },
                {
                    id: "indirectResourceLevel2",
                    name: "Indirect Resources Level 2"
                },
                {
                    id: "indirectResourceLevel3",
                    name: "Indirect Resources Level 3"
                },]);
                break;
            case "allResources":
                setReportOptions([{
                    id: "projctResourceLevel1",
                    name: "Project Resource Level 1"
                },
                {
                    id: "projctResourceLevel2",
                    name: "Project Resource Level 2"
                },
                {
                    id: "projctResourceLevel3",
                    name: "Project Resource Level 3"
                }]);
                break;
            case "price":
                setReportOptions([{
                    id: "projectPriceList",
                    name: "Project Price List"
                },
                {
                    id: "projectPriceEstQuantity",
                    name: "Project Price List (Est. Quantity)"
                },
                {
                    id: "projectPriceListCombined",
                    name: "Price List (Quantity & Est. Quantity)"
                },
                {
                    id: "projectCostPriceListCombined",
                    name: "Item Cost & Price"
                },
                {
                    id: "itemPriceRateResourceCategory",
                    name: "Item Unit Price by Resource Category"
                },
                {
                    id: "itemPriceAmountResourceCategory",
                    name: "Item Price Amount by Resource Category"
                },
                {
                    id: "itemPricePercentageResourceCategory",
                    name: "Item Price Percentage by Resource Category"
                }
                ]);
                break;
            case "directCost":
                setReportOptions([{
                    id: "directItemBreakdown",
                    name: "Direct Item Breakdown"
                },
                {
                    id: "itemBreakdown",
                    name: "Direct Item Full Breakdown"
                },
                {
                    id: "itemBreakdownByResources",
                    name: "Direct Item Full Breakdown by Resource Category"
                },
                {
                    id: "itemActivitiesCost",
                    name: "Direct Activities Cost"
                }]);
                break;
            case "indirectCost":
                setReportOptions([{
                    id: "indirectItemLevel1Breakdown",
                    name: "Indirect Item Breakdown"
                }, {
                    id: "indirectItemBreakdown",
                    name: "Indirect Item Full Breakdown"
                }, {
                    id: "indirectItemBreakdownByResources",
                    name: "Indirect Item Full Breakdown by Resource Category"
                }]);
                break;
            default:
                break;
        }
    }, [clearData])

    const onReportChange = useCallback((report: string | null) => {
        setShowReport(false);
        setReport(report);
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [])

    const handleSelectorClose = useCallback(() => {
        setSelectorOpen(false);
    }, [])

    const handleActivitySelectorClose = useCallback(() => {
        setActivitySelectorOpen(false);
    }, [])

    const handleSubcontractorPackageSelectorClose = useCallback(() => {
        setSubcontractorPackageSelectorOpen(false);
    }, [])

    const handleSupplierPackageSelectorClose = useCallback(() => {
        setSupplierPackageSelectorOpen(false);
    }, [])

    const onProjectListGenerate = useCallback((decimals: number | undefined) => {
        setDecimals(decimals);
        handleClose();
    }, [handleClose])

    const generate = useCallback(async () => {
        setShowReport(true);
        clearData();
        if (estimate && report && user?.companyId && user?.organizationId) {
            switch (report) {
                case 'supplierQuotations':
                case 'resourceQuotations':
                    setLoading(true);
                    const supplierQuotations = await getSupplierQuotations({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId,
                    });
                    if (supplierQuotations.data) {
                        setSupplierResourceReportQuotation(supplierQuotations.data);
                    }
                    setLoading(false);
                    break;
                case 'subcontractorQuotations':
                case 'itemQuotations':
                    setLoading(true);
                    const subcontractorQuotations = await getSubcontractorQuotations({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId,
                    });
                    if (subcontractorQuotations.data) {
                        setSubcontractorItemReportQuotation(subcontractorQuotations.data);
                    }
                    setLoading(false);
                    break;
                case 'supplierQuotes':
                    setSelectedSupplierPackage(undefined);
                    setSupplierResourceReportQuote(undefined);
                    handleSupplierPackageSelectorOpen();
                    break;
                case 'subcontractorQuotes':
                    setSelectedSubcontractorPackage(undefined);
                    setSubcontractorItemReportQuote(undefined);
                    handleSubcontractorPackageSelectorOpen();
                    break;
                case 'itemActivitiesCost':
                    setLoading(true);
                    const itemActivityCostList = await getItemActivityCostList({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId,
                    });
                    if (itemActivityCostList.data) {
                        setItemActivityCostList(itemActivityCostList.data);
                    }
                    setLoading(false);
                    break;
                case 'standardItemReportList':
                    setLoading(true);
                    const standardItemList = await getStandardItemList({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId,
                    });
                    if (standardItemList.data) {
                        setStandardItemList(standardItemList.data);
                    }
                    setLoading(false);
                    break;
                case 'standardItemReportCost':
                    setLoading(true);
                    const standardItemReportCost = await getStandardItemCostList({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId,
                    });
                    if (standardItemReportCost.data) {
                        setStandardItemReportCost(standardItemReportCost.data);
                    }
                    setLoading(false);
                    break;
                case 'activityReportList':
                    setLoading(true);
                    const activityReportList = await getActivityList({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId,
                    });
                    if (activityReportList.data) {
                        setActivityReportList(activityReportList.data);
                    }
                    setLoading(false);
                    break;
                case 'activityReportCost':
                    setLoading(true);
                    const activityReportCost = await getActivityCostList({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId,
                    });
                    if (activityReportCost.data) {
                        setActivityReportCost(activityReportCost.data);
                    }
                    setLoading(false);
                    break;
                case 'resourceReportCost':
                    setLoading(true);
                    const resourceReportCost = await getResourceCostList({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId,
                    });
                    if (resourceReportCost.data) {
                        setResourceReportCost(resourceReportCost.data);
                    }
                    setLoading(false);
                    break;
                case 'resourceReportList':
                    setLoading(true);
                    const resourceReportList = await getResourceList({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId,
                    });
                    if (resourceReportList.data) {
                        setResourceReportList(resourceReportList.data);
                    }
                    setLoading(false);
                    break;
                case 'projectPriceList':
                case 'projectPriceEstQuantity':
                case 'projectPriceListCombined':
                case 'projectCostPriceListCombined':
                    handleOpen();
                    break;
                case 'itemPriceAmountResourceCategory':
                case 'itemPricePercentageResourceCategory':
                case 'itemPriceRateResourceCategory':
                    setLoading(true);
                    const itemPriceReport = await getItemPriceReport({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        orgId: user.organizationId,
                        body: {
                            startRow: 0,
                            endRow: 10
                        } as any
                    });
                    if (itemPriceReport.data) {
                        setItemPriceBreakDownLevel3(itemPriceReport.data);
                    }
                    setLoading(false);
                    break;
                case 'indirectResourceLevel1':
                    setLoading(true);
                    let indirectProjResourceL1Response = await generateIndirectProjectResourceLevel1Report({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId
                    });
                    setLoading(false);
                    if (indirectProjResourceL1Response.data) {
                        setIndirectCategoryTotals(indirectProjResourceL1Response.data);
                    }
                    break;
                case 'indirectResourceLevel2':
                    setLoading(true);
                    let indirectProjResourceL2Response = await generateIndirectProjectResourceLevel2Report({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId
                    });
                    setLoading(false);
                    if (indirectProjResourceL2Response.data) {
                        setIndirectCategorySubcategoryTotals(indirectProjResourceL2Response.data);
                    }
                    break;
                case 'indirectResourceLevel3':
                    setLoading(true);
                    let projIndirectResourceL3Response = await generateIndirectProjectResourceLevel3Report({
                        companyId: user?.companyId,
                        estimateId: estimate.id,
                        organizationId: user?.organizationId
                    });
                    setLoading(false);
                    if (projIndirectResourceL3Response.data) {
                        setIndirectReportResources([...projIndirectResourceL3Response.data]);
                    }
                    break;
                case 'directResourceLevel1':
                    setLoading(true);
                    let directProjResourceL1Response = await generateDirectProjectResourceLevel1Report({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId
                    });
                    setLoading(false);
                    if (directProjResourceL1Response.data) {
                        setDirectCategoryTotals(directProjResourceL1Response.data);
                    }
                    break;
                case 'directResourceLevel2':
                    setLoading(true);
                    let directProjResourceL2Response = await generateDirectProjectResourceLevel2Report({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId
                    });
                    setLoading(false);
                    if (directProjResourceL2Response.data) {
                        setDirectCategorySubcategoryTotals(directProjResourceL2Response.data);
                    }
                    break;
                case 'directResourceLevel3':
                    setLoading(true);
                    let projDirectResourceL3Response = await generateDirectProjectResourceLevel3Report({
                        companyId: user?.companyId,
                        estimateId: estimate.id,
                        organizationId: user?.organizationId
                    });
                    setLoading(false);
                    if (projDirectResourceL3Response.data) {
                        setDirectReportResources([...projDirectResourceL3Response.data]);
                    }
                    break;
                case 'projctResourceLevel3':
                    setLoading(true);
                    let projResourceL3Response = await generateProjectResourceLevel3Report({
                        companyId: user?.companyId,
                        estimateId: estimate.id,
                        organizationId: user?.organizationId
                    });
                    setLoading(false);
                    if (projResourceL3Response.data) {
                        setBaseReportResources([...projResourceL3Response.data]);
                    }
                    break;

                case 'projctResourceLevel2':
                    setLoading(true);
                    let projResourceL2Response = await generateProjectResourceLevel2Report({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId
                    });
                    setLoading(false);
                    if (projResourceL2Response.data) {
                        setCategorySubcategoryTotals(projResourceL2Response.data);
                    }
                    break;
                case 'projctResourceLevel1':
                    setLoading(true);
                    let projResourceL1Response = await generateProjectResourceLevel1Report({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId
                    });
                    setLoading(false);
                    if (projResourceL1Response.data) {
                        setCategoryTotals(projResourceL1Response.data);
                    }
                    break;
                case 'directItemBreakdown':
                    setLoading(true);
                    let directItemBreakdownLevel1Response = await getItemReportLevel1({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        orgId: user.organizationId,
                        body: {
                            startRow: 0,
                            endRow: 10
                        } as any
                    });
                    setLoading(false);
                    setItemBreakDownLevel1(directItemBreakdownLevel1Response.data);
                    break;
                case 'indirectItemLevel1Breakdown':
                    setLoading(true);
                    let indirectItemBreakdownLevel1Response = await getIndirectItemReportLevel1({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        orgId: user.organizationId,
                        body: {
                            startRow: 0,
                            endRow: 10
                        } as any
                    });
                    setLoading(false);
                    setIndirectItemBreakDownLevel1(indirectItemBreakdownLevel1Response.data);
                    break;
                case 'itemBreakdown':
                case 'itemBreakdownByResources':
                    setLoading(true);
                    let itemBreakdownResponse = await getItemReport({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        orgId: user.organizationId,
                        body: {
                            startRow: 0,
                            endRow: 10
                        } as any
                    });
                    setLoading(false);
                    setItemBreakDownLevel3(itemBreakdownResponse.data);
                    break;
                case "resourceAllocation":
                    setLoading(true);
                    let selectedResourceResponse = await getItemReport({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        orgId: user.organizationId,
                        body: {
                            startRow: 0,
                            endRow: 10
                        } as any
                    });
                    handleSelectorOpen();
                    setLoading(false);
                    setItemBreakDownLevel3(selectedResourceResponse.data);
                    break;
                case "activityAllocation":
                    setLoading(true);
                    let selectedActivityResponse = await getItemReport({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        orgId: user.organizationId,
                        body: {
                            startRow: 0,
                            endRow: 10
                        } as any
                    });
                    handleActivitySelectorOpen();
                    setLoading(false);
                    setItemBreakDownLevel3(selectedActivityResponse.data);
                    break;
                case 'indirectItemBreakdown':
                case 'indirectItemBreakdownByResources':
                    setLoading(true);
                    let indirectItemBreakdownResponse = await getIndirectItemReport({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        orgId: user.organizationId,
                        body: {
                            startRow: 0,
                            endRow: 10
                        } as any
                    });
                    setLoading(false);
                    setIndirectItemBreakDownLevel3(indirectItemBreakdownResponse.data);
                    break;
                default:
                    break;
            }
        }
    }, [clearData, estimate, generateDirectProjectResourceLevel1Report, generateDirectProjectResourceLevel2Report, generateDirectProjectResourceLevel3Report, generateIndirectProjectResourceLevel1Report, generateIndirectProjectResourceLevel2Report, generateIndirectProjectResourceLevel3Report, generateProjectResourceLevel1Report, generateProjectResourceLevel2Report, generateProjectResourceLevel3Report, getActivityCostList, getActivityList, getIndirectItemReport, getIndirectItemReportLevel1, getItemActivityCostList, getItemPriceReport, getItemReport, getItemReportLevel1, getResourceCostList, getResourceList, getStandardItemCostList, getStandardItemList, getSubcontractorQuotations, getSupplierQuotations, report, user?.companyId, user?.organizationId])

    useEffect(() => {
        const setupReportsForEstimate = async () => {
            if (estimateId) {
                setHasEstimate(true);
                clearData();
                setSource("Estimate");
                if (user && user.companyId && user.organizationId) {
                    const estimateResponse = await getEstimates({
                        companyId: user.companyId,
                        organizationId: user.organizationId
                    });
                    if (estimateResponse.data) {
                        setEstimates(estimateResponse.data);
                        const estimate = estimateResponse.data.find((est) => (est.id === estimateId));
                        if (estimate) {
                            setEstimate(estimate);
                        }
                    }
                }
            }
        }
        setupReportsForEstimate();
    }, [clearData, estimateId, estimates, getEstimates, user])

    const onResourceSelected = useCallback((resource: ReportResource) => {
        setSelectedResource(resource);
        handleSelectorClose();
    }, [handleSelectorClose])

    const onActivitySelected = useCallback((activity: ReportActivity) => {
        setSelectedActivity(activity);
        handleActivitySelectorClose();
    }, [handleActivitySelectorClose])

    const onSubcontractorPackageSelected = useCallback(async (subcontractorPackage: ReportSubcontractorPackage) => {
        handleSubcontractorPackageSelectorClose();
        setSelectedSubcontractorPackage(subcontractorPackage);
        if (user?.companyId && estimate && subcontractorPackage?.id) {
            setLoading(true);
            const subcontractorQuotes = await getSubcontractorQuotes({
                companyId: user.companyId,
                estimateId: estimate.id,
                organizationId: user.organizationId,
                packageId: subcontractorPackage.id
            });
            if (subcontractorQuotes.data) {
                setSubcontractorItemReportQuote(subcontractorQuotes.data);
            }
            setLoading(false);
        }
    }, [estimate, getSubcontractorQuotes, handleSubcontractorPackageSelectorClose, user?.companyId, user?.organizationId])

    const onSupplierPackageSelected = useCallback(async (supplierPackage: ReportSupplierPackage) => {
        handleSupplierPackageSelectorClose();
        setSelectedSupplierPackage(supplierPackage);
        if (user?.companyId && estimate && supplierPackage?.id) {
            setLoading(true);
            const supplierQuotes = await getSupplierQuotes({
                companyId: user.companyId,
                estimateId: estimate.id,
                organizationId: user.organizationId,
                packageId: supplierPackage.id
            });
            if (supplierQuotes.data) {
                setSupplierResourceReportQuote(supplierQuotes.data);
            }
            setLoading(false);
        }
    }, [estimate, getSupplierQuotes, handleSupplierPackageSelectorClose, user?.companyId, user?.organizationId])

    if (!isReady) {
        return <></>
    }

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <><Box overflow="auto" height={hasEstimate ? "calc(100% - 107px)" : "100%"} sx={{ borderLeft: `1px solid ${colors.gray[800]}`, boxShadow: `${colors.gray[800]} 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`, }}>
        <Box paddingTop="16px" paddingBottom="16px" paddingLeft="24px" paddingRight="24px" borderBottom={`1px solid ${colors.gray[800]}`}>
            <Typography component="div" sx={{ display: "flex" }}>
                <SummarizeIcon sx={{ width: "20px", height: "20px", color: `${colors.gray[400]} !important`, marginRight: "4px" }} />
                <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }}>Reports</Box>
            </Typography>
        </Box>
        <Box margin="10px" display="flex" flexDirection="column" height="calc(100% - 80px)">
            <Box marginBottom="20px">
                <Typography variant="h4">Select Report</Typography>
            </Box>
            <Box display="flex">
                {!hasEstimate && <Box width="15%" marginRight="20px">
                    <FormControl fullWidth>
                        <InputLabel id="source">Source</InputLabel>
                        <Select
                            labelId="source"
                            value={source}
                            disabled={!hasViewAccess}
                            label="Source"
                            onChange={(event, value) => onSourceChange(event.target.value)}
                        >
                            <MenuItem value={'estimate'}>Estimate</MenuItem>
                            <MenuItem disabled value={'master'}>Master</MenuItem>
                        </Select>
                    </FormControl>
                </Box>}
                {estimates && estimates.length > 0 && !hasEstimate &&
                    <Box width="15%" marginRight="20px">
                        <FormControl fullWidth>
                            <InputLabel id="estimate">Estimate</InputLabel>
                            <Select
                                labelId="estimate"
                                disabled={hasEstimate || !hasViewAccess}
                                value={estimate?.id}
                                label="Estimate"
                                onChange={(event, value) => onEstimateChange(event.target.value)}
                            >
                                {estimates.map((estimate) => (
                                    <MenuItem value={estimate.id}>{estimate.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                }
                {estimate &&
                    <Box width="15%" marginRight="20px">
                        <FormControl fullWidth>
                            <InputLabel id="reportType">Report Type</InputLabel>
                            <Select
                                labelId="reportType"
                                value={reportType}
                                disabled={!hasViewAccess}
                                label="reportType"
                                onChange={(event, value) => onReportTypeChange(event.target.value)}>
                                <MenuItem value={'library'}>Library</MenuItem>
                                <MenuItem value={'directResources'}>Direct Resources</MenuItem>
                                <MenuItem value={'indirectResources'}>Indirect Resources</MenuItem>
                                <MenuItem value={'allResources'}>All Resources</MenuItem>
                                <MenuItem value={'directCost'}>Direct Cost</MenuItem>
                                <MenuItem value={'indirectCost'}>Indirect Cost</MenuItem>
                                <MenuItem value={'vendors'}>Vendors</MenuItem>
                                <MenuItem value={'price'}>Price</MenuItem>
                                <MenuItem value={'allocationSearch'}>Allocation Search</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                }
                {reportType && reportOptions && reportOptions.length > 0 &&
                    <Box width="15%" marginRight="20px">
                        <FormControl fullWidth>
                            <InputLabel id="report">Report</InputLabel>
                            <Select
                                labelId="report"
                                value={report}
                                disabled={!hasViewAccess}
                                label="report"
                                onChange={(event, value) => onReportChange(event.target.value)}
                            >
                                {reportOptions.map((report) => (
                                    <MenuItem value={report.id}>{report.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                }
                {estimates && estimates.length > 0 && reportType && reportOptions && reportOptions.length > 0 &&
                    <Box display="flex" alignItems="center">
                        <Button variant="contained" disabled={loading || !report} onClick={generate}>{loading ?
                            <CircularProgress sx={{ color: "white" }} size="1rem" /> : 'Generate'
                        }
                        </Button>
                    </Box>
                }
            </Box>
            {showReport && <Box height={hasEstimate ? "calc(100vh - 120px)" : "calc(100vh - 190px)"} marginTop="20px" display="flex" flexDirection="row">
                {report && report === 'projctResourceLevel3' && baseReportResources && baseReportResources.length > 0 && <ProjectResourceLevel3 baseReportResources={baseReportResources} estimateId={estimate?.id} />}
                {report && report === 'directResourceLevel3' && directReportResources && directReportResources.length > 0 && <ProjectResourceLevel3 baseReportResources={directReportResources} estimateId={estimate?.id} />}
                {report && report === 'directResourceLevel2' && directCategorySubcategoryTotals && directCategorySubcategoryTotals.length > 0 && <ProjectResourceLevel2 categoryTotals={directCategorySubcategoryTotals} estimateId={estimate?.id} />}
                {report && report === 'directResourceLevel1' && directCategoryTotals && directCategoryTotals.length > 0 && <ProjectResourceLevel1 categoryTotals={directCategoryTotals} estimateId={estimate?.id} />}
                {report && report === 'indirectResourceLevel3' && indirectReportResources && indirectReportResources.length > 0 && <ProjectResourceLevel3 baseReportResources={indirectReportResources} estimateId={estimate?.id} />}
                {report && report === 'indirectResourceLevel2' && indirectCategorySubcategoryTotals && indirectCategorySubcategoryTotals.length > 0 && <ProjectResourceLevel2 categoryTotals={indirectCategorySubcategoryTotals} estimateId={estimate?.id} />}
                {report && report === 'indirectResourceLevel1' && indirectCategoryTotals && indirectCategoryTotals.length > 0 && <ProjectResourceLevel1 categoryTotals={indirectCategoryTotals} estimateId={estimate?.id} />}
                {report && report === 'projctResourceLevel2' && categorySubcategoryTotals && categorySubcategoryTotals.length > 0 && <ProjectResourceLevel2 categoryTotals={categorySubcategoryTotals} estimateId={estimate?.id} />}
                {report && report === 'projctResourceLevel1' && categoryTotals && categoryTotals.length > 0 && <ProjectResourceLevel1 categoryTotals={categoryTotals} estimateId={estimate?.id} />}
                {report && report === 'projectPriceList' && !open && <ProjectPriceList estimateId={estimate?.id} decimals={decimals} />}
                {report && report === 'projectPriceEstQuantity' && !open && <ProjectPriceListEstQuantity estimateId={estimate?.id} decimals={decimals} />}
                {report && report === 'itemBreakdown' && itemBreakDownLevel3 && <ItemBreakdownLevel3 estimateId={estimate?.id} itemBreakDownReport={itemBreakDownLevel3} pageSize={10} />}
                {report && report === 'indirectItemBreakdown' && indirectItemBreakDownLevel3 && <IndirectItemBreakdownLevel3 estimateId={estimate?.id} indirectItemBreakDownReport={indirectItemBreakDownLevel3} pageSize={10} />}
                {report && report === 'indirectItemBreakdownByResources' && indirectItemBreakDownLevel3 && <IndirectItemBreakdownByResourceCategoryLevel3 estimateId={estimate?.id} indirectItemBreakDownReport={indirectItemBreakDownLevel3} />}
                {report && report === 'itemBreakdownByResources' && itemBreakDownLevel3 && <ItemBreakdownByResourceCategoryLevel3 estimateId={estimate?.id} itemBreakDownReport={itemBreakDownLevel3} />}
                {report && report === 'itemPriceAmountResourceCategory' && itemPriceBreakDownLevel3 && <ItemPriceBreakdownByResourceCategory estimateId={estimate?.id} itemBreakDownReport={itemPriceBreakDownLevel3} />}
                {report && report === 'itemPricePercentageResourceCategory' && itemPriceBreakDownLevel3 && <ItemPriceBreakdownByResourceCategoryPercentage estimateId={estimate?.id} itemBreakDownReport={itemPriceBreakDownLevel3} />}
                {report && report === 'itemPriceRateResourceCategory' && itemPriceBreakDownLevel3 && <ItemPriceBreakdownByResourceCategoryRate estimateId={estimate?.id} itemBreakDownReport={itemPriceBreakDownLevel3} />}
                {report && report === 'directItemBreakdown' && itemBreakDownLevel1 && <ItemBreakdownLevel3 estimateId={estimate?.id} itemBreakDownReport={itemBreakDownLevel1} pageSize={50} />}
                {report && report === 'indirectItemLevel1Breakdown' && indirectItemBreakDownLevel1 && <IndirectItemBreakdownLevel3 estimateId={estimate?.id} indirectItemBreakDownReport={indirectItemBreakDownLevel1} pageSize={50} />}
                {report && report === 'projectPriceListCombined' && !open && <ProjectPriceListCombined estimateId={estimate?.id} decimals={decimals} />}
                {report && report === 'resourceReportCost' && resourceReportCost && <ReportRateCost resourceReport={resourceReportCost} estimateId={estimate?.id} pageSize={50} />}
                {report && report === 'resourceReportList' && resourceReportList && <ResourceRateList resourceReport={resourceReportList} estimateId={estimate?.id} pageSize={50} />}
                {report && report === 'activityReportCost' && activityReportCost && <ActivityRateCost activityReport={activityReportCost} estimateId={estimate?.id} pageSize={50} />}
                {report && report === 'activityReportList' && activityReportList && <ActivityRateList activityReport={activityReportList} estimateId={estimate?.id} pageSize={50} />}
                {report && report === 'standardItemReportCost' && standardItemReportCost && <StandardItemRateCost standardItemReport={standardItemReportCost} estimateId={estimate?.id} pageSize={50} />}
                {report && report === 'standardItemReportList' && standardItemList && <StandardItemRateList standardItemReport={standardItemList} estimateId={estimate?.id} pageSize={50} />}
                {report && report === 'projectCostPriceListCombined' && !open && <ProjectCostPriceListCombined estimateId={estimate?.id} decimals={decimals} />}
                {report && report === 'itemActivitiesCost' && itemActivityCostList && <ItemActivityRateList itemActivityReport={itemActivityCostList} estimateId={estimate?.id} pageSize={50} />}
                {report && report === 'resourceAllocation' && itemBreakDownLevel3 && selectedResource && <ResourceAllocation estimateId={estimate?.id} itemBreakDownReport={itemBreakDownLevel3} pageSize={10} selectedResource={selectedResource} />}
                {report && report === 'activityAllocation' && itemBreakDownLevel3 && selectedActivity && <ActivityAllocation estimateId={estimate?.id} itemBreakDownReport={itemBreakDownLevel3} pageSize={10} selectedActivity={selectedActivity} />}
                {report && report === 'subcontractorQuotations' && subcontractorItemReportQuotation && <SubcontractorQuotations estimateId={estimate?.id} subcontractorQuotations={subcontractorItemReportQuotation} />}
                {units && report && report === 'subcontractorQuotes' && subcontractorItemReportQuote && selectedSubcontractorPackage && <SubcontractorQuotesReport estimateId={estimate?.id} subcontractorItemReport={subcontractorItemReportQuote} pageSize={10} selectedSubcontractorPackage={selectedSubcontractorPackage} units={units} />}
                {units && report && report === 'supplierQuotes' && supplierResourceReportQuote && selectedSupplierPackage && <SupplierQuotesReport estimateId={estimate?.id} supplierResourceReport={supplierResourceReportQuote} pageSize={10} selectedSupplierPackage={selectedSupplierPackage} units={units} />}
                {units && report && report === 'supplierQuotations' && supplierResourceReportQuotation && <SupplierQuotations estimateId={estimate?.id} supplierQuotations={supplierResourceReportQuotation} />}
                {units && report && report === 'itemQuotations' && subcontractorItemReportQuotation && <ItemQuotations estimateId={estimate?.id} subcontractorQuotations={subcontractorItemReportQuotation} />}
                {units && report && report === 'resourceQuotations' && supplierResourceReportQuotation && <ResourceQuotations estimateId={estimate?.id} supplierQuotations={supplierResourceReportQuotation} />}
            </Box>}
        </Box>
    </Box>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <ProjectListReportDecimals save={onProjectListGenerate} close={() => setOpen(false)} estimateId={estimateId} />
        </Modal>
        <Modal
            open={selectorOpen}
            onClose={handleSelectorClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box style={searchModalStyle}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    width="400px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px" width="100%">
                        {colors && <Typography variant="h4" color="primary" sx={{ marginBottom: "10px" }}>Select Resource</Typography>}
                        <ResourceSelector estimateId={estimateId} onSelect={onResourceSelected} />
                    </Box>
                </Box>
            </Box>
        </Modal>
        <Modal
            open={activitySelectorOpen}
            onClose={handleActivitySelectorClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box style={searchModalStyle}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    width="400px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px" width="100%">
                        {colors && <Typography variant="h4" color="primary" sx={{ marginBottom: "10px" }}>Select Activity</Typography>}
                        <ActivitySelector estimateId={estimateId} onSelect={onActivitySelected} />
                    </Box>
                </Box>
            </Box>
        </Modal>
        <Modal
            open={subcontractorPackageSelectorOpen}
            onClose={handleSubcontractorPackageSelectorClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box style={searchModalStyle}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    width="400px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px" width="100%">
                        {colors && <Typography variant="h4" color="primary" sx={{ marginBottom: "10px" }}>Select Subcontractor Package</Typography>}
                        <SubcontractorPackageSelector estimateId={estimateId} onSelect={onSubcontractorPackageSelected} />
                    </Box>
                </Box>
            </Box>
        </Modal>
        <Modal
            open={supplierPackageSelectorOpen}
            onClose={handleSupplierPackageSelectorClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box style={searchModalStyle}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    width="400px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px" width="100%">
                        {colors && <Typography variant="h4" color="primary" sx={{ marginBottom: "10px" }}>Select Supplier Package</Typography>}
                        <SupplierPackageSelector estimateId={estimateId} onSelect={onSupplierPackageSelected} />
                    </Box>
                </Box>
            </Box>
        </Modal>
    </>
}