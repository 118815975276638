import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography, useTheme } from "@mui/material";
import { Estimate } from "Models/estimate";
import { Master } from "Models/master";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { tokens } from "theme";
import { Selections } from "./Selections";

export interface CreateEstimateSelectionProps {
    close: () => void;
    saveAndNext: (selections: Selections | null) => void;
    estimates: Array<Estimate> | undefined;
    masters: Array<Master> | undefined;
    selections?: Selections | null;
    estimate: Estimate | undefined;
}

export default function CreateEstimateSelection(props: CreateEstimateSelectionProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [selections, setSelections] = useState<Selections>();
    const [selectedEstimate, setSelectedEstimate] = useState<Estimate | null>(null);
    const [selectedMaster, setSelectedMaster] = useState<Master | null>(null);
    const [selectedOption, setSelectedOption] = useState<"ImportFromEstimate" | "ImportFromMaster" | "CreateFromScratch">("ImportFromEstimate");
    // Create from another estimate
    const [checkEstimateResources, setCheckEstimateResources] = useState<boolean>(false);
    const [checkEstimateExcludeResourceFactor, setCheckEstimateExcludeResourceFactor] = useState<boolean>(false);
    const [checkEstimateActivities, setCheckEstimateActivities] = useState<boolean>(false);
    const [checkEstimateItems, setCheckEstimateItems] = useState<boolean>(false);
    const [checkEstimateDirect, setCheckEstimateDirect] = useState<boolean>(false);
    const [checkExcludeEstimateDirectQuantities, setCheckExcludeEstimateDirectQuantities] = useState<boolean>(false);
    const [checkExcludeEstimateDirectCostQuantities, setCheckExcludeEstimateDirectCostQuantities] = useState<boolean>(false);
    const [checkEstimateIndirect, setCheckEstimateIndirect] = useState<boolean>(false);
    const [checkExcludeEstimateIndirectQuantities, setCheckExcludeEstimateIndirectQuantities] = useState<boolean>(false);
    const [checkExcludeEstimateIndirectCostQuantities, setCheckExcludeEstimateIndirectCostQuantities] = useState<boolean>(false);
    const [checkEstimateMargins, setCheckEstimateMargins] = useState<boolean>(false);
    const [checkEstimateVendors, setCheckEstimateVendors] = useState<boolean>(false);
    const [checkEstimateVendorQuotations, setCheckEstimateVendorQuotations] = useState<boolean>(false);

    // Create from another master
    const [checkMasterResources, setCheckMasterResources] = useState<boolean>(false);
    const [checkMasterExcludeResourceFactor, setCheckMasterExcludeResourceFactor] = useState<boolean>(false);
    const [checkMasterActivities, setCheckMasterActivities] = useState<boolean>(false);
    const [checkMasterItems, setCheckMasterItems] = useState<boolean>(false);
    const [checkMasterDirect, setCheckMasterDirect] = useState<boolean>(false);
    const [checkExcludeMasterDirectQuantities, setCheckExcludeMasterDirectQuantities] = useState<boolean>(false);
    const [checkExcludeMasterDirectCostQuantities, setCheckExcludeMasterDirectCostQuantities] = useState<boolean>(false);
    const [checkMasterIndirect, setCheckMasterIndirect] = useState<boolean>(false);
    const [checkExcludeMasterIndirectQuantities, setCheckExcludeMasterIndirectQuantities] = useState<boolean>(false);
    const [checkExcludeMasterIndirectCostQuantities, setCheckExcludeMasterIndirectCostQuantities] = useState<boolean>(false);
    const [checkMasterMargins, setCheckMasterMargins] = useState<boolean>(false);
    const [checkMasterVendors, setCheckMasterVendors] = useState<boolean>(false);
    const [checkMasterVendorQuotations, setCheckMasterVendorQuotations] = useState<boolean>(false);

    useEffect(() => {
        if (props.selections) {
            setSelections(props.selections);
        }else{
            setSelections(undefined);
        }
    }, [props.selections])

    useEffect(() =>{
        if (selections){
            setSelectedEstimate(selections.selectedEstimate ?? null);
            setSelectedMaster(selections.selectedMaster ?? null);
            setSelectedOption(selections.selectedOption as "ImportFromEstimate" | "ImportFromMaster" | "CreateFromScratch");
            if (selections.selectedOption==="ImportFromEstimate"){
                setCheckEstimateResources(selections.resources);
                setCheckEstimateExcludeResourceFactor(selections.excludeResourceFactor);
                setCheckEstimateActivities(selections.activities);
                setCheckEstimateItems(selections.items);
                setCheckEstimateDirect(selections.direct);
                setCheckEstimateIndirect(selections.indirect);
                setCheckExcludeEstimateDirectQuantities(selections.excludeDirectItemQuantities);
                setCheckExcludeEstimateDirectCostQuantities(selections.excludeDirectItemCostQuantities);
                setCheckExcludeEstimateIndirectCostQuantities(selections.excludeIndirectItemCostQuantities);
                setCheckExcludeEstimateIndirectQuantities(selections.excludeIndirectItemQuantities);
                setCheckEstimateMargins(selections.margins);
                setCheckEstimateVendors(selections.vendors);
                setCheckEstimateVendorQuotations(selections.vendorQuotations);
            }else if (selections.selectedOption==="ImportFromMaster"){
                setCheckMasterResources(selections.resources);
                setCheckMasterExcludeResourceFactor(selections.excludeResourceFactor);
                setCheckMasterActivities(selections.activities);
                setCheckMasterItems(selections.items);
                setCheckMasterDirect(selections.direct);
                setCheckMasterIndirect(selections.indirect);
                setCheckExcludeMasterDirectQuantities(selections.excludeDirectItemQuantities);
                setCheckExcludeMasterDirectCostQuantities(selections.excludeDirectItemCostQuantities);
                setCheckExcludeMasterIndirectCostQuantities(selections.excludeIndirectItemCostQuantities);
                setCheckExcludeMasterIndirectQuantities(selections.excludeIndirectItemQuantities);
                setCheckMasterMargins(selections.margins);
                setCheckMasterVendors(selections.vendors);
                setCheckMasterVendorQuotations(selections.vendorQuotations);
            }
        }
    }, [selections])

    const onSelectedEstimate = useCallback((event: SyntheticEvent<Element, Event>, value: Estimate | null) => {
        setSelectedEstimate(value);
    }, [])

    const onSelectedMaster = useCallback((event: SyntheticEvent<Element, Event>, value: Master | null) => {
        setSelectedMaster(value);
    }, [])

    const handleCheckedEstimateResources = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateResources(event.target.checked);
        if (!event.target.checked) {
            setCheckEstimateExcludeResourceFactor(false);
            setCheckEstimateActivities(false);
            setCheckEstimateItems(false);
            setCheckEstimateDirect(false);
            setCheckEstimateIndirect(false);
            setCheckExcludeEstimateDirectQuantities(false);
            setCheckExcludeEstimateDirectCostQuantities(false);
            setCheckExcludeEstimateIndirectCostQuantities(false);
            setCheckExcludeEstimateIndirectQuantities(false);
        }
    }, [])

    const handleCheckedEstimateExcludeResourceFactor = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateExcludeResourceFactor(event.target.checked);
    }, [])

    const handleCheckedEstimateActivities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateActivities(event.target.checked);
        if (event.target.checked) {
            setCheckEstimateResources(true);
        } else {
            setCheckEstimateItems(false);
            setCheckEstimateDirect(false);
            setCheckEstimateIndirect(false);
            setCheckExcludeEstimateDirectQuantities(false);
            setCheckExcludeEstimateDirectCostQuantities(false);
            setCheckExcludeEstimateIndirectCostQuantities(false);
            setCheckExcludeEstimateIndirectQuantities(false);
        }
    }, [])

    const handleCheckedEstimateItems = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateItems(event.target.checked);
        if (event.target.checked) {
            setCheckEstimateResources(true);
            setCheckEstimateActivities(true);
        } else {
            setCheckEstimateDirect(false);
            setCheckEstimateIndirect(false);
            setCheckExcludeEstimateDirectQuantities(false);
            setCheckExcludeEstimateDirectCostQuantities(false);
            setCheckExcludeEstimateIndirectCostQuantities(false);
            setCheckExcludeEstimateIndirectQuantities(false);
        }
    }, [])

    const handleCheckedEstimateDirect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateDirect(event.target.checked);
        if (event.target.checked) {
            setCheckEstimateResources(true);
            setCheckEstimateActivities(true);
            setCheckEstimateItems(true);
            setCheckEstimateVendors(true);
            setCheckEstimateVendorQuotations(true);

        } else {
            setCheckExcludeEstimateDirectQuantities(false);
            setCheckExcludeEstimateDirectCostQuantities(false);
        }
    }, [])

    const handleCheckedEstimateDirectQuantities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckExcludeEstimateDirectQuantities(event.target.checked);
    }, [])

    const handleCheckedEstimateDirectCostQuantities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckExcludeEstimateDirectCostQuantities(event.target.checked);
    }, [])

    const handleCheckedEstimateIndirect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateIndirect(event.target.checked);
        if (event.target.checked) {
            setCheckEstimateResources(true);
            setCheckEstimateActivities(true);
            setCheckEstimateItems(true);
        } else {
            setCheckExcludeEstimateIndirectCostQuantities(false);
            setCheckExcludeEstimateIndirectQuantities(false);
        }
    }, [])

    const handleCheckedEstimateIndirectQuantities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckExcludeEstimateIndirectQuantities(event.target.checked);
    }, [])

    const handleCheckedEstimateIndirectCostQuantities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckExcludeEstimateIndirectCostQuantities(event.target.checked);
    }, [])

    const handleCheckedEstimateMargins = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateMargins(event.target.checked);
    }, [])

    const handleCheckedEstimateVendors = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateVendors(event.target.checked);
        if (!event.target.checked){
            setCheckEstimateVendorQuotations(false);
            setCheckEstimateDirect(false);
        }
    }, [])

    const handleCheckedEstimateVendorQuotations = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateVendorQuotations(event.target.checked);
        if (event.target.checked) {
            setCheckEstimateVendors(true);
        }else{
            setCheckEstimateDirect(false);
        }
    }, [])

    const clearAllEstimateSelections = useCallback(() => {
        setSelectedEstimate(null);
        setCheckEstimateExcludeResourceFactor(false);
        setCheckEstimateResources(false);
        setCheckEstimateActivities(false);
        setCheckEstimateItems(false);
        setCheckEstimateDirect(false);
        setCheckEstimateIndirect(false);
        setCheckExcludeEstimateDirectQuantities(false);
        setCheckExcludeEstimateDirectCostQuantities(false);
        setCheckExcludeEstimateIndirectCostQuantities(false);
        setCheckExcludeEstimateIndirectQuantities(false);
        setCheckEstimateMargins(false);
        setCheckEstimateVendors(false);
        setCheckEstimateVendorQuotations(false);
    }, [])

    const clearAllMasterSelections = useCallback(() => {
        setSelectedMaster(null);
        setCheckMasterResources(false);
        setCheckMasterExcludeResourceFactor(false);
        setCheckMasterActivities(false);
        setCheckMasterItems(false);
        setCheckMasterDirect(false);
        setCheckMasterIndirect(false);
        setCheckExcludeMasterDirectQuantities(false);
        setCheckExcludeMasterDirectCostQuantities(false);
        setCheckExcludeMasterIndirectCostQuantities(false);
        setCheckExcludeMasterIndirectQuantities(false);
        setCheckMasterMargins(false);
        setCheckMasterVendors(false);
        setCheckMasterVendorQuotations(false);
    }, [])

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        if (value === "ImportFromEstimate") {
            clearAllMasterSelections();
        } else if (value === "ImportFromMaster") {
            clearAllEstimateSelections();
        } else {
            clearAllMasterSelections();
            clearAllEstimateSelections();
        }
        setSelectedOption((event.target as HTMLInputElement).value as "ImportFromEstimate" | "ImportFromMaster" | "CreateFromScratch");
    }, [clearAllEstimateSelections, clearAllMasterSelections]);

    const handleCheckedMasterResources = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckMasterResources(event.target.checked);
        if (!event.target.checked) {
            setCheckMasterExcludeResourceFactor(false);
            setCheckMasterActivities(false);
            setCheckMasterItems(false);
            setCheckMasterDirect(false);
            setCheckMasterIndirect(false);
            setCheckExcludeMasterDirectQuantities(false);
            setCheckExcludeMasterDirectCostQuantities(false);
            setCheckExcludeMasterIndirectCostQuantities(false);
            setCheckExcludeMasterIndirectQuantities(false);
        }
    }, [])

    const handleCheckedMasterExcludeResourceFactor = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckMasterExcludeResourceFactor(event.target.checked);
    }, [])

    const handleCheckedMasterActivities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckMasterActivities(event.target.checked);
        if (event.target.checked) {
            setCheckMasterResources(true);
        } else {
            setCheckMasterItems(false);
            setCheckMasterDirect(false);
            setCheckMasterIndirect(false);
            setCheckExcludeMasterDirectQuantities(false);
            setCheckExcludeMasterDirectCostQuantities(false);
            setCheckExcludeMasterIndirectCostQuantities(false);
            setCheckExcludeMasterIndirectQuantities(false);
        }
    }, [])

    const handleCheckedMasterItems = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckMasterItems(event.target.checked);
        if (event.target.checked) {
            setCheckMasterResources(true);
            setCheckMasterActivities(true);
        } else {
            setCheckMasterDirect(false);
            setCheckMasterIndirect(false);
            setCheckExcludeMasterDirectQuantities(false);
            setCheckExcludeMasterDirectCostQuantities(false);
            setCheckExcludeMasterIndirectCostQuantities(false);
            setCheckExcludeMasterIndirectQuantities(false);
        }
    }, [])

    const handleCheckedMasterDirect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckMasterDirect(event.target.checked);
        if (event.target.checked) {
            setCheckMasterResources(true);
            setCheckMasterActivities(true);
            setCheckMasterItems(true);
            setCheckMasterVendors(true);
            setCheckMasterVendorQuotations(true);
        } else {
            setCheckExcludeMasterDirectQuantities(false);
            setCheckExcludeMasterDirectCostQuantities(false);
        }
    }, [])

    const handleCheckedMasterDirectQuantities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckExcludeMasterDirectQuantities(event.target.checked);
    }, [])

    const handleCheckedMasterDirectCostQuantities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckExcludeMasterDirectCostQuantities(event.target.checked);
    }, [])

    const handleCheckedMasterIndirect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckMasterIndirect(event.target.checked);
        if (event.target.checked) {
            setCheckMasterResources(true);
            setCheckMasterActivities(true);
            setCheckMasterItems(true);
        } else {
            setCheckExcludeMasterIndirectCostQuantities(false);
            setCheckExcludeMasterIndirectQuantities(false);
        }
    }, [])

    const handleCheckedMasterIndirectQuantities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckExcludeMasterIndirectQuantities(event.target.checked);
    }, [])

    const handleCheckedMasterIndirectCostQuantities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckExcludeMasterIndirectCostQuantities(event.target.checked);
    }, [])

    const handleCheckedMasterMargins = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckMasterMargins(event.target.checked);
    }, [])

    const handleCheckedMasterVendors = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckMasterVendors(event.target.checked);
        if (!event.target.checked){
            setCheckMasterVendorQuotations(false);
            setCheckMasterDirect(false);
        }
    }, [])

    const handleCheckedMasterVendorQuotations = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckMasterVendorQuotations(event.target.checked);
        if (event.target.checked) {
            setCheckMasterVendors(true);
        }else{
            setCheckMasterDirect(false);
        }
    }, [])

    const saveSelections = useCallback(() => {
        let selections: Selections | null = null;
        if (selectedOption === "ImportFromEstimate") {
            selections = {
                selectedOption: "ImportFromEstimate",
                resources: checkEstimateResources,
                activities: checkEstimateActivities,
                direct: checkEstimateDirect,
                indirect: checkEstimateIndirect,
                items: checkEstimateItems,
                margins: checkEstimateMargins,
                vendorQuotations: checkEstimateVendorQuotations,
                vendors: checkEstimateVendors,
                selectedEstimate: selectedEstimate,
                excludeDirectItemCostQuantities: checkExcludeEstimateDirectCostQuantities,
                excludeDirectItemQuantities: checkExcludeEstimateDirectQuantities,
                excludeIndirectItemCostQuantities: checkExcludeEstimateIndirectCostQuantities,
                excludeIndirectItemQuantities: checkExcludeEstimateIndirectQuantities,
                excludeResourceFactor: checkEstimateExcludeResourceFactor
            }
        } else if (selectedOption === "ImportFromMaster") {
            selections = {
                selectedOption: "ImportFromMaster",
                resources: checkMasterResources,
                activities: checkMasterActivities,
                direct: checkMasterDirect,
                indirect: checkMasterIndirect,
                items: checkMasterItems,
                margins: checkMasterMargins,
                vendorQuotations: checkMasterVendorQuotations,
                vendors: checkMasterVendors,
                selectedMaster: selectedMaster,
                excludeDirectItemCostQuantities: checkExcludeMasterDirectCostQuantities,
                excludeDirectItemQuantities: checkExcludeMasterDirectQuantities,
                excludeIndirectItemCostQuantities: checkExcludeMasterIndirectCostQuantities,
                excludeIndirectItemQuantities: checkExcludeMasterIndirectQuantities,
                excludeResourceFactor: checkMasterExcludeResourceFactor
            }
        }else{
            selections = {
                selectedOption: "CreateFromScratch",
                resources: false,
                activities: false,
                direct: false,
                indirect: false,
                items: false,
                margins: false,
                vendorQuotations: false,
                vendors: false,
                selectedMaster: undefined,
                selectedEstimate: props.estimate,
                excludeDirectItemCostQuantities: false,
                excludeDirectItemQuantities: false,
                excludeIndirectItemCostQuantities: false,
                excludeIndirectItemQuantities: false,
                excludeResourceFactor: false
            }
        }
        props.saveAndNext(selections);
    }, [checkEstimateActivities, checkEstimateDirect, checkEstimateExcludeResourceFactor, checkEstimateIndirect, checkEstimateItems, checkEstimateMargins, checkEstimateResources, checkEstimateVendorQuotations, checkEstimateVendors, checkExcludeEstimateDirectCostQuantities, checkExcludeEstimateDirectQuantities, checkExcludeEstimateIndirectCostQuantities, checkExcludeEstimateIndirectQuantities, checkExcludeMasterDirectCostQuantities, checkExcludeMasterDirectQuantities, checkExcludeMasterIndirectCostQuantities, checkExcludeMasterIndirectQuantities, checkMasterActivities, checkMasterDirect, checkMasterExcludeResourceFactor, checkMasterIndirect, checkMasterItems, checkMasterMargins, checkMasterResources, checkMasterVendorQuotations, checkMasterVendors, props, selectedEstimate, selectedMaster, selectedOption])

    return <Box flex="1" height="100%">

        <Box display="flex" flexDirection="column" margin="20px 10px">
            <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Do you want to import existing elements?</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="ImportFromEstimate"
                    name="radio-buttons-group"
                    value={selectedOption}
                    onChange={handleChange}
                >
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center">
                            <Box flex="1">
                                <FormControlLabel value="ImportFromEstimate" control={<Radio />} label="Import from another estimate" />
                            </Box>
                            <Box flex="1">
                                <Autocomplete
                                    size="small"
                                    disabled={selectedOption !== "ImportFromEstimate"}
                                    className="ag-input-field-input ag-text-field-input"
                                    sx={{ height: "inherit", "& .MuiTextField-root:": { width: "200px", height: "inherit" } }}
                                    getOptionLabel={(option) => option.name ?? ''}
                                    onChange={onSelectedEstimate}
                                    options={props.estimates ?? []}
                                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                    value={selectedEstimate}
                                    renderInput={(params) => <TextField name="areaUnit" sx={{ height: "100%" }} placeholder='Select Estimate' {...params} />}
                                    renderOption={(props, option, { inputValue }) => {
                                        const matches = match(option.name ?? '', inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.name ?? '', matches);

                                        return (
                                            <li {...props}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                fontWeight: part.highlight ? 700 : 400,
                                                            }}
                                                        >
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                />
                            </Box>
                        </Box>

                        {selectedOption === "ImportFromEstimate" && selectedEstimate && <Box flex="1" display="flex" flexDirection="column">
                            <Typography variant="h6" sx={{ fontWeight: "500" }} color={colors.blueAccent[200]}>Which Elements?</Typography>
                            <Box display="flex" flexWrap="wrap">
                                <FormControlLabel control={<Checkbox checked={checkEstimateResources} onChange={handleCheckedEstimateResources} />} label="Resources" />
                                <FormControlLabel control={<Checkbox checked={checkEstimateActivities} onChange={handleCheckedEstimateActivities} />} label="Activities" />
                                <FormControlLabel control={<Checkbox checked={checkEstimateItems} onChange={handleCheckedEstimateItems} />} label="Items" />
                                <FormControlLabel control={<Checkbox checked={checkEstimateDirect} onChange={handleCheckedEstimateDirect} />} label="Direct" />
                                <FormControlLabel control={<Checkbox checked={checkEstimateIndirect} onChange={handleCheckedEstimateIndirect} />} label="Indirect" />
                            </Box>
                            {selectedOption === "ImportFromEstimate" && selectedEstimate && (checkEstimateResources || checkEstimateDirect || checkEstimateIndirect) && <Box display="flex" flexWrap="wrap" padding="10px" sx={{ background: colors.gray[1100] }}>
                                {checkEstimateResources && <FormControlLabel control={<Checkbox checked={checkEstimateExcludeResourceFactor} onChange={handleCheckedEstimateExcludeResourceFactor} />} label="Exclude Resource Factors" />}
                                {checkEstimateDirect && <>
                                    <FormControlLabel control={<Checkbox checked={checkExcludeEstimateDirectQuantities} onChange={handleCheckedEstimateDirectQuantities} />} label="Exclude Direct Item quantities" />
                                    <FormControlLabel control={<Checkbox checked={checkExcludeEstimateDirectCostQuantities} onChange={handleCheckedEstimateDirectCostQuantities} />} label="Exclude Direct Item cost quantities" />
                                </>}
                                {checkEstimateIndirect && <>
                                    <FormControlLabel control={<Checkbox checked={checkExcludeEstimateIndirectQuantities} onChange={handleCheckedEstimateIndirectQuantities} />} label="Exclude Indirect Item quantities" />
                                    <FormControlLabel control={<Checkbox checked={checkExcludeEstimateIndirectCostQuantities} onChange={handleCheckedEstimateIndirectCostQuantities} />} label="Exclude Indirect Item cost quantities" />
                                </>}
                            </Box>}

                            <Box display="flex" flexWrap="wrap">
                                <FormControlLabel control={<Checkbox checked={checkEstimateMargins} onChange={handleCheckedEstimateMargins} />} label="Margins" />
                                <FormControlLabel control={<Checkbox checked={checkEstimateVendors} onChange={handleCheckedEstimateVendors} />} label="Vendors" />
                                <FormControlLabel control={<Checkbox checked={checkEstimateVendorQuotations} onChange={handleCheckedEstimateVendorQuotations} />} label="Vendor Quotations" />
                            </Box>
                        </Box>}
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center">
                            <Box flex="1">
                                <FormControlLabel value="ImportFromMaster" control={<Radio />} label="Import from template" />
                            </Box>
                            <Box flex="1">
                                <Autocomplete
                                    size="small"
                                    disabled={selectedOption !== "ImportFromMaster"}
                                    className="ag-input-field-input ag-text-field-input"
                                    sx={{ height: "inherit", "& .MuiTextField-root:": { width: "200px", height: "inherit" } }}
                                    getOptionLabel={(option) => option.name ?? ''}
                                    onChange={onSelectedMaster}
                                    options={props.masters ?? []}
                                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                    value={selectedMaster}
                                    renderInput={(params) => <TextField name="master" sx={{ height: "100%" }} placeholder='Select Template' {...params} />}
                                    renderOption={(props, option, { inputValue }) => {
                                        const matches = match(option.name ?? '', inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.name ?? '', matches);

                                        return (
                                            <li {...props}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                fontWeight: part.highlight ? 700 : 400,
                                                            }}
                                                        >
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                />
                            </Box>
                        </Box>
                        {selectedOption === "ImportFromMaster" && selectedMaster && <Box flex="1" display="flex" flexDirection="column">
                            <Typography variant="h6" sx={{ fontWeight: "500" }} color={colors.blueAccent[200]}>Which Elements?</Typography>
                            <Box display="flex" flexWrap="wrap">
                                <FormControlLabel control={<Checkbox checked={checkMasterResources} onChange={handleCheckedMasterResources} />} label="Resources" />
                                <FormControlLabel control={<Checkbox checked={checkMasterActivities} onChange={handleCheckedMasterActivities} />} label="Activities" />
                                <FormControlLabel control={<Checkbox checked={checkMasterItems} onChange={handleCheckedMasterItems} />} label="Items" />
                                <FormControlLabel control={<Checkbox checked={checkMasterDirect} onChange={handleCheckedMasterDirect} />} label="Direct" />
                                <FormControlLabel control={<Checkbox checked={checkMasterIndirect} onChange={handleCheckedMasterIndirect} />} label="Indirect" />
                            </Box>
                            {selectedOption === "ImportFromMaster" && selectedMaster && (checkMasterResources || checkMasterDirect || checkMasterIndirect) && <Box display="flex" flexWrap="wrap" padding="10px" sx={{ background: colors.gray[1100] }}>
                                {checkMasterResources && <FormControlLabel control={<Checkbox checked={checkMasterExcludeResourceFactor} onChange={handleCheckedMasterExcludeResourceFactor} />} label="Exclude Resource Factors" />}
                                {checkMasterDirect && <>
                                    <FormControlLabel control={<Checkbox checked={checkExcludeMasterDirectQuantities} onChange={handleCheckedMasterDirectQuantities} />} label="Exclude Direct Item quantities" />
                                    <FormControlLabel control={<Checkbox checked={checkExcludeMasterDirectCostQuantities} onChange={handleCheckedMasterDirectCostQuantities} />} label="Exclude Direct Item cost quantities" />
                                </>}
                                {checkMasterIndirect && <>
                                    <FormControlLabel control={<Checkbox checked={checkExcludeMasterIndirectQuantities} onChange={handleCheckedMasterIndirectQuantities} />} label="Exclude Indirect Item quantities" />
                                    <FormControlLabel control={<Checkbox checked={checkExcludeMasterIndirectCostQuantities} onChange={handleCheckedMasterIndirectCostQuantities} />} label="Exclude Indirect Item cost quantities" />
                                </>}
                            </Box>}

                            <Box display="flex" flexWrap="wrap">
                                <FormControlLabel control={<Checkbox checked={checkMasterMargins} onChange={handleCheckedMasterMargins} />} label="Margins" />
                                <FormControlLabel control={<Checkbox checked={checkMasterVendors} onChange={handleCheckedMasterVendors} />} label="Vendors" />
                                <FormControlLabel control={<Checkbox checked={checkMasterVendorQuotations} onChange={handleCheckedMasterVendorQuotations} />} label="Vendor Quotations" />
                            </Box>
                        </Box>}

                    </Box>

                    <FormControlLabel value="CreateFromScratch" control={<Radio />} label="Create from scratch" />
                </RadioGroup>
            </FormControl>
        </Box>
        <Box display="flex" justifyContent="space-evenly" >
            <Button variant="outlined" onClick={props.close}>
                Cancel
            </Button>
            <Button variant="contained" onClick={saveSelections} disabled={(selectedOption === "ImportFromEstimate" && !selectedEstimate) || (selectedOption === "ImportFromMaster" && !selectedMaster) }>
                Next
            </Button>
        </Box>

    </Box>
}