import { Alert, Autocomplete, Box, Button, IconButton, InputAdornment, OutlinedInput, Popover, TextField, useTheme } from "@mui/material";
import { StandardItemActivityResourceView, StandardItemView } from "Models/standard-item";
import { UnitView } from "Models/unit";
import { useCreateStandardItemMutation, useGetStandardItemMaxIdQuery, useUpdateStandardItemMutation } from "State/Services/standard-item";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useCallback, useEffect, useRef, useState } from "react";
import { tokens } from "theme";
import * as yup from 'yup';
import { useFormik } from "formik";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import AddEditStandardActivityResources from "./AddEditStandardActivityResources";
import StdItemCategory, { SelectedStandardItemCategories } from "./StandardItemCategory";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useGetStandardItemCategoriesQuery } from "State/Services/standard-item-category";
import { SaveOptions } from "Models/button-options";
import SplitButton from "Components/SplitButton";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { PanelState } from "Models/panel";
import { Errors } from "Models/errors";

export interface AddEditStandardItemProps {
    estimateId: string;
    standardItem: StandardItemView | undefined;
    backToList: () => void;
    resetAndAddNew: (category1: string | undefined, category2: string | undefined) => void;
    panelState: PanelState;
}

const resourceValidationSchema = yup.object<StandardItemView>({
    id: yup
        .string()
        .optional(),
    description: yup
        .string()
        .required(),
    displayId: yup
        .string()
        .required(),
    quantity: yup
        .number()
        .required(),
});

export default function AddEditStandardItem(props: AddEditStandardItemProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: maxId } = useGetStandardItemMaxIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [saveStandardItem] = useCreateStandardItemMutation();
    const [updateStandardItem] = useUpdateStandardItemMutation();
    const [unitViews, setUnitViews] = useState<Array<UnitView>>([]);
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [rate, setRate] = useState<string>();
    const [standardItem, setStandardItem] = useState<StandardItemView | undefined>(props.standardItem);
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [currentStandardActivitiesResources, setCurrentStandardActivitiesResources] = useState<Array<StandardItemActivityResourceView>>();
    const [openCategoryPopover, setOpenCategoryPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const categoryRef = useRef<HTMLDivElement>();
    const { data: standardItemCategories } = useGetStandardItemCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId, refetchOnMountOrArgChange: true });
    const unitRef = useRef<HTMLInputElement>();
    const [afterSaveOption, setAfterSaveOption] = useState<SaveOptions | undefined>();
    const [locked] = useState(!!props.standardItem?.masterReferenceId);
    const editDisabledRef = useRef<boolean>();
    const [pageError, setPageError] = useState<string | undefined>();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Items,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
            }
        } else {
            editDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])
    
    
    const handleOpenCategoryPopover = () => {
        setOpenCategoryPopover(true);
        if (categoryRef.current) {
            setAnchorEl(categoryRef.current);
        }
    }
    const handleCloseCategoryPopover = () => {
        setOpenCategoryPopover(false);
        setAnchorEl(null);
    };

    useEffect(() => {
        const unts = units?.map((unit) => ({
            unitDescription: unit.description,
            unitId: unit.id
        }));
        if (unts) {
            setUnitViews(unts);
        }
    }, [units])

    const getDisplayId = useCallback(() => {
        const id = (maxId) ? (maxId + 1)?.toString() : '1';
        return estimate?.isMaster ? `${settings?.masterIdPrefix}-${id}` : id;
    }, [estimate?.isMaster, maxId, settings?.masterIdPrefix])

    const {values, isValid, setFieldValue, setErrors, handleSubmit, handleChange, handleBlur, touched, errors} = useFormik<StandardItemView>({
        enableReinitialize: true,
        initialValues: (standardItem) ? {
            id: standardItem.id ?? '',
            description: standardItem.description ?? '',
            unitId: standardItem.unitId ?? '',
            displayId: standardItem.displayId ?? getDisplayId(),
            masterReferenceId: standardItem.masterReferenceId,
            unit: unitViews.find((unit) => (unit.unitId===standardItem.unitId)),
            quantity: standardItem.quantity,
            category1: standardItem.category1,
            category1Description: standardItemCategories?.some((cat) => (cat.id === standardItem?.category1)) ? standardItemCategories?.find((cat) => (cat.id === standardItem?.category1))?.description : undefined,
            category2: standardItem.category2,
            category2Description: standardItemCategories?.some((cat) => (cat.id === standardItem?.category2)) ? standardItemCategories?.find((cat) => (cat.id === standardItem?.category2))?.description : undefined,
        } : {
            id: '',
            description: '',
            unitId: undefined,
            displayId: getDisplayId(),
            unit: undefined,
            quantity: undefined,
            category1: undefined,
            category1Description: undefined,
            category2: undefined,
            category2Description: undefined
        },
        validationSchema: resourceValidationSchema,
        onSubmit: async (standardItem) => {
            await save(standardItem, afterSaveOption ?? SaveOptions.Save);
        },
    });

    const save = useCallback(async (standardItemToSave: StandardItemView, option: SaveOptions) =>{
        if (standardItemToSave && standardItemToSave.quantity) {
            try {
                let response: any;
                if (standardItemToSave.id) {
                    response = await updateStandardItem({
                        standardItemId: standardItemToSave?.id,
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        body: {
                            id: standardItemToSave.id,
                            description: standardItemToSave.description,
                            unitId: standardItemToSave.unit?.unitId,
                            displayId: standardItemToSave.displayId,
                            quantity: (standardItemToSave.quantity.toString()) ? parseFloat(standardItemToSave.quantity.toString()) : undefined,
                            category1: standardItemToSave.category1,
                            category1Description: standardItemToSave.category1Description,
                            category2: standardItemToSave.category2,
                            category2Description: standardItemToSave.category2Description
                        }
                    }).unwrap();
                } else {
                    response = await saveStandardItem({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        body: {
                            description: standardItemToSave.description,
                            unitId: standardItemToSave.unit?.unitId,
                            displayId: standardItemToSave.displayId,
                            quantity: (standardItemToSave.quantity.toString()) ? parseFloat(standardItemToSave.quantity.toString()) : undefined,
                            category1: standardItemToSave.category1,
                            category1Description: standardItemToSave.category1Description,
                            category2: standardItemToSave.category2,
                            category2Description: standardItemToSave.category2Description
                        }
                    }).unwrap();
                }
                if (response) {
                    switch (option) {
                        case SaveOptions.SaveAndClose:
                            props.backToList();
                            break;
                        case SaveOptions.SaveAndAddAnother:
                            setAfterSaveOption(undefined);
                            props.resetAndAddNew(standardItemToSave.category1, standardItemToSave.category2);
                            break;
                        default:
                        case SaveOptions.Save:
                            setStandardItem({ ...response });
                            setFieldValue('id', response.id);
                            break;
                    }
                }
            } catch (error: any) {
                if (error.status===500){
                    setPageError(Errors.generic);
                    return;
                }
                if (error && ('data' in error)) {
                    if (error.data?.description) {
                        setErrors({
                            description: error.data?.description
                        });
                    } else if (error.data.displayId) {
                        setErrors({
                            displayId: error.data?.displayId
                        });
                    }else if (error.data.page){
                        setPageError(error.data.page);
                    }
                }
            }
        }
    }, [props, saveStandardItem, setErrors, setFieldValue, updateStandardItem, user?.companyId, user?.organizationId])

    const onStandardItemActivityResourceEditing = useCallback((state: boolean) => {
        setFieldDisabled(state);
    }, [])

    useEffect(() => {
        if (currentStandardActivitiesResources && currentStandardActivitiesResources.length > 0) {
            let rate = 0;
            currentStandardActivitiesResources.forEach((det: StandardItemActivityResourceView) => {
                if (det.amount) {
                    rate += det.amount;
                }
            });
            setRate(rate.toString());
        } else {
            setRate('');
        }
    }, [currentStandardActivitiesResources, values.quantity])

    const saveCategories = useCallback((selectedStandardItemCategories: SelectedStandardItemCategories) => {
        if (selectedStandardItemCategories.category1?.id) {
            setFieldValue('category1', selectedStandardItemCategories.category1.id);
            setFieldValue('category1Description', selectedStandardItemCategories.category1.description);
        } else if (selectedStandardItemCategories.category1?.description) {
            setFieldValue('category1', undefined);
            setFieldValue('category1Description', selectedStandardItemCategories.category1.description);
        } else {
            setFieldValue('category1', undefined);
            setFieldValue('category1Description', undefined);
        }
        if (selectedStandardItemCategories.category2?.id) {
            setFieldValue('category2', selectedStandardItemCategories.category2.id);
            setFieldValue('category2Description', selectedStandardItemCategories.category2.description);
        } else if (selectedStandardItemCategories.category2?.description) {
            setFieldValue('category2', undefined);
            setFieldValue('category2Description', selectedStandardItemCategories.category2.description);
        } else {
            setFieldValue('category2', undefined);
            setFieldValue('category2Description', undefined);
        }
    }, [setFieldValue])

    const renderSwitch = useCallback((item: StandardItemView) => {
        let category = '';
        if (item.category1Description && item.category2Description) {
            category = `${item.category1Description} / ${item.category2Description}`;
        } else {
            category = item.category1Description ?? '';
        }
        return <>
            <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Category</label>
            <OutlinedInput
                size="small"
                inputRef={categoryRef}
                disabled={true}
                endAdornment={
                    <InputAdornment position="end">
                        <Box display="flex" width="16px" height="16px" color={`${colors.gray[500]}`}>
                            <IconButton disabled={fieldDisabled || locked || editDisabledRef.current} aria-label="edit item" size="small" color="inherit" onClick={handleOpenCategoryPopover}>
                                <ModeEditIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </InputAdornment>
                }
                sx={{
                    '& .MuiInputBase-input': {
                        color: "black",
                        fontColor: "black",
                        WebkitTextFillColor: "black"
                    },
                    fontWeight: "600",
                }}
                value={category} /></>
    }, [colors.gray, fieldDisabled, locked])

    const handleClose = useCallback(async (option?: string) => {
        const opt = (option ?? SaveOptions.Save) as SaveOptions;
        setAfterSaveOption(opt);
        await save(values, opt);
    }, [values, save]);

    const onEnter = useCallback((event: React.KeyboardEvent) => {
        if (
            (categoryRef.current && categoryRef.current.contains(event.target as HTMLElement)) || 
                (unitRef.current && unitRef.current.contains(event.target as HTMLElement)) || 
                event.key!=='Enter'
        ) {
            return;
        }
        handleSubmit();
    }, [handleSubmit])

    const getContainerHeight = useCallback(() => {
        if (!isValid) {
            return pageError ? 'calc(100% - 78px)' : 'calc(100% - 20px)';
        }
    
        return pageError ? 'calc(100% - 58px)' : '100%';
    }, [isValid, pageError]);

    return <>{colors && <Box height={getContainerHeight()}>
        <form onSubmit={handleSubmit} onKeyDown={onEnter} noValidate>
            <Box display="flex" justifyContent="space-between">
                <Button sx={{ marginTop: "10px", marginBottom: "10px" }} startIcon={<ArrowBackIosIcon />} onClick={props.backToList}>
                    Back to list
                </Button>
                <Box marginTop="10px" marginBottom="10px">
                    <SplitButton disabled={editDisabledRef.current} buttonIcon={<SaveIcon />} options={[{option: SaveOptions.SaveAndAddAnother, disabled: false}, {option: SaveOptions.SaveAndClose, disabled: false}]} buttonText="Save" onButtonClick={handleClose} onMenuItemClick={handleClose} />
                </Box>
            </Box>
            {pageError && <Box marginBottom="10px"><Alert severity="error">{pageError}</Alert></Box>}
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" flexDirection="column" flex="0.2" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>ID</label>
                    <TextField
                        size="small"
                        id="displayId"
                        name="displayId"
                        autoComplete="off"
                        placeholder="ID"
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.displayId}
                        error={touched.displayId && Boolean(errors.displayId)}
                        helperText={touched.displayId && errors.displayId} />
                </Box>
                <Box display="flex" flexDirection="column" marginBottom="15px" flex="1" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Description</label>
                    <TextField
                        size="small"
                        id="description"
                        name="description"
                        placeholder="Description"
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.description && Boolean(errors.description)}
                        helperText={touched.description && errors.description}
                        value={values.description} />
                </Box>
                <Box display="flex" flexDirection="column" marginBottom="15px" flex="0.5" marginRight="10px" ref={categoryRef}>
                    {renderSwitch(values)}
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
                {unitViews && unitViews.length > 0 && <Box display="flex" flexDirection="column" marginBottom="15px" flex="1" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Unit</label>
                    <Autocomplete
                        fullWidth
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        size="small"
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        getOptionLabel={(option) => option.unitDescription ?? ''}
                        onChange={(event, value) => {
                            setFieldValue('unit', value);
                        }}
                        options={unitViews}
                        ref={unitRef}
                        isOptionEqualToValue={(option, value) => (option.unitId === value.unitId)}
                        value={values.unit ?? null}
                        renderInput={(params) => <TextField name="unit" sx={{ height: "100%" }} placeholder='Select/Enter Unit' {...params} />}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.unitDescription ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.unitDescription ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    /></Box>
                }
                <Box display="flex" flexDirection="column" flex="1" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Quantity</label>
                    <TextField
                        size="small"
                        id="quantity"
                        InputProps={{
                            type: 'number'
                        }}
                        name="quantity"
                        autoComplete="off"
                        placeholder="Quantity"
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        error={touched.quantity && Boolean(errors.quantity)}
                        helperText={touched.quantity && errors.quantity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.quantity} />
                </Box>
                <Box display="flex" flexDirection="column" flex="1" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Rate</label>
                    <TextField
                        size="small"
                        InputProps={{ readOnly: true }}
                        value={rate} />
                </Box>
            </Box>
        </form>
        <Popover
            anchorEl={anchorEl}
            open={openCategoryPopover}
            onClose={handleCloseCategoryPopover}
            sx={{
                zIndex: 900,
                background: "transparent",
                '& .MuiPaper-root': {
                    background: "transparent",
                    boxShadow: "none",
                }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Box width="320px" height="auto" boxShadow={`0px 4px 12px 0px rgba(6, 22, 55, 0.12), 0px 2px 8px 0px rgba(6, 22, 55, 0.08)`} borderRadius="14px" border={`1px solid ${colors.primary[300]}`} sx={{ backgroundColor: "white" }}>
                <StdItemCategory
                    save={saveCategories}
                    close={handleCloseCategoryPopover}
                    category1Id={values?.category1}
                    category2Id={values?.category2}
                    estimateId={props.estimateId} />
            </Box>
        </Popover>
        {standardItem?.id && <AddEditStandardActivityResources onEdit={onStandardItemActivityResourceEditing} currentStandardItem={values} estimateId={props.estimateId} setStandardItemActivitiesResources={setCurrentStandardActivitiesResources} panelState={props.panelState}/>}
        {!standardItem?.id && <Box display="flex" alignItems="center" sx={{ background: colors.gray[1000] }} justifyContent="center" width="100%" height="calc(100% - 195px)">Please save the standard item to add resources and activities.</Box>}
    </Box>}</>;
}