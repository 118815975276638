import { LoadingButton } from "@mui/lab";
import { Alert, Autocomplete, Box, TextField, useTheme } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { FilterField } from "Models/resource";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useAppendItemDetailMutation, useGetItemsByFilterQuery, useLazyGetItemsPagedQuery } from "State/Services/item";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import AddIcon from '@mui/icons-material/Add';
import { useAppendIndirectItemDetailMutation, useGetIndirectItemsByFilterQuery, useLazyGetIndirectItemsPagedQuery } from "State/Services/indirect-item";

export interface QuickAppendItemProps {
    estimateId: string;
    itemId: string;
    close: () => void;
    disabled?: boolean;
    prevItem?: FilteredItem;
    parent: 'direct' | 'indirect';
}

export interface FilteredItem {
    id?: string;
    description: string;
    displayId?: string;
    disableSubElements?: boolean;
}

export default function QuickAppendItem(props: QuickAppendItemProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [pageError, setPageError] = useState<string | undefined>();
    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<Array<FilteredItem>>([]);
    const [searchValue, setSearchValue] = useState<FilteredItem>({ id: '', description: '' });
    const [appendItemDetail] = useAppendItemDetailMutation();
    const [appendIndirectItemDetail] = useAppendIndirectItemDetailMutation();
    const { data: filteredItems } = useGetItemsByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.description.length > 1 && props.parent === "direct") });
    const { data: filteredIndirectItems } = useGetIndirectItemsByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.description.length > 1 && props.parent === "indirect") });
    const [saving, setSaving] = useState(false);
    const [getItemsPaged] = useLazyGetItemsPagedQuery();
    const [getIndirectItemsPaged] = useLazyGetIndirectItemsPagedQuery();

    const getContainerHeight = useCallback(() => {
        return pageError ? 116 : 68;
    }, [pageError]);

    useEffect(() => {
        setSearchValue({ id: props.prevItem?.id, description: props.prevItem?.description ?? '' });
    }, [props.prevItem])

    useEffect(() => {
        if (filteredItems && filteredItems.length > 0) {
            const filtered = filteredItems.filter((item) => (item.itemId !== props.itemId));
            setOptions(filtered.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                type: "directitem",
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [filteredItems, props.itemId])

    const setDefaultItems = useCallback(async () => {
        const results = await getItemsPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true).unwrap();
        if (results && results.length > 0) {
            const filtered = results.filter((item) => (item.id !== props.itemId));
            setOptions(filtered.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.id,
                displayId: item.displayId?.toString(),
                type: "directitem",
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [getItemsPaged, props, user])

    const setDefaultIndirectItems = useCallback(async () => {
        const results = await getIndirectItemsPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true).unwrap();
        if (results && results.length > 0) {
            const filtered = results.filter((item) => (item.itemId !== props.itemId));
            setOptions(filtered.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                type: "directitem",
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [getIndirectItemsPaged, props, user])

    useEffect(() => {
        if (filteredIndirectItems && filteredIndirectItems.length > 0) {
            const filtered = filteredIndirectItems.filter((item) => (item.itemId !== props.itemId));
            setOptions(filtered.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                type: "directitem",
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [filteredIndirectItems, props.itemId])

    const populateDefaultValues = useCallback(async () => {
        if (props.parent === "direct") {
            await setDefaultItems();
        } else if (props.parent === "indirect") {
            await setDefaultIndirectItems();
        }
    }, [props.parent, setDefaultIndirectItems, setDefaultItems])

    useEffect(() => {
        populateDefaultValues();
    }, [populateDefaultValues])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            setSearchValue({ description: newValue });
        } else {
            setSearchValue({ description: '' });
            setOpen(false);
            await setDefaultItems();
        }
    }, [setDefaultItems])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: FilteredItem) => {
        if (newValue) {
            setSearchValue(newValue);
        } else {
            setSearchValue({ id: '', description: '', disableSubElements: false });
        }
        setOpen(false);
    }, []);

    const handleClose = useCallback(async () => {
        try {
            setPageError(undefined);
            if (props.prevItem) {
                if (props.parent === 'direct') {
                    await appendItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        itemId: props.itemId,
                        body: {
                            addOption: 'sub-element',
                            id: props.prevItem.id,
                            type: 'directitem'
                        }
                    }).unwrap();
                } else if (props.parent === 'indirect') {
                    await appendIndirectItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        indirectItemId: props.itemId,
                        body: {
                            addOption: 'sub-element',
                            id: props.prevItem.id,
                            type: 'directitem'
                        }
                    }).unwrap();
                }
                props.close();
            } else {
                if (props.parent === 'direct') {
                    await appendItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        itemId: props.itemId,
                        body: {
                            addOption: 'sub-element',
                            id: searchValue.id,
                            type: 'directitem'
                        }
                    }).unwrap();
                } else if (props.parent === 'indirect') {
                    await appendIndirectItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        indirectItemId: props.itemId,
                        body: {
                            addOption: 'sub-element',
                            id: searchValue.id,
                            type: 'directitem'
                        }
                    }).unwrap();
                }
                props.close();
            }
        } catch (error: any) {
            if (error && ('data' in error)) {
                if (error.data.page) {
                    setPageError(error.data.page);
                }
            }
        }
        finally {
            setSaving(false);
        }
    }, [appendIndirectItemDetail, appendItemDetail, props, searchValue.id, user?.companyId, user?.organizationId]);

    return <Box padding="5px 5px 0px 5px" sx={{ width: 505, height: getContainerHeight() }} >
        {
            colors && <Box>
                {pageError && <Box marginBottom="15px"><Alert sx={{ padding: 0 }} severity="error">{pageError}</Alert></Box>}
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" flexDirection="column" marginBottom="5px" flex="1" marginRight="5px">
                        <Autocomplete
                            sx={{ '& .MuiTextField-root': { height: "1.4375em" } }}
                            disableClearable
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            value={searchValue}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.description}
                            filterOptions={(x) => x}
                            size="small"
                            disabled={props.disabled ?? false}
                            onChange={onChange}
                            onInputChange={onInputChange}
                            options={options}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    sx={{ '& .MuiInputBase-root': { height: 45 } }}
                                    placeholder={props.parent === 'direct' ? 'Search Direct Item...' : 'Search Indirect Item...'} />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.description, inputValue, { insideWords: true });
                                const parts = parse(option.description, matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" flex="0.1" marginBottom="5px">
                        <LoadingButton
                            onClick={handleClose}
                            sx={{
                                width: "30px"
                            }}
                            loading={saving}
                            endIcon={<AddIcon />}
                            aria-label="save"
                            color="primary"
                            loadingPosition="end"
                            variant="contained">
                            Add
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        }
    </Box>
}