import { LoadingButton } from "@mui/lab";
import { Alert, Autocomplete, Box, TextField, useTheme } from "@mui/material";
import { Errors } from "Models/errors";
import { useGetActivitiesByFilterQuery, useLazyGetActivitiesPagedQuery } from "State/Services/activity";
import { useAppendItemDetailMutation, useGetItemIdQuery, useQuickAddActivityDetailMutation } from "State/Services/item";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useFormik } from "formik";
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { tokens } from "theme";
import * as yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import SplitButton, { MenuOption } from "Components/SplitButton";
import { AddOptions } from "Models/button-options";
import { useAppendIndirectItemDetailMutation, useGetIndirectItemIdQuery, useQuickAddActivityIndirectDetailMutation } from "State/Services/indirect-item";
export interface QuickAddActivity {
    addedActivityId: string;
    totalQuantity?: number;
    quantityPerUnit?: number;
}
export interface QuickAppendActivityProps {
    inHeader: boolean;
    type?: string;
    estimateId: string;
    itemId: string;
    standardItemId?: string;
    subItemId?: string;
    activityCategoryId?: string;
    close: () => void;
    parent: 'direct' | 'indirect';
}

export interface FilteredActivity {
    id?: string;
    description: string;
    displayId?: string;
    disableSubElements?: boolean;
}

const quickAddActivityValidationSchema = yup.object<QuickAddActivity>({
    totalQuantity: yup
        .number()
        .required(),
    quantityPerUnit: yup
        .number()
        .required()
});

export default function QuickAppendActivity(props: QuickAppendActivityProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: item } = useGetItemIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', itemId: props.itemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !props.itemId || props.parent !== 'direct' });
    const { data: indirectItem } = useGetIndirectItemIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', indirectItemId: props.itemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !props.itemId || props.parent !== 'indirect' });
    const [saving, setSaving] = useState(false);
    const [unitSuffix, setUnitSuffix] = useState<string>('Item Unit');
    const [searchValue, setSearchValue] = useState<FilteredActivity>({ id: '', description: '' });
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: filteredActivities } = useGetActivitiesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', estimateId: (props && props.estimateId) ? props.estimateId : '', categoryId: props.activityCategoryId }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.description.length > 1) });
    const [getActivitiesPaged] = useLazyGetActivitiesPagedQuery();
    const [options, setOptions] = useState<Array<FilteredActivity>>([]);
    const [pageError, setPageError] = useState<string | undefined>();
    const [open, setOpen] = useState<boolean>(false);
    const [quickAddActivityDetail] = useQuickAddActivityDetailMutation();
    const [quickAddActivityIndirectDetail] = useQuickAddActivityIndirectDetailMutation();
    const [addMenuItems] = useState<Array<MenuOption>>([{ option: AddOptions.AddElement, disabled: false }, { option: AddOptions.AddSubElements, disabled: false }]);
    const [appendItemDetail] = useAppendItemDetailMutation();
    const [appendIndirectItemDetail] = useAppendIndirectItemDetailMutation();

    useEffect(() => {
        if (filteredActivities && filteredActivities.length > 0) {
            setOptions(filteredActivities.map((act) => ({
                description: `${act.displayId} - ${act.description}`,
                id: act.activityId,
                displayId: act.displayId,
                disableSubElements: !act.hasResources
            })));
        }
    }, [filteredActivities])

    useEffect(() => {
        const itemToUse = (props.parent === 'direct') ? item : indirectItem;
        if (units && itemToUse && itemToUse.unitId) {
            const unit = units?.find(u => u.id === itemToUse.unitId);
            if (unit) {
                setUnitSuffix(`Quantity / ${unit.description}`);
            }
        } else if (itemToUse?.customUnit) {
            setUnitSuffix(`Quantity / ${itemToUse.customUnit}`);
        }
    }, [indirectItem, item, props.parent, units])

    useEffect(() => {
        setSearchValue({ description: '' });
        setOpen(false);
    }, [props.activityCategoryId])

    const setDefaultActivities = useCallback(async () => {
        const response = await getActivitiesPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : '',
            categoryId: props.activityCategoryId
        }, true);
        if ('data' in response) {
            const results = response.data;
            if (results && results.length > 0) {
                setOptions(results.map((act) => ({
                    description: `${act.displayId} - ${act.description}`,
                    id: act.activityId,
                    displayId: act.displayId,
                    disableSubElements: !act.hasResources
                })));
            }
        }
    }, [getActivitiesPaged, props, user])

    const { values, setFieldValue, handleSubmit, handleChange, handleBlur, touched, errors } = useFormik<QuickAddActivity>({
        validateOnMount: true,
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: {
            addedActivityId: '',
            totalQuantity: undefined,
            quantityPerUnit: undefined
        },
        validationSchema: quickAddActivityValidationSchema,
        onSubmit: async (activity) => {
            try {
                if (user && searchValue.id) {
                    setSaving(true);
                    if (props.parent === 'direct') {
                        await quickAddActivityDetail({
                            companyId: user.companyId,
                            orgId: user.organizationId,
                            estimateId: props.estimateId,
                            itemId: props.itemId,
                            body: {
                                addedActivityId: searchValue.id,
                                quantity: activity.totalQuantity ?? 0,
                                standardItemId: props.standardItemId,
                                subItemId: props.subItemId,
                                quantityPerUnit: activity.quantityPerUnit ?? 0
                            }
                        }).unwrap();
                    } else if (props.parent === 'indirect') {
                        await quickAddActivityIndirectDetail({
                            companyId: user.companyId,
                            orgId: user.organizationId,
                            estimateId: props.estimateId,
                            indirectItemId: props.itemId,
                            body: {
                                addedActivityId: searchValue.id,
                                quantity: activity.totalQuantity ?? 0,
                                standardItemId: props.standardItemId,
                                indirectSubItemId: props.subItemId,
                                quantityPerUnit: activity.quantityPerUnit ?? 0
                            }
                        }).unwrap();
                    }
                    props.close();
                }
            } catch (error: any) {
                if (error.status === 500) {
                    setPageError(Errors.generic);
                    return;
                }
                if (error && ('data' in error)) {
                    if (error.data?.description) {
                        setPageError(error.data?.description);
                    } else if (error.data.displayId) {
                        setPageError(error.data?.displayId);
                    } else if (error.data.page) {
                        setPageError(error.data.page);
                    } else if (error.data.message) {
                        setPageError(error.data.message);
                    }
                }
            }
            finally {
                setSaving(false);
            }
        },
    });

    const onQuantityPerUnitChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const itemQuantityToUse = (props.parent === 'direct') ? item?.quantity : indirectItem?.estimatedQuantity;
        if (itemQuantityToUse) {
            setFieldValue('totalQuantity', event.target.value ? parseFloat(event.target.value) * (itemQuantityToUse ?? 0) : undefined);
        }
        handleChange(event);
    }, [handleChange, indirectItem?.estimatedQuantity, item?.quantity, props.parent, setFieldValue])

    const onQuantityChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const itemQuantityToUse = (props.parent === 'direct') ? item?.quantity : indirectItem?.estimatedQuantity;
        if (itemQuantityToUse) {
            setFieldValue('quantityPerUnit', event.target.value ? parseFloat(event.target.value) / (itemQuantityToUse ?? 1) : undefined);
        }
        handleChange(event);
    }, [handleChange, indirectItem?.estimatedQuantity, item?.quantity, props.parent, setFieldValue])

    useEffect(() => {
        setDefaultActivities();
    }, [setDefaultActivities])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            setSearchValue({ description: newValue });
        } else {
            setSearchValue({ description: '' });
            setOpen(false);
            await setDefaultActivities();
        }
    }, [setDefaultActivities])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: FilteredActivity) => {
        if (newValue) {
            setSearchValue(newValue);
        } else {
            setSearchValue({ id: '', description: '', disableSubElements: false });
        }
        setOpen(false);
    }, []);

    const getContainerHeight = useCallback(() => {
        return pageError ? 116 : 68;
    }, [pageError]);

    const handleClose = useCallback(async (option?: string) => {
        try {
            setPageError(undefined);
            if (props.itemId) {
                if (props.parent === 'direct') {
                    await appendItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        itemId: props.itemId,
                        body: {
                            addOption: option === "Add sub-elements" ? 'sub-element' : 'element',
                            id: searchValue.id,
                            type: 'activity'
                        }
                    }).unwrap();
                } else if (props.parent === 'indirect') {
                    await appendIndirectItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        indirectItemId: props.itemId,
                        body: {
                            addOption: option === "Add sub-elements" ? 'sub-element' : 'element',
                            id: searchValue.id,
                            type: 'activity'
                        }
                    }).unwrap();
                }

                props.close();
            }
        } catch (error: any) {
            if (error && ('data' in error)) {
                if (error.data.page) {
                    setPageError(error.data.page);
                }
            }
        }
        finally {
            setSaving(false);
        }
    }, [appendIndirectItemDetail, appendItemDetail, props, searchValue.id, user?.companyId, user?.organizationId]);

    return <Box padding="5px 5px 0px 5px" sx={{ width: props.inHeader ? 505 : 700, height: getContainerHeight() }} >
        {
            colors && <Box>
                <form onSubmit={handleSubmit} noValidate>
                    {pageError && <Box marginBottom="15px"><Alert sx={{ padding: 0 }} severity="error">{pageError}</Alert></Box>}
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" flexDirection="column" marginBottom="5px" flex="1" marginRight="5px">
                            <Autocomplete
                                sx={{ '& .MuiTextField-root': { height: "1.4375em" } }}
                                disableClearable
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                value={searchValue}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.description}
                                filterOptions={(x) => x}
                                size="small"
                                onChange={onChange}
                                onInputChange={onInputChange}
                                options={options}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        sx={{ '& .MuiInputBase-root': { height: 45 } }}
                                        placeholder='Search Activity...' />}
                                renderOption={(props, option, { inputValue }) => {
                                    const matches = match(option.description, inputValue, { insideWords: true });
                                    const parts = parse(option.description, matches);

                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                            />
                        </Box>

                        {!props.inHeader && <><Box display="flex" flexDirection="column" flex="0.5" marginBottom="5px" marginRight="5px">
                            <TextField
                                InputProps={{
                                    type: 'number'
                                }}
                                size="small"
                                id="quantityPerUnit"
                                label={unitSuffix}
                                name="quantityPerUnit"
                                autoComplete="off"
                                placeholder={unitSuffix}
                                InputLabelProps={{
                                    shrink: !!values.quantityPerUnit, // Shrinks the label if a value exists
                                }}
                                error={touched.quantityPerUnit && Boolean(errors.quantityPerUnit)}
                                onBlur={handleBlur}
                                onChange={onQuantityPerUnitChanged}
                                value={values.quantityPerUnit} />
                        </Box>
                            <Box display="flex" flexDirection="column" flex="0.5" marginBottom="5px" marginRight="5px">
                                <TextField
                                    InputProps={{
                                        type: 'number'
                                    }}
                                    size="small"
                                    id="totalQuantity"
                                    label="Total Quantity"
                                    name="totalQuantity"
                                    autoComplete="off"
                                    InputLabelProps={{
                                        shrink: !!values.totalQuantity, // Shrinks the label if a value exists
                                    }}
                                    placeholder='Total Quantity'
                                    error={touched.totalQuantity && Boolean(errors.totalQuantity)}
                                    onBlur={handleBlur}
                                    onChange={onQuantityChanged}
                                    value={values.totalQuantity} />
                            </Box></>}
                        <Box display="flex" flexDirection="column" flex="0.1" marginBottom="5px">
                            <>{props.inHeader ? <SplitButton
                                options={addMenuItems}
                                disablePortal={false}
                                buttonText="Add"
                                onButtonClick={handleClose}
                                onMenuItemClick={handleClose} /> : <LoadingButton
                                    type="submit"
                                    sx={{
                                        width: "30px"
                                    }}
                                    loading={saving}
                                    endIcon={<AddIcon />}
                                    aria-label="save"
                                    color="primary"
                                    loadingPosition="end"
                                    variant="contained">
                                Add
                            </LoadingButton>}</>
                        </Box>
                    </Box>
                </form>
            </Box>
        }
    </Box>
}