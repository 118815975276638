import { Autocomplete, Box, Button, TextField, useTheme } from "@mui/material";
import { EstimateCategory } from "Models/estimate";
import { BaseItem } from "Models/item";
import { useGetCategoriesQuery, useLazyGetNestedCategoriesQuery } from "State/Services/category";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetItemsByCategory1Query, useGetItemsByCategory2Query, useGetItemsByCategory3Query, useGetItemsByCategory4Query, useGetItemsPagedQuery } from "State/Services/item";
import { useGetUserDetailsQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { tokens } from "theme";

export interface ItemFiltersProps {
    estimateId?: string;
    addItems: (items: Array<BaseItem & { searchText: string }>) => void;
    disabled: boolean;
}

export default function SubcontractorItemFilters(props: ItemFiltersProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: categories } = useGetCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId, refetchOnMountOrArgChange: true  });
    const [selectedCategory1, setSelectedCategory1] = useState<EstimateCategory | null | undefined>();
    const [selectedCategory2, setSelectedCategory2] = useState<EstimateCategory | null | undefined>();
    const [selectedCategory3, setSelectedCategory3] = useState<EstimateCategory | null | undefined>();
    const [selectedCategory4, setSelectedCategory4] = useState<EstimateCategory | null | undefined>();
    const { data: pagedItems } = useGetItemsPagedQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', skip: 0, take: 100 }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !!selectedCategory1 || !!selectedCategory2 || !!selectedCategory3 || !!selectedCategory4 });
    const { data: category1Items } = useGetItemsByCategory1Query({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', category1Id: selectedCategory1?.id ?? 0 }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !selectedCategory1?.id })
    const { data: category2Items } = useGetItemsByCategory2Query({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', category1Id: selectedCategory1?.id ?? 0, category2Id: selectedCategory2?.id ?? 0 }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !selectedCategory1?.id || !selectedCategory2?.id })
    const { data: category3Items } = useGetItemsByCategory3Query({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', category1Id: selectedCategory1?.id ?? 0, category2Id: selectedCategory2?.id ?? 0, category3Id: selectedCategory3?.id ?? 0 }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !selectedCategory1?.id || !selectedCategory2?.id || !selectedCategory3?.id })
    const { data: category4Items } = useGetItemsByCategory4Query({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', category1Id: selectedCategory1?.id ?? 0, category2Id: selectedCategory2?.id ?? 0, category3Id: selectedCategory3?.id ?? 0, category4Id: selectedCategory4?.id ?? 0 }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !selectedCategory1?.id || !selectedCategory2?.id || !selectedCategory3?.id || !selectedCategory4?.id })
    const [items, setItems] = useState<Array<BaseItem & { searchText: string }>>([]);
    const [selectedItem, setSelectedItem] = useState<BaseItem & { searchText: string }>();
    const [level1Categories, setLevel1Categories] = useState<Array<EstimateCategory> | undefined>();
    const [level2Categories, setLevel2Categories] = useState<Array<EstimateCategory> | undefined>();
    const [level3Categories, setLevel3Categories] = useState<Array<EstimateCategory> | undefined>();
    const [level4Categories, setLevel4Categories] = useState<Array<EstimateCategory> | undefined>();
    const [colors, setColors] = useState<any>();
    const [getNestedCategories] = useLazyGetNestedCategoriesQuery();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);

    useEffect(() => {
        if (pagedItems && pagedItems.length > 0) {
            setItems(pagedItems.map((item) => ({ ...item, displayId: parseInt(item.displayId?.toString() ?? "0"), searchText: `${item.displayId} - ${item.description}` })));
        }
    }, [pagedItems])

    useEffect(() => {
        if (estimate?.noOfLevels) {
            setLevel1Categories(categories?.filter((cat) => {
                return cat.level === 1;
            }));
        }
    }, [categories, estimate?.noOfLevels])

    useEffect(() => {
        if (estimate?.noOfLevels && estimate?.noOfLevels > 0) {
            if (category1Items) {
                setItems(category1Items.map((item) => ({ ...item, searchText: `${item.displayId} - ${item.description}` })));
            } else {
                setItems([]);
            }
        }
    }, [category1Items, estimate?.noOfLevels])

    useEffect(() => {
        if (estimate?.noOfLevels && estimate?.noOfLevels > 1) {
            if (category2Items) {
                setItems(category2Items.map((item) => ({ ...item, searchText: `${item.displayId} - ${item.description}` })));
            } else {
                setItems([]);
            }
        }
    }, [category2Items, estimate?.noOfLevels])

    useEffect(() => {
        if (estimate?.noOfLevels && estimate?.noOfLevels > 2) {
            if (category3Items) {
                setItems(category3Items.map((item) => ({ ...item, searchText: `${item.displayId} - ${item.description}` })));
            } else {
                setItems([]);
            }
        }
    }, [category3Items, estimate?.noOfLevels])

    useEffect(() => {
        if (estimate?.noOfLevels && estimate?.noOfLevels > 3) {
            if (category4Items) {
                setItems(category4Items.map((item) => ({ ...item, searchText: `${item.displayId} - ${item.description}` })));
            } else {
                setItems([]);
            }
        }
    }, [category4Items, estimate?.noOfLevels])

    const handleOnCategory1Changed = useCallback(async (event: React.SyntheticEvent, newValue: string | null) => {
        if (level1Categories && user && props.estimateId) {
            if (newValue) {
                const cat = level1Categories.find((cat) => (cat.category === newValue));
                setSelectedItem(undefined);
                setSelectedCategory1(cat);
                setSelectedCategory2(null);
                setSelectedCategory3(null);
                setSelectedCategory4(null);
                if (cat?.id) {
                    const categories = await getNestedCategories({
                        categoryId: cat.id,
                        currentLevel: 1,
                        companyId: user.companyId ?? '',
                        organizationId: user.organizationId,
                        estimateId: props.estimateId
                    }, true).unwrap();
                    setLevel2Categories(categories);
                }
            } else {
                setSelectedItem(undefined);
                setLevel2Categories([]);
                setLevel3Categories([]);
                setLevel4Categories([]);
                setSelectedCategory1(null);
                setSelectedCategory2(null);
                setSelectedCategory3(null);
                setSelectedCategory4(null);
                if (pagedItems && pagedItems.length > 0) {
                    setItems(pagedItems.map((item) => ({ ...item, displayId: parseInt(item.displayId?.toString() ?? "0"), searchText: `${item.displayId} - ${item.description}` })));
                }
            }
        }
    }, [getNestedCategories, level1Categories, pagedItems, props.estimateId, user])

    const handleOnCategory2Changed = useCallback(async (event: React.SyntheticEvent, newValue: string | null) => {
        if (level2Categories && user && props.estimateId) {
            if (newValue) {
                const cat = level2Categories.find((cat) => (cat.category === newValue));
                setSelectedItem(undefined);
                setSelectedCategory2(cat);
                setSelectedCategory3(null);
                setSelectedCategory4(null);
                if (cat?.id) {
                    const categories = await getNestedCategories({
                        categoryId: cat.id,
                        currentLevel: 2,
                        companyId: user.companyId ?? '',
                        organizationId: user.organizationId,
                        estimateId: props.estimateId
                    }, true).unwrap();
                    setLevel3Categories(categories);
                }
            } else {
                setSelectedItem(undefined);
                setLevel3Categories([]);
                setLevel4Categories([]);
                setSelectedCategory2(null);
                setSelectedCategory3(null);
                setSelectedCategory4(null);
                if (category1Items) {
                    setItems(category1Items.map((item) => ({ ...item, searchText: `${item.displayId} - ${item.description}` })));
                } else {
                    setItems([]);
                }
            }
        }
    }, [category1Items, getNestedCategories, level2Categories, props.estimateId, user])

    const handleOnCategory3Changed = useCallback(async (event: React.SyntheticEvent, newValue: string | null) => {
        if (level3Categories && user && props.estimateId) {
            if (newValue) {
                const cat = level3Categories.find((cat) => (cat.category === newValue));
                setSelectedItem(undefined);
                setSelectedCategory3(cat);
                setSelectedCategory4(null);
                if (cat?.id) {
                    const categories = await getNestedCategories({
                        categoryId: cat.id,
                        currentLevel: 3,
                        companyId: user.companyId ?? '',
                        organizationId: user.organizationId,
                        estimateId: props.estimateId
                    }, true).unwrap();
                    setLevel4Categories(categories);
                }
            } else {
                setSelectedItem(undefined);
                setLevel4Categories([]);
                setSelectedCategory3(null);
                setSelectedCategory4(null);
                if (category2Items) {
                    setItems(category2Items.map((item) => ({ ...item, searchText: `${item.displayId} - ${item.description}` })));
                } else {
                    setItems([]);
                }
            }
        }
    }, [category2Items, getNestedCategories, level3Categories, props.estimateId, user])

    const handleOnCategory4Changed = useCallback(async (event: React.SyntheticEvent, newValue: string | null) => {
        if (level4Categories && user && props.estimateId) {
            if (newValue) {
                setSelectedItem(undefined);
                const cat = level4Categories.find((cat) => (cat.category === newValue));
                setSelectedCategory4(cat);
            } else {
                setSelectedCategory4(null);
                setSelectedItem(undefined);
                if (category4Items) {
                    setItems(category4Items.map((item) => ({ ...item, searchText: `${item.displayId} - ${item.description}` })));
                } else {
                    setItems([]);
                }
            }
        }
    }, [category4Items, level4Categories, props.estimateId, user])

    const handleOnItemChanged = useCallback(async (event: React.SyntheticEvent, newValue: BaseItem & { searchText: string } | null) => {
        if (newValue) {
            setSelectedItem(newValue);
        } else {
            setSelectedItem(undefined);
        }
    }, [])

    const addItems = useCallback(() => {
        if (selectedItem) {
            props.addItems([selectedItem]);
        } else {
            props.addItems(items)
        }
        setSelectedItem(undefined);
    }, [items, props, selectedItem])

    const isDisabled = useCallback(() => {
        if (props.disabled) return true;

        if (selectedItem || selectedCategory1 || selectedCategory2 || selectedCategory3 || selectedCategory4) return false;

        return true;

    }, [props.disabled, selectedCategory1, selectedCategory2, selectedCategory3, selectedCategory4, selectedItem]) 

    return <>{colors && <Box display="flex" gap="15px">
        {estimate?.noOfLevels.toString() && estimate?.noOfLevels > 0 && <Autocomplete
            fullWidth
            sx={{ width: "245px" }}
            value={selectedCategory1?.category || null}
            disabled={props.disabled}
            size="small"
            onChange={handleOnCategory1Changed}
            options={level1Categories?.map((option) => option.category) ?? []}
            renderInput={(params) => <TextField {...params} name="category" InputProps={{
                ...params.InputProps
            }} label="Category" />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option, inputValue, {
                    insideWords: true,
                });
                const parts = parse(option, matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />}
        {estimate?.noOfLevels.toString() && estimate?.noOfLevels > 1 && <Autocomplete
            fullWidth
            sx={{ width: "245px" }}
            disabled={props.disabled}
            value={selectedCategory2?.category || null}
            size="small"
            onChange={handleOnCategory2Changed}
            options={level2Categories?.map((option) => option.category) ?? []}
            renderInput={(params) => <TextField {...params} name="category" InputProps={{
                ...params.InputProps
            }} label="Category" />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option, inputValue, {
                    insideWords: true,
                });
                const parts = parse(option, matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />}
        {estimate?.noOfLevels.toString() && estimate?.noOfLevels > 2 && <Autocomplete
            fullWidth
            sx={{ width: "245px" }}
            disabled={props.disabled}
            value={selectedCategory3?.category || null}
            size="small"
            onChange={handleOnCategory3Changed}
            options={level3Categories?.map((option) => option.category) ?? []}
            renderInput={(params) => <TextField {...params} name="category" InputProps={{
                ...params.InputProps
            }} label="Category" />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option, inputValue, {
                    insideWords: true,
                });
                const parts = parse(option, matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />}
        {estimate?.noOfLevels.toString() && estimate?.noOfLevels > 3 && <Autocomplete
            fullWidth
            sx={{ width: "245px" }}
            disabled={props.disabled}
            value={selectedCategory4?.category || null}
            size="small"
            onChange={handleOnCategory4Changed}
            options={level4Categories?.map((option) => option.category) ?? []}
            renderInput={(params) => <TextField {...params} name="category" InputProps={{
                ...params.InputProps
            }} label="Category" />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option, inputValue, {
                    insideWords: true,
                });
                const parts = parse(option, matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />}
        <Autocomplete
            fullWidth
            sx={{ width: "245px" }}
            value={selectedItem || null}
            size="small"
            onChange={handleOnItemChanged}
            disabled={props.disabled}
            options={items}
            getOptionLabel={(option) => (option.searchText)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} name="Search" InputProps={{
                ...params.InputProps
            }} label="Search" />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.searchText, inputValue, {
                    insideWords: true,
                });
                const parts = parse(option.searchText, matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
        <Button variant="contained" onClick={addItems} disabled={isDisabled()}>Add</Button>
    </Box>}</>
}