import { Box, Link, useTheme } from "@mui/material";
import { AdvancedFilterModel, ColDef, GetRowIdParams, GridReadyEvent, ICellRendererParams, SelectionChangedEvent } from "ag-grid-community";
import { IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { ItemFilterSettings } from "Components/Items/ItemFilters";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { ItemView } from "Models/item";
import { useMemo, useRef, memo, forwardRef, useImperativeHandle, useEffect, useCallback, useState } from "react";
import { useGetEstimateQuery, useGetGroupedItemsForEstimateMutation } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import { rounder } from "Helpers/rounder";
import ItemsGroupedRenderer from "./ItemsGroupedRenderer";
import AssignedToCellRenderer from "./AssignedToCellRenderer";
import {
    Link as RouterLink,
} from 'react-router-dom';
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";

export interface ItemGroupedProps {
    estimateId: string | undefined;
    onSelected: (rows: Array<ItemView>) => void;
}

export default memo(forwardRef((props: ItemGroupedProps, ref) => {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 58px)', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const gridRef = useRef<AgGridReact<ItemView>>(null);
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [isReady, setIsReady] = useState(false);
    const [autoRowHeightEnabled, setAutoRowHeightEnabled] = useState(false);
    const [getGroupedItemsForEstimate] = useGetGroupedItemsForEstimateMutation();
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            setAutoheight(autoHeight: boolean) {
                switchRowHeight(autoHeight);
                setAutoRowHeightEnabled(autoHeight);
            },
            setFilterChanged(settings: ItemFilterSettings) {
                filterChanged(settings);
            },
            reloadAndClearFilters() {
                gridRef.current!.api.onFilterChanged();
                gridRef.current!.api.deselectAll();
            },
            exportAsExcel() {
                gridRef.current!.api.exportDataAsExcel();
            }
        };
    });

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                setHasViewAccess(true);
                deleteDisabledRef.current = true;
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            setHasViewAccess(true);
            deleteDisabledRef.current = true;
        }
        setIsReady(true);

    }, [estimate, estimate?.EstimateUserRole, user])

    const getItems = useCallback(async (params: IServerSideGetRowsParams<ItemView>) => {
        if (user) {
            const response = await getGroupedItemsForEstimate({
                companyId: user.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId,
                body: {
                    ...params.request
                }
            }).unwrap();

            if (params?.request) {
                const rows = new Array<ItemView>();
                response.results.forEach((item) => {
                    rows.push({
                        category1: item.category1,
                        category2: item.category2,
                        category3: item.category3,
                        category4: item.category4,
                        category1Id: item.category1Id,
                        category2Id: item.category2Id,
                        category3Id: item.category3Id,
                        category4Id: item.category4Id,
                        category1DisplayId: item.category1DisplayId,
                        category2DisplayId: item.category2DisplayId,
                        category3DisplayId: item.category3DisplayId,
                        category4DisplayId: item.category4DisplayId,
                        itemBoqId: item.itemBoqId,
                        page: item.page,
                        amount: item.amount,
                        description: item.description,
                        displayId: item.displayId,
                        quantity: item.quantity,
                        estimatedQuantity: item.estimatedQuantity,
                        rate: item.rate,
                        id: item.id,
                        assignedTo: item.assignedTo,
                        unit: {
                            unitDescription: item.customUnit,
                            unitId: item.unitId
                        },
                        isNew: false,
                        code: item.code
                    });
                });

                params.success({
                    rowData: rows,
                    rowCount: response.totalCount
                });
            }
        }
    }, [getGroupedItemsForEstimate, props.estimateId, user]);
    const getItemsRef = useRef<(params: IServerSideGetRowsParams<ItemView>) => void>(() => (null));
    getItemsRef.current = getItems;

    const createServerSideDatasource = useCallback((): IServerSideDatasource => {
        return {
            getRows: (params) => getItemsRef.current(params),
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        const datasource = createServerSideDatasource();
        params.api!.setGridOption("serverSideDatasource", datasource);
        params.api!.setGridOption("rowSelection", {
            mode: "multiRow",
            enableClickSelection: false,
            groupSelects: "descendants"
        });
    }, [createServerSideDatasource]);

    const getRowId = useCallback(function (params: GetRowIdParams<ItemView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const switchRowHeight = useCallback((autoHeight: boolean) => {
        if (gridRef.current && gridRef.current!.api) {
            const colDefs = gridRef.current!.api.getColumnDefs();
            const descColDef = colDefs?.find((col) => (col.headerName === 'Description')) as any;
            if (descColDef) {
                descColDef['autoHeight'] = autoHeight;
                descColDef['wrapText'] = autoHeight;
            }
            gridRef.current!.api.setGridOption('columnDefs', colDefs);
        }
    }, [])

    const onSelectionChanged = useCallback((event: SelectionChangedEvent<ItemView>) => {
        const rows = event.api.getSelectedRows();
        if (rows && rows.length > 0) {
            props.onSelected(rows);
        } else {
            props.onSelected([]);
        }
    }, [props])

    const getBaseDefs = useCallback((): Array<ColDef<ItemView>> => {
        return [
            {
                field: 'id',
                hide: true,
                filter: 'agTextColumnFilter',
                suppressColumnsToolPanel: true
            },
            {
                field: 'assignedTo',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                resizable: true,
                headerName: 'ID',
                hide: false,
                filter: 'agTextColumnFilter',
                sort: 'asc',
                cellRendererSelector: (params) => {
                    return {
                        component: ItemsGroupedRenderer,
                        params: {
                            suppressCount: true,
                            suppressPadding: true,
                            levels: estimate?.noOfLevels,
                            estimateId: props.estimateId
                        },
                    };
                },
                showRowGroup: true,
                colSpan: (params: any) => {
                    if (params.node.rowPinned) {
                        if (estimate && estimate.noOfLevels > 0) {
                            return 4;
                        } else {
                            return 1;
                        }
                    }
                    else {
                        if (params.node.group) {
                            return 4;
                        }
                        return 1;
                    }

                },
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 95,
                autoHeight: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            },
            {
                field: 'page',
                width: 80,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'itemBoqId',
                headerName: 'Item',
                width: 80,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'description',
                headerName: 'Description',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                filter: 'agTextColumnFilter',
                flex: 1,
                contextMenuItems: () => ([]),
                cellRenderer: (params: ICellRendererParams<ItemView>) => {
                    return <Link
                        sx={{ textAlign: 'left' }}
                        to={`../item/${params.data?.id}`}
                        className="Ignore-Click"
                        variant="body2"
                        component={RouterLink}>{params.data?.description}</Link>
                },
                autoHeight: autoRowHeightEnabled,
                wrapText: autoRowHeightEnabled,
                resizable: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                width: 75,
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                width: 125,
                aggFunc: 'sum',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                resizable: true,
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'estimatedQuantity',
                width: 125,
                headerName: 'Est. Quantity',
                aggFunc: 'sum',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'code',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                width: 85,
                headerName: 'Code',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                width: 150,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                resizable: true,
                sortable: false,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                    background: `repeating-linear-gradient(
                        -45deg,
                        transparent,
                        transparent 4px,
                        transparent 1px,
                        ${colors?.gray[1000]} 7px
                      ),
                      linear-gradient(
                        to bottom,
                        transparent,
                        transparent
                      )`
                }
            },
            {
                field: 'amount',
                width: 150,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                aggFunc: 'sum',
                sortable: false,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                resizable: true,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                    background: `repeating-linear-gradient(
                        -45deg,
                        transparent,
                        transparent 4px,
                        transparent 1px,
                        ${colors?.gray[1000]} 7px
                      ),
                      linear-gradient(
                        to bottom,
                        transparent,
                        transparent
                      )`
                }
            },
            {
                field: 'assignedTo',
                width: 120,
                cellRenderer: AssignedToCellRenderer,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
        ];
    }, [colors?.gray, autoRowHeightEnabled, estimate, props.estimateId, settings?.quantityDecimals])

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(getBaseDefs());
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            editable: false
        };
    }, []);


    useEffect(() => {
        let colDefs = new Array<ColDef>();
        const baseDefs = getBaseDefs();
        switch (estimate?.noOfLevels.toString()) {
            case "0":
                colDefs = [
                    ...baseDefs
                ];
                break;
            case "1":
                baseDefs.splice(2, 0,
                    {
                        field: 'category1',
                        headerName: 'Category 1',
                        hide: true,
                        filter: 'agTextColumnFilter',
                        editable: true,
                        rowGroup: true,
                    },
                    {
                        field: 'category1Id',
                        headerName: 'Category 1 ID',
                        hide: true,
                    }
                );
                colDefs = [
                    ...baseDefs,
                ];
                break;
            case "2":
                baseDefs.splice(2, 0,
                    { field: 'category1', filter: 'agTextColumnFilter', headerName: 'Category 1', editable: true, rowGroup: true, hide: true },
                    { field: 'category1Id', headerName: 'Category 1 ID', hide: true },
                    { field: 'category2', filter: 'agTextColumnFilter', headerName: 'Category 2', editable: true, rowGroup: true, hide: true },
                    { field: 'category2Id', headerName: 'Category 2 ID', hide: true }
                );
                colDefs = [
                    ...baseDefs
                ];
                break;
            case "3":
                baseDefs.splice(2, 0,
                    { field: 'category1', filter: 'agTextColumnFilter', headerName: 'Category 1', editable: true, rowGroup: true, hide: true },
                    { field: 'category1Id', headerName: 'Category 1 ID', hide: true },
                    { field: 'category2', filter: 'agTextColumnFilter', headerName: 'Category 2', editable: true, rowGroup: true, hide: true },
                    { field: 'category2Id', headerName: 'Category 2 ID', hide: true },
                    { field: 'category3', filter: 'agTextColumnFilter', headerName: 'Category 3', editable: true, rowGroup: true, hide: true },
                    { field: 'category3Id', headerName: 'Category 3 ID', hide: true });
                colDefs = [
                    ...baseDefs
                ];
                break;
            case "4":
                baseDefs.splice(2, 0,
                    { field: 'category1', filter: 'agTextColumnFilter', headerName: 'Category 1', editable: true, rowGroup: true, hide: true },
                    { field: 'category1Id', headerName: 'Category 1 ID', hide: true },
                    { field: 'category2', filter: 'agTextColumnFilter', headerName: 'Category 2', editable: true, rowGroup: true, hide: true },
                    { field: 'category2Id', headerName: 'Category 2 ID', hide: true },
                    { field: 'category3', filter: 'agTextColumnFilter', headerName: 'Category 3', editable: true, rowGroup: true, hide: true },
                    { field: 'category3Id', headerName: 'Category 3 ID', hide: true },
                    { field: 'category4', filter: 'agTextColumnFilter', headerName: 'Category 4', editable: true, rowGroup: true, hide: true },
                    { field: 'category4Id', headerName: 'Category 4 ID', hide: true },);
                colDefs = [
                    ...baseDefs
                ];
                break;
            default:
                break;
        }
        setColumnDefs(colDefs);
    }, [estimate?.noOfLevels, getBaseDefs])

    const filterChanged = useCallback(async (filterSettings: ItemFilterSettings) => {
        let filters: AdvancedFilterModel = {} as any;
        if (filterSettings.category1) {
            const catFilter = {
                category1: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category1.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.category2) {
            const catFilter = {
                category2: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category2.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.category3) {
            const catFilter = {
                category3: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category3.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.category4) {
            const catFilter = {
                category4: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category4.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.description) {
            const descriptionFilter = {
                description: {
                    filterType: 'text',
                    type: 'contains',
                    filter: filterSettings.description
                }
            };
            filters = { ...filters, ...descriptionFilter };
        }
        if (filterSettings.idFrom) {
            const idFromFilter = {
                id: {
                    filterType: 'number',
                    type: '>=',
                    filter: filterSettings.idFrom
                }
            };
            filters = { ...filters, ...idFromFilter };
        }
        if (filterSettings.idTo) {
            const idToFilter = {
                displayId: {
                    filterType: 'number',
                    type: '<=',
                    filter: filterSettings.idTo
                }
            };
            filters = { ...filters, ...idToFilter };
        }
        gridRef.current!.api.setFilterModel(filters);
        gridRef.current!.api.onFilterChanged();
    }, [])

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            cellRenderer: ItemsGroupedRenderer,
            cellRendererParams: {
                levels: estimate?.noOfLevels,
                estimateId: props.estimateId
            },
            colSpan: () => {
                return 2;
            }
        };
    }, [estimate?.noOfLevels, props.estimateId]);

    if (!isReady) {
        return <></>
    }

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <Box
        className="ag-theme-alpine ag-theme-bidbow inner-col"
        style={gridStyle}>
        <AgGridReact<ItemView>
            ref={gridRef}
            animateRows={false}
            autoGroupColumnDef={autoGroupColumnDef}
            defaultColDef={defaultColDef}
            groupDisplayType={'custom'}
            suppressScrollOnNewData={true}
            onSelectionChanged={onSelectionChanged}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowModelType={"serverSide"}
            getRowId={getRowId}
            pagination={true}
            paginationPageSize={15}
            suppressAggFuncInHeader={true}
            cacheBlockSize={15}
            paginationPageSizeSelector={[15, 25, 50, 100]}
        />
    </Box>

}));
