import { Autocomplete, Box } from "@mui/material";
import { ICellEditorParams } from "ag-grid-enterprise";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { GridTextField } from "Components/GridTextField";
import { ActivityView } from "Models/activity";
import { ActivityCategory } from "Models/activity-category";
import { forwardRef, MutableRefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useGetActivityCategoriesQuery } from "State/Services/activity-category";
import { useGetUserDetailsQuery } from "State/Services/user";

export interface ActivityListCategoryEditCellRendererParams extends ICellEditorParams<ActivityView, ActivityCategory> {
    estimateId: string;
    setRef: (ref: MutableRefObject<any>) => void;
}

export default forwardRef((props: ActivityListCategoryEditCellRendererParams, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedActivityCategories } = useGetActivityCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId, refetchOnMountOrArgChange: true });
    const [open, setOpen] = useState(false);
    const inputRef = useRef<any>();

    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value?.description ?? null;
            },
            focusIn() {
                inputRef.current.focus();
                handleOpen();
            },
            setFocusOnAdd() {
                if (inputRef.current) {
                    inputRef.current.focus();
                    setOpen(true);
                }
            },
        };
    });

    const getInitialValue = useCallback((props: ICellEditorParams<ActivityView, ActivityCategory>) => {
        let value: ActivityCategory | null = null;
        const actCategory = storedActivityCategories?.find((actCategory) => (actCategory.id === props.data?.categoryId));
        if (actCategory) {
            value = actCategory;
        }
        return value;
    }, [storedActivityCategories]);

    const [value, setValue] = useState<ActivityCategory | null>(getInitialValue(props));

    const onChange = useCallback((event: React.SyntheticEvent, newValue: ActivityCategory | null) => {
        if (newValue && storedActivityCategories) {
            const actCategory = storedActivityCategories.find((category) => (category.id === newValue.id));
            if (actCategory) {
                setValue(actCategory);
                props.node.setDataValue('categoryId', actCategory.id);
            } else {
                setValue(null);
                props.node.setDataValue('categoryId', undefined);
            }
        } else {
            setValue(null);
            props.node.setDataValue('categoryId', undefined);
        }
    }, [props.node, storedActivityCategories])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (inputRef) {
            props.setRef(inputRef);
        }
    }, [props])

    return <Box height="100%">
        <Autocomplete
            fullWidth
            className="ag-input-field-input ag-text-field-input"
            sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
            value={value || null}
            size="small"
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            onOpen={handleOpen}
            open={open}
            onChange={onChange}
            options={storedActivityCategories ?? []}
            getOptionLabel={(actCategory) => {
                if (typeof actCategory === 'string') {
                    return actCategory;
                }
                return actCategory.description ?? '';
            }}
            renderInput={(params) => <GridTextField sx={{ height: "100%" }} inputRef={inputRef} placeholder='Select Category' {...params} />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.description ?? '', inputValue, {
                    insideWords: true,
                });
                const parts = parse(option.description ?? '', matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    </Box>
});