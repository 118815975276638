import { Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { IRowNode } from "ag-grid-community";
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
import { IndirectItemDetailView } from "Models/indirect-item";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CustomCellEditorProps } from "ag-grid-react";
export interface ISaveCancelIndirectItemDetailCellRendererProps extends CustomCellEditorProps<IndirectItemDetailView> {
    deleteRecord: (node: IRowNode<IndirectItemDetailView>) => Promise<void>;
    save: (node: IRowNode<IndirectItemDetailView>) => Promise<void>;
    cancel: (node: IRowNode<IndirectItemDetailView>) => void;
    disabled: () => boolean;
}

export default function SaveCancelIndirectItemDetailCellRenderer({ api, cancel, node, deleteRecord , save, disabled }: ISaveCancelIndirectItemDetailCellRendererProps) {
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    let editingCells = api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
        return cell.rowIndex === node.rowIndex;
    });

    const deleteRec = async () => {
        try {
            if (isCurrentRowEditing) {
                cancel(node);
            } else {
                setIsDeleting(true);
                await deleteRecord(node);
                setIsDeleting(false);
            }
        } catch (error) {
            setIsDeleting(false);
            console.log(error);
        }
    }

    const saveRec = async () => {
        try {
            setIsSaving(true);
            await save(node);
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
        }
    }

    return (<>
        {node.data &&
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                {isCurrentRowEditing && !isSaving &&
                    <IconButton aria-label="save" color="primary" onClick={saveRec}>
                        <SaveIcon />
                    </IconButton>
                }
                {isSaving &&
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
                {(!isDeleting && (node.data?.hierarchy?.length===1)) && 
                    <IconButton aria-label="delete" onClick={deleteRec} disabled={disabled()}>
                        {isCurrentRowEditing && <CloseIcon />}
                        {!isCurrentRowEditing && <DeleteOutlineIcon />}
                    </IconButton>
                }
                {isDeleting &&
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
            </Box>}
    </>
    );
};
