import { Alert, Avatar, Box, Button, IconButton, Modal, Tooltip, Typography, useTheme } from "@mui/material";
import { BaseItem, EntityNotFound, EstimateItemView, ItemDetail, ItemDetailView, ReorderItemDetail } from "Models/item";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { CellClickedEvent, RowEditingStartedEvent, RowEditingStoppedEvent, CellKeyDownEvent, ColDef, EditableCallbackParams, GetRowIdParams, HeaderValueGetterParams, IRowNode, RowDragEndEvent, RowNode, SuppressKeyboardEventParams, ICellEditor } from "ag-grid-community";
import { AgGridReact, getInstance } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import SaveCancelItemDetailCellRenderer from "./SaveCancelItemDetailCellRenderer";
import { useConfirm } from "material-ui-confirm";
import { useAppendItemDetailMutation, useCreateFromEstimateItemMutation, useDeleteItemDetailMutation, useDuplicateDetailMutation, useGetItemDetailsForItemQuery, useReorderItemDetailsMutation, useUpdateItemDetailMutation } from "State/Services/item";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ActivityView } from "Models/activity";
import ResourceList from "Components/Resources/ResourceList";
import { ResourceView } from "Models/resource";
import StandardItemList from "Components/StandardItems/StandardItemList";
import { StandardItemView } from "Models/standard-item";
import { ItemForm } from "./item-form";
import ActivityList from "Components/Activities/ActivityList";
import { useGetSettingsQuery } from "State/Services/settings";
import { rounder } from "Helpers/rounder";
import { useGetEstimateQuery } from "State/Services/estimate";
import { Append } from "Components/Items/Append";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import QuantityCalculator from "Components/Shared/QuantityCalculator";
import CloseIcon from '@mui/icons-material/Close';
import itemDetailQuantityPerUnitCellRenderer from "./itemDetailQuantityPerUnitCellRenderer";
import { PanelState } from "Models/panel";
import { Errors } from "Models/errors";
import { NavItem } from "Models/nav";
import ItemDetailGroupCellRenderer from "./ItemDetailGroupCellRenderer";
import ItemDetailDescriptionCellRenderer from "./ItemDetailDescriptionCellRender";
import ItemDetailInnerHeader from "./ItemDetailInnerHeader";
import ItemList from "Components/Estimates/ItemList";
import ItemDetailsNotFound from "./itemDetailsNotFound";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";
import { BaseEditCellRenderer } from "Models/grid";
import GenericNumberEditCellRenderer from "Components/Shared/GenericNumberEditCellRenderer";

export interface ItemDetailsProps {
    estimateId: string | undefined;
    item: BaseItem;
    currentForm: ItemForm;
    onAmountUpdate: (amount: number) => void;
    previousItem: NavItem;
}
export interface Fields {
    unit: boolean;
    displayId: boolean;
    description: boolean;
    rate: boolean;
}

export default function ItemDetails(props: ItemDetailsProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: storedItemDetails } = useGetItemDetailsForItemQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '', itemId: props?.item.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !props?.item?.id });
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [appendItemDetail] = useAppendItemDetailMutation();
    const [updateItemDetail] = useUpdateItemDetailMutation();
    const [deleteItemDetail] = useDeleteItemDetailMutation();
    const gridRef = useRef<AgGridReact<ItemDetailView>>(null);
    const [rowData, setRowData] = useState<Array<ItemDetailView>>([]);
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<ItemDetailView> | undefined, column?: string }>();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const confirm = useConfirm();
    const [panelState, setPanelState] = useState<PanelState>({ position: { state: 'hidden' } });
    const [selectedActivityId, setSelectedActivityId] = useState<string | undefined>();
    const [selectedResourceId, setSelectedResourceId] = useState<string | undefined>();
    const [selectedStandardItemId, setSelectedStandardItemId] = useState<string | undefined>();
    const [selectedDetail, setSelectedDetail] = useState<ItemDetailView>();
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates (could also do a useEffect hook)
    const itemRef = useRef<any>();
    itemRef.current = props.item;
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();
    const [pageError, setPageError] = useState<string | undefined>();
    const [errors, setErrors] = useState<Array<{ field: string, error: string }>>([]);
    const [reorderItemDetails] = useReorderItemDetailsMutation();
    const [duplicateDetail] = useDuplicateDetailMutation();
    const [copyEstimateItem] = useCreateFromEstimateItemMutation();
    const [entitiesNotFound, setEntitiesNotFound] = useState<Array<EntityNotFound>>([]);
    const [openCalculateModal, setOpenCalculateModal] = useState(false);
    const handleOpenCalculateModal = () => {
        setOpenCalculateModal(true);
    };
    const handleCloseCalculateModal = () => {
        setOpenCalculateModal(false);
        setSelectedDetail(undefined);
    };
    const [openEntitiesNotFound, setOpenEntitiesNotFound] = useState(false);
    const handleOpenEntitiesNotFoundModal = () => {
        setOpenEntitiesNotFound(true);
    };
    const handleCloseEntitiesNotFoundModal = () => {
        setOpenEntitiesNotFound(false);
        setEntitiesNotFound([]);
    };

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((errorDetails) => {
                const instances = gridRef.current!.api.getCellEditorInstances({
                    columns: [errorDetails.field]
                });
                if (instances.length > 0) {
                    getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
                        if (instance && instance.setError) {
                            instance.setError(errorDetails.error);
                        }
                    });
                }
            });
        }
    }, [errors])

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [504]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    // Panel grid and components
    const [value, setValue] = useState('Resources');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setSelectedResourceId(undefined);
        setSelectedActivityId(undefined);
        setSelectedStandardItemId(undefined);
    };

    const calculateAmount = useCallback((itemDetail: ItemDetail) => {
        let amount: number | undefined = undefined;
        if (!itemDetail.quantityPerUnit || !itemDetail.rate) return;
        let parentFactor = 1;
        switch (itemDetail.type) {
            case "resource":
                if (itemDetail.hierarchy && itemDetail.hierarchy.length > 1) {
                    const parentId = itemDetail?.hierarchy[0];
                    const parentResource = storedItemDetails?.find((idt) => (idt.resourceId === parentId && idt.hierarchy?.length === 1));
                    if (parentResource) {
                        if (parentResource.factor) {
                            parentFactor = parentResource.factor;
                        }
                        if (itemDetail.factor) {
                            amount = itemDetail.quantityPerUnit * itemDetail.rate * itemDetail.factor * parentFactor;
                        } else {
                            amount = itemDetail.quantityPerUnit * itemDetail.rate * parentFactor;
                        }
                        return amount;
                    }
                    const parentActivity = storedItemDetails?.find((idt) => (idt.activityId === parentId && idt.hierarchy?.length === 1));
                    if (parentActivity) {
                        if (itemDetail.quantityPerUnit) {
                            if (parentActivity?.factor) {
                                amount = itemDetail.quantityPerUnit * itemDetail.rate * parentActivity.factor;
                            } else {
                                amount = itemDetail.quantityPerUnit * itemDetail.rate;
                            }
                        }
                    }
                    const parentItem = storedItemDetails?.find((idt) => (idt.id === parentId && idt.hierarchy?.length === 1));
                    if (itemDetail.quantityPerUnit) {
                        if (parentItem?.type === "item") {
                            switch (itemDetail.hierarchy.length) {
                                case 2:
                                    if (parentItem) {
                                        if (parentItem.factor) {
                                            parentFactor = parentItem.factor;
                                        }
                                        if (itemDetail.factor) {
                                            amount = itemDetail.quantityPerUnit * itemDetail.rate * itemDetail.factor * parentFactor;
                                        } else {
                                            amount = itemDetail.quantityPerUnit * itemDetail.rate * parentFactor;
                                        }
                                        return amount;
                                    }
                                    break;
                                case 3:
                                    if (parentItem) {
                                        if (parentItem.factor) {
                                            parentFactor = parentItem.factor;
                                        }
                                        const resId = itemDetail.hierarchy[1];
                                        const parentRes = storedItemDetails?.find((idt) => (idt.hierarchy && idt.hierarchy.length === 2 && idt.hierarchy[0] === parentId && idt.hierarchy[1] === resId));
                                        const parentResFactor = parentRes?.factor ?? 1;
                                        if (itemDetail.factor) {
                                            amount = itemDetail.quantityPerUnit * itemDetail.rate * itemDetail.factor * parentFactor * parentResFactor;
                                        } else {
                                            amount = itemDetail.quantityPerUnit * itemDetail.rate * parentFactor * parentResFactor;
                                        }
                                        return amount;
                                    }
                                    break;
                                default:
                                    break;
                            }
                        } else if (parentItem?.type === 'subcontractor') {
                            if (itemDetail.factor) {
                                amount = itemDetail.quantityPerUnit * itemDetail.rate * itemDetail.factor;
                            } else {
                                amount = itemDetail.quantityPerUnit * itemDetail.rate;
                            }
                        }
                    }
                } else {
                    if (itemDetail.factor) {
                        amount = itemDetail.quantityPerUnit * itemDetail.rate * itemDetail.factor;
                    } else {
                        amount = itemDetail.quantityPerUnit * itemDetail.rate;
                    }
                    return amount;
                }
                break;
            case "activity":
                if (itemDetail?.hierarchy) {
                    switch (itemDetail.hierarchy.length) {
                        case 1:
                            if (itemDetail.quantityPerUnit && itemDetail.rate) {
                                if (itemDetail.factor) {
                                    return itemDetail.quantityPerUnit * itemDetail.factor * itemDetail.rate;
                                } else {
                                    return itemDetail.quantityPerUnit * itemDetail.rate;
                                }
                            }
                            break;
                        case 2:
                            const parentId = itemDetail?.hierarchy[0];
                            const parentItem = storedItemDetails?.find((idt) => (idt.id === parentId && idt.hierarchy?.length === 1));
                            if (itemDetail.quantityPerUnit && itemDetail.rate && parentItem) {
                                if (parentItem.factor) {
                                    parentFactor = parentItem.factor;
                                }
                                if (itemDetail.factor) {
                                    return itemDetail.quantityPerUnit * itemDetail.factor * itemDetail.rate * parentFactor;
                                } else {
                                    return itemDetail.quantityPerUnit * itemDetail.rate * parentFactor;
                                }
                            }
                            break
                        default:
                            break;
                    }
                }
                break;
            case "item":
            case "subcontractor":
                const factor = itemDetail.factor ?? 1;
                if (itemDetail.quantityPerUnit) {
                    return itemDetail.quantityPerUnit * factor * itemDetail.rate;
                }
                break;
            default:
                break;
        }

        return amount;
    }, [storedItemDetails])

    const calculateQuantityPerUnit = useCallback((details: Array<ItemDetailView>) => {
        const itemDetsQtyUpdated = new Array<ItemDetailView>()
        // Handling items first
        const firstLevelItems = details.filter((detail) => (detail.hierarchy?.length === 1 && detail.type === "item"));
        if (firstLevelItems.length > 0) {
            firstLevelItems.forEach((item) => {
                itemDetsQtyUpdated.push(item);
                if (item.hierarchy && item.hierarchy.length > 0) {
                    const itemId = item.hierarchy[0];
                    const secondlevelActRes = details.filter((detail) => (detail?.hierarchy && detail.hierarchy[0] === itemId && detail.hierarchy.length === 2));
                    secondlevelActRes.forEach((itemDetail) => {
                        let secondLevelQty = itemDetail.quantityPerUnit;
                        if (itemDetail.quantityPerUnit && item.quantityPerUnit) {
                            secondLevelQty = item.quantityPerUnit * itemDetail.quantityPerUnit
                            itemDetsQtyUpdated.push({ ...itemDetail, quantityPerUnit: secondLevelQty });
                        } else {
                            itemDetsQtyUpdated.push({ ...itemDetail });
                        }
                        if (itemDetail.activityId) {
                            const thirdLevelResources = details.filter((detail) => (detail?.hierarchy
                                && detail.hierarchy.length === 3
                                && detail.hierarchy[0] === itemId
                                && detail.hierarchy[1] === itemDetail.activityId));
                            thirdLevelResources.forEach((itemDetailRes) => {
                                if (itemDetailRes.quantityPerUnit && secondLevelQty) {
                                    itemDetsQtyUpdated.push({
                                        ...itemDetailRes, quantityPerUnit: secondLevelQty * itemDetailRes.quantityPerUnit
                                    });
                                } else {
                                    itemDetsQtyUpdated.push({
                                        ...itemDetailRes
                                    });
                                }
                            });
                        } else if (itemDetail.resourceId) {
                            const thirdLevelResources = details.filter((detail) => (detail?.hierarchy
                                && detail.hierarchy.length === 3
                                && detail.hierarchy[0] === itemId
                                && detail.hierarchy[1] === itemDetail.resourceId));
                            thirdLevelResources.forEach((itemDetailRes) => {
                                if (itemDetailRes.quantityPerUnit && secondLevelQty) {
                                    itemDetsQtyUpdated.push({
                                        ...itemDetailRes, quantityPerUnit: secondLevelQty * itemDetailRes.quantityPerUnit
                                    });
                                } else {
                                    itemDetsQtyUpdated.push({
                                        ...itemDetailRes
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
        // Handling first level activities
        const firstLevelActivities = details.filter((detail) => (detail.hierarchy?.length === 1 && detail.type === "activity"));
        firstLevelActivities.forEach((itemDetail) => {
            itemDetsQtyUpdated.push({ ...itemDetail });
            if (itemDetail.hierarchy) {
                const itemId = itemDetail.hierarchy[0];
                const secondlevelRes = details.filter((detail) => (detail?.hierarchy && detail.hierarchy[0] === itemId && detail.hierarchy.length === 2));
                secondlevelRes.forEach((secondDetail) => {
                    if (itemDetail.quantityPerUnit && secondDetail.quantityPerUnit) {
                        itemDetsQtyUpdated.push({ ...secondDetail, quantityPerUnit: secondDetail.quantityPerUnit * itemDetail.quantityPerUnit });
                    } else {
                        itemDetsQtyUpdated.push({ ...secondDetail });
                    }
                });
            }
        });
        // Handling first level resources
        const firstLevelResources = details.filter((detail) => (detail.hierarchy?.length === 1 && detail.type === "resource"));
        firstLevelResources.forEach((itemDetail) => {
            itemDetsQtyUpdated.push({ ...itemDetail });
            if (itemDetail.hierarchy) {
                const itemId = itemDetail.hierarchy[0];
                const secondlevelRes = details.filter((detail) => (detail?.hierarchy && detail.hierarchy[0] === itemId && detail.hierarchy.length === 2));
                secondlevelRes.forEach((secondDetail) => {
                    if (itemDetail.quantityPerUnit && secondDetail.quantityPerUnit) {
                        itemDetsQtyUpdated.push({ ...secondDetail, quantityPerUnit: secondDetail.quantityPerUnit * itemDetail.quantityPerUnit });
                    } else {
                        itemDetsQtyUpdated.push({ ...secondDetail });
                    }
                });
            }
        });
        // Handling first level subcontractor
        const firstLevelSubcontractors = details.filter((detail) => (detail.hierarchy?.length === 1 && detail.type === "subcontractor"));
        firstLevelSubcontractors.forEach((itemDetail) => {
            itemDetsQtyUpdated.push({ ...itemDetail });
            if (itemDetail.hierarchy) {
                const itemId = itemDetail.hierarchy[0];
                const secondlevelRes = details.filter((detail) => (detail?.hierarchy && detail.hierarchy[0] === itemId && detail.hierarchy.length === 2));
                secondlevelRes.forEach((secondDetail) => {
                    itemDetsQtyUpdated.push({ ...secondDetail });
                });
            }
        });
        return itemDetsQtyUpdated;
    }, [])

    const showFullSidepanel = useCallback((position: "hidden" | "half" | "full") => {
        switch (position) {
            case "hidden":
                gridRef.current!.api.setColumnsVisible(["unit", "quantityPerUnit", "quantity", "factor", "rate", "amount", "actions"], true);
                break;
            case "half":
                gridRef.current!.api.setColumnsVisible(["unit", "quantityPerUnit", "quantity", "factor", "rate", "amount", "actions"], false);
                break;
            default:
                break;
        }
        setPanelState({ position: { state: position } });
    }, [])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const saveItemDetail = useCallback(async (nodeToSave: IRowNode<ItemDetailView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<ItemDetailView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && props.estimateId && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    await updateItemDetail({
                        itemDetailId: nodeToSave.data.id,
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user.organizationId,
                        itemId: props.item.id,
                        body: {
                            id: nodeToSave.data.id,
                            quantity: nodeToSave.data.quantity,
                            quantityPerUnit: nodeToSave.data.quantityPerUnit,
                            factor: nodeToSave.data.factor,
                            rate: nodeToSave.data.rate,
                            type: nodeToSave.data.type,
                            activityId: nodeToSave.data.activityId
                        }
                    }).unwrap();
                    resolve();
                    if (toEditAfterSave) {
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    } else {
                        setCurrentEditing(undefined);
                    }
                }
            } catch (error: any) {
                if (error && error.data) {
                    if (typeof nodeToSave.rowIndex === 'number') {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: 'factor',
                        });
                    }
                    if (error.data.factor) {
                        setErrors([{ field: 'factor', error: error.data.factor }]);
                    }
                }
                reject(error);
            }
        });
    }, [props.estimateId, props.item.id, updateItemDetail, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const saveItemDetailRef = useRef<any>();
    saveItemDetailRef.current = saveItemDetail;

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<ItemDetailView>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();
            saveItemDetail(params.node);
            return true;
        }
        return false;
    }, [saveItemDetail])

    const cancelEditing = useCallback((node: IRowNode<ItemDetailView>) => {
        if (node && node.data) {
            setIsCancelClicked(true);
            const itemDetail = storedItemDetails?.find((c) => (c.id === node.data?.id));
            if (itemDetail) {
                const unit = units?.find((unit) => (unit.id === itemDetail.unitId));
                const amount = calculateAmount(itemDetail);
                gridRef.current!.api.applyTransaction({
                    update: [
                        {
                            ...itemDetail,
                            amount: amount,
                            totalAmount: (amount ?? 0) * parseFloat(itemRef.current?.quantity ?? 0),
                            unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined
                        }]
                });
            }
            gridRef.current!.api.stopEditing(true);
            setCurrentEditing(undefined);
        }
    }, [calculateAmount, storedItemDetails, units])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const cancelEditingRef = useRef<any>();
    cancelEditingRef.current = cancelEditing;

    const deleteRow = useCallback((node: RowNode<ItemDetailView>) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await confirm({ description: `Are you sure you want to delete the record?`, title: 'Delete confirmation' });
                if (user && node.data) {
                    await deleteItemDetail({
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        itemId: props.item.id,
                        orgId: user.organizationId,
                        itemDetailId: node.data.id,
                        type: node.data.type
                    });
                }

                resolve();
            } catch (error) {
                reject('Cancelled by user.');
            }
        });
    }, [confirm, deleteItemDetail, props.estimateId, props.item.id, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const deleteRef = useRef<any>();
    deleteRef.current = deleteRow;

    const isColEditable = useCallback((params: EditableCallbackParams<ItemDetailView>) => {
        if (props.item.assignedTo && props.item.assignedTo.length > 0) {
            if (props.item.assignedTo.some((assignedTo) => (assignedTo.id === user?.userId))) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    }, [props.item.assignedTo, user?.userId]);
    const isColEditableRef = useRef<any>();
    isColEditableRef.current = isColEditable;

    const openCalculateModalFunc = useCallback((node: IRowNode<ItemDetailView>) => {
        setSelectedDetail(node.data);
        handleOpenCalculateModal();
    }, []);
    const openCalculateModalRef = useRef<any>();
    openCalculateModalRef.current = openCalculateModalFunc;

    const isDeleteDisabled = useCallback(() => {
        if (editDisabledRef.current) return true;

        if (props.item.assignedTo && props.item.assignedTo.length > 0) {
            return !props.item.assignedTo.some((assignedTo) => (assignedTo.id === user?.userId));
        }
        return false;
    }, [props.item.assignedTo, user?.userId])
    const isDeleteDisabledRef = useRef<any>();
    isDeleteDisabledRef.current = isDeleteDisabled;

    const getItemQuantityHeader = useCallback((params: HeaderValueGetterParams<ItemDetailView>, item: BaseItem | undefined) => {
        if (units && item && item.unitId) {
            const unit = units?.find(u => u.id === item.unitId);
            if (unit) {
                return `Quantity / ${unit.description}`;
            }
        } else if (item?.customUnit) {
            return `Quantity / ${item.customUnit}`;
        }
        return 'Quantity / Item Unit';
    }, [units])
    const getItemUnitHeaderRef = useRef<any>();
    getItemUnitHeaderRef.current = getItemQuantityHeader;

    const getItemAmountHeader = useCallback((params: HeaderValueGetterParams<ItemDetailView>, item: BaseItem | undefined) => {
        if (item && item.unitId) {
            const unit = units?.find(u => u.id === item.unitId);
            if (unit) {
                return `Amount / ${unit.description}`;
            }
        } else if (item?.customUnit) {
            return `Amount / ${item.customUnit}`;
        }
        return 'Amount / Item Unit';
    }, [units])
    const getItemAmountHeaderRef = useRef<any>();
    getItemAmountHeaderRef.current = getItemAmountHeader;

    const onDuplicate = useCallback(async (node: IRowNode<ItemDetailView>) => {
        await duplicateDetail({
            body: {
                activityId: node.data?.activityId ?? '',
                itemActivityId: node.data?.itemActivityId ?? '',
                standardItemId: node.data?.standardItemId,
                subItemId: node.data?.subitemId
            },
            companyId: user?.companyId,
            estimateId: props.estimateId,
            itemId: props.item.id,
            orgId: user?.organizationId
        });
    }, [duplicateDetail, props.estimateId, props.item.id, user?.companyId, user?.organizationId])
    const onOverrideRef = useRef<any>();
    onOverrideRef.current = onDuplicate;

    const onFactorChange = useCallback((node: IRowNode<ItemDetailView>, value: number | null) => {
        let quantityPerUnit: number | undefined;
        const instances = gridRef.current!.api.getCellEditorInstances({
            columns: ['quantityPerUnit']
        });

        getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
            if (!node.data?.rate) {
                node.setDataValue('amount', undefined);
                return;
            };

            if (isNaN(parseFloat(node.data.rate.toString()))) {
                node.setDataValue('amount', undefined);
                return;
            };
            if (instance && instance.getValue) {
                quantityPerUnit = instance.getValue();
            }
            if (!quantityPerUnit) {
                node.setDataValue('amount', undefined);
                return;
            }

            if (isNaN(parseFloat(quantityPerUnit.toString()))) {
                node.setDataValue('amount', undefined);
                return;
            }
            let amount: number | undefined = undefined;
            if (value && !isNaN(value)) {
                amount = quantityPerUnit * parseFloat(node.data.rate.toString()) * value;
            } else {
                amount = quantityPerUnit * parseFloat(node.data.rate.toString());
            }
            node.setDataValue('amount', amount);
        });
    }, [])
    const onFactorChangeRef = useRef<any>();
    onFactorChangeRef.current = onFactorChange;

    const onQuantityPerUnitChange = useCallback((node: IRowNode<ItemDetailView>, value: number | null) => {
        if (!value) {
            node.setDataValue('amount', undefined);
            return;
        }

        if (isNaN(value)) {
            node.setDataValue('amount', undefined);
            return;
        };
        const quantityInstances = gridRef.current!.api.getCellEditorInstances({
            columns: ['quantity']
        });

        getInstance<ICellEditor, BaseEditCellRenderer>(quantityInstances[0], (instance) => {
            const currentItem = itemRef.current;
            if (instance && instance.changeValue) {
                if (value && !isNaN(value) && currentItem?.quantity) {
                    instance.changeValue(value * currentItem.quantity);
                } else {
                    instance.changeValue('');
                }
            }
        });
        let rate: number | undefined;
        if (node.data?.rate) {
            rate = parseFloat(node.data.rate.toString());
        } else {
            node.setDataValue('amount', undefined);
            return;
        }
        let factor: number | undefined;
        const factorInstances = gridRef.current!.api.getCellEditorInstances({
            columns: ['factor']
        });
        getInstance<ICellEditor, BaseEditCellRenderer>(factorInstances[0], (instance) => {
            if (instance) {
                factor = instance.getValue();
            }

            let amount: number | undefined = undefined;
            if (rate) {
                if (factor && !isNaN(factor)) {
                    amount = value * rate * factor;
                } else {
                    amount = value * rate;
                }
            }
            node.setDataValue('amount', amount);
        });
    }, [])
    const onQuantityPerUnitChangeRef = useRef<any>();
    onQuantityPerUnitChangeRef.current = onQuantityPerUnitChange;

    const onTotalQuantityChange = useCallback((node: IRowNode<ItemDetailView>, value: number | null) => {
        const instances = gridRef.current!.api.getCellEditorInstances({
            columns: ['quantityPerUnit']
        });

        getInstance<ICellEditor, BaseEditCellRenderer>(instances[0], (instance) => {
            const currentItem = itemRef.current;
            if (instance && instance.changeValue) {
                if (value && !isNaN(value) && currentItem.quantity) {
                    instance.changeValue(value / parseFloat(currentItem.quantity.toString()));
                } else {
                    instance.changeValue('');
                }
            }
        });
    }, [])
    const onTotalQuantityChangeRef = useRef<any>();
    onTotalQuantityChangeRef.current = onTotalQuantityChange;

    const getBaseDefs = useCallback((): Array<ColDef<ItemDetailView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'type',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'resourceId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'activityId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'subitemId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'subcontractorId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'order',
                hide: true,
                suppressColumnsToolPanel: true,
                sort: 'asc'
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellRenderer: ItemDetailDescriptionCellRenderer,
                cellRendererParams: {
                    setSelectedActivityId: setSelectedActivityId,
                    setSelectedResourceId: setSelectedResourceId,
                    setSelectedStandardItemId: setSelectedStandardItemId,
                    setPanelState: setPanelState,
                    setValue: setValue,
                    onDuplicate: onDuplicate,
                    estimateId: props.estimateId
                },
                suppressKeyboardEvent: saveOnEnter,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                cellRenderer: GenericUnitCellRenderer,
                width: 75,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantityPerUnit',
                headerValueGetter: (params) => getItemQuantityHeader(params, itemRef.current),
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellRenderer: itemDetailQuantityPerUnitCellRenderer,
                cellRendererParams: {
                    calculate: (node: IRowNode<ItemDetail>) => openCalculateModalRef.current(node)
                },
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    field: 'Quantity / Unit',
                    onChangeCallBack: (node: IRowNode<ItemDetail>, value: number | null) => onQuantityPerUnitChangeRef.current(node, value)
                },
                cellDataType: "number",
                editable: (params: EditableCallbackParams<ItemDetailView>) => isColEditableRef.current(params),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                headerName: 'Total Quantity',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    onChangeCallBack: (node: IRowNode<ItemDetail>, value: number | null) => onTotalQuantityChangeRef.current(node, value)
                },
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                cellDataType: "number",
                editable: (params: EditableCallbackParams<ItemDetailView>) => isColEditableRef.current(params),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'factor',
                headerName: 'Factor',
                cellEditor: GenericNumberEditCellRenderer,
                cellEditorParams: {
                    onChangeCallBack: (node: IRowNode<ItemDetail>, value: number | null) => onFactorChangeRef.current(node, value)
                },
                width: 90,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellDataType: "number",
                editable: (params: EditableCallbackParams<ItemDetailView>) => isColEditableRef.current(params),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                suppressKeyboardEvent: saveOnEnter,
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'amount',
                headerValueGetter: (params) => getItemAmountHeader(params, itemRef.current),
                suppressKeyboardEvent: saveOnEnter,
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                }
            },
            {
                field: 'totalAmount',
                headerName: 'Amount',
                editable: false,
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                }
            },
            {
                field: 'actions',
                width: 80,
                headerName: 'Actions',
                menuTabs: [],
                editable: false,
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRenderer: SaveCancelItemDetailCellRenderer,
                cellRendererParams: {
                    save: (node: IRowNode<ItemDetailView>) => saveItemDetailRef.current(node),
                    cancel: (node: IRowNode<ItemDetailView>) => cancelEditingRef.current(node),
                    deleteRecord: (node: IRowNode<ItemDetailView>) => deleteRef.current(node),
                    disabled: () => isDeleteDisabledRef.current()
                }
            },
        ];
    }, [onDuplicate, props.estimateId, saveOnEnter, colors?.gray, getItemQuantityHeader, settings?.quantityDecimals, estimate?.CompanyCurrency?.Currency?.minorUnit, getItemAmountHeader])

    useEffect(() => {
        if (storedItemDetails && units && gridRef.current && gridRef.current!.api) {
            gridRef.current!.api.setGridOption('columnDefs', getBaseDefs());
            const rows = new Array<ItemDetailView>();
            const itemDetails = calculateQuantityPerUnit(storedItemDetails);
            for (let index = 0; index < itemDetails.length; index++) {
                const det = itemDetails[index];
                const unit = units?.find((unit) => (unit.id === det.unitId));
                const amount = calculateAmount(det);
                rows.push({
                    id: det.id,
                    description: det.description,
                    displayId: det.displayId,
                    quantityPerUnit: det.quantityPerUnit,
                    isFromSubcontractor: det.isFromSubcontractor,
                    factor: det.factor,
                    quantity: (det && props.currentForm.quantity && det.quantityPerUnit) ? (parseFloat(props.currentForm.quantity) * (det.quantityPerUnit ?? 1)) : undefined,
                    rate: det.rate,
                    type: det.type,
                    unitId: det.unitId,
                    amount: amount,
                    unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined,
                    resourceId: det.resourceId,
                    activityId: det.activityId,
                    hierarchy: det.hierarchy,
                    subitemId: det.subitemId,
                    itemActivityId: det.itemActivityId,
                    itemResourceId: det.itemResourceId,
                    standardItemId: det.standardItemId,
                    subcontractorId: det.subcontractorId,
                    calculatedQuantityId: det.calculatedQuantityId,
                    totalAmount: (amount ?? 0) * parseFloat(props.currentForm.quantity),
                    order: det.order,
                    masterReferenceId: det.masterReferenceId
                });
            }
            setRowData(rows);
            const filtered = rows.filter(r => r?.hierarchy?.length === 1 && r.type !== "subcontractor" && !r.isFromSubcontractor);
            let amount = 0;
            if (filtered.length > 0) {
                filtered.forEach((itemDetail) => {
                    if (itemDetail.amount) {
                        amount += itemDetail.amount;
                    }
                });
            }
            const subFiltered = rows.filter(r => r.type === "subcontractor" || r.isFromSubcontractor);
            if (subFiltered.length > 0) {
                subFiltered.forEach((itemDetail) => {
                    if (itemDetail.amount) {
                        amount += itemDetail.amount;
                    }
                });
            }
            props.onAmountUpdate(amount);
        } else {
            setRowData([]);
        }
    }, [calculateAmount, calculateQuantityPerUnit, getBaseDefs, props, props.currentForm.quantity, props.item.quantity, storedItemDetails, units])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column ?? 'quantityPerUnit',
            });
        }
    }, [currentEditing])

    const onRowEditingStarted = useCallback((event: RowEditingStartedEvent<ItemDetailView>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const handleSave = useCallback(async (event: CellClickedEvent) => {
        setPageError(undefined);
        if (!currentEditing?.node) {
            if (event.column.isCellEditable(event.node)) {
                setCurrentEditing({ node: event.node, column: event.column.getColId() });
            } else {
                setCurrentEditing({ node: event.node, column: undefined });
            }
        } else {
            await saveItemDetail(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
        }
    }, [currentEditing?.node, saveItemDetail])

    const onCellClicked = useCallback(async (cellClicked: CellClickedEvent) => {
        if (isCancelClicked) {
            setIsCancelClicked(false);
            return;
        };

        if (editDisabledRef.current) {
            setCurrentEditing(undefined);
            return;
        };

        if (currentEditing?.node === cellClicked.node) {
            return;
        }
        if (cellClicked.column.getColId() === 'actions') {
            return;
        }
        if (cellClicked?.event?.target) {
            const classList = (cellClicked?.event?.target as any).classList;
            if (classList.contains('arrow-click')) {
                return;
            }
        }

        if (cellClicked.column.getColId() === 'description' && cellClicked?.event?.target instanceof HTMLElement) {
            const classList = cellClicked?.event?.target.classList;
            if (classList.contains('Ignore-Click')) {
                return;
            }
        }

        if (cellClicked.node.data.hierarchy.length === 1) {
            if (props.item.assignedTo && props.item.assignedTo.length > 0) {
                if (props.item.assignedTo.some((assignedTo) => (assignedTo.id === user?.userId))) {
                    await handleSave(cellClicked);
                } else {
                    return;
                }
            } else {
                await handleSave(cellClicked);
            }
        }
    }, [currentEditing?.node, handleSave, isCancelClicked, props.item.assignedTo, user?.userId])

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelEditing]);

    const isDisabledIfUserAssigned = useCallback(() => {
        if (props.item.assignedTo && props.item.assignedTo.length > 0) {
            return !props.item.assignedTo.some((assignedTo) => (assignedTo.id === user?.userId));
        } else {
            return false;
        }
    }, [props.item.assignedTo, user?.userId])

    const getRowId = useCallback(function (params: GetRowIdParams<ItemDetailView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const autoGroupColumnDef = useMemo<ColDef<ItemDetailView>>(() => {
        return {
            field: 'displayId',
            resizable: true,
            rowDrag: (params) => {
                if (params.node.data?.hierarchy && params.node.data?.hierarchy.length === 1) {
                    return true;
                }
                return false;
            },
            headerName: 'ID',
            headerComponent: ItemDetailInnerHeader,
            headerComponentParams: {
                itemId: props.item.id,
                estimateId: props.estimateId,
                previousItem: props.previousItem
            },
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            width: 200,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            cellRenderer: ItemDetailGroupCellRenderer,
            cellRendererParams: {
                suppressCount: true,
                estimateId: props.estimateId,
                itemId: props.item.id,
                previousItem: props.previousItem
            },
            suppressKeyboardEvent: (params) => {
                if (!params.node.rowPinned) {
                    return saveOnEnter(params);
                }
                return false;
            },
        };
    }, [colors?.gray, props.estimateId, props.item.id, props.previousItem, saveOnEnter]);

    const onDrop = useCallback(async (event: any) => {
        try {
            setPageError(undefined);
            event.preventDefault();
            if (editDisabledRef.current || isDisabledIfUserAssigned()) return;
            const jsonData = event.dataTransfer.getData('application/json');

            if (jsonData) {
                const entity = JSON.parse(jsonData);
                // if data missing or data has no it, do nothing
                if (!entity || entity.id == null) {
                    return;
                }

                switch (entity.type) {
                    case "estimate-item":
                        const item = entity as EstimateItemView;
                        if (entity && entity.id) {
                            if (user && props.estimateId && entity) {
                                gridRef.current!.api.stopEditing();
                                const entitiesNotFound = await copyEstimateItem({
                                    companyId: user?.companyId,
                                    estimateId: props.estimateId,
                                    orgId: user.organizationId,
                                    itemId: props.item.id,
                                    fromEstimateId: item.estimateId,
                                    fromItemId: item.id
                                }).unwrap();
                                console.log("entities", entitiesNotFound);
                                if (entitiesNotFound && entitiesNotFound?.notFound.length > 0) {
                                    setEntitiesNotFound(entitiesNotFound.notFound);
                                    handleOpenEntitiesNotFoundModal();
                                }
                                setCurrentEditing(undefined);
                            }
                        }
                        break;
                    case "activity":
                        const activity = entity as ActivityView;
                        // Duplicate row check
                        if (rowData.some((row) => (row.activityId === entity.id && row.hierarchy?.length === 1))) {
                            return;
                        }
                        if (entity && entity.id) {
                            if (user && props.estimateId && entity) {
                                gridRef.current!.api.stopEditing();
                                await appendItemDetail({
                                    companyId: user?.companyId,
                                    estimateId: props.estimateId,
                                    orgId: user.organizationId,
                                    itemId: props.item.id,
                                    body: {
                                        addOption: 'element',
                                        type: "activity",
                                        id: activity.id
                                    }
                                }).unwrap();
                                setCurrentEditing(undefined);
                            }
                        }
                        break;
                    case "resource":
                        const resource = entity as ResourceView;
                        // Duplicate row check
                        if (rowData.some((row) => (row.resourceId === entity.id && row.hierarchy?.length === 1))) {
                            return;
                        }
                        if (entity && entity.id) {
                            if (user && props.estimateId && entity) {
                                gridRef.current!.api.stopEditing();
                                await appendItemDetail({
                                    companyId: user?.companyId,
                                    estimateId: props.estimateId,
                                    orgId: user.organizationId,
                                    itemId: props.item.id,
                                    body: {
                                        addOption: 'element',
                                        id: resource.id,
                                        type: "resource",
                                    }
                                }).unwrap();
                                setCurrentEditing(undefined);
                            }
                        }
                        break;
                    case "item":
                        const standardItem = entity as StandardItemView;
                        // Duplicate row check
                        if (rowData.some((row) => (row.standardItemId === entity.id && row.hierarchy?.length === 1))) {
                            return;
                        }
                        if (entity && entity.id) {
                            if (user && props.estimateId && entity) {
                                gridRef.current!.api.stopEditing();
                                await appendItemDetail({
                                    companyId: user?.companyId,
                                    estimateId: props.estimateId,
                                    orgId: user.organizationId,
                                    itemId: props.item.id,
                                    body: {
                                        addOption: 'element',
                                        type: "item",
                                        id: standardItem.id
                                    }
                                }).unwrap();
                                setCurrentEditing(undefined);
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        } catch (error: any) {
            if (error.status === 500) {
                setPageError(Errors.generic);
                return;
            }
            if (error && ('data' in error)) {
                if (error.data.page) {
                    setPageError(error.data.page);
                }
            }
        }
    }, [appendItemDetail, copyEstimateItem, isDisabledIfUserAssigned, props.estimateId, props.item.id, rowData, user])

    const gridDragOver = useCallback((event: any) => {
        if (editDisabledRef.current) return;

        const dragSupported = event.dataTransfer.types.length;

        if (dragSupported) {
            event.dataTransfer.dropEffect = 'copy';
            event.preventDefault();
        }
    }, []);

    const onGridReady = useCallback(() => {
        if (gridRef.current!.api) {
            switch (panelState.position.state) {
                case "hidden":
                    gridRef.current!.api.setColumnsVisible(["unit", "quantityPerUnit", "quantity", "factor", "rate", "amount", "actions"], true);
                    break;
                case "half":
                    gridRef.current!.api.setColumnsVisible(["unit", "quantityPerUnit", "quantity", "factor", "rate", "amount", "actions"], false);
                    break;
                default:
                    break;
            }
        }
    }, [panelState.position.state])

    const onRowDragEnd = useCallback(async (event: RowDragEndEvent<ItemDetailView>) => {
        if (editDisabledRef.current || isDisabledIfUserAssigned()) return;

        if (rowData) {
            const movedItemDetail = rowData.find((item) => item.id === event?.node?.data?.id);
            const overItemDetail = rowData.find((item) => item.id === event.overNode?.data?.id);

            if (overItemDetail && movedItemDetail) {
                const movedItemOrder = movedItemDetail.order ?? 0; // Default to 0 if undefined
                const targetOrder = overItemDetail.order ?? 0; // Default to 0 if undefined

                if (movedItemOrder < targetOrder) {
                    // Moving DOWN: Decrease the order of items in between
                    rowData.forEach((item) => {
                        if (item.order && item.order > movedItemOrder && item.order <= targetOrder) {
                            item.order -= 1;
                        }
                    });
                } else if (movedItemOrder > targetOrder) {
                    // Moving UP: Increase the order of items in between
                    rowData.forEach((item) => {
                        if (item.order && item.order >= targetOrder && item.order < movedItemOrder) {
                            item.order += 1;
                        }
                    });
                }

                // Assign the new order to the moved item
                movedItemDetail.order = targetOrder;

                // Sort the rowData to reflect the new order
                rowData.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
            }
            // Prepare the payload for the reorder API
            const details = rowData.filter((row: ItemDetailView) => row.hierarchy?.length === 1);
            const body: ReorderItemDetail[] = details
                .map((detail) => {
                    const order = detail.order ?? 0; // Ensure a default order
                    switch (detail.type) {
                        case 'activity':
                            return {
                                itemActivityId: detail.id,
                                order,
                            } as ReorderItemDetail;
                        case 'resource':
                            return {
                                itemResourceId: detail.id,
                                order,
                            } as ReorderItemDetail;
                        case 'subcontractor':
                            return {
                                itemSubcontractorId: detail.subcontractorId,
                                order,
                            } as ReorderItemDetail;
                        case 'item':
                            return {
                                subItemId: detail.id,
                                order,
                            } as ReorderItemDetail;
                        default:
                            return null; // Return null for unmatched cases
                    }
                })
                .filter((item): item is ReorderItemDetail => item !== null); // Remove null values and validate type

            await reorderItemDetails({
                companyId: user?.companyId,
                orgId: user?.organizationId,
                estimateId: props.estimateId,
                itemId: props.item.id,
                body
            });
        }
    }, [isDisabledIfUserAssigned, props.estimateId, props.item.id, reorderItemDetails, rowData, user?.companyId, user?.organizationId]);

    return <>
        <Box display="flex" width="100%" height="100%">
            {<Box flexDirection="column" width="100%" sx={{ display: (panelState.position.state === "hidden" || panelState.position.state === "half") ? "flex" : "none" }} height="100%" flex="1">
                <Box padding="20px 24px" display="flex" alignItems="center" width="500px">
                    <Append disabled={!!editDisabledRef.current || isDisabledIfUserAssigned()} item={props.item} previousItem={props.previousItem} estimateId={props.estimateId} parent="direct" />
                </Box>
                {pageError && <Box padding="0px 20px 10px 0px"><Alert severity="error">{pageError}</Alert></Box>}
                <Box display="flex" width="100%" height="100%">
                    <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}
                        onDragOver={gridDragOver}
                        onDrop={(e: any) => onDrop(e)}>
                        <AgGridReact<ItemDetailView>
                            ref={gridRef}
                            rowData={rowData}
                            editType={'fullRow'}
                            defaultColDef={defaultColDef}
                            suppressClickEdit={true}
                            autoGroupColumnDef={autoGroupColumnDef}
                            onRowEditingStarted={onRowEditingStarted}
                            onRowEditingStopped={onRowEditingStopped}
                            onGridReady={onGridReady}
                            onCellClicked={onCellClicked}
                            onCellKeyDown={onCellKeyDown}
                            getRowId={getRowId}
                            treeData={true}
                            onRowDragEnd={onRowDragEnd}
                            getDataPath={(data: any) => (data.hierarchy)}
                        />
                    </Box>
                    {(panelState.position.state === "half") && <Box height="100%">
                        <Tooltip title="Expand" placement="left">
                            <Button onClick={() => {
                                showFullSidepanel("full");
                            }} color="primary" sx={{ minWidth: "20px", padding: "0px", height: "inherit", maxWidth: "10px", background: "" }}>{<ArrowLeftIcon />}</Button>
                        </Tooltip>
                    </Box>}
                    {(panelState.position.state === "hidden" || panelState.position.state === "half") && <Box height="100%">
                        <Tooltip title={(panelState.position.state === "hidden") ? 'Open library' : 'Close library'} placement="left">
                            <Button onClick={() => {
                                showFullSidepanel((panelState.position.state === "hidden") ? "half" : "hidden");
                            }} color="primary" sx={{ minWidth: "20px", padding: "0px", height: "inherit", maxWidth: "10px", background: "" }}>{(panelState.position.state === "hidden") ? <ArrowLeftIcon /> : <ArrowRightIcon />}</Button>
                        </Tooltip>
                    </Box>
                    }
                </Box>
            </Box>}
            {(panelState.position.state === "full") && <Box height="100%">
                <Tooltip title="Collapse" placement="left">
                    <Button onClick={() => {
                        showFullSidepanel("half");
                    }} color="primary" sx={{ minWidth: "20px", padding: "0px", height: "inherit", maxWidth: "10px", background: "" }}>{<ArrowRightIcon />}</Button>
                </Tooltip>
            </Box>}
            {(panelState.position.state === "half" || panelState.position.state === "full") && <Box flex="1">
                <Box padding="20px 24px 0px 0px" height={value === "Resources" ? "calc(100% - 68px)" : "calc(100% - 48px)"}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange}>
                                <Tab sx={{ textTransform: "none", padding: "15px", minHeight: "50px", maxHeight: "50px" }} icon={<Avatar sx={{ bgcolor: colors.blueAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">R</Avatar>} iconPosition="start" label="Resources" value="Resources" />
                                <Tab sx={{ textTransform: "none", padding: "15px", minHeight: "50px", maxHeight: "50px" }} icon={<Avatar sx={{ bgcolor: colors.primary[300], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">A</Avatar>} iconPosition="start" label="Activities" value="Activities" />
                                <Tab sx={{ textTransform: "none", padding: "15px", minHeight: "50px", maxHeight: "50px" }} icon={<Avatar sx={{ bgcolor: colors.purpleAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">I</Avatar>} iconPosition="start" label="Items" value="Items" />
                                <Tab sx={{ textTransform: "none", padding: "15px", minHeight: "50px", maxHeight: "50px" }} icon={<Avatar sx={{ bgcolor: "#ff9500", width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">E</Avatar>} iconPosition="start" label="Estimates" value="Estimates" />
                            </TabList>
                        </Box>
                        <TabPanel value="Resources" sx={{ height: "100%", padding: "0px" }}>
                            <ResourceList estimateId={props.estimateId} onDrop={onDrop} resourceId={selectedResourceId} panelState={panelState} />
                        </TabPanel>
                        <TabPanel value="Activities" sx={{ height: "100%", padding: "0px" }}>
                            <ActivityList estimateId={props.estimateId} onDrop={onDrop} activityId={selectedActivityId} panelState={panelState} />
                        </TabPanel>
                        <TabPanel value="Items" sx={{ height: "100%", padding: "0px" }}>
                            <StandardItemList estimateId={props.estimateId} onDrop={onDrop} standardItemId={selectedStandardItemId} panelState={panelState}></StandardItemList>
                        </TabPanel>
                        <TabPanel value="Estimates" sx={{ height: "100%", padding: "0px" }}>
                            <ItemList estimateId={props.estimateId} onDrop={onDrop} panelState={panelState}></ItemList>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>
            }
        </Box>
        <Modal
            open={openCalculateModal}
            onClose={handleCloseCalculateModal}
            aria-labelledby="edit-items-title"
            aria-describedby="edit-items-description"
        >
            <Box className="calculator-modal">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    marginBottom="10px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" color="primary">Calculate Quantity</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="edit" color="primary" onClick={handleCloseCalculateModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <QuantityCalculator directItem={props.item} type="item" estimateId={props.estimateId} directIndirectItemId={props.item.id} selectedDetail={selectedDetail} close={handleCloseCalculateModal} />
            </Box>
        </Modal>
        <Modal
            open={openEntitiesNotFound}
            onClose={handleCloseEntitiesNotFoundModal}
            aria-labelledby="not-found-entities"
            aria-describedby="not-found-entities"
        >
            <Box className="medium-modal">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    marginBottom="10px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" color="primary">Not Found</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="edit" color="primary" onClick={handleCloseEntitiesNotFoundModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <ItemDetailsNotFound close={handleCloseCalculateModal} entities={entitiesNotFound} />
            </Box>
        </Modal>
    </>
}