import { Box, Breadcrumbs, Button, Card, IconButton, Link, Modal, TextField, Typography, useTheme } from "@mui/material";
import { MarkupItem } from "Models/item";
import { useClearPreliminaryRateMutation, useGetEstimateQuery, useGetPagedItemsForEstimateMutation, useLockMutation, useUpdateMarkupForAllItemsMutation, useUpdateMarkupItemMutation, useUpdateMarkupMutation } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef, IRowNode } from "ag-grid-enterprise";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import { AgGridReact } from "ag-grid-react";
import MarkupMarginHeader from "./MarkupMarginHeader";
import MarkupItemLocked from "./MarkupItemLocked";
import { FilterSettings, Filters } from "Pages/Core/Pricing/Filters";
import MarkupEstimatedRateHeader from "./MarkupEstimatedRateHeader";
import CloseIcon from '@mui/icons-material/Close';
import { AdvancedFilterModel, CellClickedEvent, RowEditingStartedEvent, RowEditingStoppedEvent, CellKeyDownEvent, FilterChangedEvent, GetRowIdParams, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams, PaginationChangedEvent, SideBarDef, SortChangedEvent, SuppressKeyboardEventParams } from "ag-grid-community";
import MarkupSaveCancelCellRenderer from "./MarkupSaveCancelCellRenderer";
import { useConfirm } from "material-ui-confirm";
import MarkupLockedCellRenderer from "./MarkupLockedCellRenderer";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { CalculationOption } from "Models/price";
import { useGetSettingsQuery } from "State/Services/settings";
import { rounder } from "Helpers/rounder";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { useGetPricingQuery } from "State/Services/pricing";
import GenericUnitCellRenderer from "Components/Shared/GenericUnitCellRenderer";

export interface MarkupProps {
    priceLabel: string;
    directCostLabel: string;
    currentPriceLabel: string;
    estimateId: string;
    onBackToPricing: () => void;
    targetPrice?: number | null;
    indirectMarginSum?: number;
    selectedOption: CalculationOption;
}

const marginModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "15%",
    height: "20%",
    bgcolor: 'background.paper',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    borderRadius: "5px",
    display: 'flex',
    flexDirection: "column"
};

export default function Markup(props: MarkupProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: price } = useGetPricingQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridRef = useRef<AgGridReact<MarkupItem>>(null);
    const [updateMarkup] = useUpdateMarkupMutation();
    const [updateLock] = useLockMutation();
    const [markupValue, setMarkupValue] = useState<number | undefined>();
    const [openMarkupModal, setOpenMarkupModal] = useState(false);
    const [clearPreRate] = useClearPreliminaryRateMutation();
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<MarkupItem> | undefined, column?: string }>();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [updateMarkupItem] = useUpdateMarkupItemMutation();
    const [netMarkupAmount, setNetMarkupAmount] = useState<number | undefined>();
    const [netDirectCost, setNetDirectCost] = useState<number | undefined | string>();
    const [netMarkup, setNetMarkup] = useState<number | undefined | string>();
    const [currentPrice, setCurrentPrice] = useState<number | undefined>();
    const confirm = useConfirm();
    const [updateMarkupForAllItems] = useUpdateMarkupForAllItemsMutation();
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const editDisabledRef = useRef<boolean>();
    const [isReady, setIsReady] = useState(false);
    const [getPagedItems] = useGetPagedItemsForEstimateMutation();
    const [currentItems, setCurrentItems] = useState<Array<MarkupItem>>([]);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Pricing,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Pricing,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
            }
        } else {
            editDisabledRef.current = true;
            setHasViewAccess(true);
        }
        setIsReady(true);
    }, [estimate, estimate?.EstimateUserRole, user])

    const handleOpenMarkupModal = () => {
        setOpenMarkupModal(true);
    };

    const handleCloseMarkupModal = async (save: boolean) => {
        setOpenMarkupModal(false);
        if (save && markupValue) {
            await markupSet(markupValue);
            setMarkupValue(undefined);
        }
    };

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node?.rowIndex,
                colKey: currentEditing.column ?? 'preliminaryRate',
            });
        }
    }, [currentEditing])

    const filterChanged = useCallback(async (filterSettings: FilterSettings) => {
        let filters: AdvancedFilterModel = {} as any;
        if (filterSettings.category) {
            const catFilter = {
                category: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.description) {
            const descriptionFilter = {
                description: {
                    filterType: 'text',
                    type: 'contains',
                    filter: filterSettings.description
                }
            };
            filters = { ...filters, ...descriptionFilter };
        }
        if (filterSettings.idFrom) {
            const idFromFilter = {
                id: {
                    filterType: 'number',
                    type: '>=',
                    filter: filterSettings.idFrom
                }
            };
            filters = { ...filters, ...idFromFilter };
        }
        if (filterSettings.idTo) {
            const idToFilter = {
                displayId: {
                    filterType: 'number',
                    type: '<=',
                    filter: filterSettings.idTo
                }
            };
            filters = { ...filters, ...idToFilter };
        }
        gridRef.current!.api.setFilterModel(filters);
        gridRef.current!.api.onFilterChanged();
    }, [])

    const getItems = useCallback(async (params: IServerSideGetRowsParams<MarkupItem>) => {
        if (user) {
            const response = await getPagedItems({
                companyId: user.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId,
                body: {
                    ...params.request
                }
            }).unwrap();


            if (params?.request) {
                const rows = new Array<MarkupItem>();
                setCurrentItems(response.results);
                response.results.forEach((item) => {
                    rows.push({
                        category1: item.category1,
                        category2: item.category2,
                        category3: item.category3,
                        category4: item.category4,
                        category1Id: item.category1Id,
                        category2Id: item.category2Id,
                        category3Id: item.category3Id,
                        category4Id: item.category4Id,
                        category1DisplayId: item.category1DisplayId,
                        category2DisplayId: item.category2DisplayId,
                        category3DisplayId: item.category3DisplayId,
                        category4DisplayId: item.category4DisplayId,
                        unitCost: item.unitCost,
                        itemBoqId: item.itemBoqId,
                        page: item.page,
                        amount: item.amount,
                        description: item.description,
                        displayId: item.displayId,
                        quantity: item.quantity,
                        estimatedQuantity: item.estimatedQuantity,
                        rate: item.rate,
                        id: item.id,
                        locked: item.locked,
                        markup: item.markup,
                        preliminaryRate: item.preliminaryRate,
                        category: '',
                        unit: {
                            unitDescription: item.customUnit,
                            unitId: item.unitId
                        },
                    });
                });

                params.success({
                    rowData: rows,
                    rowCount: response.totalCount
                });
            }
        }
    }, [getPagedItems, props.estimateId, user]);
    const getItemsRef = useRef<(params: IServerSideGetRowsParams<MarkupItem>) => void>(() => (null));
    getItemsRef.current = getItems;

    const createServerSideDatasource = useCallback((): IServerSideDatasource => {
        return {
            getRows: (params) => getItemsRef.current(params),
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        const datasource = createServerSideDatasource();
        params.api!.setGridOption("serverSideDatasource", datasource);
    }, [createServerSideDatasource]);

    useEffect(() => {
        if (price) {
            if (props.selectedOption === CalculationOption.overrideQuantity || props.selectedOption === CalculationOption.quantity) {
                const netMarkupAmount = (props.indirectMarginSum ?? 0) - ((price.markupDistributedPrelim ?? 0) + (price.markupLockedSum ?? 0));
                if (price.netDirectCost === 0) {
                    setNetMarkup("");
                } else {
                    setNetMarkup((netMarkupAmount / (price.netDirectCost ?? 1)) * 100);
                }
                setNetMarkupAmount(netMarkupAmount);
                setNetDirectCost(price.netDirectCost ?? 0);
                setCurrentPrice(price.currentPrice);
            } else {
                const netMarkupAmount = (props.indirectMarginSum ?? 0) - ((price.markupEstDistributedPrelim ?? 0) + (price.markupEstLockedSum ?? 0));
                if (price.netEstDirectCost === 0) {
                    setNetMarkup("");
                } else {
                    setNetMarkup((netMarkupAmount / (price.netEstDirectCost ?? 1)) * 100);
                }
                setNetMarkupAmount(netMarkupAmount);
                setNetDirectCost(price.netEstDirectCost ?? 0);
                setCurrentPrice(price.currentEstimatedPrice);
            }
        }
    }, [props.indirectMarginSum, price, props.selectedOption])

    const markupSet = useCallback(async (value: number) => {
        const filters = gridRef.current!.api.getFilterModel();
        if (user && value) {
            await updateMarkup({
                companyId: user.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId,
                body: {
                    filters: filters,
                    markup: value
                }
            });
            gridRef.current!.api.refreshServerSide();
        }
    }, [props.estimateId, updateMarkup, user])

    const lockUnlockAll = useCallback(async (lock: boolean) => {
        if (user) {
            const filters = gridRef.current!.api.getFilterModel();
            await updateLock({
                companyId: user?.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId,
                body: {
                    filter: filters,
                    lock: lock
                }
            });
            gridRef.current!.api.refreshServerSide();
        }
    }, [props.estimateId, updateLock, user])

    const lockUnlockItem = useCallback(async (lock: boolean, item: MarkupItem) => {
        if (user && item?.id) {
            await updateLock({
                companyId: user?.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId,
                body: {
                    idsToUpdate: [item.id],
                    lock: lock
                }
            });
            gridRef.current!.api.refreshServerSide();
        }
    }, [props.estimateId, updateLock, user])

    const clear = useCallback(async () => {
        try {
            await confirm({ confirmationText: "Yes", description: "This will clear the preliminary rate for all items. Do you want to continue?" });
            const filters = gridRef.current!.api.getFilterModel();
            if (user) {
                await clearPreRate({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    body: {
                        filter: filters
                    }
                });
                gridRef.current!.api.refreshServerSide();
            }
        } catch (error) {

        }
    }, [clearPreRate, confirm, props.estimateId, user])

    const saveRow = useCallback(async (nodeToSave: IRowNode<MarkupItem>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<MarkupItem>, column?: string }) => {
        try {
            gridRef.current!.api.stopEditing();
            if (nodeToSave.data && user) {
                await updateMarkupItem({
                    companyId: user.companyId,
                    itemId: nodeToSave.data.id,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    body: {
                        markup: nodeToSave.data.markup,
                        preliminaryRate: nodeToSave.data.preliminaryRate,
                        locked: nodeToSave.data.locked ?? false
                    }
                });
            }
            const unitPrice = (nodeToSave.data?.unitCost ?? 0) + (nodeToSave.data?.preliminaryRate ?? 0) + (nodeToSave.data?.unitCost ?? 0) * ((nodeToSave.data?.markup ?? 0) / 100);
            nodeToSave.updateData({
                ...nodeToSave.data,
                rate: (nodeToSave.data?.unitCost ?? 0) + (nodeToSave.data?.preliminaryRate ?? 0) + (nodeToSave.data?.unitCost ?? 0) * ((nodeToSave.data?.markup ?? 0) / 100),
                amount: unitPrice * (nodeToSave.data?.quantity ?? 0)
            });
            if (toEditAfterSave) {
                setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
            } else {
                setCurrentEditing(undefined);
            }
        } catch (error) {
            console.log(error);
        }
    }, [props.estimateId, updateMarkupItem, user])

    const cancelEditing = useCallback((node: IRowNode<MarkupItem>) => {
        gridRef.current!.api.stopEditing(true);
        setIsCancelClicked(true);
        const item = currentItems?.find((v) => (v.id === node.data?.id));
        if (item) {
            gridRef.current!.api.applyTransaction({ update: [{ ...item }] });
        }
        setCurrentEditing(undefined);
    }, [currentItems])

    const clearMarkup = useCallback(async () => {
        try {
            await confirm({ confirmationText: "Yes", description: "This will clear the markup for all items. Do you want to continue?" });
            const filters = gridRef.current!.api.getFilterModel();
            if (user) {
                await updateMarkup({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    body: {
                        filters: filters,
                        markup: null
                    }
                });
                gridRef.current!.api.refreshServerSide();
            }
        } catch (error) {
            // User cancelled. Do nothing
        }
    }, [confirm, props.estimateId, updateMarkup, user])

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<MarkupItem>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();
            saveRow(params.node);
            return true;
        }
        return false;
    }, [saveRow])

    const columnToolPanelParams = useMemo<SideBarDef>(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        };
    }, []);

    const getBaseDefs = useCallback((): Array<ColDef<MarkupItem>> => {
        return [
            {
                field: 'id',
                hide: true,
                filter: 'agTextColumnFilter',
                suppressColumnsToolPanel: true
            },
            {
                field: 'category',
                hide: true,
                filter: 'agTextColumnFilter',
            },
            {
                field: 'displayId',
                headerName: 'ID',
                cellDataType: "number",
                filter: 'agTextColumnFilter',
                sort: 'asc',
                hide: false,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 80,
                autoHeight: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
                showRowGroup: true,
            },
            {
                field: 'itemBoqId',
                headerName: 'Item',
                width: 80,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'description',
                headerName: 'Description',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                flex: 1,
                filter: 'agTextColumnFilter',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                cellRenderer: GenericUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'estimatedQuantity',
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unitCost',
                headerName: 'Unit Cost',
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'preliminaryRate',
                headerComponent: MarkupEstimatedRateHeader,
                headerComponentParams: {
                    clear: clear,
                    estimateId: props.estimateId
                },
                suppressKeyboardEvent: saveOnEnter,
                editable: true,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                width: 115,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'markup',
                width: 100,
                editable: true,
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                headerComponent: MarkupMarginHeader,
                headerComponentParams: {
                    markupSet: handleOpenMarkupModal,
                    markupClear: clearMarkup,
                    estimateId: props.estimateId
                },
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'locked',
                width: 100,
                editable: true,
                cellRenderer: MarkupLockedCellRenderer,
                cellRendererParams: {
                    lockUnlockItem: lockUnlockItem,
                    estimateId: props.estimateId
                },
                headerComponent: MarkupItemLocked,
                headerComponentParams: {
                    lockUnlock: lockUnlockAll,
                    estimateId: props.estimateId
                },
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "boolean",
                cellStyle: { alignItems: "center", textAlign: "center", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                headerName: 'Unit Price',
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'amount',
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'actions',
                width: 80,
                headerName: 'Actions',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRendererSelector: (params) => {
                    return {
                        component: MarkupSaveCancelCellRenderer,
                        params: {
                            save: saveRow,
                            cancel: (node: IRowNode<MarkupItem>) => cancelEditing(node),
                        },
                    };
                },
            },
        ];
    }, [cancelEditing, clear, clearMarkup, colors?.gray, estimate?.CompanyCurrency?.Currency?.minorUnit, lockUnlockAll, lockUnlockItem, props.estimateId, saveOnEnter, saveRow, settings?.quantityDecimals])

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const onRowEditingStarted = useCallback((event: RowEditingStartedEvent<MarkupItem>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent) => {
        try {
            if (editDisabledRef.current) return;

            if (currentEditing?.node === event.node) {
                return;
            }

            if (!currentEditing?.node) {
                if (isCancelClicked) {
                    setIsCancelClicked(false);
                    return;
                };

                if (event.column.getColId() === 'actions' || event.column.getColId() === 'locked') {
                    return;
                }

                if (event.column.isCellEditable(event.node)) {
                    setCurrentEditing({ node: event.node, column: event.column.getColId() });
                } else {
                    setCurrentEditing({ node: event.node, column: undefined });
                }
            } else {
                await saveRow(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) { }
    }, [currentEditing?.node, isCancelClicked, saveRow])

    const getRowId = useCallback(function (params: GetRowIdParams<MarkupItem>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const onPageChanged = useCallback((event: PaginationChangedEvent<MarkupItem>) => {
        if (currentEditing && currentEditing.node?.data && event.newPage) {
            cancelEditing(currentEditing.node);
        }
    }, [cancelEditing, currentEditing])

    const onPageFilterSortChanged = useCallback((event: SortChangedEvent<MarkupItem> | FilterChangedEvent<MarkupItem>) => {
        if (currentEditing && currentEditing.node?.data) {
            cancelEditing(currentEditing.node);
        }
    }, [cancelEditing, currentEditing])

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelEditing]);

    const breadcrumbs = [
        <Link
            component="button"
            onClick={props.onBackToPricing}
            underline="hover"
            key="1"
            color="inherit"
        >
            Pricing
        </Link>,
        <Typography key="2" color="text.primary">
            Markup
        </Typography>,
    ];

    const apply = useCallback(async () => {
        try {
            await confirm({ description: `Any unlocked items with markup values will be locked, then the Net Markup % will be applied to all unlocked items.  Do you want to continue?`, title: 'Confirmation' });
            if (netMarkup && user) {
                await updateMarkupForAllItems({
                    markup: parseFloat(netMarkup.toString()),
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId
                }).unwrap();
            }
            gridRef.current!.api.refreshServerSide();
        } catch (error) {
            // Cancelled. Do nothing
        }

    }, [confirm, netMarkup, props.estimateId, updateMarkupForAllItems, user])

    if (!isReady) {
        return <></>
    }

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <>
        {colors && <>
            <Box margin="15px 0px 5px 20px">
                <Breadcrumbs
                    sx={{
                        " & .MuiBreadcrumbs-li": {
                            color: `${colors.gray[500]}`
                        },
                        " & .MuiBreadcrumbs-separator": {
                            color: `${colors.gray[700]}`
                        }
                    }}
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>
            <Box display="flex" alignItems="flex-end">
                <Box flex="1">
                    <Filters estimateId={props.estimateId} onFilterChanged={filterChanged} relativeWidthPercent="80%" />
                </Box>
                <Card sx={{ marginRight: "20px", padding: "15px" }}>
                    <Box display="flex" justifyContent="flex-end">
                        <Box display="flex" marginRight="20px">
                            <Box display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center">
                                    <Box marginRight="15px">
                                        <Box fontWeight="bold">Net Markup Amount</Box>
                                        <Box margin="0px 14px 8.5px 0px">&nbsp;{(netMarkupAmount) ? rounder(netMarkupAmount, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2) : null}</Box>
                                    </Box>
                                    <Card sx={{ display: "flex", margin: "0px 15px 0px 0px", alignItems: "center", padding: "5px" }}>
                                        <Box>
                                            <Box fontWeight="bold">Net Markup %</Box>
                                            <Box margin="0px 14px 8.5px 0px">&nbsp;{(netMarkup) ? rounder(netMarkup, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2) : null}</Box>
                                        </Box>
                                        <Box margin="0px 5px 0px 15px">
                                            <Button size="small" disabled={!netMarkup || editDisabledRef.current} onClick={apply} variant="contained">Apply</Button>
                                        </Box>
                                    </Card>
                                </Box>
                                <Box>
                                    <Box fontWeight="bold">{props.directCostLabel}</Box>
                                    <Box margin="0px 14px 8.5px 0px">&nbsp;{(netDirectCost) ? rounder(netDirectCost, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2) : null}</Box>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Box>
                                    <Box fontWeight="bold">{props.priceLabel}</Box>
                                    <Box margin="0px 14px 8.5px 0px">&nbsp;{(props.targetPrice) ? rounder(props.targetPrice, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2) : null}</Box>
                                </Box>
                                <Box>
                                    <Box fontWeight="bold">{props.currentPriceLabel}</Box>
                                    <Box margin="0px 14px 8.5px 0px">&nbsp;{(currentPrice) ? rounder(currentPrice, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2) : null}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Box>


            <Box margin="20px" height="calc(100% - 320px)">
                <Box style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
                    <AgGridReact<MarkupItem>
                        ref={gridRef}
                        animateRows={false}
                        editType={'fullRow'}
                        suppressScrollOnNewData={true}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        rowModelType={"serverSide"}
                        getRowId={getRowId}
                        pagination={true}
                        suppressClickEdit={true}
                        sideBar={columnToolPanelParams}
                        paginationPageSize={15}
                        defaultColDef={defaultColDef}
                        cacheBlockSize={15}
                        paginationPageSizeSelector={[15, 25, 50, 100]}
                        onRowEditingStarted={onRowEditingStarted}
                        onRowEditingStopped={onRowEditingStopped}
                        onCellKeyDown={onCellKeyDown}
                        onCellClicked={onCellClicked}
                        onPaginationChanged={onPageChanged}
                        onSortChanged={onPageFilterSortChanged}
                        onFilterChanged={onPageFilterSortChanged}
                    />
                </Box>
            </Box>
            <Modal
                open={openMarkupModal}
                onClose={handleOpenMarkupModal}
                aria-labelledby="import-modal"
                aria-describedby="import-modal-description"
            >
                <Box sx={{ ...marginModalStyle }}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignContent="center"
                        alignItems="center"
                        marginBottom="10px"
                        borderBottom={`1px solid ${colors?.gray[800]}`}>
                        <Box padding="10px">
                            {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Set markup</Typography>}
                        </Box>
                        <Box>
                            <IconButton aria-label="close" onClick={() => handleCloseMarkupModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box height="100%" display="flex" alignItems="center" alignContent="center" gap="10px" justifyContent="center" flexDirection="column">
                        <Box>
                            <TextField size="small" onChange={(event) => setMarkupValue(parseFloat(event.target.value))} InputProps={{ type: 'number' }}></TextField>
                        </Box>
                        <Box display="flex" gap="10px">
                            <Button variant="contained" onClick={() => handleCloseMarkupModal(true)} disabled={!markupValue}>Save</Button>
                            <Button variant="contained" onClick={() => handleCloseMarkupModal(false)}>Cancel</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>}
    </>;
}