import Intercom from '@intercom/messenger-js-sdk';
import { UserDetails } from 'Models/user-details';
import { useGetUserDetailsQuery } from 'State/Services/user';

export interface ChatProps {
    userDetails?: UserDetails;
}
export default function Chat(props: ChatProps) {
    const { data: user } = useGetUserDetailsQuery();

    Intercom({
        app_id: 'me4hf9wn',
        user_id: user?.userId,
        user_hash: user?.userIdIntercomHash,
        name: user?.name,
        email: user?.email,
        created_at: user?.signupDate ? Math.floor(new Date(user?.signupDate).getTime() / 1000) : undefined
    });

    return <div>Bidbow</div>;
}