import { BaseResource, CompositeResourceView, ResourceView } from "Models/resource";
import { Alert, Autocomplete, Box, Button, IconButton, Modal, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { UnitView } from "Models/unit";
import { useCreateResourceMutation, useDeleteCalculatedResourceMutation, useGetCalculatedResourceByResourceIdQuery, useGetResourceMaxIdQuery, useUpdateResourceMutation } from "State/Services/resource";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useState, useMemo, useEffect, useCallback, useRef, ChangeEvent } from "react";
import { tokens } from "theme";
import * as yup from 'yup';
import { useFormik } from "formik";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import AddEditCompositeResource from "./AddEditCompositeResource";
import { useLazyGetResourceCategoriesQuery } from "State/Services/resource-category";
import { ResourceCategory, ResourceSubCategoryOption } from "Models/resource-category";
import SplitButton from "Components/SplitButton";
import { SaveOptions } from "Models/button-options";
import CalculatedResourceModal from "./CalculatedResourceModal";
import CloseIcon from '@mui/icons-material/Close';
import { useConfirm } from "material-ui-confirm";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { Errors } from "Models/errors";
import { PanelState } from "Models/panel";
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    height: "33%",
    bgcolor: 'background.paper',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    borderRadius: "5px",
    display: 'flex',
    flexDirection: "column"
};

export interface AddResourceProps {
    estimateId: string;
    resource: ResourceView | undefined;
    backToList: () => void;
    resetAndAddNew: (subCategoryId: string | undefined) => void;
    panelState: PanelState;
}

const resourceValidationSchema = yup.object<ResourceView>({
    id: yup
        .string()
        .optional(),
    description: yup
        .string()
        .required(),
    remarks: yup
        .string()
        .optional(),
    displayId: yup
        .string()
        .required(),
    basicRate: yup
        .number()
        .required(),
    resourceFactor: yup
        .number()
        .nullable()
        .optional()
        .test(
            'is-greater-than-zero',
            'Resource factor must be greater than zero',
            value => value === null || value === undefined || value > 0
        )
});

export default function AddEditResource(props: AddResourceProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: maxId } = useGetResourceMaxIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [saveResource] = useCreateResourceMutation();
    const [updateResource] = useUpdateResourceMutation();
    const [unitViews, setUnitViews] = useState<Array<UnitView>>([]);
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [colors, setColors] = useState<any>();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [rate, setRate] = useState<string>();
    const [resource, setResource] = useState<ResourceView | undefined>(props.resource);
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [getResourceCategories] = useLazyGetResourceCategoriesQuery();
    const [currentCompositeResources, setCurrentCompositeResources] = useState<Array<CompositeResourceView>>();
    const [storedResourceCategories, setStoredResourceCategories] = useState<Array<ResourceCategory>>();
    const [resourceSubCategoryOptions, setResourceSubCategoryOptions] = useState<Array<ResourceSubCategoryOption>>([]);
    const [afterSaveOption, setAfterSaveOption] = useState<SaveOptions | undefined>();
    const categoryRef = useRef<HTMLInputElement>();
    const unitRef = useRef<HTMLInputElement>();
    const [modalOpen, setModalOpen] = useState(false);
    const { data: storedCalculatedResource } = useGetCalculatedResourceByResourceIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', resourceId: (props.resource?.id) ? props.resource.id : '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !props.resource?.id });
    const [deleteCalculatedResource] = useDeleteCalculatedResourceMutation();
    const [newCalculatedResult, setNewCalculatedResult] = useState<{ total?: number, calculatedResourceId: string }>();
    const confirm = useConfirm();
    const [locked] = useState(!!props.resource?.masterReferenceId);
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();
    const [pageError, setPageError] = useState<string | undefined>();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() => {
        const unitViews = units?.map((unit) => ({
            unitDescription: unit.description,
            unitId: unit.id
        }));
        if (unitViews) {
            setUnitViews(unitViews);
        }
    }, [units])

    useEffect(() => {
        if (user?.companyId && user.organizationId) {
            getResourceCategories({
                companyId: user.companyId,
                estimateId: props.estimateId,
                organizationId: user.organizationId
            }, true).then((resourceCategoriesResponse) => {
                if (resourceCategoriesResponse.data) {
                    setStoredResourceCategories(resourceCategoriesResponse.data);
                } else {
                    setStoredResourceCategories([]);
                }
            });
        }
    }, [getResourceCategories, props.estimateId, user?.companyId, user?.organizationId])

    useEffect(() => {
        const options = new Array<ResourceSubCategoryOption>();
        if (storedResourceCategories && storedResourceCategories.length > 0) {
            storedResourceCategories.forEach((resourceCategory) => {
                resourceCategory.resourceSubCategories?.forEach((subCategory) => {
                    options.push({
                        ...subCategory,
                        resourceCategoryName: resourceCategory.description ?? ''
                    });
                });
            });
        }
        setResourceSubCategoryOptions(options);
    }, [storedResourceCategories]);

    const getDisplayId = useCallback(() => {
        const id = (maxId) ? (maxId + 1)?.toString() : '1';
        return estimate?.isMaster ? `${settings?.masterIdPrefix}-${id}` : id;
    }, [estimate?.isMaster, maxId, settings?.masterIdPrefix])

    const { values, isValid, setFieldValue, setErrors, handleSubmit, handleChange, handleBlur, touched, errors } = useFormik<ResourceView>({
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
        initialValues: (resource) ? {
            id: resource.id ?? '',
            description: resource.description ?? '',
            remarks: resource.remarks ?? '',
            unitId: resource.unitId ?? '',
            displayId: resource.displayId ?? getDisplayId(),
            basicRate: resource.basicRate,
            isSupplierRate: resource.isSupplierRate,
            supplierName: resource.supplierName,
            unit: unitViews.find((unit) => (unit.unitId === resource.unitId)),
            isComposite: resource.isComposite,
            resourceFactor: resource.resourceFactor,
            subCategoryId: resource.subCategoryId,
            masterReferenceId: resource.masterReferenceId,
            subCategory: (resource.subCategoryId && resourceSubCategoryOptions.length > 0 && resource) ? resourceSubCategoryOptions.find((option) => (option.id === resource?.subCategoryId)) : undefined
        } : {
            id: '',
            description: '',
            remarks: '',
            unitId: undefined,
            basicRate: 0,
            isSupplierRate: false,
            supplierName: undefined,
            displayId: getDisplayId(),
            unit: undefined,
            isComposite: undefined,
            resourceFactor: undefined,
            subCategoryId: undefined
        },
        validationSchema: resourceValidationSchema,
        onSubmit: async (resource) => {
            await save(resource, afterSaveOption ?? SaveOptions.Save);
        },
    });

    const save = useCallback(async (resourceToSave: ResourceView, option: SaveOptions) => {
        setPageError(undefined);
        if (resourceToSave) {
            try {
                let response: BaseResource;
                if (resourceToSave.id) {
                    response = await updateResource({
                        resourceId: resourceToSave?.id,
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        body: {
                            subCategoryId: resourceToSave.subCategoryId,
                            id: resourceToSave.id,
                            basicRate: parseFloat(resourceToSave.basicRate?.toString() ?? '0'),
                            description: resourceToSave.description,
                            displayId: resourceToSave.displayId,
                            unitId: resourceToSave.unit?.unitId,
                            isComposite: resourceToSave.isComposite,
                            isSupplierRate: props.resource?.isSupplierRate ?? false,
                            resourceFactor: parseFloat(resourceToSave.resourceFactor?.toString() ?? '') ?? undefined,
                            remarks: resourceToSave.remarks,
                        }
                    }).unwrap();
                } else {
                    response = await saveResource({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        body: {
                            subCategoryId: resourceToSave.subCategoryId,
                            basicRate: parseFloat(resourceToSave.basicRate?.toString() ?? '0'),
                            description: resourceToSave.description,
                            displayId: resourceToSave.displayId,
                            unitId: resourceToSave.unit?.unitId,
                            isSupplierRate: false,
                            resourceFactor: parseFloat(resourceToSave.resourceFactor?.toString() ?? '') ?? undefined,
                            remarks: resourceToSave.remarks,
                        }
                    }).unwrap();
                }
                if (response) {
                    switch (option) {
                        case SaveOptions.SaveAndClose:
                            props.backToList();
                            break;
                        case SaveOptions.SaveAndAddAnother:
                            setAfterSaveOption(undefined);
                            props.resetAndAddNew(resourceToSave.subCategoryId);
                            break;
                        default:
                        case SaveOptions.Save:
                            setResource({ ...response });
                            setFieldValue('id', response.id);
                            break;
                    }
                }
            } catch (error: any) {
                if (error.status === 500) {
                    setPageError(Errors.generic);
                    return;
                }
                if (error && ('data' in error)) {
                    if (error.data?.description) {
                        setErrors({
                            description: error.data?.description
                        });
                    } else if (error.data.displayId) {
                        setErrors({
                            displayId: error.data?.displayId
                        });
                    } else if (error.data.page) {
                        setPageError(error.data.page);
                    }
                }
            }
        }
    }, [props, saveResource, setErrors, setFieldValue, updateResource, user?.companyId, user?.organizationId])

    const handleClose = useCallback(async (option?: string) => {
        const opt = (option ?? SaveOptions.Save) as SaveOptions;
        setAfterSaveOption(opt);
        await save(values, opt);
    }, [values, save]);

    const calculateAmount = useCallback((activityResource: CompositeResourceView) => {
        if (!activityResource.quantity || !activityResource.rate) return;

        let amount = 0;
        if (activityResource.factor) {
            amount = activityResource.quantity * activityResource.rate * activityResource.factor;
        } else {
            amount = activityResource.quantity * activityResource.rate;
        }
        return amount;

    }, [])

    useEffect(() => {
        if (currentCompositeResources && currentCompositeResources.length > 0) {
            let total = 0;
            currentCompositeResources.forEach((det: any) => {
                const amount = calculateAmount(det);
                if (amount) {
                    total += amount;
                }
            });
            if (values.basicRate) {
                if (values.resourceFactor) {
                    setRate((total + (parseFloat(values.basicRate.toString()) * parseFloat(values.resourceFactor.toString()))).toString());
                } else {
                    setRate((total + parseFloat(values.basicRate.toString())).toString());
                }
            }
        }
    }, [calculateAmount, currentCompositeResources, values.basicRate, values.resourceFactor])

    useEffect(() => {
        let factor = 1;
        if (values.resourceFactor) {
            factor = values.resourceFactor;
        }
        if (values.basicRate) {
            const rate = parseFloat(values.basicRate.toString()) * factor;
            setRate(rate.toString());
        }
        else {
            setRate(undefined);
        }
    }, [values.basicRate, values.resourceFactor])

    const onResourceEditing = useCallback((state: boolean) => {
        setFieldDisabled(state);
    }, [])

    const onEnter = useCallback((event: React.KeyboardEvent) => {
        if (
            (categoryRef.current &&
                categoryRef.current.contains(event.target as HTMLElement)) ||
            (unitRef.current &&
                unitRef.current.contains(event.target as HTMLElement)) || event.key !== 'Enter'
        ) {
            return;
        }
        handleSubmit();
    }, [handleSubmit])

    const unitComparer = useCallback((option: UnitView, value: UnitView) => {
        return option.unitId === value.unitId;
    }, [])

    const handleModalClose = () => setModalOpen(false);

    const onClosingModal = useCallback((result?: { total?: number, calculatedResourceId: string }) => {
        if (result) {
            setFieldValue('basicRate', result.total);
            setNewCalculatedResult(result);
        }
        handleModalClose();
    }, [setFieldValue])

    const onRateChanged = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
        try {
            if ((newCalculatedResult) || (storedCalculatedResource && user)) {
                handleChange(event);
                await confirm({ cancellationText: "No, cancel", confirmationText: "Yes, continue", description: "The basic rate of this resource is calculated. Do you want to override the calculation?", title: 'Change confirmation' });
                if (user) {
                    if (newCalculatedResult) {
                        await deleteCalculatedResource({
                            calculatedResourceId: newCalculatedResult.calculatedResourceId,
                            companyId: user.companyId,
                            estimateId: props.estimateId,
                            orgId: user.organizationId,
                            resourceId: resource?.id
                        });
                        setNewCalculatedResult(undefined);
                    }
                    else if (storedCalculatedResource) {
                        await deleteCalculatedResource({
                            calculatedResourceId: storedCalculatedResource.id,
                            companyId: user.companyId,
                            estimateId: props.estimateId,
                            orgId: user.organizationId,
                            resourceId: storedCalculatedResource.resourceId
                        });
                    }
                }
            } else {
                handleChange(event);
            }
        } catch (error) {
            if (newCalculatedResult) {
                setFieldValue('basicRate', newCalculatedResult.total)
            } else {
                setFieldValue('basicRate', props.resource?.basicRate)
            }

            event.stopPropagation();
        }
    }, [confirm, deleteCalculatedResource, handleChange, newCalculatedResult, props.estimateId, props.resource?.basicRate, resource?.id, setFieldValue, storedCalculatedResource, user])

    const getContainerHeight = useCallback(() => {
        if (!isValid) {
            return pageError ? 'calc(100% - 78px)' : 'calc(100% - 20px)';
        }

        return pageError ? 'calc(100% - 58px)' : '100%';
    }, [isValid, pageError]);


    const handleModalOpen = useCallback(async () => {
        if (!values.id) {
            await save(values, SaveOptions.Save);
        }
        setModalOpen(true);
    }, [save, values])

    return <>{colors && <Box height={getContainerHeight()}>
        <form onSubmit={handleSubmit} onKeyDown={onEnter} noValidate>
            <Box display="flex" justifyContent="space-between">
                <Button sx={{ marginTop: "10px", marginBottom: "10px" }} startIcon={<ArrowBackIosIcon />} onClick={props.backToList}>
                    Back to list
                </Button>
                <Box marginTop="10px" marginBottom="10px">
                    <SplitButton disabled={editDisabledRef.current} buttonIcon={<SaveIcon />} options={[{ option: SaveOptions.SaveAndAddAnother, disabled: false }, { option: SaveOptions.SaveAndClose, disabled: false }]} buttonText="Save" onButtonClick={handleClose} onMenuItemClick={handleClose} />
                </Box>
            </Box>
            {pageError && <Box marginBottom="10px"><Alert severity="error">{pageError}</Alert></Box>}
            <Box display="grid" gridTemplateColumns={props.panelState.position.state === "full" ? "repeat(5, 1fr)" : "repeat(4, 1fr)"} gridTemplateRows="repeat(2, 1fr)" gap="10px">
                <Box display="flex" flexDirection="column">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>ID</label>
                    <TextField
                        size="small"
                        id="displayId"
                        name="displayId"
                        autoComplete="off"
                        placeholder="ID"
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.displayId}
                        error={touched.displayId && Boolean(errors.displayId)}
                        helperText={touched.displayId && errors.displayId} />
                </Box>
                <Box display="flex" flexDirection="column" marginBottom="15px" gridColumn="span 2 / span 2">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Description</label>
                    <TextField
                        size="small"
                        id="description"
                        name="description"
                        placeholder="Description"
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.description && Boolean(errors.description)}
                        helperText={touched.description && errors.description}
                        value={values.description} />
                </Box>
                {resourceSubCategoryOptions && resourceSubCategoryOptions.length > 0 && <Box style={{ gridColumnStart: "4" }} display="flex" flexDirection="column" marginBottom="15px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Category</label>
                    <Autocomplete
                        size="small"
                        value={values.subCategory ?? null}
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        options={resourceSubCategoryOptions}
                        groupBy={(option) => option.resourceCategoryName}
                        getOptionLabel={(option) => option.description ?? ''}
                        renderInput={(params) => <TextField name="subCategoryId" placeholder='Select Category' {...params} />}
                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                        }}
                        ref={categoryRef}
                        onChange={(event, value) => {
                            if (value) {
                                setFieldValue('subCategory', value);
                                setFieldValue('subCategoryId', value.id);
                            } else {
                                setFieldValue('subCategory', undefined);
                                setFieldValue('subCategoryId', undefined);
                            }
                        }}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.description ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.description ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                </Box>}
                {unitViews && unitViews.length > 0 && <Box display="flex" flexDirection="column" marginBottom="15px" style={{ gridColumnStart: "1", gridRowStart: "2" }}>
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Unit</label>
                    <Autocomplete
                        fullWidth
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        size="small"
                        autoHighlight
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        getOptionLabel={(option) => option.unitDescription ?? ''}
                        onChange={(event, value) => {
                            setFieldValue('unit', value);
                        }}
                        ref={unitRef}
                        options={unitViews}
                        isOptionEqualToValue={unitComparer}
                        value={values.unit ?? null}
                        renderInput={(params) => <TextField name="unit" sx={{ height: "100%" }} placeholder='Select/Enter Unit' {...params} />}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.unitDescription ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.unitDescription ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                </Box>}
                <Box display="flex" flexDirection="column" style={{ gridColumnStart: "2", gridRowStart: "2" }}>
                    <Box display="flex" flexDirection="column" width="100%">
                        <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Basic Rate</label>
                        <Box display="flex" width="100%">
                            <TextField
                                InputProps={{
                                    type: 'number'
                                }}
                                size="small"
                                id="basicRate"
                                name="basicRate"
                                autoComplete="off"
                                fullWidth
                                placeholder="Basic Rate"
                                disabled={fieldDisabled || editDisabledRef.current}
                                error={touched.basicRate && Boolean(errors.basicRate)}
                                helperText={touched.basicRate && errors.basicRate}
                                onChange={onRateChanged}
                                onBlur={handleBlur}
                                value={values.basicRate} />
                            <Box display="flex" alignItems="center">
                                <IconButton aria-label="open modal" onClick={handleModalOpen} disabled={!isValid}>
                                    <CalculateOutlinedIcon />
                                </IconButton>
                                {values.isSupplierRate && <Tooltip title={`Supplier: ${values.supplierName}`}>
                                    <IconButton>
                                        <LocalShippingOutlinedIcon />
                                    </IconButton>
                                </Tooltip>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" style={{ gridColumnStart: "3", gridRowStart: "2" }}>
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Factor</label>
                    <TextField
                        size="small"
                        id="resourceFactor"
                        name="resourceFactor"
                        autoComplete="off"
                        InputProps={{
                            type: 'number'
                        }}
                        placeholder="Factor"
                        disabled={fieldDisabled || editDisabledRef.current}
                        error={touched.resourceFactor && Boolean(errors.resourceFactor)}
                        helperText={touched.resourceFactor && errors.resourceFactor}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.resourceFactor} />
                </Box>
                <Box display="flex" flexDirection="column" style={{ gridColumnStart: "4", gridRowStart: "2" }}>
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Rate</label>
                    <TextField
                        size="small"
                        InputProps={{ readOnly: true }}
                        value={rate} />
                </Box>
                {props.panelState.position.state === "full" && <Box flexDirection="column" gridRow="span 2 / span 2" style={{ gridColumnStart: "5", gridRowStart: "1" }}>
                    <Box display="flex" flexDirection="column" flexGrow="1">
                        <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Remarks</label>
                        <TextField
                            sx={{ flexGrow: 1, height: "100%" }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            multiline
                            error={touched.remarks && Boolean(errors.remarks)}
                            helperText={touched.remarks && errors.remarks}
                            id="remarks"
                            name="remarks"
                            disabled={fieldDisabled || locked || editDisabledRef.current}
                            rows={5}
                            placeholder="Remarks"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.remarks}
                        />
                    </Box>
                </Box>}
            </Box>
        </form>
        {resource?.id && <AddEditCompositeResource panelState={props.panelState} onEdit={onResourceEditing} currentResource={values} estimateId={props.estimateId} setCompositeResources={setCurrentCompositeResources} />}
        {!resource?.id && <Box display="flex" alignItems="center" sx={{ background: colors.gray[1000] }} justifyContent="center" width="100%" height="calc(100% - 195px)">Please save the resource to add composite resources.</Box>}
        {resource?.id && <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...style }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    marginBottom="10px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Basic Rate Calculation</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="delete" onClick={handleModalClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <CalculatedResourceModal disabled={!!editDisabledRef.current} unit={values.unit?.unitDescription} estimateId={props.estimateId} close={onClosingModal} resourceId={resource.id} />
            </Box>
        </Modal>}
    </Box>}</>;
}