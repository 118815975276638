import { useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { Box, Tooltip } from '@mui/material';
import { IndirectItemView } from 'Models/indirect-item';
import { useGetIndirectItemCategoriesQuery } from 'State/Services/indirect-item-category';

export interface IndirectItemCategoryCellRendererProps extends ICellRendererParams<IndirectItemView> {
    estimateId: string;
    level: number;
}

export default function IndirectItemCategoryCellRenderer(props: IndirectItemCategoryCellRendererProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedCategories } = useGetIndirectItemCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId, refetchOnMountOrArgChange: true });
    const [title, setTitle] = useState('');

    useEffect(() =>{
     if (storedCategories && storedCategories.length>0){
        const cat = storedCategories.find((c) => (c.displayId===props.value && c.level===props.level));
        if (cat){
            setTitle(cat.category);
        }
     }
    }, [props.level, props.value, storedCategories])

    return (
        <Tooltip title={title}>
            <Box>{props.value}</Box>
        </Tooltip>
    );
};