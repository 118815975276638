import {
    forwardRef,
    useState,
} from 'react';
import { ICellEditorParams, IRowNode } from 'ag-grid-community';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { UserInfoView } from 'Models/user-details';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

export interface SAaveCancelUserRoleParams extends ICellEditorParams<UserInfoView> {
    save: (node: IRowNode<UserInfoView>) => Promise<void>;
    cancel: (node: IRowNode<UserInfoView>) => void;
}

export default forwardRef((props: SAaveCancelUserRoleParams, ref) => {
        const { node } = props;
        const [isSaving, setIsSaving] = useState(false);

        const deleteRec = async () => {
            props.cancel(node);
        }

        const saveRec = async () => {
            try {
                setIsSaving(true);
                await props.save(node);
                setIsSaving(false);
            } catch (error) {
                setIsSaving(false);
            }
        }

        return (
            <Box display="flex" alignItems="center" justifyContent="center">
                {!isSaving &&
                    <IconButton aria-label="save" color="primary" onClick={saveRec}>
                        <SaveIcon />
                    </IconButton>
                }
                {isSaving &&
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
                {!isSaving &&
                    <span>
                        <IconButton aria-label="delete" onClick={deleteRec}>
                            <CloseIcon />
                        </IconButton>
                    </span>
                }

            </Box>
        );
    });
