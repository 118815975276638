import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'handsontable/dist/handsontable.full.min.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { Provider } from 'react-redux';
import { store } from 'State/store';
import { Config, configManager } from 'Helpers/config-manager';
import { registerAllModules } from 'handsontable/registry';
import { loadOutseta } from 'outseta';
import { PostHogProvider } from 'posthog-js/react'
import PostHogPageView from 'PageView';
import {
  ModuleRegistry,
  ColumnAutoSizeModule,
  PinnedRowModule,
  RowAutoHeightModule,
  RowStyleModule,
  PaginationModule,
  RowDragModule,
  CellStyleModule,
  TextFilterModule,
  NumberFilterModule,
  DateFilterModule,
  CustomFilterModule,
  ExternalFilterModule,
  QuickFilterModule,
  RowSelectionModule,
  TextEditorModule,
  LargeTextEditorModule,
  SelectEditorModule,
  NumberEditorModule,
  DateEditorModule,
  CheckboxEditorModule,
  CustomEditorModule,
  LocaleModule,
  DragAndDropModule,
  ValueCacheModule,
  GridStateModule,
  ColumnApiModule,
  RowApiModule,
  CellApiModule,
  ScrollApiModule,
  RenderApiModule,
  EventApiModule,
  ClientSideRowModelApiModule,
  provideGlobalGridOptions,
  ClientSideRowModelModule,
  ValidationModule,
} from 'ag-grid-community';
import {
  SetFilterModule,
  MultiFilterModule,
  AdvancedFilterModule,
  CellSelectionModule,
  RichSelectModule,
  RowGroupingModule,
  RowGroupingPanelModule,
  GroupFilterModule,
  TreeDataModule,
  SideBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  ColumnMenuModule,
  ContextMenuModule,
  ExcelExportModule,
  ServerSideRowModelApiModule,
  ViewportRowModelModule,
  LicenseManager,
  ServerSideRowModelModule
} from 'ag-grid-enterprise';
import { PostHogConfig } from 'posthog-js';

ModuleRegistry.registerModules([
  ColumnAutoSizeModule,
  PinnedRowModule,
  RowAutoHeightModule,
  RowStyleModule,
  PaginationModule,
  RowDragModule,
  CellStyleModule,
  TextFilterModule,
  NumberFilterModule,
  DateFilterModule,
  CustomFilterModule,
  ExternalFilterModule,
  QuickFilterModule,
  RowSelectionModule,
  TextEditorModule,
  LargeTextEditorModule,
  SelectEditorModule,
  NumberEditorModule,
  DateEditorModule,
  CheckboxEditorModule,
  CustomEditorModule,
  LocaleModule,
  DragAndDropModule,
  ValueCacheModule,
  GridStateModule,
  ColumnApiModule,
  RowApiModule,
  CellApiModule,
  ScrollApiModule,
  RenderApiModule,
  EventApiModule,
  ClientSideRowModelApiModule,
  SetFilterModule,
  MultiFilterModule,
  AdvancedFilterModule,
  CellSelectionModule,
  RichSelectModule,
  RowGroupingModule,
  RowGroupingPanelModule,
  GroupFilterModule,
  TreeDataModule,
  SideBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  ColumnMenuModule,
  ContextMenuModule,
  ExcelExportModule,
  ServerSideRowModelApiModule,
  ViewportRowModelModule,
  ClientSideRowModelModule,
  ValidationModule,
  ServerSideRowModelModule
]);

registerAllModules();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

fetch('/config.json').then((res) => res.json()).then(async (config: Config) => {
  configManager.setConfig(config);
  const options: Partial<PostHogConfig> = {
    api_host: config.posthog.host,
    person_profiles: 'identified_only' as "identified_only" | "always" | "never" | undefined,
    capture_pageview: false,
    session_recording: {
      maskTextSelector: "*"
    }
  }

  provideGlobalGridOptions({ theme: "legacy"});
  LicenseManager.setLicenseKey(config.agGridLicenseKey);
  await loadOutseta(config.outseta.domain);
  root.render(
    <React.StrictMode>
      <PostHogProvider apiKey={config.posthog.apiKey} options={options}>
        <Provider store={store}>
          <BrowserRouter>
            <Auth0ProviderWithNavigate>
              <App />
              <PostHogPageView />
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </Provider>
      </PostHogProvider>
    </React.StrictMode>
  );
});

