import { Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, Typography, useTheme } from "@mui/material";
import { useCallback, useState } from "react";
import { tokens } from "theme";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { useRefreshEstimateMutation } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 2,
};

export interface RefreshFromTemplateProps {
    close: () => void;
    estimateId?: string;
    templateEstimateId?: string;
}


export default function RefreshFromTemplate(props: RefreshFromTemplateProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: user } = useGetUserDetailsQuery();
    const [checkEstimateResources, setCheckEstimateResources] = useState<boolean>(false);
    const [checkEstimateExcludeResourceFactor, setCheckEstimateExcludeResourceFactor] = useState<boolean>(false);
    const [checkEstimateActivities, setCheckEstimateActivities] = useState<boolean>(false);
    const [checkEstimateVendors, setCheckEstimateVendors] = useState<boolean>(false);
    const [checkEstimateItems, setCheckEstimateItems] = useState<boolean>(false);
    const [checkEstimateDirect, setCheckEstimateDirect] = useState<boolean>(false);
    const [checkEstimateIndirect, setCheckEstimateIndirect] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState(false);
    const [refreshFromTemplate] = useRefreshEstimateMutation();
    
    const handleCheckedEstimateResources = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateResources(event.target.checked);
        if (!event.target.checked) {
            setCheckEstimateExcludeResourceFactor(false);
            setCheckEstimateActivities(false);
            setCheckEstimateItems(false);
            setCheckEstimateDirect(false);
            setCheckEstimateIndirect(false);
        }
    }, [])

    const handleCheckedEstimateExcludeResourceFactor = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateExcludeResourceFactor(event.target.checked);
    }, [])

    const handleCheckedEstimateActivities = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateActivities(event.target.checked);
        if (event.target.checked) {
            setCheckEstimateResources(true);
        } else {
            setCheckEstimateItems(false);
            setCheckEstimateDirect(false);
            setCheckEstimateIndirect(false);
        }
    }, [])

    const handleCheckedEstimateItems = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateItems(event.target.checked);
        if (event.target.checked) {
            setCheckEstimateResources(true);
            setCheckEstimateActivities(true);
        } else {
            setCheckEstimateDirect(false);
            setCheckEstimateIndirect(false);
        }
    }, [])

    const handleCheckedEstimateVendors = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckEstimateVendors(event.target.checked);
    }, [])

    const handleClose = useCallback(() => {
        props.close();
    }, [props])

    const submit = useCallback(async () =>{
        setIsSaving(true);
        if (user){
            await refreshFromTemplate({
                body: {
                    resources: checkEstimateResources,
                    activities: checkEstimateActivities,
                    items: checkEstimateItems,
                    excludeResourceFactor: checkEstimateExcludeResourceFactor,
                    vendors: checkEstimateVendors
                },
                companyId: user.companyId,
                estimateId: props.estimateId,
                fromEstimateId: props.templateEstimateId,
                orgId: user.organizationId
            });
            setIsSaving(false);
            props.close();
        }
    }, [checkEstimateActivities, checkEstimateExcludeResourceFactor, checkEstimateItems, checkEstimateResources, checkEstimateVendors, props, refreshFromTemplate, user])

    return <Box sx={style}>
        <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            marginBottom="10px"
            borderBottom={`1px solid ${colors?.gray[800]}`}>
            <Box padding="10px">
                {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Create new from template</Typography>}
            </Box>
            <Box>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>

        <Box flex="1" display="flex" flexDirection="column">
            <Typography variant="h6" sx={{ fontWeight: "500" }} color={colors.blueAccent[200]}>Which Elements?</Typography>
            <Box display="flex" flexWrap="wrap">
                <FormControlLabel control={<Checkbox checked={checkEstimateResources} onChange={handleCheckedEstimateResources} />} label="Resources" />
                <FormControlLabel control={<Checkbox checked={checkEstimateActivities} onChange={handleCheckedEstimateActivities} />} label="Activities" />
                <FormControlLabel control={<Checkbox checked={checkEstimateItems} onChange={handleCheckedEstimateItems} />} label="Items" />
                <FormControlLabel control={<Checkbox checked={checkEstimateVendors} onChange={handleCheckedEstimateVendors} />} label="Vendors" />
            </Box>
            {(checkEstimateResources || checkEstimateDirect || checkEstimateIndirect) && <Box display="flex" flexWrap="wrap" padding="10px" sx={{ background: colors.gray[1100] }}>
                {checkEstimateResources && <FormControlLabel control={<Checkbox checked={checkEstimateExcludeResourceFactor} onChange={handleCheckedEstimateExcludeResourceFactor} />} label="Exclude Resource Factors" />}
            </Box>}

            <Box display="flex" justifyContent="center" width="100%" gap="10px" marginTop="10px">
                <Button onClick={submit} color="primary" variant="contained" type="submit" startIcon={!isSaving ? <SaveIcon /> : <CircularProgress sx={{ color: "white" }} size="1rem" />}>
                    Submit
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                    Cancel
                </Button>
            </Box>
        </Box>
    </Box>
}