import { Box, Button, Card, useTheme } from "@mui/material";
import { rounder } from "Helpers/rounder";
import { CategoryTotal } from "Models/report";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef, Column, GridApi, IRowNode, SideBarDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { format } from "date-fns";
import { Pie } from 'react-chartjs-2';
import { ChartData } from "chart.js";
import { getChartData } from "Helpers/chart";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import fullLogo from 'Images/logo.png';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export interface ProjectResourceLevel1Props {
    categoryTotals: Array<CategoryTotal>;
    estimateId: string | undefined;
}
// Row colors
const HEADER_ROW_COLOR = '#f8f8f8';
const EVEN_ROW_COLOR = '#fcfcfc';
const ODD_ROW_COLOR = '#E6E6E6';

const PDF_INNER_BORDER_COLOR = '#dde2eb';
const PDF_OUTER_BORDER_COLOR = '#babfc7';

export default function ProjectResourceLevel1(props: ProjectResourceLevel1Props) {

    const [rowData, setRowData] = useState<Array<CategoryTotal>>([]);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const groupDefaultExpanded = -1;
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridRef = useRef<AgGridReact<CategoryTotal>>(null);
    const [chartEstQuantityData, setChartEstQuantityData] = useState<ChartData<"pie", number[], string>>();
    const [chartQuantityData, setChartQuantityData] = useState<ChartData<"pie", number[], string>>();
    const chartByQuantityRef = useRef<ChartJSOrUndefined<"pie", number[], string>>();
    const chartByEstQuantityRef = useRef<ChartJSOrUndefined<"pie", number[], string>>();
    const [quantityOptions] = useState({
        maintainAspectRatio: true,
        responsive: true,
        devicePixelRatio: 2,
        plugins: {
            title: {
                display: true,
                text: 'Resources by quantity', // Set your title text
                font: {
                    size: 18,
                    weight: 'bold' as 'bold',
                },
                color: '#333',
                padding: {
                    top: 10,
                    bottom: 20,
                },
                align: 'center' as 'center',
            },
            legend: {
                position: 'top' as 'top', // Position of legend
            },
        }
    });
    const [estQuantityOptions] = useState({
        maintainAspectRatio: true,
        responsive: true,
        devicePixelRatio: 3,
        plugins: {
            title: {
                display: true,
                text: 'Resources by estimated quantity', // Set your title text
                font: {
                    size: 18,
                    weight: 'bold' as 'bold',
                },
                color: '#333',
                padding: {
                    top: 10,
                    bottom: 20,
                },
                align: 'center' as 'center',
            },
            legend: {
                position: 'top' as 'top', // Position of legend
            },
        }
    });
    const [dataTypeDefinitions] = useState<any>({
        amount: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => {
                return rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2);
            }
        }
    });

    const onGridReady = useCallback(() => {
        setRowData([...props.categoryTotals]);
        const chartEstQuantityInfo = props.categoryTotals.map(ct => ({ label: ct.description, data: ct.estimatedQuantityPercentage ? parseFloat(ct.estimatedQuantityPercentage.replace('%', '')) : 0 }));
        const chartQuantityInfo = props.categoryTotals.map(ct => ({ label: ct.description, data: ct.quantityPercentage ? parseFloat(ct.quantityPercentage.replace('%', '')) : 0 }));
        const chartEstData = getChartData('Project Resources by Category', chartEstQuantityInfo);
        setChartEstQuantityData(chartEstData);
        const chartQuantityData = getChartData('Project Resources by Category', chartQuantityInfo);
        setChartQuantityData(chartQuantityData);
    }, [props.categoryTotals])

    const getRowStyle = (params: any) => {
        if (params.node.group) {
            return { fontWeight: 'bold' };
        }
    };

    const [columnDefs] = useState<Array<ColDef<CategoryTotal>>>([
        {
            field: 'displayId',
            headerName: 'ID',
            hide: false,
            cellRenderer: 'agGroupCellRenderer',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            width: 150,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            showRowGroup: true,
        },
        {
            field: 'description',
            flex: 1,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'quantityPercentage',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            headerName: 'Quantity',
            width: 175,
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'estimatedQuantityPercentage',
            headerName: 'Estimated Quantity',
            width: 175,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'amount',
            width: 175,
            enableValue: true,
            aggFunc: 'sum',
            cellDataType: 'amount',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'estimatedAmount',
            headerName: 'Estimated Amount',
            width: 175,
            aggFunc: 'sum',
            cellDataType: 'amount',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
    ]);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const columnToolPanelParams = useMemo<SideBarDef>(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        };
    }, []);


    /**border: solid 12px #1188E6;
     * This function iterates over all of the columns to create a row of header cells
     */
    const getHeaderToExport = useCallback((gridApi: GridApi<CategoryTotal>) => {
        const columns = gridApi.getAllDisplayedColumns();

        return columns.map((column) => {
            const { field, sort } = column.getColDef();
            let headerCell = { text: '', bold: false, margin: [] as Array<number>, alignment: '' };
            if (field) {
                // Enables export when row grouping
                const headerName = column.getColDef().headerName ?? field;
                const headerNameUppercase =
                    headerName[0].toUpperCase() + headerName.slice(1);
                const cellStyle = column.getColDef().cellStyle;
                headerCell = {
                    text: headerNameUppercase + (sort ? ` (${sort})` : ''),
                    alignment: ((cellStyle && 'textAlign' in cellStyle) && cellStyle?.textAlign) ? cellStyle?.textAlign as string : 'left',
                    // styles
                    bold: true,
                    margin: [0, 12, 0, 0],
                };
            }

            return headerCell;
        });
    }, []);

    /**
     * This function iterates over all of the rows and columns to create
     * a matrix of cells
     */
    const getRowsToExport = useCallback((gridApi: GridApi<CategoryTotal>) => {
        const columns = gridApi.getAllDisplayedColumns();

        const getCellToExport = (column: Column<CategoryTotal>, node: IRowNode<CategoryTotal>) => {
            let cellText = gridApi.getCellValue({
                rowNode: node,
                colKey: column
            });
            switch (column.getColDef().cellDataType) {
                case "amount":
                    cellText = rounder(cellText, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)
                    break;
                default:
                    break;
            }
            const cellStyle = column.getColDef().cellStyle;
            return {
                text: cellText,
                alignment: ((cellStyle && 'textAlign' in cellStyle) && cellStyle?.textAlign) ?? 'left',
                fontSize: 10,
                // styles
                ...column.getColDef().cellStyle,
            };
        };

        const rowsToExport = new Array<{
            text: string | CategoryTotal;
        }[]>();

        gridApi.forEachNodeAfterFilterAndSort((node) => {
            const rowToExport = columns.map((column) => getCellToExport(column, node));
            rowsToExport.push(rowToExport as any);
        });

        return rowsToExport;
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit]);

    /**
     * This function iterates over all of the rows and columns to create
     * a matrix of cells
     */
    const getSummaryRow = useCallback((gridApi: GridApi<CategoryTotal>) => {
        const quantitySum = `${props.categoryTotals.reduce((sum, item) => sum + parseFloat(item.quantityPercentage?.replace('%', '') || '0'), 0)}%`
        const estimatedQuantitySum = `${props.categoryTotals.reduce((sum, item) => sum + parseFloat(item.estimatedQuantityPercentage?.replace('%', '') || '0'), 0)}%`
        const totalAmount = props.categoryTotals.reduce((sum, item) => sum + item.amount, 0);
        const totalEstimatedAmount = props.categoryTotals.reduce((sum, item) => sum + item.estimatedAmount, 0);
        const rowsToExport = [[{
            colSpan: 2,
            text: 'Project Total Resources (Direct and Indirect) =',
            alignment: 'right'
        },
        {},
        {
            text: rounder(parseFloat(quantitySum), (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
            alignment: 'right'
        },
        {
            text: rounder(parseFloat(estimatedQuantitySum), (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
            alignment: 'right'
        },
        {
            text: rounder(totalAmount, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
            alignment: 'right'
        },
        {
            text: rounder(totalEstimatedAmount, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
            alignment: 'right'
        }]];
        return rowsToExport;
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit, props.categoryTotals]);

    const createLayout = useCallback((numberOfHeaderRows: number) => ({
        fillColor: (rowIndex: number) => {
            if (rowIndex < numberOfHeaderRows) {
                return HEADER_ROW_COLOR;
            }
            return rowIndex % 2 === 0 ? EVEN_ROW_COLOR : ODD_ROW_COLOR;
        },
        paddingTop: function (rowIndex: number, node: any) {
            return rowIndex === 0 ? 2 : 8;
        },
        paddingBottom: function (rowIndex: number, node: any) {
            return rowIndex === 0 ? 2 : 8;
        },
        //vLineHeight not used here.
        vLineWidth: (rowIndex: number, node: any) =>
            rowIndex === 0 || rowIndex === node.table.widths.length ? 1 : 0,
        hLineColor: (rowIndex: number, node: any) =>
            rowIndex === 0 || rowIndex === node.table.body.length
                ? PDF_OUTER_BORDER_COLOR
                : PDF_INNER_BORDER_COLOR,
        vLineColor: (rowIndex: number, node: any) =>
            rowIndex === 0 || rowIndex === node.table.widths.length
                ? PDF_OUTER_BORDER_COLOR
                : PDF_INNER_BORDER_COLOR,
    }), []);

    const convertImageToBase64 = useCallback(async () => {
        return new Promise((resolve, reject) => {
            try {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.src = fullLogo;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    const ctx = canvas.getContext('2d');
                    ctx!.fillStyle = 'white';
                    ctx!.fillRect(0, 0, canvas.width, canvas.height);
                    ctx!.drawImage(img, 0, 0);
                    const base64data = canvas.toDataURL('image/png', 5.0);
                    resolve(base64data);
                };
            } catch (error) {
                reject(error);
            }
        });
    }, [])

    /** 
    * This function returns a PDF document definition object - the input for pdfMake.
    */
    const getDocument = useCallback(async (gridApi: GridApi<CategoryTotal>): Promise<TDocumentDefinitions> => {
        const headerRow = getHeaderToExport(gridApi);
        const rows = getRowsToExport(gridApi);
        const imagebase = await convertImageToBase64();
        const summaryRow = getSummaryRow(gridApi);
        const chartByQuantityBase64 = chartByQuantityRef.current?.toBase64Image();
        const chartByEstQuantityBase64 = chartByEstQuantityRef.current?.toBase64Image();
        return {
            pageOrientation: 'portrait' as any, // can also be 'portrait'
            pageMargins: [10, 20] as any,
            content: [
                {
                    style: 'header',
                    columns: [
                        {
                            image: imagebase as string,
                            width: 196,
                            height: 60

                        },
                    ]
                },
                {
                    columns: [
                        {
                            text: `Project: ${estimate?.name}`,
                            style: 'headerInfo'
                        },
                        {
                            text: `Date: ${format(new Date().getTime(), 'MM/dd/yyyy')}`,
                            style: 'headerDate'
                        }
                    ]
                },
                // {
                //     columns: [
                //         {
                //             text: `Currency: ${estimate?.CompanyCurrency?.Currency?.currency}`,
                //             style: 'headerInfo'
                //         },
                //         {
                //             text: `Total Built Up Area: ${estimate?.area} sq km`,
                //             style: 'headerInfo'
                //         }
                //     ]
                // },
                {
                    table: {
                        // the number of header rows
                        headerRows: 1,

                        // the width of each column, can be an array of widths
                        widths: [20, '*', 75, 75, 120, 120],
                        // widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        // all the rows to display, including the header rows
                        body: [headerRow, ...rows, ...summaryRow],

                        // Header row is 40px, other rows are 15px
                        heights: (rowIndex: number) => (rowIndex === 0 ? 40 : 15),
                    },
                    layout: createLayout(1),
                },
                {
                    columnGap: 20,
                    marginTop: 20,
                    columns: [
                        { image: chartByQuantityBase64 as string, cover: { width: 260, height: 260, valign: "center", align: "center" }, alignment: 'center' },
                        { image: chartByEstQuantityBase64 as string, cover: { width: 260, height: 260, valign: "center", align: "center" }, alignment: 'center' },
                    ],
                },
            ],
            styles: {
                headerDate: {
                    alignment: 'right' as any,
                },
                header: {
                    marginBottom: 20,
                },
                headerInfo: {
                    marginTop: 5,
                    marginBottom: 15,
                    fontSize: 12,
                    alignment: 'left'
                }
            },
            defaultStyle: {
                columnGap: 20
            }

        };
    }, [convertImageToBase64, createLayout, estimate?.name, getHeaderToExport, getRowsToExport, getSummaryRow]);

    const exportToPDF = useCallback(async () => {
        if (gridRef.current!.api) {
            const doc = await getDocument(gridRef.current!.api);
            pdfMake.createPdf(doc).download();
        }

    }, [getDocument]);

    return <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box display="flex" justifyContent="flex-end" gap="20px" marginBottom="20px">
            <Button variant="outlined" onClick={exportToPDF}>Download PDF</Button>
        </Box>
        <Box style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
            <Box display="flex" justifyContent="center" width="100%" marginBottom="20px">
                {chartQuantityData && <>
                        <Card sx={{ display: "flex", justifyContent: "center", width: "50%", height: "300px", margin: "20px", padding: "20px" }}>
                            <Pie ref={chartByQuantityRef} data={chartQuantityData} options={quantityOptions} />
                        </Card>
                    </>
                }
                {chartEstQuantityData && <>
                    <Card sx={{ display: "flex", justifyContent: "center", width: "50%", height: "300px", margin: "20px", padding: "20px" }}>
                        <Pie ref={chartByEstQuantityRef} data={chartEstQuantityData} options={estQuantityOptions} />
                    </Card>
                </>
                }
            </Box>
            <AgGridReact<CategoryTotal>
                dataTypeDefinitions={dataTypeDefinitions}
                rowData={rowData}
                ref={gridRef}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={columnToolPanelParams}
                suppressAggFuncInHeader={true}
                groupDefaultExpanded={groupDefaultExpanded}
                suppressClickEdit={true}
                getRowStyle={getRowStyle}
                grandTotalRow="bottom"
            />
        </Box>
    </Box>;
}