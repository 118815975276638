
import { FilterField } from 'Models/resource';
import { api } from './api'
import { AppendItemDto, AssignItem, BaseItem, DuplicateDetailDto, EntityNotFound, FilteredItem, ItemCalculated, ItemCreated, ItemDetail, ItemMinimalDto, QuickAddActivityDetailDto, QuickAddResourceDetailDto, QuickCreateActivityDetailDto, QuickCreateResourceDetailDto, QuickCreateStandardItemDetailDto, ReorderItemDetail } from 'Models/item';
import { Nav, NavItem } from 'Models/nav';
import { CustomError } from 'Models/error-info';

export const itemApi = api.injectEndpoints({
  endpoints: (build) => ({
    createItem: build.mutation<ItemCreated, Partial<{ body: BaseItem, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Items', 'EstimateCategories', 'ItemCodes', 'Estimates'],
    }),
    syncItemsForCode: build.mutation<{ count: number }, Partial<{ orgId: string, companyId: string, estimateId: string, itemId: string, code: string }>>({
      query({ orgId, companyId, estimateId, itemId, code }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/code/${code}/sync`,
          method: 'POST',
          body: {},
        }
      },
      invalidatesTags: ['Pricing', 'ItemDetails', 'Resources', 'Activities', 'StandardItems']
    }),
    getItemsByFilter: build.query<Array<FilteredItem>, { organizationId: string, companyId: string, estimateId: string, filterText: string, field: FilterField }>({
      query: ({ organizationId, companyId, filterText, estimateId, field }: { organizationId: string, companyId: string, estimateId: string, filterText: string, field: FilterField }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/field/${field}/filter/${btoa(filterText)}` }),
      transformResponse: (response: Array<FilteredItem>) => { return [...response] },
      providesTags: ['Items']
    }),
    getItemsByCategory1: build.query<Array<BaseItem>, { organizationId: string, companyId: string, estimateId: string, category1Id: number }>({
      query: ({ organizationId, companyId, estimateId, category1Id }: { organizationId: string, companyId: string, estimateId: string, category1Id: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/category1/${category1Id}` }),
      transformResponse: (response: Array<BaseItem>) => { return [...response] },
      providesTags: ['Items']
    }),
    getItemMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/max-id` }),
      providesTags: ['Items']
    }),
    getItemId: build.query<BaseItem, { organizationId: string, companyId: string, estimateId: string, itemId: string }>({
      query: ({ organizationId, companyId, estimateId, itemId }: { organizationId: string, companyId: string, estimateId: string, itemId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/${itemId}` }),
      providesTags: ['Items']
    }),
    getItemNav: build.query<Nav, { organizationId: string, companyId: string, estimateId: string, itemId: string }>({
      query: ({ organizationId, companyId, estimateId, itemId }: { organizationId: string, companyId: string, estimateId: string, itemId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/nav` }),
      providesTags: ['Items']
    }),
    getFilteredItemNavs: build.query<Array<NavItem>, { organizationId: string, companyId: string, estimateId: string, filterText: string }>({
      query: ({ organizationId, companyId, estimateId, filterText }: { organizationId: string, companyId: string, estimateId: string, filterText: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/filter-text/${btoa(filterText)}` }),
      providesTags: ['Items']
    }),
    getItemsByCategory2: build.query<Array<BaseItem>, { organizationId: string, companyId: string, estimateId: string, category1Id: number, category2Id: number }>({
      query: ({ organizationId, companyId, estimateId, category1Id, category2Id }: { organizationId: string, companyId: string, estimateId: string, category1Id: number, category2Id: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/category1/${category1Id}/category2/${category2Id}` }),
      transformResponse: (response: Array<BaseItem>) => { return [...response] },
      providesTags: ['Items']
    }),
    getItemsByCategory3: build.query<Array<BaseItem>, { organizationId: string, companyId: string, estimateId: string, category1Id: number, category2Id: number, category3Id: number }>({
      query: ({ organizationId, companyId, estimateId, category1Id, category2Id, category3Id }: { organizationId: string, companyId: string, estimateId: string, category1Id: number, category2Id: number, category3Id: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/category1/${category1Id}/category2/${category2Id}/category3/${category3Id}` }),
      transformResponse: (response: Array<BaseItem>) => { return [...response] },
      providesTags: ['Items']
    }),
    getItemsByCategory4: build.query<Array<BaseItem>, { organizationId: string, companyId: string, estimateId: string, category1Id: number, category2Id: number, category3Id: number, category4Id: number }>({
      query: ({ organizationId, companyId, estimateId, category1Id, category2Id, category3Id, category4Id }: { organizationId: string, companyId: string, estimateId: string, category1Id: number, category2Id: number, category3Id: number, category4Id: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/category1/${category1Id}/category2/${category2Id}/category3/${category3Id}/category4/${category4Id}` }),
      transformResponse: (response: Array<BaseItem>) => { return [...response] },
      providesTags: ['Items']
    }),
    getItemsPaged: build.query<Array<BaseItem & { hasResourcesActivities: boolean }>, { organizationId: string, companyId: string, estimateId: string, skip: number, take: number }>({
      query: ({ organizationId, companyId, skip, estimateId, take }: { organizationId: string, companyId: string, estimateId: string, skip: number, take: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/paged?skip=${skip}&take=${take}` }),
      transformResponse: (response: Array<BaseItem & { hasResourcesActivities: boolean }>) => { return [...response] },
      providesTags: ['Items']
    }),
    deleteItem: build.mutation<BaseItem, Partial<{ orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['Pricing', 'Items', 'Activities', 'Resources', 'StandardItems'],
    }),
    updateItem: build.mutation<ItemCreated, Partial<{ body: BaseItem, orgId: string, companyId: string, estimateId: string, itemId: string, clearCosts: boolean }>>({
      query({ body, orgId, companyId, estimateId, itemId, clearCosts }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}?clearCosts=${clearCosts}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Items', 'EstimateCategories', 'ItemCodes', 'Estimates'],
    }),
    assign: build.mutation<void, Partial<{ body: AssignItem, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/assign`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Items'],
    }),
    bulkUpdateItem: build.mutation<void, Partial<{ body: Array<ItemMinimalDto>, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/bulk`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Items', 'ItemCodes', 'Estimates'],
    }),
    deleteItems: build.mutation<ItemCreated, Partial<{ body: Array<string>, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/delete`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Items', 'Activities', 'Resources', 'StandardItems'],
    }),
    reorderItemDetails: build.mutation<void, Partial<{ body?: Array<ReorderItemDetail>, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/detail/re-order`,
          method: 'Put',
          body,
        }
      },
      invalidatesTags: ['ItemDetails'],
    }),
    createItemDetail: build.mutation<ItemDetail, Partial<{ body: ItemDetail, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/detail`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'ItemDetails', 'Resources', 'Activities', 'StandardItems', 'Items'],
    }),
    createFromEstimateItem: build.mutation<{errors: Array<CustomError>, notFound: Array<EntityNotFound>}, Partial<{ orgId: string, companyId: string, estimateId: string, itemId: string, fromEstimateId: string, fromItemId: string }>>({
      query({ orgId, companyId, estimateId, itemId, fromEstimateId, fromItemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/from-estimate/${fromEstimateId}/from-item/${fromItemId}`,
          method: 'POST',
          body: {},
        }
      },
      invalidatesTags: ['Pricing', 'ItemDetails', 'Resources', 'Activities', 'StandardItems', 'Items'],
    }),
    overrideItemActivityDetail: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, itemId: string, itemActivityId: string, resourceId: string }>>({
      query({ orgId, companyId, estimateId, itemId, itemActivityId, resourceId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/item-activity/${itemActivityId}/override?resourceId=${resourceId}`,
          method: 'POST',
          body: {},
        }
      },
      invalidatesTags: ['Pricing', 'ItemDetails', 'Items'],
    }),
    appendItemDetail: build.mutation<null, Partial<{ body: AppendItemDto, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/append-detail`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'ItemDetails', 'Resources', 'Activities', 'StandardItems', 'Items'],
    }),
    saveItemAsStandardItem: build.mutation<null, Partial<{ orgId: string, companyId: string, estimateId: string, itemId: string, overwrite: boolean }>>({
      query({ orgId, companyId, estimateId, itemId, overwrite }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/save-standard-item?overwrite=${overwrite}`,
          method: 'POST',
          body: {},
        }
      },
      invalidatesTags: ['StandardItems', 'StandardItemCategories', 'StandardItemActivityResources', 'Units'],
    }),
    updateItemDetail: build.mutation<ItemDetail, Partial<{ body: ItemDetail, orgId: string, companyId: string, estimateId: string, itemId: string, itemDetailId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId, itemDetailId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/detail/${itemDetailId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'ItemDetails', 'Items'],
    }),
    deleteItemDetail: build.mutation<ItemDetail, Partial<{ body: ItemDetail, orgId: string, companyId: string, estimateId: string, itemId: string, itemDetailId: string, type: string }>>({
      query({ body, orgId, companyId, estimateId, itemId, itemDetailId, type }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/detail/${itemDetailId}?type=${type}`,
          method: 'DELETE',
          body,
        }
      },
      invalidatesTags: ['SubPackages', 'Pricing', 'ItemDetails', 'Activities', 'Resources', 'StandardItems', 'Items'],
    }),
    getItemDetailsForItem: build.query<Array<ItemDetail>, { organizationId: string, companyId: string, estimateId: string, itemId: string }>({
      query: ({ organizationId, companyId, estimateId, itemId }: { organizationId: string, companyId: string, estimateId: string, itemId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/detail` }),
      providesTags: ['ItemDetails']
    }),
    createItemCalculated: build.mutation<ItemCalculated, Partial<{ body: ItemCalculated, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/calculated`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'ItemDetails', 'Items', 'ItemCalculated'],
    }),
    updateItemCalculated: build.mutation<ItemCalculated, Partial<{ body: ItemCalculated, orgId: string, companyId: string, estimateId: string, itemId: string, calculatedId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId, calculatedId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/calculated/${calculatedId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'ItemDetails', 'Items', 'ItemCalculated'],
    }),
    getItemCalculated: build.query<ItemCalculated, { organizationId: string, companyId: string, estimateId: string, itemId: string, entityType: 'resource' | 'activity' | 'subitem' | 'item' | 'subcontractor', entityId: string }>({
      query: ({ organizationId, companyId, estimateId, itemId, entityId, entityType }:
        { organizationId: string, companyId: string, estimateId: string, itemId: string, entityType: 'resource' | 'activity' | 'subitem' | 'item' | 'subcontractor', entityId: string }) => (
        {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/${entityType}/${entityId}/calculated`
        }),
      providesTags: ['ItemCalculated']
    }),
    quickCreateResourceDetail: build.mutation<void, Partial<{ body: QuickCreateResourceDetailDto, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/quick-create/resource`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities',
        'Pricing', 'CompositeResources',
        'Resources', 'IndirectItemDetails',
        'FilteredResources', "StandardItemActivityResources",
        'ItemDetails', 'Activities', 'ActivityResources',
        'StandardItems', 'Items', 'IndirectItems'] : [])
    }),
    quickCreateActivityDetail: build.mutation<void, Partial<{ body: QuickCreateActivityDetailDto, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/quick-create/activity`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'Activities', 'ActivityResources',
        'IndirectItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources",
        'ItemDetails', 'Items', 'IndirectItems'] : []),
    }),
    quickCreateStandardItemDetail: build.mutation<void, Partial<{ body: QuickCreateStandardItemDetailDto, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/quick-create/standard-item`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['Pricing', 'ItemDetails', 'IndirectItemDetails', 'StandardItems', 'Items', 'IndirectItems'] : []),
    }),
    quickAddResourceDetail: build.mutation<void, Partial<{ body: QuickAddResourceDetailDto, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/quick-add/resource`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities',
        'Pricing', 'CompositeResources',
        'Resources', 'IndirectItemDetails',
        'FilteredResources', "StandardItemActivityResources",
        'ItemDetails', 'Activities', 'ActivityResources',
        'StandardItems', 'Items', 'IndirectItems'] : []),
    }),
    quickAddActivityDetail: build.mutation<void, Partial<{ body: QuickAddActivityDetailDto, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/quick-add/activity`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'Activities', 'ActivityResources',
        'IndirectItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources",
        'ItemDetails', 'Items', 'IndirectItems'] : []),
    }),
    duplicateDetail: build.mutation<void, Partial<{ body: DuplicateDetailDto, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/detail/duplicate`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'Activities', 'ActivityResources',
        'IndirectItemDetails', "FilteredActivities", "StandardItems", "StandardItemActivityResources",
        'ItemDetails', 'Items', 'IndirectItems'] : []),
    }),
  })
});

export const {
  useCreateFromEstimateItemMutation,
  useDuplicateDetailMutation,
  useQuickAddActivityDetailMutation,
  useQuickAddResourceDetailMutation,
  useQuickCreateActivityDetailMutation,
  useQuickCreateResourceDetailMutation,
  useQuickCreateStandardItemDetailMutation,
  useOverrideItemActivityDetailMutation,
  useReorderItemDetailsMutation,
  useLazyGetFilteredItemNavsQuery,
  useGetItemNavQuery,
  useGetItemIdQuery,
  useLazyGetItemMaxIdQuery,
  useCreateItemCalculatedMutation,
  useUpdateItemCalculatedMutation,
  useLazyGetItemCalculatedQuery,
  useAssignMutation,
  useGetItemsByCategory1Query,
  useGetItemsByCategory2Query,
  useGetItemsByCategory3Query,
  useGetItemsByCategory4Query,
  useSyncItemsForCodeMutation,
  useSaveItemAsStandardItemMutation,
  useAppendItemDetailMutation,
  useGetItemsByFilterQuery,
  useCreateItemMutation,
  useUpdateItemMutation,
  useDeleteItemMutation,
  useDeleteItemsMutation,
  useBulkUpdateItemMutation,
  useCreateItemDetailMutation,
  useGetItemDetailsForItemQuery,
  useLazyGetItemsPagedQuery,
  useGetItemsPagedQuery,
  useUpdateItemDetailMutation,
  useDeleteItemDetailMutation
} = itemApi